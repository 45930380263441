import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  userEmail = localStorage.getItem('email');
  theYear = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
  }



  isUserLoggedIn(): string {
    return localStorage.getItem('email');
  }

}
