import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import {NotificationsModalComponent} from '../notifications-modal/notifications-modal.component';
import {LocationStrategy} from '@angular/common';
import {NotificationsService} from '../../notifications.service';
import {MatDialog} from '@angular/material/dialog';
import {DataSharingService} from '../../data-sharing.service';
import {AdminService} from "../../admin.service";
import {PasswordChangeComponent} from "../password-change/password-change.component";

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.css']
})
export class MenuBarComponent implements OnInit {

  mflGroup = localStorage.getItem('mflGroup');
  email = localStorage.getItem('email');
  baseHref: any;
  showUserSubmenu = false;
  notificationsRaw: any;
  mediaQueue: any;
  menuToggle = false;


  @ViewChild('submenuBtn') submenuBtn;


  constructor(private locationStrategy: LocationStrategy, private notificationService: NotificationsService,
              private dialog: MatDialog, private dataShare: DataSharingService) {
    document.addEventListener('click', this.offClickHandler.bind(this));
  }

  ngOnInit(): void {
    this.baseHref = this.locationStrategy.getBaseHref();
    // this.refreshNotifications();
    //
    // setInterval(() => {
    //   this.refreshNotifications();
    // }, 60000);
  }

  offClickHandler(event): void {
    if (!this.submenuBtn?.nativeElement?.contains(event.target)) { // check click origin
      this.showUserSubmenu = false;
    }
  }

  logout(): void {
    localStorage.clear();
    window.location.href = '/';
  }

  superUserModules(): boolean{
    if (localStorage.getItem('authorisation') === 'superuser'){
      return true;
    } else {
      return false;
    }
  }

  openApp(): void {
    window.location.href = environment.APP_URL;
  }

  refreshNotifications(): void {
    this.notificationService.fetchNotifications().subscribe(r => {
      this.dataShare.notificationsRaw.next(r);
      this.notificationsRaw = r;
      console.log('fetched notifications');
    });

    this.notificationService.fetchMediaQueue().subscribe(r => {
      this.dataShare.mediaQueue.next(r);
      this.mediaQueue = r;
      console.log('fetched media queue');
    });

  }

  showNotifications(): void {
    // this.refreshNotifications();
    this.dialog.open(NotificationsModalComponent, { closeOnNavigation: true, hasBackdrop: true, width: '90%', height: '80%'});
  }

  isUserLoggedIn(): string {
    return localStorage.getItem('email');
  }

  openChangePasswordModal(): void {
    this.dialog.open(PasswordChangeComponent, {closeOnNavigation: true, width: '50%', height: '35%', hasBackdrop: true});
  }

}

