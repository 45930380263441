import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {VehiclesService} from '../vehicles.service';
// import {CommunicationService} from '../communication-service.service';

@Component({
  selector: 'app-custom-mapbox-popup',
  templateUrl: './custom-mapbox-popup.component.html',
  styleUrls: ['./custom-mapbox-popup.component.css']
})
export class CustomMapboxPopupComponent implements OnInit {

  constructor(private vehiclesService: VehiclesService) { }
  public message: boolean;

  // @Output() newUserEventEmitter = new EventEmitter();

  public model: any[];   // PopupModel; // Model Property
  loadingAddress;

  ngOnInit() {
  }

  generateEventFromPopup() {


    console.log(this.model);

    this.vehiclesService.generateEvent(this.model)
      .subscribe(data => {
          console.log(data);
          this.message = true;
      });


  }


  makeEndTime(st) {

    let date = new Date(st);
    const userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date.setSeconds(date.getSeconds() + 10);
    date = new Date(date.getTime() - userTimezoneOffset);
    return  date.toISOString().replace( 'Z', '' ).slice(0, 19);
  }



  reverseGeocodeInPopup() {
    this.loadingAddress = true;
    console.log('reverseGeocodeInPopup');
    this.vehiclesService.reverseGeocode(this.model['lngLat'])  // postTest
      .subscribe(addressData => {
        console.log(addressData);
        this.model['address'] = addressData.features[0].place_name;
        //  this.eventDataForPopup.address = addressData.features[0].place_name;
        this.loadingAddress = false;
      });
  }



  reloadJourney() {
    // console.log('reload');
    // this.newUserEventEmitter.emit('event');
    // this.communicationService.callComponentMethod();
  }



}


