import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AdminService} from '../admin.service';
import {FormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';

import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import { MatPaginator} from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {

  group: any;
  groups: any;
  tableLoading: boolean;
  dataSource: MatTableDataSource<any>;
  groupsDisplayedColumns: string[] = ['group', 'webhook', 'groupKey', 'totalDevices', 'active', 'install', 'edit'];
  showSearch = false;
  searchValue: string;
  allDevices: any;


  groupForm = new UntypedFormGroup({
    mflGroup: new UntypedFormControl(''),
    webhook: new UntypedFormControl(''),
    groupKey: new UntypedFormControl(''),

  });

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;
  @ViewChild('editGroupDialog', {static: true}) editGroupDialog: TemplateRef<any>;


  constructor(private adminService: AdminService, private dialog: MatDialog,
              private titleService: Title) { }

  ngOnInit(): void {
    this.titleService.setTitle('Groups');
    this.tableLoading = true;

    this.adminService.groups({}).subscribe(result => {
      // console.log(result);
      this.groups = result;

      this.dataSource = new MatTableDataSource(result);
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = function(data: any, filterValue: string) {

        return (data.group || '').toLowerCase().includes(filterValue.toLowerCase())
          || (data.webhook || '').toLowerCase().includes(filterValue.toLowerCase())
          || (data.groupKey || '').toString().toLowerCase().includes(filterValue.toLowerCase());

      };
      this.dataSource.paginator = this.paginator;
      this.tableLoading = false;
    });

    this.fetchAllDevices();



  }

  showHideSearch(): void {
    this.showSearch ? this.showSearch = false : this.showSearch = true;
  }

  cancelFilter(): void {
    this.searchValue = '';
    this.dataSource.filter = '';
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  editGroup(event, groupObject): void {

    if (!groupObject){
      groupObject = {
        mflGroup: '',
        webhook: '',
        groupKey: '',
      };
    }

    // if (!userObject.active){
    //   userObject.active = 1;
    // }

    // console.log(userObject.fleets);
    this.groupForm.patchValue(groupObject);

    this.group = groupObject;
    this.dialog.open(this.editGroupDialog, {closeOnNavigation: true, width: '75%', hasBackdrop: true});

  }


  onFormSubmit(): void {

    this.adminService.updateGroup(this.groupForm.value).subscribe(() => {
      this.dialog.closeAll();
      this.groupForm.reset();
      this.ngOnInit();
    });

  }

  fetchAllDevices(): void {
    this.adminService.vehicles({}).subscribe(result => {
      this.allDevices = result;
    });
  }

  deviceStatusCount(group, status): number {
    let array =  this.allDevices.filter(device => {
      if (status === 'ALL'){
        if (device?.mflGroup === group) {
          return true;
        } else {
          return false;
        }
      } else {
        if (device?.mflGroup === group && device?.commissioning?.commissionStatus === status){
          return true;
        } else {
          return false;
        }
      }
    });

    return array.length;
  }

}
