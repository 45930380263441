import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {AdminService} from '../admin.service';
import {NotificationsService} from '../notifications.service';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import { Title } from '@angular/platform-browser';
import { DataSharingService } from '../data-sharing.service';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('editUserDialog', {static: true}) editUserDialog: TemplateRef<any>;
  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;

  user; any;
  users: any;
  allFleets: any;
  groups: any;
  dataSource: MatTableDataSource<any>;
  usersDisplayedColumns: string[] = ['Email', 'Name', 'fleets', 'edit'];
  showSearch = false;
  searchValue: string;
  disableFleets: boolean;
  deleteUser: false;
  curUser = localStorage.getItem('email');
  fleetAdminDropDown: any;



  fleetDropDown: any;
  blockedEndpointDropdown: any;
  password: string;
  authorisation: string;
  mflGroup: string = localStorage.getItem('mflGroup');
  userLevel: string = localStorage.getItem('authorisation');

  userForm = new UntypedFormGroup({
    _id: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    name: new UntypedFormControl(''),
    authorisation: new UntypedFormControl('telematics'),
    mflGroup: new UntypedFormControl(this.mflGroup),
    fleets: new UntypedFormArray([]),
    adminFleets: new UntypedFormArray([]),
    active: new UntypedFormControl('1'),
    videoRequestTimeLimit: new UntypedFormControl(600),
    blockedEndpoints: new UntypedFormArray([])
  });

  tableLoading = false;

  endpoints = [
    {value: 'view-snapshot', display: 'View Snapshots'},
    {value: 'view-video', display: 'View Videos'},
    {value: 'media-token', display: 'Media Token'},
    {value: 'live-preview', display: 'View Livestream'},
    {value: 'update-fleet', display: 'Update Fleet'},
    {value: 'updateUser', display: 'Update User'},
    {value: 'update-vehicle', display: 'Update Vehicle'},
    {value: 'update-device', display: 'Update Device'},
    {value: 'video-request', display: 'Upload Video'},
    {value: 'hyperlapse-request', display: 'Upload Hyperlapse'}
  ]


  get fleets(): UntypedFormArray {
    return this.userForm.get('fleets') as UntypedFormArray;
  }

  get adminFleets(): UntypedFormArray {
    return this.userForm.get('adminFleets') as UntypedFormArray;
  }

  get blockedEndpoints(): UntypedFormArray {
    return this.userForm.get('blockedEndpoints') as UntypedFormArray;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  constructor(private adminService: AdminService, private dialog: MatDialog,
              private titleService: Title, private  dataShare: DataSharingService, private notificationsService: NotificationsService) {}

  ngOnInit(): void {

    window.addEventListener('scroll', this.onPageScroll);

    if (localStorage.getItem('authorisation') === 'superuser'){
      this.adminService.groups({}).subscribe(r => {
        this.groups = r.reduce((acc, curVal) => acc.concat(curVal.mflGroup), []);
      });
    } else {
      this.groups = [localStorage.getItem('mflGroup')];
    }

    this.user =  {
      _id: null,
      email: '',
      name: '',
      authorisation: 'telematics',
      fleets: [],
      adminFleets: [],
      videoRequestTimeLimit: 600
    };


    this.refresh();


    this.adminService.fleets({}).subscribe(result => {
      this.allFleets = result;
    });


    this.paginator._intl.itemsPerPageLabel = 'Users per page';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number): string => {
      if (length === 0 || pageSize === 0) { return `0 of ${length}`; }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;

      if (endIndex === length){
        return 'Showing all users';
      } else {
        return `Showing  users ${startIndex + 1} - ${endIndex} of ${length} total users`;
      }
    };

    this.titleService.setTitle('Users');
  }

  refresh() {
    this.tableLoading = true;

    const data = {active: {'$in': [1, null]}};

    this.adminService.users(data).subscribe(result => {
      this.users = result;

      this.dataSource = new MatTableDataSource(result);
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = function(data: any, filterValue: string) {

        return (data.email || '').toLowerCase().includes(filterValue.toLowerCase())
          || (data.name || '').toLowerCase().includes(filterValue.toLowerCase())
          || (data.fleets || '').toString().toLowerCase().includes(filterValue.toLowerCase());

      };
      this.dataSource.paginator = this.paginator;
      this.tableLoading = false;
    });

  }

  editUser(event, userObject): void {


    this.deleteUser = false;

    if (!userObject){
      userObject = {
        _id: null,
        email: '',
        name: '',
        authorisation: 'telematics',
        fleets: [],
        adminFleets: [],
        videoRequestTimeLimit: 600,
        blockedEndpoints: []
      };
    }

    // if (!userObject.active){
    //   userObject.active = 1;
    // }

    this.disableFleets = !userObject._id;

    this.fleets.clear();
    this.adminFleets.clear();
    this.blockedEndpoints.clear();
    this.authorisation = localStorage.getItem('authorisation');
    this.mflGroup = localStorage.getItem('mflGroup');


    userObject?.fleets?.forEach((fleet) => {
      this.fleets.push(new UntypedFormControl(fleet));
    });

    userObject?.adminFleets?.forEach((fleet) => {
      this.adminFleets.push(new UntypedFormControl(fleet));
    });

    userObject?.blockedEndpoints?.forEach((ep) => {
      this.blockedEndpoints.push(new UntypedFormControl(ep));
    });


    if (userObject.videoRequestTimeLimit){
      parseInt(userObject.videoRequestTimeLimit)
    }
    // console.log(userObject.fleets);
    this.userForm.patchValue(userObject);


    this.user = userObject;
    this.dialog.open(this.editUserDialog, {closeOnNavigation: true, width: '75%', hasBackdrop: true});

  }

  addFleet(fleetDropDown) {

    if (this.userForm.value.fleets.includes(fleetDropDown) === false) {
      this.fleets.push(new UntypedFormControl(fleetDropDown));
    }
  }

  removeFleet(index: number) {
    this.fleets.removeAt(index);
  }


  addAdminFleet(fleetDropDown) {

    if (this.userForm.value.adminFleets.includes(fleetDropDown) === false) {
      this.adminFleets.push(new UntypedFormControl(fleetDropDown));
    }
  }

  removeAdminFleet(index: number) {
    this.adminFleets.removeAt(index);
  }




  addBlockedEndpoint(endpointDropDown) {

    if (this.userForm.value.blockedEndpoints.includes(endpointDropDown) === false) {
      this.blockedEndpoints.push(new UntypedFormControl(endpointDropDown));
    }
  }

  removeBlockedEndpoint(index: number) {
    this.blockedEndpoints.removeAt(index);
  }

  toggleEndpointAccess(val){
    const index = this.blockedEndpointIndex(val)

    if (index > -1){
      this.blockedEndpoints.removeAt(index);
    } else {
      this.blockedEndpoints.push(new UntypedFormControl(val));
    }

  }

  blockedEndpointIndex(val){
    return this.blockedEndpoints.controls.findIndex((x) => x.value == val);
}






  onFormSubmit() {


    if (this.userForm.value.videoRequestTimeLimit?.length < 1){
      this.userForm.removeControl('videoRequestTimeLimit');
    }

    if (this.deleteUser){
      this.userForm.patchValue({
        active: 0
      });
    } else {
      this.userForm.patchValue({
        active: 1
      });
    }


    this.adminService.updateUser(this.userForm.value).subscribe(() => {
      // set notification that userdata has been changed/ user has been created
      if (!this.userForm.value._id){
        // this.notificationsService.newUser(this.userForm.value).subscribe(r => {
        //   console.log(r);
        // });
      } else {
        // set arbitrary new password value to avoid passing on the new password
        if (this.userForm.controls.password){
          this.userForm.patchValue({password: 'newPassword'});
        }
        // this.notificationsService.updateUser(this.userForm.value, this.user).subscribe(r => {
        //   console.log(r);
        //   this.notificationsService.fetchNotifications().subscribe(r => {
        //     this.dataShare.notificationsRaw.next(r);
        //   });
        // });

      }
      this.dialog.closeAll();
      this.userForm.reset();
      this.userForm.removeControl('password');
      this.fleets.clear();

      this.userForm.addControl('videoRequestTimeLimit', new UntypedFormControl(600));


      this.refresh();
    });

  }


  generatePassword() {

    this.disableFleets = false;

    var numberChars = "0123456789";
    var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lowerChars = "abcdefghijklmnopqrstuvwxyz";
    var allChars = numberChars + upperChars + lowerChars;
    var randPasswordArray = Array(20);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray = randPasswordArray.fill(allChars, 3);

    this.userForm.addControl('password',
      new UntypedFormControl(this.shuffleArray(randPasswordArray.map(x => x[Math.floor(Math.random() * x.length)])).join('')));

  }


  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  showHideSearch(): void {
    this.showSearch ? this.showSearch = false : this.showSearch = true;
  }

  cancelFilter(): void {
    this.searchValue = '';
    this.dataSource.filter = '';
  }

  scrollToTop(): void {
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  onPageScroll(): void {
    const vh = window.innerHeight;
    const y = window.scrollY;
    if (y > (vh/2)) {
      (document.getElementById('scrollTopButtonEle') as HTMLElement).style.display = 'block';
    } else {
      (document.getElementById('scrollTopButtonEle') as HTMLElement).style.display = 'none';
    }
  }

}

