import {Component, Input, OnInit, Inject, ViewChild, TemplateRef} from '@angular/core';
import {formatDate} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {AdminService} from '../admin.service';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import { environment } from 'src/environments/environment';
import {SocketioService} from '../socketio.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { Title } from '@angular/platform-browser';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Color, Label} from 'ng2-charts';



import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {map, subscribeOn, switchMap} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';

import {Observable} from 'rxjs';



export interface DialogData {
  animal: 'panda' | 'unicorn' | 'lion';
}



@Component({
  selector: 'app-device',
  templateUrl: './diagnostics.component.html',
  styleUrls: ['./diagnostics.component.css'],
  providers: [DatePipe, {provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
})
export class DiagnosticsComponent implements OnInit {

  dn: string;
  id: string;
  deviceStatus: any;
  deviceParameters: any;
  allFleets: any;
  cameras: any;
  cameraProfiles: any;
  appVersion: string;
  deviceConnected: boolean;
  latestSnapshots = {};
  vehicle: any;
  configsMatch: boolean;
  toggleStatusData: boolean;
  gSensorCalibratePass: boolean;
  commissioning: any;
  calendar: any;
  events: any;
  partnerEvents: any;
  partnerEventsDisplay: any;
  videoBudget; any;
  myMessage = [];
  notes: any;
  deviceStatusLoading = true;
  usersGroup = localStorage.getItem('mflGroup');
  maxDateForEvents = new Date();
  eventsDatePicker = new Date();
  eventsLoading = false;
  // style = 'mapbox://styles/fleetfocus/cjvxkhjay5ti71dqa1lldx82p?optimize=true';
  map: mapboxgl.Map;
  map2: mapboxgl.Map;
  eventMap: mapboxgl.Map;
  pageLoading = true;
  API_URL = environment.API_URL;
  frontCamOrientationHelp = false;
  driverCamOrientationHelp = false;
  theVehicleSettings: any;
  vehicleRegistration: string;
  vehicleFleet: string;
  commissioningFinalNotes: string;
  theVehicleClass: string;
  theVehicleFuelType: string;
  theVehicleMake: string;
  theVehicleModel: string;
  snapshotsPerChannel: any;
  requestSnapshotFlag = false;
  eventVideoSelected: string;
  eventVideoSelectedLoading = true;
  eventSnapshotSelected: string;
  snapshotMessageToListen: string;
  snapshotChannelRequested: string;
  numberOfStatusUpdatesForHealth = 10;
  healthGraphRawData: any;
  mediaPendingUpload: any;


  deviceHealthChartData: ChartDataSets[] = [];
  deviceHealthChartLabels: Label[] = [];
  deviceHealthChartOptions: ChartOptions  = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
      }
    },
    hover: {mode: null},
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => data.labels[tooltipItem[0].index].toString(),
        label: (tooltipItem, data) => {

          const label = data.datasets[tooltipItem.datasetIndex].label;

          switch (label){
            case 'GPS signal strength': {
              return label + ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + ' satellites connected';
            }
            case '4G signal strength': {
              return label + ': ' + this.getNetworkTypeReadable(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
            }
            case 'G-force sensor': {
              if (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] === '10'){
                return label + ': Calibrated';
              } else {
                return label + ': Needs calibration';
              }
            }
            case 'Memory status': {
              if (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] === '10'){
                return label + ': Recording present';
              } else {
                return label + ': No recording present';
              }
            }
          }


            // return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + '%)' + ;
        }
      },
      titleFontSize: 14,
      titleFontColor: 'yellow',
      bodyFontColor: 'white',
      bodyFontSize: 14,
      displayColors: false
    },
    scales: {
      yAxes: [{
        display: false,
        ticks: {
          suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
          // OR //
          beginAtZero: true   // minimum value will be 0.
        }
      }]
    }
  };


  // deviceHealthChartColour;
  deviceHealthChartLegend = true;
  deviceHealthChartPlugins = [];
  deviceHealthChartType: ChartType = 'bar';


  deviceHealth: any  = {
    connection : '',
    storage : '',
    cellular : '',
    gforce : '',
    gps: '',
    cams: []
  };





  commissioningCameraCheckCompleteValue = true;

  eventsFilters = {
    user: true,
    journey: false,
    severeOnly: false,
    automatic: true,
    videoOnly: false,
    hyperlapseOnly: false,
    videoQueueOnly: false
  };





  engineers = [
    'LEX', 'NOVA', 'AUTO AIR', 'YORKTRACKED', 'SOUTHCOAST', 'CBS',
    'RS CONNECT', 'QUARTIX ENGINEER', 'RAM ENGINEER', 'BANLAW SYSTEMS', 'AJT', 'Motormatics',
    'Owlvue', 'Cornwall Fleet Solutions', 'S&G Auto', 'The FFS Group', 'Mobile Awareness Products',
    'Ns Installations', 'Intellistall ', 'MobileValley ', 'Valleys Performance', 'Yortracked',
    'Commcare', 'Ark fleet', 'Highland Telecom'
  ];

  commissionForm = new UntypedFormGroup({
    installEngineer: new UntypedFormControl('', [Validators.required]),
    installDate: new UntypedFormControl(new Date(),  [Validators.required]),
    testBenchDate: new UntypedFormControl(new Date(),  [Validators.required]),
    signedOffBy: new UntypedFormControl(localStorage.getItem('email')),
    gSensorCalibratePass: new UntypedFormControl(false, [Validators.requiredTrue]),
    ignitionPass: new UntypedFormControl(false, [Validators.requiredTrue]),
    gpsPass: new UntypedFormControl(false, [Validators.requiredTrue]),
    cameras: new UntypedFormArray([
    ])
  });

  @ViewChild('commissoningDialog', { static: true }) commissoningDialog: TemplateRef<any>;
  @ViewChild('settingsDialog', { static: true }) settingsDialog: TemplateRef<any>;
  @ViewChild('viewEventMedia') viewEventMedia: TemplateRef<any>;
  @ViewChild('eventLocationMapModal') eventLocationMapModal: TemplateRef<any>;
  @ViewChild('videoRequestResultModal') videoRequestResultModal: TemplateRef<any>;




  get camerasFormArray(): UntypedFormArray {
    return this.commissionForm.get('cameras') as UntypedFormArray;
  }


  testedForm = new UntypedFormGroup({
    testBenchDate: new UntypedFormControl(new Date(),  [Validators.required]),
    signedOffBy: new UntypedFormControl(localStorage.getItem('email')),
    gpsPass: new UntypedFormControl(false, [Validators.requiredTrue]),
    cameras: new UntypedFormArray([
    ])

  });


  get camerasTestedFormArray(): UntypedFormArray {
    return this.testedForm.get('cameras') as UntypedFormArray;
  }

  changeStatusForm = new UntypedFormGroup({
    // testBenchDate: new FormControl(new Date(),  [Validators.required]),
    // signedOffBy: new FormControl(localStorage.getItem('email')),
    commissionStatus: new UntypedFormControl('', [Validators.required]),

  });



  note = new UntypedFormControl();



  constructor(private route: ActivatedRoute, private adminService: AdminService, public dialog: MatDialog,
              private datePipe: DatePipe, private socketService: SocketioService, private _snackBar: MatSnackBar,
              private titleService: Title) {
  }

  ngOnInit(): void {

    this.requestSnapshotFlag = false;

  // watch for camera commissioning checks changes to validate form
    this.camerasFormArray.valueChanges.subscribe(data => {
      this.commissioningCameraCheckComplete();
    });

    if (this.usersGroup !== 'undefined'){
      this.commissionForm.patchValue({installEngineer: this.usersGroup + ' Engineer'});
    }


    this.engineers.sort();

    this.camerasFormArray.clear();
    this.camerasTestedFormArray.clear();


    this.route.paramMap.subscribe(params => {
      this.dn = params.get('dn');
    });

    const data = {dn: this.dn, channel: ''};

    this.adminService.isDeviceConnected(data).subscribe(result => {
      // console.log('isDeviceConnected');
      // console.log(result);
      this.deviceConnected = result;

    });


    this.adminService.videoBudget(data).subscribe(result => {
      this.videoBudget = result;
      console.log(result);
    });


    this.adminService.deviceParameters(data).subscribe(result => {
      this.deviceParameters = result[0].deviceParameters;
      this.cameras = result[0].cameras;
      this.appVersion = result[0].appVersion;
      this.vehicle = result[0];
      this.commissioning = result[0].commissioning;

      this.notes = result[0].notes;
      this.id = result[0]._id;


      if (typeof this.commissioning === 'undefined') {
        this.commissioning = {
          commissionStatus: 'tested'
        };
      }
      if (typeof this.vehicle.deviceParameters !== 'undefined' && typeof this.vehicle.config !== 'undefined') {
        this.configsMatch = this.isSubset(this.vehicle.deviceParameters, this.vehicle.config);
      }
      if (typeof this.cameras !== 'undefined') {

        this.cameraProfiles = [];

        this.cameras.forEach(cam => {

          if (cam.camPosition === 'Front'){
            this.cameraProfiles.front = {};
            this.cameraProfiles.front.config = cam.camConfig;
            this.cameraProfiles.front.ch = cam.channel;
          }
          if (cam.camPosition === 'Driver'){
            this.cameraProfiles.driver = {};
            this.cameraProfiles.driver.config = cam.camConfig;
            this.cameraProfiles.driver.ch = cam.channel;
            this.cameraProfiles.driver.HaveAudio = cam.HaveAudio;
          }

          if (cam.camPosition === 'Rear'){
            this.cameraProfiles.rear = {};
            this.cameraProfiles.rear.config = cam.camConfig;
            this.cameraProfiles.rear.ch = cam.channel;
          }


          this.camerasFormArray.push( new UntypedFormGroup({
            orientation: new UntypedFormControl(false, [Validators.requiredTrue]),
            recording: new UntypedFormControl(false, [Validators.requiredTrue]),
            ch: new UntypedFormControl(cam.channel),
          }));

          this.camerasTestedFormArray.push( new UntypedFormGroup({
            recording: new UntypedFormControl(false, [Validators.requiredTrue]),
            ch: new UntypedFormControl(cam.channel),
          }));

          const data = {dn: this.dn, channel: cam.channel};

          this.adminService.latestSnapshots(data).subscribe(result => {
            const obj = {};
            obj['ch' + cam.channel] = result;
            const st = 'ch' + cam.channel.toString();

            this.latestSnapshots[st] = result;
          });

        });
        console.log(this.cameraProfiles);
      }

      this.getDeviceStatus(this.dn);


      this.socketService.setupSocketConnection([this.dn]);


      // this.socketService.onNewSnapshotMessage().subscribe((msg: any) => {
      //   if (msg.includes(this.snapshotMessageToListen)){
      //     this.requestSnapshotFlag = false;
      //     delete this.snapshotChannelRequested;
      //     this.requestEvents('today', null);
      //   }
      // });





      // this.socketService.onNewMessage().subscribe((msg: any) => {
      //   // console.log('got a msg: ' + msg);
      //   // console.log(msg);
      //
      //   // this._snackBar.open(msg, '', {
      //   //   duration: 2000,
      //   // });
      //
      //   if (msg.managementAction === 'reload something') {
      //     this.deviceStatusLoading = true;
      //     this.getDeviceStatus(this.dn);
      //   }
      //
      //
      //
      //
      //   // start with  written file
      //   this.myMessage.unshift(msg);
      //
      //   if (this.myMessage.length > 30) {
      //     this.myMessage.pop();
      //   }
      // });
    });

    const data2 = {};
    this.adminService.fleets(data2).subscribe(result => {
      this.allFleets = result;
    });

// GET VEHICLE SETTINGS, ONLY IF DEVICE NUMBERS MATCH

    const theId = JSON.parse(localStorage.getItem('devices')).filter(device => device.device === this.dn)[0].id;

    this.adminService.vehicle({id: theId}).subscribe(result => {
      if (result.dn.toString() === this.dn.toString()){
        this.theVehicleSettings = result;
        this.vehicleRegistration = result.registration;
        this.vehicleFleet = result.fleetId;
      }
    });



    this.adminService.calendar({dn: this.dn}).pipe(
      map(result => {

        console.log(result);

        this.calendar = result;

        if (result){
          if (result.hours < 10){
            this.deviceHealth.storage = 'red';
          } else if (result.hours < 20){
            this.deviceHealth.storage = 'yellow';
          } else {
            this.deviceHealth.storage = 'green';
          }
        }
      }),
      switchMap( () => this.adminService.deviceHealth({dn: this.dn, limit: this.numberOfStatusUpdatesForHealth})),
      map(result2 => {

        this.healthGraphRawData = result2;


        if (result2.length > 1){

          let satelliteTotal = 0;
          let cellularTotal = 0;

          result2.reverse().forEach(alarm => {

            if (alarm?.alarmData?.statusData?.speed === 0 ){
              if (Math.abs(alarm.alarmData.statusData.gSensorX) < 5 && Math.abs(alarm.alarmData.statusData.gSensorY) < 5
                && Math.abs(alarm.alarmData.statusData.gSensorZ) < 5){
                this.deviceHealth.gforce = 'green';
              } else {
                this.deviceHealth.gforce = 'red';
              }
            } else {
              this.deviceHealth.gforce = '';
            }

            satelliteTotal += alarm?.alarmData?.statusData?.satellitesQuantity;

            if (alarm?.alarmData?.statusData?.networkType === 5){
              cellularTotal ++;
            }
          });

          if ((satelliteTotal / this.numberOfStatusUpdatesForHealth) === 0){
            this.deviceHealth.gps = 'red';
          } else if ((satelliteTotal / this.numberOfStatusUpdatesForHealth) < 5){
            this.deviceHealth.gps = 'yellow';
          } else {
            this.deviceHealth.gps = 'green';
          }

          if ((cellularTotal / this.numberOfStatusUpdatesForHealth) * 100 < 30){
            this.deviceHealth.cellular = 'red';
          } else if ((cellularTotal / this.numberOfStatusUpdatesForHealth) * 100 < 50){
            this.deviceHealth.cellular = 'yellow';
          } else {
            this.deviceHealth.cellular = 'green';
          }

          this.drawDeviceHealthGraph();

        }



      })
    ).subscribe();


    this.requestEvents('today', null);

    this.titleService.setTitle('MFL Connect - Diagnostics');


  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      this.pageLoading = false;
      setTimeout(() => {
        if (this.deviceStatus && this.deviceStatus.longitude && this.deviceStatus.latitude){
          if (document.getElementById('deviceStatusMapElement')){
            this.showDeviceStatusMap();
          }
        }
      }, 3000);
    }, 0);

    // if (this.commissioning.commissionStatus !== 'active'){
    //   this.dialog.open(this.commissoningDialog,  { closeOnNavigation: true, disableClose: true });
    // }

  }

  healthGraphUpdate(): void {
    this.adminService.deviceHealth({dn: this.dn, limit: this.numberOfStatusUpdatesForHealth}).subscribe(result => {
      this.healthGraphRawData = result;
      console.log(result);
      this.drawDeviceHealthGraph();
    });
  }




  drawDeviceHealthGraph(): void{
    const satelliteChartData = [];
    const cellularChartData = [];
    const gforceChartData = [];
    const memoryChartData = [];

    this.deviceHealthChartLabels = [];
    this.deviceHealthChartData = [];

    this.healthGraphRawData.reverse().forEach(alarm => {
      const dateCheck = new Date(alarm?.alarmData?.statusData?.deviceTime);
      if (dateCheck instanceof Date && !isNaN(dateCheck.valueOf())) {

        // console.log(this.healthGraphRawData);

        let memoryOk = 0;

        this.calendar.modRecordingHistory.forEach(recording => {


          const recordingStartDate = new Date(recording.st).getTime();
          const recordingEndDate = new Date(recording.et).getTime();
          const eventDate = new Date(alarm.alarmData.statusData.deviceTime).getTime();

          if (eventDate >= recordingStartDate && eventDate <= recordingEndDate) {
            memoryOk++;
          }

        });

        let gForce = '0';

        if (alarm?.alarmData?.statusData) {
          if ((alarm.alarmData.statusData.speed === 0 && (Math.abs(alarm.alarmData.statusData.gSensorX) < 5 && Math.abs(alarm.alarmData.statusData.gSensorY) < 5
            && Math.abs(alarm.alarmData.statusData.gSensorZ) < 5)) || (alarm.alarmData.statusData.speed >= 0 && (Math.abs(alarm.alarmData.statusData.gSensorX) >= 0 &&
            Math.abs(alarm.alarmData.statusData.gSensorY) >= 0 && Math.abs(alarm.alarmData.statusData.gSensorZ) >= 0))) {
            gForce = '10';
          }


          memoryChartData.push(memoryOk >= 1 ? '10' : '0');
          satelliteChartData.push(alarm.alarmData.statusData.satellitesQuantity);
          cellularChartData.push(alarm.alarmData.statusData.networkType);
          gforceChartData.push(gForce);
          this.deviceHealthChartLabels.push(formatDate(new Date(alarm.alarmData.statusData.deviceTime), 'dd-MM-yyyy HH:mm:ss', 'en_US'));
        }
      }

    });

    console.log(cellularChartData);
    console.log(satelliteChartData);



    this.deviceHealthChartData = [
      {
        data: satelliteChartData,
        label: 'GPS signal strength',
        backgroundColor: 'rgba(255, 205, 86, 0.2)',
        borderColor: 'rgb(255, 205, 86)',
        borderWidth: 2,
      },
      {
        data: cellularChartData,
        label: '4G signal strength',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgb(75, 192, 192)',
        borderWidth: 2,
      },
      {
        type: 'line',
        data: gforceChartData,
        lineTension: 0,
        label: 'G-force sensor',
        fill: false,
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        borderColor: 'rgb(54, 162, 235)',
        borderWidth: 2,
      },
      {
        type: 'line',
        data: memoryChartData,
        lineTension: 0,
        label: 'Memory status',
        fill: false,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgb(153, 102, 255)',
        borderWidth: 2,
      }
    ];


  }






  snapshotRequest(ch): void {
    this.requestSnapshotFlag = true;
    this.snapshotChannelRequested = ch;

    const dateString = this.datePipe.transform( new Date(), 'yyyy-MM-dd HH:mm:ss');

    const data = {
      callback: '',
      ch,
      dn: this.dn,
      st: dateString
    };

    console.log(data);

    const re = new RegExp('-', 'g');
    const re2 = new RegExp(':', 'g');
    const re3 = new RegExp(' ', 'g');

    const test = dateString.replace(re, '').replace(re2, '').replace(re3, '').trim();
    const filename = this.dn + '_' + test + '_' + ch + '.h265';
    this.snapshotMessageToListen = 'media/' + this.dn + '/snapshot/' + filename;
    // console.log(this.snapshotMessageToListen);

    this.adminService.snapshotRequest(data).subscribe(result => {console.log(result); });

  }


  showOrientationHelp(camPos, $event): void {
    $event.stopPropagation();
    if (camPos === 'Front'){
      this.frontCamOrientationHelp === true ? this.frontCamOrientationHelp = false : this.frontCamOrientationHelp = true;
    } else if (camPos === 'Driver' ){
      this.driverCamOrientationHelp === true ? this.driverCamOrientationHelp = false : this.driverCamOrientationHelp = true;
    }
  }


  showDeviceStatusMap(): void {

    mapboxgl.accessToken = environment.mapbox.accessToken;

    this.map2 = new mapboxgl.Map({
      container: 'deviceStatusMapElement',
      style: 'mapbox://styles/fleetfocus/cjvxkhjay5ti71dqa1lldx82p?optimize=true',
      zoom: 12,
      center: [this.deviceStatus.longitude, this.deviceStatus.latitude]
    });
    // Add map controls
    this.map2.addControl(new mapboxgl.NavigationControl());

    new mapboxgl.Marker()
      .setLngLat([this.deviceStatus.longitude, this.deviceStatus.latitude])
      .addTo(this.map2);


  }











  openDialog(event, fileType): void {

    // console.log(event);

    this.dialog.open(this.viewEventMedia, {
      data: {
        event,
        fileType
      }, hasBackdrop: true, width: '65%'
    });

    if (fileType === 'video') {
      if (event.videos2[0].filename){
        this.eventVideoSelected = event.videos2[0].filename;
      } else if (event.videos2[0].file){
        this.eventVideoSelected = event.videos2[0].file;
      }

      this.changeEventVideoToView();
    }

    if (fileType === 'image'){
      this.eventSnapshotSelected = event.snapshots[0].file;
    }

  }

  changeEventVideoToView(): void {
    this.eventVideoSelectedLoading = true;
    this.adminService.viewVideo(this.eventVideoSelected).then(
      blob => {
        // console.log(blob);
        (document.getElementById('EventVideo') as HTMLVideoElement).src = URL.createObjectURL(blob);
        this.eventVideoSelectedLoading = false;
      });
  }

  getAllParameters(dn): void {
    const data = {dn};
    this.adminService.getAllParameters(data).subscribe(result => {
    });
  }




  getDeviceStatus(dn): void {
    const data = {dn};
    this.adminService.deviceStatus(data).subscribe(result => {

      console.log(result);

      this.deviceStatusLoading = false;

      if (typeof result[0] !== 'undefined') {
        if (typeof result[0].alarmData !== 'undefined') {
          this.deviceStatus = result[0].alarmData.statusData;
          this.interpretModuleWorkingStatus();
        }
      }

    });
  }




  restart(): void {
    const data = {dn: this.dn};
    this.adminService.restart(data).subscribe(result => {
    });
  }




  /*  setParameters(config) {
      let data = {dn: this.dn, config: config, message: {}};


      data.message = {
        "ss": "xxxxxxxxx",
          "sc": {
          "JTBASE": {
            "license": this.vehiclePushForm.value.registration,
            "model:": this.vehiclePushForm.value.sim,
          }
        }
      }

      this.adminService.setParameters(data).subscribe(result => {
        console.log(result);

      });
    }*/



  triggerUnderSpeedAlarm(): void {
    this.deviceStatusLoading = true;

    this.adminService.triggerUnderSpeedAlarm({dn: this.dn}).subscribe(result => {
      console.log(result);
      setTimeout(() => {
        this.deviceStatusLoading = false;
      }, 2000);
    });


  }




  calibrateGSensor(): void {
    this.adminService.calibrateGSensor({dn: this.dn}).subscribe(result => {
      this.triggerUnderSpeedAlarm();
    });
  }





  /*  addCamera() {
      this.camerasFormArray.push( new FormGroup({
        channel: new FormControl(''),
        fps: new FormControl(''),
        model: new FormControl('')
      }));
    }*/


  /*

    removeCamera(index: number) {
      this.camerasFormArray.removeAt(index);
    }

  */



  commissionVehicle(): void {
    const email = localStorage.getItem('email');
    const dateString = this.datePipe.transform( new Date(), 'dd-MM-yyyy HH:mm');

    const data2 =
      {
        id: this.id,
        note: 'The diagnostics has been commissioned by ' + email + ' on ' + dateString + ' with the following notes being left "'
        + this.commissioningFinalNotes + '"'
      };


    this.adminService.updateVehicleNotes(data2).subscribe(result => {});

    this.commissionForm.value.commissionStatus = 'active';

    const data = {
      dn: this.dn,
      commissioning: this.commissionForm.value
    };

    console.log(this.commissionForm.value);

    this.adminService.commissionVehicle(data).subscribe(result => {
      console.log(result);
      this.dialog.closeAll();
      this.ngOnInit();
    });

    if (this.vehicleRegistration.trim().toLocaleLowerCase() !== this.theVehicleSettings.registration){
      const data = this.vehicleRegistration.trim().toLocaleLowerCase();
      this.adminService.getVehicleData(data).subscribe(result => {
        this.theVehicleMake = result.Response.DataItems.VehicleRegistration.Make;
        this.theVehicleModel = result.Response.DataItems.VehicleRegistration.Model;
        this.theVehicleClass = result.Response.DataItems.VehicleRegistration.VehicleClass.toUpperCase();
        this.theVehicleFuelType = result.Response.DataItems.VehicleRegistration.FuelType;
      });
    } else {
      this.theVehicleMake = this.theVehicleSettings.vehicleMake;
      this.theVehicleModel = this.theVehicleSettings.vehicleModel;
      this.theVehicleClass = this.theVehicleSettings.vehicleClass.toUpperCase();
      this.theVehicleFuelType = this.theVehicleSettings.vehicleFuelType;
    }


    if (this.theVehicleSettings) {
      const theUpdatedVehicleData = {
        cameras: this.theVehicleSettings.cameras,
        device: this.theVehicleSettings.device,
        deviceBarCode: this.theVehicleSettings.deviceBarCode,
        deviceDob: this.theVehicleSettings.deviceDob,
        deviceIEMI: this.theVehicleSettings.deviceIEMI,
        deviceSIM: this.theVehicleSettings.deviceSIM,
        deviceSerial: this.theVehicleSettings.deviceSerial,
        dn: this.theVehicleSettings.dn,
        driver: this.theVehicleSettings.driver,
        dvrUser: this.theVehicleSettings.dvrUser,
        dvrUserPwd: this.theVehicleSettings.dvrUserPwd,
        fleetId: this.vehicleFleet,
        id: this.theVehicleSettings._id,
        overrun: this.theVehicleSettings.overrun,
        overrunRecording: this.theVehicleSettings.overrunRecording,
        registration: this.vehicleRegistration,
        vehicleClass: this.theVehicleClass,
        vehicleFuelType: this.theVehicleFuelType,
        vehicleMake: this.theVehicleMake,
        vehicleModel: this.theVehicleModel,
      };

      this.adminService.updateVehicle(theUpdatedVehicleData).subscribe(result => {
        this.getAllParameters(this.dn);
      });
    }
  }



  testedVehicle() {

    this.testedForm.value.commissionStatus = 'tested';

    const data = {
      dn: this.dn,
      commissioning: this.testedForm.value
    };


    this.adminService.commissionVehicle(data).subscribe(result => {
      this.dialog.closeAll();
      this.ngOnInit();
    });

  }




  changeCommissionStatus() {

    // this.changeStatusForm.value.commissionStatus = 'tested';

    const data = {
      dn: this.dn,
      'commissioning.commissionStatus': this.changeStatusForm.value.commissionStatus
    };


    this.adminService.commissionVehicle(data).subscribe(result => {});

    const data2 =
      {
        id: this.id,
        note: 'Commissioning status changed from ' + this.commissioning.commissionStatus + ' to '
          + this.changeStatusForm.value.commissionStatus
      };


    this.adminService.updateVehicleNotes(data2).subscribe(result => {
      this.dialog.closeAll();
      this.ngOnInit();

    });

  }






  updateMirror(mirror, ch): void {
    const data =
      {
        dn: this.dn,
        ch: ch.toString(),
        mirror
      };


    this.adminService.updateMirror(data).subscribe(result => {
      console.log(result);
    });
    // this.getAllParameters(this.dn);
    // this.snapshotRequest(ch.toString());
    // this.requestTodaysEvents();

  }


  interpretModuleWorkingStatus(): void {

    this.gSensorCalibratePass = Math.abs(this.deviceStatus.gSensorX) < 10 &&
      Math.abs(this.deviceStatus.gSensorY) < 10 && Math.abs(this.deviceStatus.gSensorZ) < 10 &&
      this.deviceStatus.gSensorX !== null && this.deviceStatus.gSensorY !== null && this.deviceStatus.gSensorZ !== null;


    this.commissionForm.patchValue({gSensorCalibratePass: this.gSensorCalibratePass});
    this.commissionForm.patchValue({gpsPass:  this.deviceStatus.satellitesQuantity > 4 });
    this.testedForm.patchValue({gpsPass:  this.deviceStatus.satellitesQuantity > 4 });
    const moduleBits = this.deviceStatus.ModuleWorkingStatusidentifierBit .split('').reverse();
    this.deviceStatus.mobileNetworkModule = moduleBits[0];
    this.deviceStatus.locationModule  = moduleBits[1];
    this.deviceStatus.wifiModule = moduleBits[2];
    this.deviceStatus.gSensorModule = moduleBits[3];
    this.deviceStatus.recording = moduleBits[4];
    this.deviceStatus.recordingStatusArray = this.deviceStatus.recordingStatus.split('').reverse();
    this.deviceStatus.HardDiskStatusBitArray =  this.deviceStatus.HardDiskStatusBit.split('').reverse();
    this.deviceStatus.identifierBit1Array =  this.deviceStatus.identifierBit1.split('').reverse();
    const alarmBits = this.deviceStatus.alarmStatusIdBit.split('').reverse();
    this.deviceStatus.videoLossAny = alarmBits[0];
    this.deviceStatus.motionDetectionAny = alarmBits[1];
    this.deviceStatus.videoBlindAny = alarmBits[2];
    this.deviceStatus.alarmInputTriggerAny = alarmBits[3];
    this.deviceStatus.overSpeedAlarm = alarmBits[4];
    this.deviceStatus.lowSpeedAlarm = alarmBits[5];
    this.deviceStatus.emergencyAlarm = alarmBits[6];
    this.deviceStatus.overTimeStop = alarmBits[7];
    this.deviceStatus.vibrationAlarm = alarmBits[8];
    this.deviceStatus.outGEOFencingAlarm = alarmBits[9];
    this.deviceStatus.enterGEOFencingAlarm = alarmBits[10];
    this.deviceStatus.exitLineAlarm = alarmBits[11];
    this.deviceStatus.enterLineAlarm = alarmBits[12];
    this.deviceStatus.fuelLevelAlarm = alarmBits[13];
    this.deviceStatus.videoLossArray   = this.deviceStatus.videoLoss.split('').reverse();
    this.deviceStatus.motionDetectionArray   = this.deviceStatus.motionDetection.split('').reverse();
    this.deviceStatus.videoBlindArray   = this.deviceStatus.videoBlind.split('').reverse();
    this.deviceStatus.alarmInputTriggerArray   = this.deviceStatus.alarmInputTrigger.split('').reverse();



    if (Object.prototype.toString.call(new Date(this.deviceStatus.deviceTime)) === '[object Date]') {
      if (isNaN(new Date(this.deviceStatus.deviceTime).getTime())) {  // d.valueOf() could also work
        this.deviceStatus.timeSinceUpdateShort = 'some time';
      } else {
        const secondsSinceUpdate = ((new Date().getTime() - new Date(this.deviceStatus.deviceTime).getTime()) / 1000);
        const d = Math.floor(secondsSinceUpdate / (3600 * 24));
        const h = Math.floor(secondsSinceUpdate % (3600 * 24) / 3600);
        const m = Math.floor(secondsSinceUpdate % 3600 / 60);
        const s = Math.floor(secondsSinceUpdate % 60);
        const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : '';
        const hDisplay = h > 0 ? h + (h === 1 ? ' hour and ' : ' hours and ') : '';
        const mDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes ') : '';
        this.deviceStatus.timeSinceUpdateDetail = dDisplay + hDisplay + mDisplay;
        this.deviceStatus.timeSinceUpdateShort = this.deviceStatus.timeSinceUpdateDetail.match('[a-z0-9]+(\\s+[a-z0-9]+)?')[0];

        console.log('days=' + d);

        if (d < 5) {
          this.deviceHealth.connection = 'green';
        } else if (d < 10) {
          this.deviceHealth.connection = 'yellow';
        } else if (d > 10) {
          this.deviceHealth.connection = 'red';
        }

      }


    }


    if (this.deviceStatus.networkType === '?' || this.deviceStatus.networkType === 3){
      // ? or 2g
      this.deviceStatus.signalTrafficLight = 'weak';
    } else if (this.deviceStatus.networkType === 4) {
      // 3g
      if (this.deviceStatus.signalIntensity <= 5){
        this.deviceStatus.signalTrafficLight = 'weak';
      } else {
        this.deviceStatus.signalTrafficLight = 'average';
      }
    } else {
      // all others
      if (this.deviceStatus.signalIntensity < 5){
        this.deviceStatus.signalTrafficLight = 'weak';
      } else if (this.deviceStatus.signalIntensity >= 5 && this.deviceStatus.signalIntensity < 7) {
        this.deviceStatus.signalTrafficLight = 'average';
      } else {
        this.deviceStatus.signalTrafficLight = 'good';
      }
    }


    this.deviceStatus.networkTypeReadable = this.getNetworkTypeReadable(this.deviceStatus.networkType);



    // this.commissionForm.patchValue({camerasFormArray: {orientation: 'balls'}});
    this.camerasFormArray.controls.forEach(c => {
      const ch = (parseInt(c.value.ch) - 1);
      let recordingValue = false;

      if (this.deviceStatus.recordingStatusArray[ch] === '1' && this.deviceStatus.videoLossArray[ch] !== '1') {
        recordingValue = true;
      }

      c.patchValue({recording: recordingValue});
    });
    this.commissionForm.markAllAsTouched();


    this.camerasTestedFormArray.controls.forEach(c => {
      const ch = (parseInt(c.value.ch) - 1);
      let recordingValue = false;

      if (this.deviceStatus.recordingStatusArray[ch] === '1' && this.deviceStatus.videoLossArray[ch] !== '1') {
        recordingValue = true;
      }

      c.patchValue({recording: recordingValue});
    });


    this.camerasTestedFormArray.markAllAsTouched();

    if (this.deviceStatus.longitude && this.deviceStatus.latitude){
      if (document.getElementById('deviceStatusMapElement')){
        this.showDeviceStatusMap();
      }
    }


    this.deviceStatusLoading = false;

  }


  getNetworkTypeReadable(networkType): string{
    switch (networkType){
      case '?': {
        return '?';
      }
      case 1: {
        return 'wired';
      }
      case 2: {
        return 'WI-FI';
      }
      case 3: {
        return '2G';
      }
      case 4: {
        return '3G';
      }
      case 5: {
        return '4G';
      }
      case 6: {
        return '5G';
      }
    }
  }









  isSubset = (superObj, subObj) => {
    return Object.keys(subObj).every(ele => {
      if (typeof subObj[ele] === 'object') {
        return this.isSubset(superObj[ele], subObj[ele]);
      }
      return subObj[ele] === superObj[ele];
    });
  }



  requestEvents(type: string, event: MatDatepickerInputEvent<Date>): void {
    this.partnerEvents = [];
    this.partnerEventsDisplay = [];
    this.eventsLoading = true;
    const matchData = {match: {startTime: {$gte: '', $lt: ''}, dn: this.dn}};

    if (type === 'today'){
      const today = this.datePipe.transform(new Date(), 'yyyy-MM-dd 00:00:00');
      const tomorrow = new Date();
      const tomorrowString = this.datePipe.transform(tomorrow.setDate(tomorrow.getDate() + 1), 'yyyy-MM-dd 00:00:00');
      matchData.match.startTime.$gte = today;
      matchData.match.startTime.$lt = tomorrowString;
    } else {
      const theDaySelected = this.datePipe.transform( new Date(event.value[`_d`]), 'yyyy-MM-dd 00:00:00');
      const theNextDaySelected = new Date(event.value[`_d`]);
      const theNextDaySelectedString = this.datePipe.transform( theNextDaySelected.setDate(theNextDaySelected.getDate() + 1), 'yyyy-MM-dd 00:00:00');
      matchData.match.startTime.$gte = theDaySelected;
      matchData.match.startTime.$lt = theNextDaySelectedString;
    }

    this.adminService.events(
      matchData
    ).subscribe(result => {


      this.events = result;
      const coldBoot = this.events.find( ({eventSource}) => eventSource === 'coldBoot'  );
      const ignitionOn = this.events.find( ({eventSource}) => eventSource === 'ignition_on'  );
      const ignitionOff = this.events.find( ({eventSource}) => eventSource === 'ignition_off'  );

      console.log('strt');
      console.log(coldBoot);
      console.log(ignitionOn);
      console.log(ignitionOff);
      console.log('end');

      this.commissionForm.patchValue({ignitionPass: (typeof ignitionOn !== 'undefined' || coldBoot !== 'undefined') && typeof ignitionOff !== 'undefined' });


      this.snapshotsPerChannel = {};
      for (const e of result) {

        if (e.overpass && e.overpass.length >= 1){
          for (const over of e.overpass){
            if (over.tags && over.tags.name){
              e.locationStreetName = over.tags.name;
              break;
            }
          }
        }


        if ((e.snapshots && e.snapshots.length >= 1)  || (e.videos && e.videos.length >= 1)) {


          this.partnerEvents.push(e);
          for (const snap of e.snapshots){
            if (!this.snapshotsPerChannel[snap.ch.toString()]){
              this.snapshotsPerChannel[snap.ch] = [];
            }

            snap.theUrl = snap.file  + '_lrg?' + new Date().getTime();

            this.snapshotsPerChannel[snap.ch].push(snap);

          }

        }
      }
      this.matchVideoToEvents();
      this.filterEvents(null);
      this.eventsLoading = false;
      });
  }

  openCommissioningForm(): void {

    let options = {};

    if (this.commissioning.commissionStatus === 'active'){
      options = { closeOnNavigation: true, disableClose: true, hasBackdrop: true };
    } else {
       options = { closeOnNavigation: true, disableClose: true, width: '60%', height: '95%', hasBackdrop: true };
    }

    this.dialog.open(this.commissoningDialog, options);

  }

  // commissioning install details checks form validation
  commissioningInstallDataCheckComplete(): boolean {
    let disabled = false;
    if (!this.vehicleRegistration || this.commissionForm.value.installDate === '' || this.commissionForm.value.installEngineer.trim() === '' ||
      this.vehicleRegistration.trim() === '' || this.vehicleFleet.trim() === ''
    ){
      disabled = true;
    }
    return disabled;
  }

  // commissioning system checks form validation
  commissioningSystemCheckComplete(): boolean{
    let disabled = false;
    if (!this.commissionForm.value.ignitionPass || !this.commissionForm.value.gpsPass ||
      !this.commissionForm.value.gSensorCalibratePass || !this.deviceConnected){
      disabled = true;
    }
    return disabled;
  }
  // commissioning camera checks form validation
  commissioningCameraCheckComplete(): void {
    let disabled = false;
    for (const camera of this.camerasFormArray.value){
      if (!camera.orientation){
        disabled = true;
      }
    }
    this.commissioningCameraCheckCompleteValue =  disabled;
  }


  // commissioning validation for submission
  commissioningFinalCheckComplete(): boolean {
    let disabled = false;
    if (!this.commissionForm.valid){
      disabled = true;
    } else {
      console.log(this.commissionForm);
    }

    return disabled;
  }

  addNote() {

    const data =
      {
        id: this.id,
        note: this.note.value
      };

    this.adminService.updateVehicleNotes(data).subscribe(result => {
      console.log(result);
      this.ngOnInit();

    });

  }



  isADate(date): boolean {
    if (Object.prototype.toString.call(new Date(date)) === '[object Date]') {
      if (isNaN(new Date(date).getTime())) {  // d.valueOf() could also work
        return false;
      } else {
       return true;
      }
    } else {
      return false;
    }
  }

  filterEvents(event): void {
    event = event != null ? event : 'default';
    this.partnerEventsDisplay = [];

    if (event === 'severeOnly'){
      this.eventsFilters.videoOnly = false;
      this.eventsFilters.hyperlapseOnly = false;
      this.eventsFilters.videoQueueOnly = false;
    }

    if (event === 'videoOnly'){
      this.eventsFilters.severeOnly = false;
      this.eventsFilters.hyperlapseOnly = false;
      this.eventsFilters.videoQueueOnly = false;
    }
    if (event === 'videoQueueOnly'){
      this.eventsFilters.severeOnly = false;
      this.eventsFilters.videoOnly = false;
      this.eventsFilters.hyperlapseOnly = false;
    }

    if (event === 'hyperlapseOnly'){
      this.eventsFilters.severeOnly = false;
      this.eventsFilters.videoOnly = false;
      this.eventsFilters.videoQueueOnly = false;
    }





    for (const event of this.partnerEvents){

      if (this.eventsFilters.automatic){
        if (event.eventSource !== 'user'){
          if (this.eventsFilters.journey && event.eventSource !== 'journeyStart'){
            this.partnerEventsDisplay.push(event);
          }

          if (!this.eventsFilters.journey){
            if (event.eventSource !== 'journeyEnd' && event.eventSource !== 'journeyStart'){
              this.partnerEventsDisplay.push(event);
            }
          }
        }
      }

      if (this.eventsFilters.user){
        if (event.eventSource === 'user'){
          if (this.eventsFilters.journey){
            this.partnerEventsDisplay.push(event);
          }

          if (!this.eventsFilters.journey){
            if (event.eventSource !== 'journeyEnd' && event.eventSource !== 'journeyStart'){
              this.partnerEventsDisplay.push(event);
            }
          }
        }
      }

      if (this.eventsFilters.severeOnly) {
        this.partnerEventsDisplay = this.partnerEvents.filter(event => event.analysis && event.analysis.severity === 'severe');
      }

      if (this.eventsFilters.videoOnly) {
        this.partnerEventsDisplay = this.partnerEvents.filter(event => (event.videos2 && this.anyEventsViewable(event) && event.videos2.length >= 1));
      }

      if (this.eventsFilters.hyperlapseOnly) {
        this.partnerEventsDisplay = this.partnerEvents.filter(event => (event.videos2 && this.anyEventsViewable(event) && event.videos2.length >= 1
        && event.eventSource === 'hyperlapse'));
      }

      if (this.eventsFilters.videoQueueOnly) {
        this.partnerEventsDisplay = this.partnerEvents.filter(event => (event.videos2 && !this.anyEventsViewable(event) && event.videos2.length >= 1));
      }
    }

    console.log(this.partnerEventsDisplay);
  }




  viewEventOnMap(event): void{
    console.log(event);
    const mapDialog = this.dialog.open(this.eventLocationMapModal, {hasBackdrop: true});

    mapDialog.afterOpened().subscribe(_ => {

    mapboxgl.accessToken = environment.mapbox.accessToken;

    this.eventMap = new mapboxgl.Map({
      container: 'eventMap',
      style: 'mapbox://styles/fleetfocus/cjvxkhjay5ti71dqa1lldx82p?optimize=true',
      zoom: 14,
      center: [event.lng, event.lat]
    });
    this.eventMap.addControl(new mapboxgl.NavigationControl());
    new mapboxgl.Marker()
      .setLngLat([event.lng, event.lat])
      .addTo(this.eventMap);

    });

  }


  requestEventVideo(event): void {
    const clipStart = new Date(event.startTime).setSeconds(new Date(event.startTime).getSeconds() - 10);
    const clipEnd = new Date(event.startTime).setSeconds(new Date(event.startTime).getSeconds() + 10);

    const dataToSend = {
      dn: this.dn,
      callback: 'https://howenfront.tcstaging.co.uk/api/video-test.php',
      ch: 1,
      st: this.datePipe.transform( new Date(clipStart), 'yyyy-MM-dd HH:mm:ss'),
      et: this.datePipe.transform( new Date(clipEnd), 'yyyy-MM-dd HH:mm:ss')
    };



    this.adminService.videoRequest(dataToSend).subscribe( result => {
      console.log(result);
      this.dialog.open(this.videoRequestResultModal).afterOpened().subscribe(_ => {
        document.getElementById('videoRequestResultModalTitle').innerText = 'Requesting event video';
        document.getElementById('videoRequestResultModalContentWarn').style.display = 'none';
        document.getElementById('videoRequestResultModalContentSuccess').style.display = 'none';
        document.getElementById('videoRequestResultModalContentError').style.display = 'none';

        setTimeout(() => {
          document.getElementById('videoRequestResultModalLoading').style.display = 'none';
          if (result.message === 'Request for time period for this device-setup already exists') {
            document.getElementById('videoRequestResultModalContentWarn').style.display = 'block';
            document.getElementById('videoRequestResultModalContentWarn').innerText = 'A video request for this time period has already been received, and is being processed.';
          } else if (result.message === 'Connection failed, video request queued') {
            document.getElementById('videoRequestResultModalContentWarn').style.display = 'block';
            document.getElementById('videoRequestResultModalContentWarn').innerText = 'The device-setup could not be reached at this time, your request will be processed when it is next online.';
          } else if (result.message === 'Connection success, video upload requested') {
            document.getElementById('videoRequestResultModalContentSuccess').style.display = 'block';
            document.getElementById('videoRequestResultModalContentSuccess').innerText = 'Your request has been received, and is now being processed.';
          }
        }, 1000);
      });

    }, err => {

      this.dialog.open(this.videoRequestResultModal).afterOpened().subscribe(_ => {
        document.getElementById('videoRequestResultModalTitle').innerText = 'Requesting event video';

        setTimeout(() => {
          document.getElementById('videoRequestResultModalLoading').style.display = 'none';
          document.getElementById('videoRequestResultModalTitle').innerText = 'Requested event video response';
          document.getElementById('videoRequestResultModalContentError').style.display = 'block';
          document.getElementById('videoRequestResultModalContentError').innerText = err.error.message;
        }, 1000);
      });

    });

    this.matchVideoToEvents();
  }


  matchVideoToEvents(): void {
    this.adminService.videoAvailable({dn : this.dn}).subscribe( result => {
      if (result){
        result.forEach(video => {
          const startOfVideo = new Date(video.st).getTime();
          const endOfVideo = new Date(video.et).getTime();
          let match = false;


          this.partnerEventsDisplay.forEach(event => {
            const eventStart = new Date(event.startTime).getTime();

            // match timestamps of video to event
            if (eventStart >= startOfVideo && eventStart <= endOfVideo){
              if (!event.videos2 || event.videos2.length < 1) {
                event.videos2 = [];
              }
              video.isViewable = true;
              event.videos2.push(video);
              event.videoType = 'video';
              match = true;
            }
          });

          if (!match && new Date(video.st).toLocaleDateString() === new Date(this.eventsDatePicker).toLocaleDateString()){

            video.isViewable = true;

            const obj = {
              startTime: video.st,
              locationStreetName: '-',
              eventSource: 'videoUpload',
              videos2: [video],
              video: [video],
              videoType: 'video'
            };
            this.partnerEventsDisplay.push(obj);
            this.partnerEvents.push(obj);
            this.partnerEvents.sort(function(a, b) {
              return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
            });
            this.partnerEventsDisplay.sort(function(a, b) {
              return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
            });

          }

        });
      }

    });

// look at queued videos also, to disable button if video for timespan is already requested
    this.videosQueuedForUpload();
    this.hyperlapseVideos();
  }

  videosQueuedForUpload(): void {
    this.adminService.queuedVideoForDevice({dn: this.dn}).subscribe( result => {
      result.forEach(video => {

        const startOfVideo = new Date(video.st).getTime();
        const endOfVideo = new Date(video.et).getTime();
        let match = false;

        this.partnerEventsDisplay.forEach(event => {
          const eventStart = new Date(event.startTime).getTime();
          // match timestamps of video to event
          if (eventStart >= startOfVideo && eventStart <= endOfVideo){
            if (!event.videos2 || event.videos2.length < 1) {
              event.videos2 = [];
            }
            video.isViewable = false;
            event.videoType = 'video';
            event.videos2.push(video);
            match = true;
          }
        });

        if (!match && new Date(video.st).toLocaleDateString() === new Date(this.eventsDatePicker).toLocaleDateString()){

          video.isViewable = false;


          const obj = {
            startTime: video.st,
            locationStreetName: '-',
            eventSource: 'videoUploadPending',
            videos2: [video],
            video: [video],
            videoType: 'video'
          };
          this.partnerEventsDisplay.push(obj);
          this.partnerEvents.push(obj);
          this.partnerEvents.sort(function(a, b) {
            return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
          });

        }
      });
    });
  }




  hyperlapseVideos(): void {
    this.adminService.hyperlapse({dn: this.dn}).subscribe( result => {
      console.log(result);

      if (result && result.finished.length >= 1){
        result.finished.forEach(video => {
          if (new Date(video.startTimeHyper).toLocaleDateString() === new Date(this.eventsDatePicker).toLocaleDateString()) {
            video.isViewable = true;
            const obj = {
              startTime: video.startTimeHyper,
              locationStreetName: '-',
              eventSource: 'hyperlapse',
              videos2: [video],
              video: [video],
              percentComplete: 0,
              videoType: 'hyperlapse'
            };

            this.partnerEventsDisplay.push(obj);
            this.partnerEvents.push(obj);
            this.partnerEventsDisplay.sort(function(a, b) {
              return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
            });
          }
        });
      }

      if (result && result.queued.length >= 1){
        result.queued.forEach(video => {
          if (new Date(video.startTimeHyper).toLocaleDateString() === new Date(this.eventsDatePicker).toLocaleDateString()) {
            video.isViewable = false;
            const obj = {
              startTime: video.startTimeHyper,
              locationStreetName: '-',
              eventSource: 'hyperlapsePending',
              videos2: [video],
              video: [video],
              percentComplete: 0,
              videoType: 'hyperlapse'
            };

            if (video?.totalFrames > 0){
              obj.percentComplete = ((video.framesUploaded / video.totalFrames) * 100);
            }


            this.partnerEventsDisplay.push(obj);
            this.partnerEvents.push(obj);
            this.partnerEventsDisplay.sort(function(a, b) {
              return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
            });
          }
        });
      }
    });
  }

  anyEventsViewable(event): boolean {
    let anyVideosViewable = false;
    if (event.videos2 && event.videos2.length >= 1){
      event.videos2.forEach(video => {
        if (video.isViewable){
          anyVideosViewable = true;
        }
      });
    }
    return anyVideosViewable;
  }




}
////////////////////////////////////////////////////////////////////////////////////////////////////

@Component({
  selector: 'dialog-data-example-dialog',
  templateUrl: 'dialog-data-example-dialog.html',
})
export class DialogDataExampleDialog {

  API_URL = environment.API_URL;


  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
