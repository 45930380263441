import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import {ReactiveFormsModule} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { AuthInterceptor } from './auth.interceptor';
import {MatInputModule} from '@angular/material/input';
import { FleetsComponent } from './fleets/fleets.component';
import { UsersComponent } from './users/users.component';
import { DevicesComponent } from './devices/devices.component';
import { DialogDataExampleDialog } from './diagnostics/diagnostics.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatExpansionModule} from '@angular/material/expansion';
import { DeviceSetup } from './device-setup/device-setup.component';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import { DiagnosticsComponent } from './diagnostics/diagnostics.component';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
// import {SocketioService} from './socketio.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTabsModule} from '@angular/material/tabs';
import { DatePipe } from '@angular/common';
import { ReportsComponent } from './reports/reports.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatStepperModule} from '@angular/material/stepper';
import {MatChipsModule} from '@angular/material/chips';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
// @ts-ignore
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DataSharingService} from './data-sharing.service';
import { MediaComponent } from './media/media.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import { FooterComponent } from './components/footer/footer.component';
import { MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatBadgeModule} from '@angular/material/badge';
// @ts-ignore
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import {MatSliderModule} from '@angular/material/slider';
import { ImageCropperModule } from 'ngx-image-cropper';



import { DxRangeSelectorModule } from 'devextreme-angular';


import { MapComponent } from './map/map.component';
import { CustomMapboxPopupComponent } from './custom-mapbox-popup/custom-mapbox-popup.component';
import { PictureBoxComponent } from './events/events.component';
// import { DialogOverviewExampleDialogComponent } from './dialog-map-example/dialog-map-example.component';
import { EventsComponent } from './events/events.component';

import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatRadioModule} from '@angular/material/radio';
import { ChartsModule } from 'ng2-charts';
import { DynamicComponentService } from './dynamic-component.service';
import { MatMenuModule } from '@angular/material/menu';
import { DeviceComponent } from './device/device.component';
import { GroupsComponent } from './groups/groups.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { HealthChartComponent } from './device/health-chart/health-chart.component';
import { HealthSummaryComponent } from './device/health-summary/health-summary.component';
import { NotificationsModalComponent } from './components/notifications-modal/notifications-modal.component';
import { RawComponent } from './device/raw/raw.component';
import { DeviceSettingsComponent } from './device/device-settings/device-settings.component';
import { MenuBarComponent } from './components/menu-bar/menu-bar.component';
import { EventProfileModalComponent } from './device/event-profile-modal/event-profile-modal.component';
import { LivestreamComponent } from './livestream/livestream.component';
import { MultiLivestreamComponent } from './multiLivestream/multiLivestream.component';
import { CommissioningComponent } from './device/commissioning/commissioning.component';
import { PasswordChangeComponent } from './components/password-change/password-change.component';
import { DriverHealthCameraReportComponent } from './reports/driver-health-camera-report/driver-health-camera-report.component';
import { ViewSnapshotComponent } from './view-snapshot/view-snapshot.component';
import { TrackingSummaryComponent } from './device/tracking-summary/tracking-summary.component';
import {DevicesSummaryComponent} from "./devices-summary/devices-summary.component";
import { DeviceSearchComponent } from './device-search/device-search.component';
import { CanaryComponent } from './canary/canary.component';


// FullCalendarModule.registerPlugins([
//   dayGridPlugin
// ]);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FleetsComponent,
    UsersComponent,
    DevicesComponent,
    DeviceSetup,
    DiagnosticsComponent,
    DialogDataExampleDialog,
    ReportsComponent,
    MediaComponent,
    FooterComponent,
    MapComponent,
    CustomMapboxPopupComponent,
    PictureBoxComponent,
    EventsComponent,
    DeviceComponent,
    GroupsComponent,
    VideoPlayerComponent,
    HealthChartComponent,
    HealthSummaryComponent,
    NotificationsModalComponent,
    RawComponent,
    DeviceSettingsComponent,
    MenuBarComponent,
    EventProfileModalComponent,
    LivestreamComponent,
    MultiLivestreamComponent,
    CommissioningComponent,
    PasswordChangeComponent,
    DriverHealthCameraReportComponent,
    ViewSnapshotComponent,
    TrackingSummaryComponent,
    DevicesSummaryComponent,
    DeviceSearchComponent,
    CanaryComponent
  ],
  imports: [
    BrowserModule, HttpClientModule,
    BrowserAnimationsModule, MatExpansionModule,
    MatCardModule, MatSliderModule,
    ReactiveFormsModule, FormsModule,
    MatFormFieldModule, MatInputModule,
    AppRoutingModule, MatOptionModule, MatSelectModule, MatButtonModule, MatAutocompleteModule, MatIconModule, MatSnackBarModule,
    MatTableModule, MatSortModule,
    MatDialogModule, MatGridListModule, MatDatepickerModule, MatMomentDateModule, MatProgressSpinnerModule,
    MatTabsModule, MatTooltipModule, MatStepperModule, MatChipsModule, MatSlideToggleModule, ClipboardModule,
    FullCalendarModule, MatPaginatorModule, MatSidenavModule, MatRadioModule,
    ChartsModule, MatMenuModule, MatProgressBarModule, MatButtonToggleModule, DxRangeSelectorModule, MatBadgeModule,
    PasswordStrengthMeterModule.forRoot(), ImageCropperModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false, disableClose: false } }
    , DatePipe, DataSharingService, EventsComponent, DynamicComponentService, Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
