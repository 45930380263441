  <div style="position:relative;height:100%;">

<!--    <div style="position:absolute;bottom:0;right:0;">-->
<!--      <button type="button" mat-flat-button (click)="closeModal()">Close</button>-->
<!--    </div>-->

    <form *ngIf="commissioning.commissionStatus == 'setup'" [formGroup]="testedForm">
      <mat-form-field>
        <input
          matInput
          [matDatepicker]="dp"
          placeholder="Test bench date"
          formControlName="testBenchDate">
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp></mat-datepicker>
      </mat-form-field>
      &nbsp;
      <span class="pass-status">
        <span *ngIf="testedForm.value.gpsPass" class="pass">GPS<mat-icon>check</mat-icon></span>
        <span *ngIf="!testedForm.value.gpsPass" class="engineer-issue">
            GPS
          <mat-icon color="warn">close</mat-icon>
        </span>
      </span>

      <ng-container formArrayName="cameras">
        <strong>Cameras:</strong>
        <span *ngFor="let camerasGroup of camerasTestedFormArray.controls; index as i">
          <span [formGroup]="camerasGroup">
            {{testedForm.value.cameras[i].ch}}
            <span class="pass-status">
              <span *ngIf="testedForm.value.cameras[i].recording" class="pass">
                Recording
                <mat-icon color="primary">check</mat-icon></span>
                <span *ngIf="!testedForm.value.cameras[i].recording" class="engineer-issue">Error<mat-icon>close</mat-icon></span>
              </span>
            </span>
        </span>
      </ng-container>

      <div>
        <button mat-button color="primary" (click)="testedVehicle()" [disabled]="!testedForm.valid">
          Tested
        </button>
        &nbsp;&nbsp;&nbsp; Signed off by: <strong> {{testedForm.value.signedOffBy }}</strong> <br>
      </div>
      <!--{{commissionForm.value | json}}-->
    </form>


    <form [formGroup]="changeStatusForm" *ngIf="commissioning.commissionStatus == 'active' || commissioning.commissionStatus == 'service' ||
!commissioning?.commissionStatus">
      <h1>Change Commissioning Status</h1>
      <mat-form-field class="example-full-width">
        <mat-label>Change status</mat-label>
        <mat-select formControlName="commissionStatus">
          <mat-option [value]="'active'">active</mat-option>
          <mat-option [value]="'setup'">setup</mat-option>
          <mat-option [value]="'tested'">Install</mat-option>
          <mat-option [value]="'service'">service</mat-option>
          <mat-option [value]="'expired'">expired</mat-option>
          <mat-option [value]="'disabled'">disabled</mat-option>
          <mat-option [value]="'dead'">dead</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-button color="primary" (click)="changeCommissionStatus()" [disabled]="!changeStatusForm.valid">
        Change status
      </button>
      <br>
      <br>
      &nbsp;&nbsp;&nbsp;
    </form>


    <form [formGroup]="commissionForm" *ngIf="commissioning.commissionStatus == 'tested' || commissioning.commissionStatus == 'install'">
      <h1 style="font-size: 28px!important;">Device commissioning</h1>
      <mat-horizontal-stepper linear #stepper>
        <mat-step label="Installation details" state="engineer">
          <br>
          <div style="display: flex;flex-wrap: nowrap;">
            <div style="padding-right:10px;">
              Select the date of installation and the engineer that attended from the dropdown boxes below. Check with the
              engineer onsite that the vehicle registration and fleet name (customer name ) is correct and update it if required.
            </div>
            <div style="flex:1;text-align: right!important">
              <button mat-raised-button (click)="goForward(stepper)" style="width:150px;"
                      [disabled]="commissioningInstallDataCheckComplete()"
                      [ngClass]="{'greenCommissioningButton': !commissioningInstallDataCheckComplete(), 'blackCommissioningButton': commissioningInstallDataCheckComplete()}">
                <mat-icon style="vertical-align: center;" *ngIf="!commissioningInstallDataCheckComplete()">chevron_right</mat-icon>
                &nbsp;Next
              </button>
            </div>
          </div>
          <br>
          <mat-card>
            <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;">
              Installation details
            </mat-card-title>
            <mat-card-content>
              <div style="width:100%;justify-content: center;display: flex;flex-wrap: wrap;">
                <mat-form-field style="padding:5px;">
                  <mat-label>Install date</mat-label>
                  <input
                    matInput
                    [matDatepicker]="dp"
                    placeholder="Install date"
                    formControlName="installDate">
                  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                  <mat-datepicker #dp></mat-datepicker>
                </mat-form-field>

                <mat-form-field style="padding:5px;" *ngIf="usersGroup !== 'undefined'">
                  <mat-label>Install engineer</mat-label>
                  <input matInput formControlName="installEngineer" readonly>
                  <!--              <input  matInput formControlName="installEngineer">-->
                </mat-form-field>

                <mat-form-field style="padding:5px;" *ngIf="usersGroup === 'undefined'">
                  <mat-label>Install engineer</mat-label>
                  <mat-select formControlName="installEngineer">
                    <mat-option *ngFor="let eng of engineers" [value]="eng">
                      {{eng}}
                    </mat-option>
                  </mat-select>
                  <!--              <input  matInput formControlName="installEngineer">-->
                </mat-form-field>



                <mat-form-field style="padding:5px;" *ngIf="theVehicleSettings">
                  <mat-label>Vehicle registration/VIN</mat-label>
                  <input matInput [(ngModel)]="vehicleRegistration" [ngModelOptions]="{standalone: true}" oninput="this.value = this.value.toUpperCase()">
                  <!--              <input  matInput formControlName="installEngineer">-->
                </mat-form-field>

                <mat-form-field style="padding:5px;" *ngIf="theVehicleSettings">
                  <mat-label>Vehicle fleet</mat-label>
                  <mat-select [(ngModel)]="vehicleFleet" [ngModelOptions]="{standalone: true}">
                    <mat-option *ngFor="let fleet of allFleets" [value]="fleet.fleetId">
                      {{fleet.fleetName}}
                    </mat-option>
                  </mat-select>
                  <!--              <input  matInput formControlName="installEngineer">-->
                </mat-form-field>

              </div>
            </mat-card-content>
          </mat-card>
        </mat-step>

        <mat-step label="Confirm vehicle" state="vehicle">
          <div style="width:100%;height:100%;">
            <div *ngIf="!vehicleData" style="position: absolute;top:50%;left:50%;transform: translate(-50%,-50%)">
              <mat-spinner></mat-spinner>
            </div>

            <div *ngIf="vehicleData == 'noData'">
              No vehicle data found, reg may be incorrect please check and try again

              <br/><br/>
              <button mat-flat-button (click)="goBack(stepper)" style="background:#f24141!important;color:white!important;">
                Go Back
              </button>
              <button mat-flat-button (click)="goForward(stepper)" style="background:#f24141!important;color:white!important;">
                Proceed anyway
              </button>
            </div>

            <div style="font-size:16px;text-align: center;width: 100%" *ngIf="vehicleData !== 'noData'">

              <h3 style="font-weight: bold;">Do these details look correct for the vehicle?</h3>
              <br/>

              <img src="https://cdn.vdicheck.com/badges/{{vehicleData?.Make}}.png?width=250">
              <br />

              <table style="margin: 0 auto;text-align:left;">
                <tr>
                  <th>Model:</th>
                  <td>{{vehicleData?.Model}}</td>
                </tr>
                <tr>
                  <th>Colour:</th>
                  <td>
                    <div style="width:10px;height:10px;display:inline-block;border: 1px solid black; background:{{vehicleData?.Colour}}"></div>
                    ({{vehicleData?.Colour}})
                  </td>
                </tr>
                <tr>
                  <th>Fuel:</th>
                  <td>{{vehicleData?.FuelType}}</td>
                </tr>
                <tr>
                  <th>Door Plan:</th>
                  <td>{{vehicleData?.DoorPlanLiteral}}</td>
                </tr>
                <tr>
                  <th>Wheel Plan:</th>
                  <td>{{vehicleData?.WheelPlan}}</td>
                </tr>
                <tr>
                  <th>Class:</th>
                  <td>{{vehicleData?.VehicleClass}}</td>
                </tr>
              </table>

              <br><br>
              <button mat-flat-button (click)="goForward(stepper)" style="background:#5C8459!important;color:white!important;margin-right:10px;">
                Confirm
              </button>

              <button mat-flat-button (click)="goBack(stepper)" style="background:#f24141!important;color:white!important;">
                Go Back
              </button>
            </div>
          </div>
        </mat-step>




        <mat-step label="Event Profile" state="device">
          <br>
          <div style="display: flex;flex-wrap: nowrap;">
            <div style="padding-right:10px;">
              Select the event profile required for the device.
            </div>
            <div style="flex:1;text-align: right!important">
              <button mat-raised-button style="width:150px;" (click)="goForward(stepper)" class="greenCommissioningButton">
                <!--- [disabled]="commissioningSystemCheckComplete()" --->
                <mat-icon style="vertical-align: center;">chevron_right</mat-icon>
                &nbsp;Next
              </button>

              <br>

              <button mat-raised-button matStepperPrevious style="color:white!important;background:#757575!important;width:150px;margin-top:5px;">
                <mat-icon style="vertical-align: center;">chevron_left</mat-icon>
                &nbsp;Back
              </button>
            </div>
          </div>
          <br>
          <mat-card [formGroup]="vehicleForm">
            <mat-form-field style="padding:5px;" *ngIf="eventProfiles">
              <mat-label>Event Profile</mat-label>
              <mat-select formControlName="eventProfile">

                <mat-optgroup label="Recommended profile(s)">
                  <mat-option *ngFor="let profile of recommendedProfiles" [value]="profile.profileName">
                    {{profile.profileName}}
                  </mat-option>
                </mat-optgroup>
                <mat-optgroup label="All profiles">
                  <mat-option *ngFor="let profile of eventProfiles" [value]="profile.profileName">
                    {{profile.profileName}}
                  </mat-option>
                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </mat-card>
        </mat-step>


        <mat-step label="Systems checks" state="device">
          <br>
          <div style="display: flex;flex-wrap: nowrap;">
            <div style="padding-right:10px;">
              To confirm the correct installation of the device automatic checks on the wiring, antenna positioning, calibration
              and cameras are performed. Where a check fails (shown in red) hover over the tooltip icon (?) for tips and guidance on how to solve.
            </div>
            <div style="flex:1;text-align: right!important">
              <button mat-raised-button style="width:150px;" (click)="goForward(stepper)"
                      [ngClass]="{'greenCommissioningButton': !commissioningSystemCheckComplete(), 'blackCommissioningButton': commissioningSystemCheckComplete()}"
                      >
                <!--- [disabled]="commissioningSystemCheckComplete()" --->
                <mat-icon style="vertical-align: center;">chevron_right</mat-icon>
                &nbsp;Next
              </button>

              <br>

              <button mat-raised-button matStepperPrevious style="color:white!important;background:#757575!important;width:150px;margin-top:5px;">
                <mat-icon style="vertical-align: center;">chevron_left</mat-icon>
                &nbsp;Back
              </button>
            </div>
          </div>
          <br>
          <mat-card>
            <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;">
              Systems checks
            </mat-card-title>
            <mat-card-content>


              <!---underspeedalarm--->
              <button style="float:right;" mat-flat-button (click)="getDeviceStatus(); triggerUnderSpeedAlarm()">
                <mat-icon>refresh</mat-icon>
                Refresh
              </button>

              <div style="display: flex;width:100%; justify-content: space-evenly;flex-wrap: wrap;font-size:16px;">
                <div style="text-align: center;padding:10px;flex:1;">
                  <div *ngIf="commissionForm.value.ignitionPass" class="deviceActive">
                    <svg style="width:24px;height:24px;vertical-align: middle" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M9.5,0A3,3 0 0,0 6.5,3A3,3 0 0,0 9.5,6C10.81,6 11.92,5.17 12.33,4H14.5V6H16.5V4H17.5V2H12.33C11.92,0.83 10.81,0 9.5,0M9.5,2A1,1 0 0,1 10.5,3A1,1 0 0,1 9.5,4A1,1 0 0,1 8.5,3A1,1 0 0,1 9.5,2M6.5,8C5.84,8 5.28,8.42 5.08,9L3,15V23A1,1 0 0,0 4,24H5A1,1 0 0,0 6,23V22H18V23A1,1 0 0,0 19,24H20A1,1 0 0,0 21,23V15L18.92,9C18.72,8.42 18.16,8 17.5,8H6.5M6.5,9.5H17.5L19,14H5L6.5,9.5M6.5,16A1.5,1.5 0 0,1 8,17.5A1.5,1.5 0 0,1 6.5,19A1.5,1.5 0 0,1 5,17.5A1.5,1.5 0 0,1 6.5,16M17.5,16A1.5,1.5 0 0,1 19,17.5A1.5,1.5 0 0,1 17.5,19A1.5,1.5 0 0,1 16,17.5A1.5,1.5 0 0,1 17.5,16Z" />
                    </svg>
                    <br>
                    Wiring<br>
                    <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="The vehicle wiring test has passed.">
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </div>
                  <div *ngIf="!commissionForm.value.ignitionPass" class="deviceWarning">
                    <svg style="width:24px;height:24px;vertical-align: middle" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M9.5,0A3,3 0 0,0 6.5,3A3,3 0 0,0 9.5,6C10.81,6 11.92,5.17 12.33,4H14.5V6H16.5V4H17.5V2H12.33C11.92,0.83 10.81,0 9.5,0M9.5,2A1,1 0 0,1 10.5,3A1,1 0 0,1 9.5,4A1,1 0 0,1 8.5,3A1,1 0 0,1 9.5,2M6.5,8C5.84,8 5.28,8.42 5.08,9L3,15V23A1,1 0 0,0 4,24H5A1,1 0 0,0 6,23V22H18V23A1,1 0 0,0 19,24H20A1,1 0 0,0 21,23V15L18.92,9C18.72,8.42 18.16,8 17.5,8H6.5M6.5,9.5H17.5L19,14H5L6.5,9.5M6.5,16A1.5,1.5 0 0,1 8,17.5A1.5,1.5 0 0,1 6.5,19A1.5,1.5 0 0,1 5,17.5A1.5,1.5 0 0,1 6.5,16M17.5,16A1.5,1.5 0 0,1 19,17.5A1.5,1.5 0 0,1 17.5,19A1.5,1.5 0 0,1 16,17.5A1.5,1.5 0 0,1 17.5,16Z" />
                    </svg>
                    <br>
                    Wiring<br>
                    <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="The vehicle wiring test has failed, please ask engineer to check yellow wire is connected to a switched live and to cycle ignition off for 60 seconds and then back on.">
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </div>
                </div>
                <div style="text-align: center;padding:10px;flex:1;">
                  <div *ngIf="commissionForm.value.gpsPass" class="deviceActive">
                    <svg viewBox="0 0 24 24" style="width: 24px; height: 24px; vertical-align: middle;">
                      <path fill="currentColor" d="M11.62,1L17.28,6.67L15.16,8.79L13.04,6.67L11.62,8.09L13.95,10.41L12.79,11.58L13.24,12.04C14.17,11.61 15.31,11.77 16.07,12.54L12.54,16.07C11.77,15.31 11.61,14.17 12.04,13.24L11.58,12.79L10.41,13.95L8.09,11.62L6.67,13.04L8.79,15.16L6.67,17.28L1,11.62L3.14,9.5L5.26,11.62L6.67,10.21L3.84,7.38C3.06,6.6 3.06,5.33 3.84,4.55L4.55,3.84C5.33,3.06 6.6,3.06 7.38,3.84L10.21,6.67L11.62,5.26L9.5,3.14L11.62,1M18,14A4,4 0 0,1 14,18V16A2,2 0 0,0 16,14H18M22,14A8,8 0 0,1 14,22V20A6,6 0 0,0 20,14H22Z"></path>
                    </svg>
                    <br>
                    GPS<br>
                    <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="GPS signal strength test has passed (connected to {{this.deviceStatus?.satellitesQuantity}} satellites)_">
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </div>
                  <div *ngIf="!commissionForm.value.gpsPass" class="deviceWarning">
                    <svg viewBox="0 0 24 24" style="width: 24px; height: 24px; vertical-align: middle;">
                      <path fill="currentColor" d="M11.62,1L17.28,6.67L15.16,8.79L13.04,6.67L11.62,8.09L13.95,10.41L12.79,11.58L13.24,12.04C14.17,11.61 15.31,11.77 16.07,12.54L12.54,16.07C11.77,15.31 11.61,14.17 12.04,13.24L11.58,12.79L10.41,13.95L8.09,11.62L6.67,13.04L8.79,15.16L6.67,17.28L1,11.62L3.14,9.5L5.26,11.62L6.67,10.21L3.84,7.38C3.06,6.6 3.06,5.33 3.84,4.55L4.55,3.84C5.33,3.06 6.6,3.06 7.38,3.84L10.21,6.67L11.62,5.26L9.5,3.14L11.62,1M18,14A4,4 0 0,1 14,18V16A2,2 0 0,0 16,14H18M22,14A8,8 0 0,1 14,22V20A6,6 0 0,0 20,14H22Z"></path>
                    </svg>
                    <br>
                    GPS<br>
                    <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="The GPS performance test has failed (connected to {{this.deviceStatus?.satellitesQuantity}} satellites), please ask engineer to check the connection of the GPS antenna and reposition it if required to a position with a clear view of the sky.">
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </div>
                </div>
                <div style="text-align: center;padding:10px;flex:1;">
                  <div *ngIf="commissionForm.value.gSensorCalibratePass" class="deviceActive" >
                    <svg style="width:24px;height:24px;vertical-align: middle;" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M13,19.92C14.8,19.7 16.35,18.95 17.65,17.65C18.95,16.35 19.7,14.8 19.92,13H16.92C16.7,14 16.24,14.84 15.54,15.54C14.84,16.24 14,16.7 13,16.92V19.92M10,8H14L17,11H19.92C19.67,9.05 18.79,7.38 17.27,6C15.76,4.66 14,4 12,4C10,4 8.24,4.66 6.73,6C5.21,7.38 4.33,9.05 4.08,11H7L10,8M11,19.92V16.92C10,16.7 9.16,16.24 8.46,15.54C7.76,14.84 7.3,14 7.08,13H4.08C4.3,14.77 5.05,16.3 6.35,17.6C7.65,18.9 9.2,19.67 11,19.92M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12C22,14.75 21,17.1 19.05,19.05C17.1,21 14.75,22 12,22C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z" />
                    </svg>
                    <br>
                    G-Sensor<br>
                    <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="The G-Force sensor is correctly calibrated.">
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </div>
                  <div *ngIf="!commissionForm.value.gSensorCalibratePass" class="deviceWarning" style="cursor: pointer;">
                    <svg style="width:24px;height:24px;vertical-align: middle;color: white;" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M13,19.92C14.8,19.7 16.35,18.95 17.65,17.65C18.95,16.35 19.7,14.8 19.92,13H16.92C16.7,14 16.24,14.84 15.54,15.54C14.84,16.24 14,16.7 13,16.92V19.92M10,8H14L17,11H19.92C19.67,9.05 18.79,7.38 17.27,6C15.76,4.66 14,4 12,4C10,4 8.24,4.66 6.73,6C5.21,7.38 4.33,9.05 4.08,11H7L10,8M11,19.92V16.92C10,16.7 9.16,16.24 8.46,15.54C7.76,14.84 7.3,14 7.08,13H4.08C4.3,14.77 5.05,16.3 6.35,17.6C7.65,18.9 9.2,19.67 11,19.92M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12C22,14.75 21,17.1 19.05,19.05C17.1,21 14.75,22 12,22C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z" />
                    </svg>
                    <br>
                    G-Sensor<br>
                    <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="The G-Force sensor is out of calibration">
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </div>
                </div>
                <div *ngFor="let camera of cameras index as i" style="text-align: center;padding:10px;flex:1;">
                  <div *ngIf="deviceStatus?.recordingStatusArray[(camera.channel - 1)] === '1' && deviceConnected" class="deviceActive">
                    <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
                    </svg>
                    <br>
                    {{cameras[i].camPosition}} cam<br>
                    <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="{{cameras[i].camPosition}} camera is connected correctly and recording.">
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </div>
                  <div *ngIf="deviceStatus?.recordingStatusArray[(camera.channel - 1)] !== '1' || !deviceConnected" class="deviceWarning">
                    <svg style="width:24px;height:24px;vertical-align:middle;color: white;" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
                    </svg>
                    <br>{{cameras[i].camPosition}} cam<br>
                    <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="{{cameras[i].camPosition}} camera is not recording, ask engineer to ensure ignition is on and the camera is wired correctly.">
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </div>
                </div>
              </div>

<!--              <div *ngIf="!commissionForm.value.gSensorCalibratePass" style="text-align: center">-->
<!--                <button mat-raised-button (click)="calibrateGSensor()" [disabled]="deviceStatus.speed > 0 || disableCalibrateGButton">-->
<!--                  Calibrate G-Sensor-->
<!--                </button>-->
<!--                <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-left:5px;" viewBox="0 0 24 24" matTooltip="Click here to calibrate the G-Sensor. The ignition must be on and the vehicle must be stationary.">-->
<!--                  <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />-->
<!--                </svg>-->
<!--              </div>-->
            </mat-card-content>
          </mat-card>
        </mat-step>


        <mat-step label="Camera alignment checks" state="camera">
          <br>
          <div style="display: flex;flex-wrap: nowrap;">
            <div style="padding-right:10px;">
              Use the snapshots below to confirm the correct installation and orientation of cameras, ask the engineer to adjust
              the camera if required and request an updated snapshot. Use the alignment confirmation toggle to proceed to
              commission the device.
            </div>
            <div style="flex:1;text-align: right!important">
              <button mat-raised-button (click)="stopAllAndNext(stepper)" style="width:150px;" [disabled]="commissioningCameraCheckCompleteValue"
                      [ngClass]="{'greenCommissioningButton': !commissioningCameraCheckCompleteValue, 'blackCommissioningButton': commissioningCameraCheckCompleteValue}">
                <mat-icon style="vertical-align: center;" *ngIf="!commissioningCameraCheckCompleteValue">chevron_right</mat-icon>
                &nbsp;Next
              </button>
              <br>

              <button mat-raised-button matStepperPrevious style="color:white!important;background:#757575!important;width:150px;margin-top:5px;">
                <mat-icon style="vertical-align: center;">chevron_left</mat-icon>
                &nbsp;Back
              </button>
            </div>
          </div>
          <br>
          <mat-card>
            <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;">
              Camera alignment checks (Ensure ignition is on)


            <span *ngIf="deviceStatus && deviceStatus.signalTrafficLight == 'good'" style="margin-left: auto;color:green;">
                <mat-icon matTooltip="Device has good signal">signal_cellular_4_bar</mat-icon>
            </span>
            <span *ngIf="deviceStatus && deviceStatus.signalTrafficLight == 'average'" style="margin-left: auto;color:orange">
                <mat-icon matTooltip="Device has average signal, livestreaming may take some time">signal_cellular_2_bar</mat-icon>
            </span>
            <span *ngIf="deviceStatus && deviceStatus.signalTrafficLight == 'weak'" style="margin-left: auto;color:red;">
                <mat-icon matTooltip="Device has weak signal, livestreaming may be slow">signal_cellular_1_bar</mat-icon>
            </span>

              <button (click)="startStopAll('play')" mat-flat-button [disabled]="playing ==='play'"
                      style="background:#5C8459!important;color:white!important;">
                start
              </button>

              <button (click)="startStopAll('stop')" mat-flat-button style="background:#F24141!important;color:white!important;margin-right:10px;" [disabled]="playing ==='stop'">
                stop
              </button>

              <div style="flex:1;display:flex;justify-content: flex-start;">
                <div style="padding-right:5px;">
                  Positioned<br/>
                  correctly?
                </div>

                <mat-slide-toggle [checked]="!commissioningCameraCheckCompleteValue"
                                  (change)="commissioningCameraCheckComplete($event)"></mat-slide-toggle>
              </div>

            </mat-card-title>
            <mat-card-content formArrayName="cameras" style="width: 100%!important;display: flex;padding:0px!important;">
              <app-multiLivestream [device]="vehicle"></app-multiLivestream>
            </mat-card-content>
          </mat-card>
        </mat-step>

        <mat-step label="Commission device" state="finshLine">
          <br>
          <div style="width: 100%;text-align: right!important">
            <button mat-raised-button class="greenCommissioningButton" (click)="commissionVehicle(stepper)"  style="width: 150px;vertical-align: center;">
              <!--                  [disabled]="commissioningFinalCheckComplete()" [ngClass]="{'greenCommissioningButton': !commissioningFinalCheckComplete(), 'blackCommissioningButton': commissioningFinalCheckComplete()}"-->
              <mat-icon>done</mat-icon>
              <!--- *ngIf="!commissioningFinalCheckComplete()" --->
              &nbsp;Commission
            </button>

            <br>

            <button mat-raised-button matStepperPrevious style="color:white!important;background:#757575!important;width:150px;margin-top:5px;">
              <mat-icon style="vertical-align: center;">chevron_left</mat-icon>
              &nbsp;Back
            </button>
            <!--          <button mat-flat-button (click)="multiLivestream()">Test device params recheck</button>-->


          </div>
          <br>
          <mat-card>
            <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;">
              Final checks for commissioning:
            </mat-card-title>
            <mat-card-content>
              <div style="font-size: 18px;">
                <mat-icon style="color: black!important;">done</mat-icon>	Check device status lights, SD should be flashing, all three others should be on.<br><br>
                <mat-icon style="color: black!important;">done</mat-icon>	Check device has been installed securely and does not move around.<br><br>
                <mat-icon style="color: black!important;">done</mat-icon>	Check engineer has tightened the camera locking screws.<br><br>
                <mat-icon style="color: black!important;">done</mat-icon>	Remind engineer to take photos of both the device and camera positions.
              </div>

              <br><br>

              <mat-form-field style="width: 100%;">
                <mat-label>Additional notes from commissioning (optional)</mat-label>
                <textarea matInput [(ngModel)]="commissioningFinalNotes" [ngModelOptions]="{standalone: true}"></textarea>
              </mat-form-field>


            </mat-card-content>
          </mat-card>
        </mat-step>


        <mat-step label="Commission device" state="end">
            <div style="text-align: center;">
              <h3 style="font-weight: bold;font-size: 32px;">Commissioning complete</h3>
              Your unique reference is {{commissionRef}}.
              <br /><br />
              <button mat-flat-button (click)="closeModal()" style="margin: 0 auto;">Finish</button>
            </div>

        </mat-step>

        <!-- Icon overrides. -->
        <ng-template matStepperIcon="engineer">
          <mat-icon>account_circle</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="vehicle">
          <mat-icon>directions_car</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="device">
          <mat-icon>router</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="camera">
          <mat-icon>videocam</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="finshLine">
          <mat-icon>done</mat-icon>
        </ng-template>
        <ng-template matStepperIcon="end">
          <mat-icon>sports_score</mat-icon>
        </ng-template>
      </mat-horizontal-stepper>
    </form>
  </div>
  <ng-template #selfCertModal>
    <div style="width:100%;height:100%;position:relative">
      <div style="font-size:16px;text-align: center;position: absolute;top:30%;padding:10px;">

        <svg width="150px" height="150px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0-beta3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2021 Fonticons, Inc. --><path d="M256 320c4.406 0 8.013-3.594 8.013-8v-160c0-4.406-3.607-8-8.013-8c-4.406 0-7.987 3.594-7.987 8v160C248 316.4 251.6 320 256 320zM506.3 417l-213.3-364C284.8 39 270.4 32 256 32C241.7 32 227.2 39 218.1 53l-213.2 364C-10.62 444.9 9.848 480 42.74 480h426.6C502 480 522.7 445 506.3 417zM492.6 450.7C487.8 459 479.1 464 469.3 464H42.73c-9.789 0-18.49-4.982-23.27-13.33c-4.678-8.162-4.641-17.49 .1035-25.58L232.8 61.1C237.6 52.9 246.3 48 256 48c9.699 0 18.34 4.887 23.14 13.09l213.3 364C497.2 433.2 497.2 442.5 492.6 450.7zM256.1 368c-8.822 0-16 7.178-16 16s7.138 16 15.96 16c8.822 0 16.04-7.178 16.04-16S264.9 368 256.1 368z"/></svg>

        <br><br>

        You have clicked to proceed with commissioning without passing
        the system checks. Incorrectly installed devices may not record video or track properly,
        they may also cause battery and storage problems and use excessive data. To
        continue without completing the system checks, click Self-Certify or choose Go
        Back to complete the tests.

        <br><br>

        <button mat-flat-button (click)="selfCertify()" mat-dialog-close style="background:#F24141!important;color:white!important;margin-right:10px;">
          Self-Certify
        </button>

        <button mat-flat-button mat-dialog-close style="background:#5C8459!important;color:white!important;">
          Go Back
        </button>
      </div>
    </div>
  </ng-template>
