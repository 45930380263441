<div style="display: flex;">
  <h1 style="font-size: 28px!important;font-weight: bold;">Users</h1>
  <div style="flex:1;"></div>
  <button mat-flat-button (click)="showHideSearch()" style="margin-right:5px;height: 40px !important;" matTooltip="Search users">
    <mat-icon>search</mat-icon>
  </button>
  <button mat-raised-button (click)="editUser($event, null)"
          style="margin-right:5px;height: 40px !important;background-color:#5C8459 !important;color:white!important;box-shadow: 2px 2px 0 0 rgb(0 0 0 / 20%)">
    + Add User
  </button>
</div>
<br>
<div [hidden]="!showSearch" style="text-align: center;">
<form>
  <mat-form-field style="width:300px;">
    <mat-label>Search By Email, Name or Fleet Access</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Filter" [(ngModel)]="searchValue" [ngModelOptions]="{standalone: true}">
  </mat-form-field>
  <button mat-button   mat-flat-button  color="primary"  aria-label="Clear" (click)="cancelFilter()" style="margin-left:20px;">
    <mat-icon>close</mat-icon>
    Reset Filter
  </button>
</form>
</div>

<div style="max-height: 75vh!important;overflowY:scroll;">
<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="Email">
    <th mat-header-cell *matHeaderCellDef style="text-align: left;">
      Email
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Users email and login">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: left;">{{element.email}}</td>
  </ng-container>
  <ng-container matColumnDef="Name">
    <th mat-header-cell *matHeaderCellDef style="text-align: left;">
      Name
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Users full name">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: left;">{{element.name}}</td>
  </ng-container>
  <ng-container matColumnDef="fleets">
    <th mat-header-cell *matHeaderCellDef style="text-align:left!important;">
      Fleets
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Fleets user has access to">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-chip-list >
        <mat-chip *ngFor="let fleet of element.fleets">{{fleet}}</mat-chip>
      </mat-chip-list>
    </td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef style="text-align: center;">Edit</th>
    <td mat-cell *matCellDef="let element">
      <button mat-flat-button (click)="editUser($event, element)" style="line-height: 30px;" [disabled]="authorisation === 'Fleet'">
        <mat-icon style="font-size: 22px;">edit</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="usersDisplayedColumns;sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: usersDisplayedColumns;"></tr>
</table>

<mat-paginator [pageSizeOptions]="[25, 50, 75]" showFirstLastButtons style="margin-right:10px;"></mat-paginator>
</div>



<mat-card *ngIf="tableLoading" style="display: flex; justify-content: center; align-items: center">
  <mat-progress-spinner
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
</mat-card>


<button (click)="scrollToTop()" class="scrollTopButton" id="scrollTopButtonEle">
  <mat-icon>arrow_circle_up</mat-icon>
</button>





<ng-template #editUserDialog>

  <h1 *ngIf="user" style="font-size: 28px!important;">Editing User</h1>
  <h1 *ngIf="!user" style="font-size: 28px!important;">Adding User</h1>
  <form [formGroup]="userForm" (ngSubmit)="onFormSubmit()">


    <div style="display:flex;gap:10px;height:100%">
      <div style="flex:1;">
        <mat-card>
        <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;background:#000000d4;color:white;">
<!--          <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">-->
<!--            <path fill="currentColor" d="M19.07,4.93L17.66,6.34C19.1,7.79 20,9.79 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12C4,7.92 7.05,4.56 11,4.07V6.09C8.16,6.57 6,9.03 6,12A6,6 0 0,0 12,18A6,6 0 0,0 18,12C18,10.34 17.33,8.84 16.24,7.76L14.83,9.17C15.55,9.9 16,10.9 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12C8,10.14 9.28,8.59 11,8.14V10.28C10.4,10.63 10,11.26 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12C14,11.26 13.6,10.62 13,10.28V2H12A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,9.24 20.88,6.74 19.07,4.93Z" />                </svg>-->
<!--          -->
          Form
        </mat-card-title>
        <mat-card-content style="text-align: center;">



          <table style="text-align: left!important;width: 100%;">
            <tr>
              <th style="text-align: left;padding-left:30px;">
                User email:
              </th>
              <td style="display:flex;">
                <mat-form-field style="width:300px;margin-left: auto;">
                  <input matInput formControlName="email" oninput="this.value = this.value.toLowerCase()">
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="User email address and login" matSuffix>
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <th style="text-align: left;padding-left:30px;">
                User name:
              </th>
              <td style="display:flex;">
                <mat-form-field style="width:300px;margin-left: auto;">
                  <input matInput formControlName="name" oninput="this.value = this.value.toLowerCase()">
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="User full name" matSuffix>
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </mat-form-field>
              </td>
            </tr>
            <tr>
              <th style="text-align: left;padding-left:30px;">
                User group:
              </th>
              <td style="display:flex;">
                <mat-form-field  *ngIf="authorisation !== 'superuser'" style="width: 300px;margin-left:auto;">
                  <mat-label>Group</mat-label>
                  <mat-select formControlName="mflGroup">
                    <mat-option [value]="mflGroup">{{mflGroup}}</mat-option>
                  </mat-select>
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="The group the user belongs to" matSuffix>
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </mat-form-field>

                <!---mfl ability to set group--->
                <mat-form-field  *ngIf="authorisation == 'superuser'" style="width: 300px;margin-left:auto;">
                  <mat-label>Group</mat-label>
                  <mat-select formControlName="mflGroup">
                    <mat-option *ngFor="let group of groups" [value]="group">{{group}}</mat-option>
                  </mat-select>
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="The group the user belongs to" matSuffix>
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </mat-form-field>
            </tr>
            <tr>
              <th style="text-align: left;padding-left:30px;">
                Authorisation:
              </th>
              <td style="display:flex;">
                <mat-form-field style="width: 300px;margin-left:auto;">
                  <mat-select formControlName="authorisation">
<!--                    <mat-option value="superuser" *ngIf="authorisation =='superuser'">SUPERUSER</mat-option>-->
                    <mat-option value="mflGroup" *ngIf="authorisation === 'mflGroup' || authorisation === 'superuser'">
                      <span *ngIf="mflGroup !== 'undefined'">{{userForm?.value?.mflGroup}} Admin</span>
                      <span *ngIf="mflGroup == 'undefined'">Group Admin</span>
                    </mat-option>
                    <mat-option value="fleet">Fleet</mat-option>
                  </mat-select>
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="User authorisation" matSuffix>
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </mat-form-field>
              </td>
            </tr>



            <tr *ngIf="userLevel === 'superuser'">
              <th style="text-align: left;padding-left:30px;">
                Max video duration (seconds):
              </th>
              <td style="display:flex;">
                <mat-form-field style="width: 300px;margin-left:auto;">
                  <input type="number" matInput formControlName="videoRequestTimeLimit" />

                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="Maximum duration for a single video request" matSuffix>
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </mat-form-field>
              </td>
            </tr>




            <tr>
              <th style="text-align:left;padding-left:30px;">Delete user:</th>
              <td>
                <mat-slide-toggle [(ngModel)]="deleteUser" [ngModelOptions]="{standalone: true}"
                                  style="transform: scale(1.2);float: right;">
                </mat-slide-toggle>
              </td>
            </tr>

          </table>

          <div style="text-align: center; margin-top: 20px;">
            <button *ngIf="!userForm.value.password"  mat-flat-button  color="primary" (click)="generatePassword()">Generate password</button>
          </div>
          <br>


          <mat-card *ngIf="userForm.value.password" style="text-align: center;">
            A new password has been generated for the user, and is shown below.
            You must copy this to a safe place, or cancel the new password via the button below.
            <br><br>
            <div *ngIf="userForm.value.password" style="display: inline-block;margin-right: 5px;">
              <h4>New password: <strong>{{userForm.value.password}}</strong></h4>
            </div>
            <button type="button" mat-flat-button [cdkCopyToClipboard]="userForm.value.password" >
              <mat-icon>content_copy</mat-icon>
              Copy
            </button>

            <br>
            <button *ngIf="userForm.value.password" mat-flat-button  color="primary" (click)="userForm.value.password = null" style=" margin-bottom: 5px;">
              I don't want a new password
            </button>



          </mat-card>



        </mat-card-content>
        </mat-card>
      </div>
      <div style="flex:1;">
        <mat-card style="height:100%">
          <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;background:#000000d4;color:white;">
            <!--          <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">-->
            <!--            <path fill="currentColor" d="M19.07,4.93L17.66,6.34C19.1,7.79 20,9.79 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12C4,7.92 7.05,4.56 11,4.07V6.09C8.16,6.57 6,9.03 6,12A6,6 0 0,0 12,18A6,6 0 0,0 18,12C18,10.34 17.33,8.84 16.24,7.76L14.83,9.17C15.55,9.9 16,10.9 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12C8,10.14 9.28,8.59 11,8.14V10.28C10.4,10.63 10,11.26 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12C14,11.26 13.6,10.62 13,10.28V2H12A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,9.24 20.88,6.74 19.07,4.93Z" />                </svg>-->
            <!--          -->
            Fleets
          </mat-card-title>
          <mat-card-content style="text-align: center;">



           <div style="font-weight: bold;">Access</div>
            <div style="text-align: center;">
              <mat-form-field>
                <button matPrefix mat-icon-button ><strong>+</strong></button>
                <mat-label>Add fleet</mat-label>
                <mat-select [(value)]="fleetDropDown" (valueChange)="addFleet(fleetDropDown)">
                  <mat-option *ngFor="let fleet of allFleets" [value]="fleet.fleetId">
                    {{fleet.fleetName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <br>
            </div>


            <ng-container formArrayName="fleets" *ngIf="fleets">
              <center>
                <span *ngFor="let fleet of fleets.controls; index as i">
                  <mat-form-field class="example-full-width" style="margin-top: 2px;margin-left: 16px;">
                    <input matInput [formControlName]="i"/>
                    <!--<mat-icon (click)="removeFleet(i)" matSuffix>x</mat-icon>-->
                    <button type="button" matSuffix mat-icon-button (click)="removeFleet(i)"><strong>x</strong></button>
                  </mat-form-field>
                </span>
              </center>
            </ng-container>



            <div style="font-weight: bold;">Admin</div>
            <div style="text-align: center;">
              <mat-form-field>
                <button matPrefix mat-icon-button ><strong>+</strong></button>
                <mat-label>Add fleet admin</mat-label>
                <mat-select [(value)]="fleetAdminDropDown" (valueChange)="addAdminFleet(fleetAdminDropDown)">
                  <mat-option *ngFor="let fleet of allFleets" [value]="fleet.fleetId">
                    {{fleet.fleetName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <br>
            </div>


            <ng-container formArrayName="adminFleets" *ngIf="adminFleets">
              <center>
                <span *ngFor="let fleet of adminFleets.controls; index as i">
                  <mat-form-field class="example-full-width" style="margin-top: 2px;margin-left: 16px;">
                    <input matInput [formControlName]="i"/>
                    <!--<mat-icon (click)="removeFleet(i)" matSuffix>x</mat-icon>-->
                    <button type="button" matSuffix mat-icon-button (click)="removeAdminFleet(i)"><strong>x</strong></button>
                  </mat-form-field>
                </span>
              </center>
            </ng-container>



            <!--            <div style="text-align: left;" *ngIf="userLevel !== 'fleet'">-->

<!--              <div *ngIf="userForm.value.authorisation === 'fleet'"><hr/></div>-->
<!--              <div style="text-align: center;">user permissions</div>-->

<!--              <div *ngFor="let endpoint of endpoints">-->
<!--                <mat-slide-toggle  (change)="toggleEndpointAccess(endpoint.value)" [checked]="blockedEndpointIndex(endpoint.value) === -1">-->
<!--                  {{endpoint.display}}-->
<!--                </mat-slide-toggle>-->
<!--              </div>-->

<!--            </div>-->
          </mat-card-content>
        </mat-card>
      </div>
      <div style="flex:1;" *ngIf="userForm.value.email !== curUser">
        <mat-card style="height:100%">
          <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;background:#000000d4;color:white;">
            Permissions
          </mat-card-title>
          <mat-card-content style="text-align: center;">
            <div *ngFor="let endpoint of endpoints" style="display: flex;justify-content: space-between">
              {{endpoint.display}}
              <mat-slide-toggle (change)="toggleEndpointAccess(endpoint.value)" [checked]="blockedEndpointIndex(endpoint.value) === -1"></mat-slide-toggle>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <br/>
    <center>
      <div *ngIf="userForm?.value?.authorisation === 'mflGroup'" class="alert alert-warning">
        WARNING: The authorisation level selected will give the user access to all devices/fleets within the {{userForm?.value?.mflGroup}} group.
      </div>


      <button mat-flat-button color="primary" type="submit">
        <mat-icon>save</mat-icon>
        &nbsp;Save changes
      </button>
    </center>

  </form>

  <br>

<!--  <div class="id">{{user._id}}</div>-->

</ng-template>
