import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AdminService} from '../admin.service';
import {MatTableDataSource} from '@angular/material/table';

import {MatSort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { MatPaginator} from '@angular/material/paginator';import {switchMap, map} from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { countryList } from '../countries';

@Component({
  selector: 'app-fleets',
  templateUrl: './fleets.component.html',
  styleUrls: ['./fleets.component.css']
})
export class FleetsComponent implements OnInit {
  theFleetId: any;
  allDevices: any;


  constructor(private adminService: AdminService, private dialog: MatDialog,
              private titleService: Title) {}

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('editFleetDialog', {static: true}) editFleetDialog: TemplateRef<any>;
  @Input() fleet: any;
  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;

  groups: any;
  fleets: any;
  dataSource: MatTableDataSource<any>;
  fleetsDisplayedColumns: string[] = ['fleetId', 'fleetName', 'budgetSecondsPerMonthPerVehicle', 'totalVehicles', 'totalActiveVehicles', 'totalStatusTestedVehicles',
    'mflGroup', 'edit'];
  authorisation;
  mflGroup;
  warningMsg: string;
  showSubmit: boolean;
  vehicles: any;
  fleetTableData: any;
  showSearch = false;
  searchValue: string;
  createUser = false;
  newUserEmail = '';
  newUserName = '';
  newUserAuth = '';
  newUserPass = '';
  deleteFleet = false;
  emailList: any;
  newFleet = false;
  countries = [
    {code: 'CA', code3: 'CAN', name: 'Canada', number: '124'},
    {code: 'GB', code3: 'GBR', name: 'United Kingdom', number: '826'},
    {code: 'US', code3: 'USA', name: 'United States of America', number: '840'},
  ];
  usersEmails = [localStorage.getItem('email')];

  get fleetEmailsArray(): UntypedFormArray {
    return this.fleetForm.get('emailList') as UntypedFormArray;
  }


  fleetForm = new UntypedFormGroup({
    budgetSecondsPerMonthPerVehicle: new UntypedFormControl(''),
    fleetId: new UntypedFormControl(''),
    fleetName: new UntypedFormControl(''),
    _id: new UntypedFormControl(''),
    apiPartner: new UntypedFormControl(''),
    mflGroup: new UntypedFormControl(localStorage.getItem('mflGroup')),
    // country: new UntypedFormControl(''),
    active: new UntypedFormControl(1),
    emailList: new UntypedFormArray([]),
    // notificationSensitivity: new UntypedFormControl(2),
    // daysMissingAfter: new UntypedFormControl(10)
  });




  selectedFleet: any;
  tableLoading = false;


  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    // this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }




  ngOnInit(): void {

    if (localStorage.getItem('authorisation') === 'superuser'){
      this.adminService.groups({}).subscribe(r => {
        this.groups = r.reduce((acc, curVal) => acc.concat(curVal.mflGroup), []);
      });
    } else {
      this.groups = [localStorage.getItem('mflGroup')];
    }


    window.addEventListener('scroll', this.onPageScroll);



    this.authorisation = localStorage.getItem('authorisation');
    this.mflGroup = localStorage.getItem('mflGroup');
    this.refresh();
    this.showSubmit = false;
    this.paginator._intl.itemsPerPageLabel = 'Fleets per page';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number): string => {
      if (length === 0 || pageSize === 0) { return `0 of ${length}`; }

      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
      if (endIndex === length){
        return 'Showing all fleets';
      } else {
        return `Showing  fleets ${startIndex + 1} - ${endIndex} of ${length} total fleets`;
      }
    };

    this.titleService.setTitle('Fleets');
  }

    createFleetId(): void {

    if (!this.theFleetId){
      this.fleetForm.patchValue({
        fleetId: this.fleetForm.value.fleetName.toLowerCase().replaceAll(' ', '_').replace(/\W/g, ''),
        fleetName: this.fleetForm.value.fleetName.replace(/[^a-zA-Z0-9\s]/g, '')
      });
    }

  }

  refresh(): void {
    delete this.dataSource;
    this.tableLoading = true;
    this.newUserName = '';
    this.newUserEmail = '';
    this.newUserPass = '';
    this.newUserAuth = '';

    this.fetchAllDevices();

    const data = {active: {'$in': [1, null]}};

    this.adminService.fleets(data).subscribe(r => {
      this.fleets = r;
      this.tableLoading = false;
      this.dataSource = new MatTableDataSource(r);
      this.dataSource.filterPredicate = function(data: any, filterValue: string) {
        return (data?.fleetId?.trim()?.toLocaleLowerCase()?.indexOf(filterValue?.trim()?.toLocaleLowerCase()) >= 0);
      };
      this.dataSource.paginator = this.paginator;
    });

  }


  editFleet(event, fleetObject): void {

    this.fleetForm.reset();
    this.newFleet = false;

    if (!fleetObject) {
      this.newFleet = true;
      this.fleetForm.patchValue({mflGroup: this.mflGroup})
    }

    //
    // if (!fleetObject?.notificationSensitivity) this.fleetForm.patchValue({notificationSensitivity: 2})
    // if (!fleetObject?.daysMissingAfter) this.fleetForm.patchValue({daysMissingAfter: 10})


    while (this.fleetEmailsArray.length !== 0) {
      this.fleetEmailsArray.removeAt(0)
    }

    if (fleetObject?.emailList?.length > 0){
      fleetObject.emailList.forEach(item => {
        this.fleetEmailsArray.push( new UntypedFormGroup({
          email: new UntypedFormControl(item.email, [Validators.required]),
          list: new UntypedFormControl(item.list, [Validators.required])
        }));
      })
    }

    if (!this.newFleet){
      this.adminService.users({fleets: fleetObject.fleetId}).subscribe(r => {
        this.usersEmails = r.reduce((acc, curVal) => acc.concat(curVal?.email), []);


        if (!this.usersEmails.includes(localStorage.getItem('email'))){
          this.usersEmails.push(localStorage.getItem('email'))
        }

      });




    }





    // this.fleetForm.reset();


    delete this.warningMsg;
    this.createUser = false;
    this.deleteFleet = false;
    delete this.theFleetId;
    this.theFleetId = fleetObject?.fleetId;

    this.showSubmit = true;
    this.selectedFleet = fleetObject;




    this.dialog.open(this.editFleetDialog, {closeOnNavigation: true, width: '75%', hasBackdrop: true});
    this.fleetForm.patchValue(fleetObject);


  }

  onFormSubmit(): void {


    delete this.warningMsg;

    if (this.deleteFleet){
      this.fleetForm.patchValue({
        active: 0
      });
    } else {
      this.fleetForm.patchValue({
        active: 1
      });
    }

    const value = {...this.fleetForm.value, budgetSecondsPerMonthPerVehicle: +this.fleetForm.value.budgetSecondsPerMonthPerVehicle}

    delete value._id

    if (this.newFleet){
      this.adminService.addFleet(value).subscribe();
    } else {
      this.adminService.updateFleet(value).subscribe();
    }



    this.newFleet = false;


    if (this.createUser){
      const newUserData = {
        email: this.newUserEmail,
        name: this.newUserName,
        authorisation: this.newUserAuth,
        password: this.newUserPass,
        _id: '',
        fleets: [this.fleetForm.controls.fleetId.value],
        mflGroup: this.mflGroup,
        active: 1
      };

      console.log(newUserData);


      this.adminService.updateUser(newUserData).subscribe();
    }
    this.refresh();

    this.dialog.closeAll();

  }


  formSubmitChecker(): void {

    console.log(this.fleetForm);

    if (this.fleetForm.value.fleetId !== this.selectedFleet.fleetId) {
      this.warningMsg = 'Changing the fleet name will create a new fleet, or overwrite the fleet "' + this.fleetForm.value.fleetId
        + '" if it already exists. ';
      this.showSubmit = true;
    }

    if (this.createUser && (this.newUserName.trim() === '' || this.newUserEmail.trim() === '' || this.newUserPass.trim() === ''
      || this.newUserAuth.trim() === '')){
      this.warningMsg = 'Ensure all new user fields are filled out';
    }

    if (this.warningMsg.trim().length < 1){
      this.onFormSubmit();
    }
  }

  showHideSearch(): void {
    this.showSearch ? this.showSearch = false : this.showSearch = true;
  }

  cancelFilter(): void {
    this.searchValue = '';
    this.dataSource.filter = '';
  }

  generatePassword() {

    var numberChars = "0123456789";
    var upperChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lowerChars = "abcdefghijklmnopqrstuvwxyz";
    var allChars = numberChars + upperChars + lowerChars;
    var randPasswordArray = Array(20);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray = randPasswordArray.fill(allChars, 3);
    this.newUserPass = this.shuffleArray(randPasswordArray.map(function(x) { return x[Math.floor(Math.random() * x.length)] })).join('');
  }


  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }


  scrollToTop(): void {
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  onPageScroll(): void {
    const vh = window.innerHeight;
    const y = window.scrollY;
    if (y > (vh/2)) {
      (document.getElementById('scrollTopButtonEle') as HTMLElement).style.display = 'block';
    } else {
      (document.getElementById('scrollTopButtonEle') as HTMLElement).style.display = 'none';
    }
  }

  fetchAllDevices(): void {
    this.adminService.vehicles({}).subscribe(result => {
      this.allDevices = result;
    });
  }

  deviceStatusCount(fleet, status): number {
    let array =  this.allDevices.filter(device => {
      if (status === 'ALL'){
        if (device?.fleetId === fleet) {
          return true;
        } else {
          return false;
        }
      } else {
        if (device?.fleetId === fleet && device?.commissioning?.commissionStatus === status){
          return true;
        } else {
          return false;
        }
      }
    });

    return array.length;
  }


  filterCountries(val): void {
    this.countries = countryList.filter(option => option.name.toLowerCase().startsWith(val.toLowerCase()));
  }


  addEmailToFleet(): void {
    this.fleetEmailsArray.push( new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required]),
      list: new UntypedFormControl('', [Validators.required])
    }));
  }

  removeEmail(index: number): void {
    this.fleetEmailsArray.removeAt(index);
  }





}

