<form [formGroup]="vehicleForm">
  <div style="display: flex;flex-wrap: wrap;">

    <mat-card style="margin-right:10px;flex: 1;">
      <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;background:#000000d4;color:white;">
        <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">
          <path fill="currentColor" d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z" />
        </svg>
        &nbsp;Binding
      </mat-card-title>
      <mat-card-content>
        <table style="text-align: left!important;width: 100%;">
          <tr>
            <th>
              Model number:
            </th>
            <td style="display:flex;;">
              <mat-form-field style="width:300px;margin-left: auto;"  *ngIf="vehicleForm?.value?.device?.length < 1 || userLevel === 'superuser'">
                <mat-select formControlName="device">
                  <mat-option *ngFor="let modelNo of environment?.MODEL_NUMBER_ARRAY" [value]="modelNo?.modelNoDb">{{modelNo?.modelNoDisplay}}</mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="vehicleForm?.value?.device?.length > 1 && userLevel !== 'superuser'" class="disabledInput">
                <span>{{modelNumberDisplay(vehicle?.device)}}</span>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              Barcode:
            </th>
            <td style="display: flex;">
              <mat-form-field style="width:300px;margin-left: auto;" *ngIf="!vehicle || !vehicle?.deviceBarCode">
                <!--              <mat-label>Device bar code</mat-label>-->
                <input matInput formControlName="deviceBarCode" oninput="this.value = this.value.toUpperCase().replace(' ', '')">
              </mat-form-field>
              <div *ngIf="vehicle && vehicle?.deviceBarCode" class="disabledInput">
                {{vehicle?.deviceBarCode}}
              </div>
            </td>
          </tr>
          <tr>
            <th>
              Device number (DN):
            </th>
            <td style="display: flex;">
              <mat-form-field style="width:300px;margin-left: auto;" *ngIf="!vehicle || !vehicle?.dn">
                <!--              <mat-label>Device Number</mat-label>-->
                <input matInput formControlName="dn">
              </mat-form-field>
              <div *ngIf="vehicle && vehicle?.dn" class="disabledInput">
                {{vehicle?.dn}}
              </div>
            </td>
          </tr>
<!--          <tr>-->
<!--            <th>-->
<!--              DOB:-->
<!--            </th>-->
<!--            <td style="display: flex;">-->
<!--              <mat-form-field style="width:300px;margin-left: auto;" *ngIf="!vehicle || !vehicle?.deviceDob">-->
<!--                &lt;!&ndash;              <mat-label>Device DOB</mat-label>&ndash;&gt;-->
<!--                <input matInput formControlName="deviceDob">-->
<!--              </mat-form-field>-->
<!--              <div *ngIf="vehicle && vehicle?.deviceDob" class="disabledInput">-->
<!--                {{vehicle?.deviceDob}}-->
<!--              </div>-->
<!--            </td>-->
<!--          </tr>-->
          <tr>
            <th>
              Serial number:
            </th>
            <td style="display: flex;">
              <mat-form-field style="width:300px;margin-left: auto;" *ngIf="!vehicle || !vehicle?.deviceSerial">
                <!--              <mat-label>Device Serial Number</mat-label>-->
                <input matInput formControlName="deviceSerial">
              </mat-form-field>
              <div *ngIf="vehicle && vehicle?.deviceSerial" class="disabledInput">
                {{vehicle?.deviceSerial}}
              </div>
            </td>
          </tr>
          <tr>
            <th>
              SIM number:
            </th>
            <td style="display: flex;">
              <mat-form-field style="width:300px;margin-left: auto;">
                <!--              <mat-label>Device SIM Number</mat-label>-->
                <input matInput formControlName="deviceSIM">
              </mat-form-field>
            </td>
          </tr>
          <tr>
            <th>
              API connections:
            </th>
            <td style="display: flex;">

              <div class="disabledInput">
                <span *ngIf="vehicle && vehicle?.mflGroup">{{vehicle?.mflGroup}}</span>
                <span *ngIf="!vehicle || !vehicle?.mflGroup"></span>
              </div>
            </td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>



    <mat-card style="margin-right:10px;flex: 1;">
      <mat-card-title style="padding:21px 10px!important;display:flex;align-content:center;align-items:center;background:#000000d4;color:white;">
        <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">
          <path fill="currentColor" d="M20.2,5.9L21,5.1C19.6,3.7 17.8,3 16,3C14.2,3 12.4,3.7 11,5.1L11.8,5.9C13,4.8 14.5,4.2 16,4.2C17.5,4.2 19,4.8 20.2,5.9M19.3,6.7C18.4,5.8 17.2,5.3 16,5.3C14.8,5.3 13.6,5.8 12.7,6.7L13.5,7.5C14.2,6.8 15.1,6.5 16,6.5C16.9,6.5 17.8,6.8 18.5,7.5L19.3,6.7M19,13H17V9H15V13H5A2,2 0 0,0 3,15V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V15A2,2 0 0,0 19,13M8,18H6V16H8V18M11.5,18H9.5V16H11.5V18M15,18H13V16H15V18Z" />
        </svg>
        &nbsp;Settings
      </mat-card-title>
      <mat-card-content style="overflow-y: scroll; height:52vh">
        <table style="text-align: left!important;width:100%;">
          <tr>
            <th>
              <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
                <path fill="currentColor" d="M13,3H11V13H13V3M17.83,5.17L16.41,6.59C18.05,7.91 19,9.9 19,12A7,7 0 0,1 12,19C8.14,19 5,15.88 5,12C5,9.91 5.95,7.91 7.58,6.58L6.17,5.17C2.38,8.39 1.92,14.07 5.14,17.86C8.36,21.64 14.04,22.1 17.83,18.88C19.85,17.17 21,14.65 21,12C21,9.37 19.84,6.87 17.83,5.17Z" />
              </svg>
              Device standby time:
            </th>
            <td style="display:flex;align-items: center">
              <mat-form-field style="width:300px;margin-left: auto;">
                <!--              <mat-label>Overrun power</mat-label>-->
                <mat-select formControlName="overrun">
                  <mat-option [value]="'10'">10 minutes</mat-option>
                  <mat-option [value]="'20'">20 minutes</mat-option>
                  <mat-option [value]="'30'">30 minutes</mat-option>
                  <mat-option [value]="'40'">40 minutes</mat-option>
                  <mat-option [value]="'50'">50 minutes</mat-option>
                  <mat-option [value]="'60'">1 hour</mat-option>
                  <mat-option [value]="'90'">1 hour 30 minutes</mat-option>
                  <mat-option [value]="'120'">2 hours</mat-option>
                  <mat-option [value]="'360'">6 hours</mat-option>
                  <mat-option [value]="'720'">12 hours</mat-option>
                  <mat-option [value]="'1080'">18 hours</mat-option>
                  <mat-option [value]="'1440'">24 hours</mat-option>
                </mat-select>
              </mat-form-field>
              <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Time device remains on after vehicle switched off">
                <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
              </svg>
              <mat-icon style="color:orange" matTooltip="{{config?.POWER?.delay}} mins pending"  *ngIf="config?.POWER?.delay && deviceParameters?.POWER?.delay !== config?.POWER?.delay"  matSuffix>
                warning
              </mat-icon>
            </td>
          </tr>
          <tr>
            <th>
              <mat-icon style="color: black!important;">more_time</mat-icon>
              Camera overrun time:
            </th>
            <td style="display:flex;align-items: center">
              <mat-form-field style="width:300px;margin-left: auto;">
                <!--              <mat-label>Overrun recording time</mat-label>-->
                <mat-select formControlName="overrunRecording">
                  <mat-option [value]="'10'">10 minutes</mat-option>
                  <mat-option [value]="'20'">20 minutes</mat-option>
                  <mat-option [value]="'30'">30 minutes</mat-option>
                  <mat-option [value]="'40'">40 minutes</mat-option>
                  <mat-option [value]="'50'">50 minutes</mat-option>
                  <mat-option [value]="'60'">1 hour</mat-option>
                  <mat-option [value]="'90'">1 hour 30 minutes</mat-option>
                  <mat-option [value]="'120'">2 hours</mat-option>
                  <mat-option [value]="'360'">6 hours</mat-option>
                  <mat-option [value]="'720'">12 hours</mat-option>
                  <mat-option [value]="'1080'">18 hours</mat-option>
                  <mat-option [value]="'1440'">24 hours</mat-option>
                </mat-select>
              </mat-form-field>
              <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Time camera continues to record after vehicle switched off">
                <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
              </svg>
              <mat-icon style="color:orange" matTooltip="{{config?.POWER?.AccOffRecTime}} pending" *ngIf="config?.POWER?.AccOffRecTime && deviceParameters?.POWER?.AccOffRecTime !== config?.POWER?.AccOffRecTime" matSuffix>
                warning
              </mat-icon>
            </td>
          </tr>

          <tr>
            <th>
              <mat-icon style="color: black!important;">public</mat-icon>
              Timezone:
            </th>
            <td style="display:flex;align-items: center">
              <mat-form-field style="width:300px;margin-left: auto;">
                <mat-select formControlName="timezone">
                  <mat-option *ngFor="let time of timeZones" [value]="time">{{time}}</mat-option>
                </mat-select>
                <mat-icon style="color:orange" matTooltip="{{vehicle?.timezone}} pending" *ngIf="deviceParametersRaw?.timezone != vehicle?.timezone" matSuffix>
                  warning
                </mat-icon>
              </mat-form-field>

              <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Timezone the device operates in">
                <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
              </svg>


            </td>
          </tr>



          <tr *ngIf="camerasSettingsFormArray?.controls?.length < 1">
            <td colspan="2" style="text-align: center">
              <button mat-flat-button (click)="addDefaultCameras()">Add default cameras</button>
            </td>
          </tr>




          <ng-container *ngFor="let cam of camerasSettingsFormArray?.controls; let i = index">
            <tr>
              <th>
                <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
                </svg>
                {{splitStr(cam.value?.camPosition)}} camera profile:
              </th>
              <td>
                <div style="display:flex;align-items: baseline;box-sizing:border-box;width:318px;margin-left:auto;">
                  <div style="border-bottom: solid 1px black;flex:auto;text-align:left;">
                    <span *ngIf="cam.value?.camConfig == 'bestQuality'">Best quality</span>
                    <span *ngIf="cam.value?.camConfig == 'bestStorage'">Best storage</span>
                    <span *ngIf="cam.value?.camConfig != 'bestStorage' && cam.value.camConfig != 'bestQuality'">
                          {{cam.value?.camConfig | titlecase}}
                        </span>
                    <span *ngIf="cam.value?.HaveAudio === '1'"> - Audio on</span>
                    <span *ngIf="cam.value?.HaveAudio === '0'"> - Audio off</span>
                  </div>
                  <div style="border-bottom:solid 1px black;">
                    <mat-icon style="color: #606060!important;vertical-align: bottom!important;margin-left: auto;cursor:pointer;" matSuffix mat-flat-button (click)="showCameraForm()">
                      settings
                    </mat-icon>

                    <mat-icon *ngIf="cam.value?.camPosition === 'Front' || cam.value?.camPosition === 'Driver'" style="color: #606060!important;vertical-align: bottom!important;margin-left: auto;cursor:pointer;"
                              matSuffix mat-flat-button (click)="cropCamera(cam.value?.camPosition, i)">
                      crop_free
                    </mat-icon>



<!--                    <mat-icon style="color:orange;vertical-align: bottom!important;" matTooltip="Pending change"-->
<!--                              *ngIf="isSameJson(config?.RECORD?.MainChn['chn'+(cam?.value?.channel-1)], deviceParameters?.RECORD?.MainChn['chn'+(cam?.value?.channel-1)])">-->
<!--                      warning-->
<!--                    </mat-icon>-->

                  </div>


                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Balanced video quality and storage time">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
              </td>
            </tr>
          </ng-container>



          <tr *ngIf="deviceParameters && deviceParameters?.DIALUP && deviceParameters?.DIALUP.apn">
            <th>
              <mat-icon style="color: black!important;">sim_card</mat-icon>
              APN:
            </th>
            <td style="display:flex;align-items: center">
              <mat-form-field style="width:300px;margin-left: auto;">
                <input matInput readonly value="{{deviceParameters?.DIALUP?.apn}}">
              </mat-form-field>
              <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Data gateway set on the device">
                <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
              </svg>
            </td>
          </tr>
        </table>

      </mat-card-content>
    </mat-card>


  </div>

  <ng-template #camerasModal>
    <div style="display: flex;">
      <h1>Camera settings</h1>&nbsp;
      <span style="margin-left: auto;">
              <button mat-raised-button (click)="addCamera()">+ Add camera</button>
        </span>
    </div>
    <br>
    <ng-container formArrayName="cameras">
      <div *ngFor="let camerasGroup of camerasSettingsFormArray.controls; index as i">

        <div [formGroup]="camerasGroup">

          <mat-icon style="color:#7318EC!important;">videocam</mat-icon>
          &nbsp;&nbsp;

<!--          todo only allow channels that aren't currently in use-->
          <mat-form-field class="short-width">
            <mat-label>Channel</mat-label>
            <mat-select formControlName="channel">
              <mat-option  *ngFor="let ch of theChannels(camerasGroup)" [value]="ch">{{ch}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Camera position</mat-label>
            <mat-select formControlName="camPosition">
              <mat-option [value]="'Front'">Front</mat-option>
              <mat-option [value]="'Driver'">Driver</mat-option>
              <mat-option [value]="'Nearside'">Nearside</mat-option>
              <mat-option [value]="'Offside'">Offside</mat-option>
              <mat-option [value]="'Load'">Load</mat-option>
              <mat-option [value]="'Load2'">Load 2</mat-option>
              <mat-option [value]="'Load3'">Load 3</mat-option>
              <mat-option [value]="'Load4'">Load 4</mat-option>
              <mat-option [value]="'RearLoad'"> Rear Load</mat-option>
              <mat-option [value]="'Rear'">Rear</mat-option>
              <mat-option [value]="'Chute'">Chute</mat-option>
              <mat-option [value]="'nsvru'">Nearside-VRU</mat-option>
              <mat-option [value]="'osvru'">Offside-VRU</mat-option>
              <mat-option [value]="'rvru'">Rear-VRU</mat-option>
              <mat-option [value]="'fvru'">Front-VRU</mat-option>
              <mat-option [value]="'inCab'">In-Cab</mat-option>

            </mat-select>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Camera config</mat-label>
            <mat-select formControlName="camConfig">
              <mat-option [value]="'bestStorage'">Best storage</mat-option>
              <mat-option [value]="'bestQuality'">Best quality</mat-option>
              <mat-option [value]="'balanced'">Balanced</mat-option>
              <mat-option [value]="'minimumStorage'">Minimum storage</mat-option>
              <mat-option [value]="'legacy'">Legacy</mat-option>
            </mat-select>
          </mat-form-field>

<!--          <mat-form-field class="example-full-width">-->
<!--            <mat-label>Camera model</mat-label>-->
<!--            <mat-select formControlName="model">-->
<!--              <mat-option [value]="'C60S0V42F-2M'">MFL FRONT + DRIVER CAM V1</mat-option>-->
<!--              <mat-option [value]="'C60S0V42-2MR2'">MFL FRONT CAM V1</mat-option>-->
<!--              <mat-option [value]="'UT3-SIDE 1080'">MFL SIDE CAM V1</mat-option>-->
<!--              <mat-option [value]="'UT3-REAR 1080'">MFL REAR CAM V1</mat-option>-->
<!--            </mat-select>-->
<!--          </mat-form-field>-->

          <mat-form-field class="example-full-width">
            <mat-label>Recording</mat-label>
            <mat-select formControlName="isRec" [disabled]="(camerasGroup?.value?.channel === '1' && camerasGroup?.value?.channel.isRec === '1')">
              <mat-option [value]="'0'">No</mat-option>
              <mat-option [value]="'1'">Yes</mat-option>
            </mat-select>

            <mat-icon style="color:orange" [matTooltip]="haveRecTooltip(config?.RECORD?.MainChn['chn'+(camerasGroup?.value?.channel-1)]?.isRec !== deviceParameters?.RECORD?.MainChn['chn'+(camerasGroup?.value?.channel-1)]?.isRec)"
                      *ngIf="config?.RECORD?.MainChn['chn'+(camerasGroup?.value?.channel-1)]?.isRec && deviceParameters?.RECORD?.MainChn['chn'+(camerasGroup?.value?.channel-1)]?.isRec && config?.RECORD?.MainChn['chn'+(camerasGroup?.value?.channel-1)]?.isRec !== deviceParameters?.RECORD?.MainChn['chn'+(camerasGroup?.value?.channel-1)]?.isRec" matSuffix>warning</mat-icon>

          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Audio</mat-label>
            <mat-select formControlName="HaveAudio" [compareWith]="attributeDisplay">
              <mat-option [value]="'0'">No</mat-option>
              <mat-option [value]="'1'">Yes</mat-option>
            </mat-select>

            <mat-icon style="color:orange" [matTooltip]="haveAudioTooltip(config?.RECORD?.MainChn['chn'+(camerasGroup?.value?.channel-1)]?.HaveAudio !== deviceParameters?.RECORD?.MainChn['chn'+(camerasGroup?.value?.channel-1)]?.HaveAudio)"
                      *ngIf="config?.RECORD?.MainChn['chn'+(camerasGroup?.value?.channel-1)]?.HaveAudio && deviceParameters?.RECORD?.MainChn['chn'+(camerasGroup?.value?.channel-1)]?.HaveAudio && config?.RECORD?.MainChn['chn'+(camerasGroup?.value?.channel-1)]?.HaveAudio !== deviceParameters?.RECORD?.MainChn['chn'+(camerasGroup?.value?.channel-1)]?.HaveAudio" matSuffix>warning</mat-icon>
          </mat-form-field>



          <mat-form-field class="example-full-width">
            <mat-label>Image flip</mat-label>
            <mat-select formControlName="Mirror">
              <mat-option [value]="'0'">None</mat-option>
              <mat-option [value]="'1'">Horizontally</mat-option>
              <mat-option [value]="'2'">Vertically</mat-option>
              <mat-option [value]="'3'">All</mat-option>
            </mat-select>


            <mat-icon style="color:red"  *ngIf="camerasGroup?.value?.Mirror !== '0' && ['1', '8'].includes(camerasGroup?.value?.channel)"
                      matTooltip="Warning, changing this setting for ADAS or DSM cameras will cause the device to permanently disconnect" matSuffix>
              warning
            </mat-icon>

          </mat-form-field>

            <mat-form-field class="example-full-width" *ngIf="camerasGroup?.value?.camPosition === 'Driver'">
              <mat-label>Driver Recognition</mat-label>
              <mat-select formControlName="driverRecognition"  [disabled]="userLevel !== 'superuser'">
                <mat-option [value]="false">Off</mat-option>
                <mat-option [value]="true">On</mat-option>
              </mat-select>
          </mat-form-field>

          <button mat-icon-button (click)="removeCamera(i)" ><mat-icon style="color: #b1b3b2">delete</mat-icon></button>
        </div>


      </div>

    </ng-container>
  </ng-template>




  <ng-template #cropModal>
    <div style="max-width: 480px">

      <h3>Optimise AI Detection area</h3>

      <image-cropper
        [maintainAspectRatio]="false"
        [imageURL]="originalImage"
        [cropper]="(initCrop ? initCrop : {x1:0,y1:0,x2:10,y2:10})"
        (imageLoaded)="imageLoaded()"
        (imageCropped)="imageCropped($event)"
      ></image-cropper>

      <button (click)="saveCropping()">save</button>

    </div>


  </ng-template>

</form>
