<div class="loadingOverlay" *ngIf="deviceStatusLoading || pageLoading">
  <div class="loader"></div>
</div>

<h1 style="font-size: 28px!important;font-weight: bold;">
  Device
</h1>
<div style="display: flex;flex-wrap: wrap;">
  <div>
    <h3 style="font-size: 21px!important;" matTooltip="Fleet: {{vehicle?.fleetName}}">
      <span style="font-weight: bold">{{vehicleRegistration}}</span> ({{dn}})
    </h3>
    <p style="margin-top: -10px;">

    <span *ngIf="deviceConnected && !isDeviceParked(vehicle?.lastLocation)" class="onlineChip">Online</span>
    <span *ngIf="deviceConnected && isDeviceParked(vehicle?.lastLocation)" class="standbyChip">Standby</span>
    <span *ngIf="!deviceConnected || !vehicle?.lastHeartbeat" class="offlineChip">Offline</span>


<!--      -->
<!--      <span *ngIf="deviceConnected" class="onlineChip">Online</span>-->
<!--      <span *ngIf="!deviceConnected" class="offlineChip">Offline</span>-->
      &nbsp;| <span style="font-weight: 500;">Mode:</span>&nbsp;
      <span *ngIf="commissioning && commissioning.commissionStatus == 'tested'">Install</span>
      <span *ngIf="commissioning && commissioning.commissionStatus != 'tested'">{{commissioning.commissionStatus | titlecase}}</span>
      <span *ngIf="videoBudget && videoBudget.totalFleetBudget > 0">
      &nbsp;| <span style="font-weight: 500;">Budget:</span> {{(((videoBudget.totalFleetBudget - videoBudget.usedSeconds) / videoBudget.totalFleetBudget) *100) | number: '1.0-0'}}%
    </span>
    </p>
  </div>
  <div style="flex:1;display: flex;min-width: 250px;">
    <div style="flex: 1;text-align: right;padding-left:100px;" class="header-less-tabs">
      <mat-tab-group [(selectedIndex)]="showButtons" animationDuration="500" style="margin-left:25px;">
        <!---diagnostics buttons--->
        <mat-tab style="display: flex;justify-content: end;">

<!--          <button type="button" mat-flat-button *ngIf="usersPermission === 'superuser'"-->
<!--                  [disabled]="!deviceConnected || (deviceStatus?.speed / 100) * 0.621371 > 1"-->
<!--                  (click)="calibrateGSensor()" style="margin-right:10px;">-->
<!--            <mat-icon *ngIf="deviceConnected && (deviceStatus?.speed / 100) * 0.621371 < 1">adjust</mat-icon>-->
<!--            Calibrate-->
<!--          </button>-->



          <button mat-flat-button type="button"  (click)="triggerUnderSpeedAlarm()" [disabled]="!deviceConnected || disableUpdateButton"
                  style="margin-right:10px;">
            <mat-icon *ngIf="deviceConnected">refresh</mat-icon>
            Update
          </button>

          <button mat-flat-button (click)="openCommissioningForm()" style="margin-right:10px;" *ngIf="usersPermission !== 'Fleet'">
            <mat-icon>av_timer</mat-icon>
            Mode
          </button>

          <a mat-flat-button [href]="baseHref + 'media/' + dn">
            <mat-icon>ondemand_video</mat-icon>
            Media
          </a>
        </mat-tab>

        <!--- device-settings buttons--->
        <mat-tab>
          <div style="display: flex;justify-content: end;align-items: center">
<!--            <a mat-flat-button [href]="baseHref + 'device-setup/' + vehicle?._id" style="margin-right:10px;">-->
<!--              <mat-icon>settings</mat-icon>-->
<!--              Setup-->
<!--            </a>-->



<!--            <button mat-flat-button type="button" (click)="getAllParameters(vehicle?.dn)" [disabled]="!deviceConnected"-->
<!--                    style="margin-right:10px;">-->
<!--              <mat-icon *ngIf="deviceConnected">sync</mat-icon>-->
<!--              Sync-->
<!--            </button>-->



            <button mat-flat-button type="button" (click)="beginInternalTesting()"
                    [disabled]="!vehicle || !deviceConnected" *ngIf="usersPermission === 'superuser' || usersEmail === 'sbudd@amsgroupusa.com' ||
                    usersEmail ==='jack.howlett@fleetfocus.co.uk' || usersEmail === 'brittanyw@ramtracking.com'"
                    style="margin-right:10px;">
              <mat-icon *ngIf="vehicle && deviceConnected">network_check</mat-icon>
              Bench
            </button>
            <button (click)="openCloneDeviceModal()" mat-flat-button style="margin-right:10px;" *ngIf="usersPermission !== 'Fleet'">
              <mat-icon>content_copy</mat-icon>
              Clone
            </button>
            <button (click)="restart()" mat-flat-button style="margin-right:10px;" *ngIf="usersPermission === 'superuser'">
              <mat-icon>power_settings_new</mat-icon>
              Restart
            </button>
            <button mat-raised-button  type="submit" (click)="openConfirmChangesDialog()" [disabled]="vehicleForm.invalid || pageLoading" style="background:#5C8459!important;">
              <mat-icon *ngIf="!vehicleForm.invalid" style="color:white!important;">save</mat-icon>
              Save
            </button>


            <mat-icon style="color:orange" [matTooltip]="errorsDisplay?.join(', ')"
                      *ngIf="vehicleForm.invalid">warning</mat-icon>

          </div>
        </mat-tab>
        <!---vehicle buttons--->
        <mat-tab>
          <div style="display: flex;justify-content: end;align-items: center">
            <button mat-raised-button  type="submit" (click)="openConfirmChangesDialog()" [disabled]="vehicleForm.invalid || pageLoading" style="background:#5C8459!important;">
              <mat-icon *ngIf="!vehicleForm.invalid" style="color:white!important;">save</mat-icon>
              Save
            </button>
            <mat-icon style="color:orange" [matTooltip]="errorsDisplay?.join(', ')"
                      *ngIf="vehicleForm.invalid">warning</mat-icon>
          </div>
        </mat-tab>
        <mat-tab><!---placeholder---></mat-tab>
        <mat-tab><!---placeholder---></mat-tab>

      </mat-tab-group>
    </div>
  </div>
</div>


<mat-tab-group (selectedTabChange)="tabChanged($event)">
  <!----STATUS TAB--->
  <mat-tab>
    <ng-template mat-tab-label>
      <svg style="width:22px;height:22px;display: block;" viewBox="0 0 24 24">
        <g><rect fill="none" height="24" width="24"/></g><g><g><path d="M15.11,12.45L14,10.24l-3.11,6.21C10.73,16.79,10.38,17,10,17s-0.73-0.21-0.89-0.55L7.38,13H2v5c0,1.1,0.9,2,2,2h16 c1.1,0,2-0.9,2-2v-5h-6C15.62,13,15.27,12.79,15.11,12.45z"/><path d="M20,4H4C2.9,4,2,4.9,2,6v5h6c0.38,0,0.73,0.21,0.89,0.55L10,13.76l3.11-6.21c0.34-0.68,1.45-0.68,1.79,0L16.62,11H22V6 C22,4.9,21.1,4,20,4z"/></g></g>
      </svg>
      &nbsp;&nbsp;Status
    </ng-template>
    <br>
    <div class="invertBackground flex-container">
      <mat-card style="margin-right:10px;flex: 1;">
        <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;background:#000000d4;color:white!important;">
          <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="36px" viewBox="0 0 24 24" width="36px" fill="#FFFFFF">
            <g><rect fill="none" height="24" width="24"/></g><g><g><path d="M15.11,12.45L14,10.24l-3.11,6.21C10.73,16.79,10.38,17,10,17s-0.73-0.21-0.89-0.55L7.38,13H2v5c0,1.1,0.9,2,2,2h16 c1.1,0,2-0.9,2-2v-5h-6C15.62,13,15.27,12.79,15.11,12.45z"/><path d="M20,4H4C2.9,4,2,4.9,2,6v5h6c0.38,0,0.73,0.21,0.89,0.55L10,13.76l3.11-6.21c0.34-0.68,1.45-0.68,1.79,0L16.62,11H22V6 C22,4.9,21.1,4,20,4z"/></g></g>
          </svg>
          <div matTooltip="Updated {{deviceStatus?.timeSinceUpdateDetail}} ago">
            &nbsp;Status
            <small *ngIf="deviceStatus?.timeSinceUpdateShort">
              (Updated {{deviceStatus?.timeSinceUpdateShort}} ago)
            </small>
          </div>
        </mat-card-title>
        <mat-card-content style="overflow-y: scroll; height:52vh">


         <app-health-summary [healthGraphRawData]="healthGraphRawData" [cameras]="cameras"
         [numberOfStatusUpdatesForHealth]="numberOfStatusUpdatesForHealth" [calendar]="calendar"
         [deviceConnected]="deviceConnected" [deviceStatus]="deviceStatus" [vehicle]="vehicle"></app-health-summary>

        </mat-card-content>
      </mat-card>

      <mat-card style="margin-right:10px;flex: 1;">
        <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;background:#000000d4;color:white;">
          <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M19.07,4.93L17.66,6.34C19.1,7.79 20,9.79 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12C4,7.92 7.05,4.56 11,4.07V6.09C8.16,6.57 6,9.03 6,12A6,6 0 0,0 12,18A6,6 0 0,0 18,12C18,10.34 17.33,8.84 16.24,7.76L14.83,9.17C15.55,9.9 16,10.9 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12C8,10.14 9.28,8.59 11,8.14V10.28C10.4,10.63 10,11.26 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12C14,11.26 13.6,10.62 13,10.28V2H12A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,9.24 20.88,6.74 19.07,4.93Z" />                </svg>
          &nbsp;Tracking
        </mat-card-title>
        <mat-card-content style="text-align:center;">
          <app-tracking-summary [deviceDetails]="vehicle" [deviceConnected]="deviceConnected"></app-tracking-summary>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-tab>
  <!---device-settings tab--->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">settings</mat-icon>
      &nbsp;&nbsp;Config
    </ng-template>
    <br>
    <app-device-settings [vehicleForm]="vehicleForm" [vehicle]="vehicle" [camerasSettingsFormArray]="camerasSettingsFormArray"
    [deviceParameters]="deviceParameters" [dn]="dn" [getAllParameters]="getAllParameters" [config]="config" [cameras]="cameras"
                         [deviceParametersRaw]="deviceParametersRaw" [availableChannels]="availableChannels"></app-device-settings>
  </mat-tab>
  <!---vehicle tab--->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">drive_eta</mat-icon>
      &nbsp;&nbsp;Vehicle
    </ng-template>
    <br>
    <form [formGroup]="vehicleForm">
      <div style="display: flex;flex-wrap: wrap;" [formGroup]="vehicleForm">
        <mat-card style="margin-right:10px;flex:1;">
          <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;background:#000000d4;color:white!important;">
            <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M19 15H17V13H19M20 20V22H16V20H17V18H16V16H19V20M18.92 5A1.5 1.5 0 0 0 17.5 4H6.5A1.5 1.5 0 0 0 5.08 5L3 11V19A1 1 0 0 0 4 20H5A1 1 0 0 0 6 19V18H11A7 7 0 0 1 18 11A6.84 6.84 0 0 1 21 11.68V11M6.5 15A1.5 1.5 0 1 1 8 13.5A1.5 1.5 0 0 1 6.5 15M5 10L6.5 5.5H17.5L19 10Z" />
            </svg>
            &nbsp;Vehicle
          </mat-card-title>
          <mat-card-content>
            <table style="text-align: left!important;width: 100%;">
              <tr>
                <th>
                  Fleet name:
                </th>
                <td style="display: flex;">
                  <mat-form-field style="margin-left: auto;width: 300px;">
                    <!--              <mat-label>The Fleet ID The Vehicle Belongs To</mat-label>-->
                    <mat-select formControlName="fleetId">
                      <mat-option *ngFor="let fleet of allFleets" [value]="fleet.fleetId">
                        <span *ngIf="fleet?.fleetName?.length > 0">{{fleet.fleetName}}</span>
                        <span *ngIf="fleet?.fleetName?.length === 0">{{fleet.fleetId}}</span>

                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
              </tr>
              <tr formGroupName="commissioning">
                <th>
                  Installation date:
                </th>
                <td style="display: flex">

                  <mat-form-field style="width:300px;margin-left: auto;"  *ngIf="usersPermission === 'superuser'" >
                    <input
                      matInput
                      [matDatepicker]="dp2"
                      formControlName="installDate">
                    <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                    <mat-datepicker #dp2></mat-datepicker>

                  </mat-form-field>

                  <span  class="disabledInput" *ngIf="usersPermission !== 'superuser'" >
                    {{vehicleForm['controls'].commissioning['controls'].installDate.value | date: 'd/M/yy'}}
                  </span>


                </td>
              </tr>
              <tr>
                <th>
                  Driver:
                </th>
                <td style="display: flex;">
                  <mat-form-field style="width:300px;margin-left: auto;">
                    <!--              <mat-label>Vehicle Registration</mat-label>-->
                    <input matInput formControlName="driver">
                  </mat-form-field>
                </td>
              </tr>
              <tr>
                <th>
                  Registration:
                </th>
                <td style="display: flex;">
                  <mat-form-field style="width:300px;margin-left: auto;">
                    <!--              <mat-label>Vehicle Registration</mat-label>-->
                    <input matInput formControlName="registration" oninput="this.value = this.value.toUpperCase()">

                    <mat-icon style="color: #606060!important;vertical-align: bottom!important;margin-left: auto;cursor:pointer;margin-bottom:-3px;"
                              matSuffix mat-flat-button (click)="lookupRegistration()">
                      search
                    </mat-icon>
                    <svg matSuffix style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Registration, alphanumeric characters only up to 10 characters">
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                    <mat-icon style="color:orange" matTooltip="{{config?.JTBASE?.license}} pending"  *ngIf="config?.JTBASE?.license && deviceParameters?.JTBASE?.license !== config?.JTBASE?.license"  matSuffix>
                      warning
                    </mat-icon>

                  </mat-form-field>
                </td>
              </tr>
              <tr *ngIf="vehicleForm.controls.vehicleDvlaData">
                <th>
                  Vehicle make:
                </th>
                <td style="display:flex;">
                  <span  class="disabledInput">{{vehicleForm['controls'].vehicleDvlaData?.value?.VehicleRegistration?.Make}}</span>
                </td>
              </tr>
              <tr *ngIf="vehicleForm.controls.vehicleDvlaData">
                <th>
                  Vehicle model:
                </th>
                <td style="display: flex">
                  <span  class="disabledInput">{{vehicleForm['controls'].vehicleDvlaData?.value?.VehicleRegistration?.Model}}</span>
                </td>
              </tr>
              <tr *ngIf="vehicleForm.controls.vehicleDvlaData">
                <th>
                  Vehicle class:
                </th>
                <td style="display: flex;">
                  <span  class="disabledInput">{{vehicleForm['controls'].vehicleDvlaData?.value?.VehicleRegistration?.VehicleClass}}</span>
                </td>
              </tr>
              <tr *ngIf="vehicleForm.controls.vehicleDvlaData">
                <th>
                  Fuel type:
                </th>
                <td style="display: flex">
                  <span  class="disabledInput">{{vehicleForm['controls'].vehicleDvlaData?.value?.VehicleRegistration?.FuelType}}</span>
                </td>
              </tr>


            </table>
            <span *ngIf="!vehicleForm['controls'].vehicleDvlaData?.value?.VehicleRegistration?.Make" style="text-align: center;">
        No vehicles found for that registration in the DVLA database
      </span>
          </mat-card-content>
        </mat-card>



        <mat-card style="flex:1;">
          <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;background:#000000d4;color:white!important;">
            <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M6 1V3H5C3.89 3 3 3.89 3 5V19C3 20.11 3.9 21 5 21H19C20.11 21 21 20.11 21 19V5C21 3.9 20.11 3 19 3H18V1H16V3H8V1H6M5 8H19V19H5V7M11 9V14H13V9H11M11 16V18H13V16H11Z" />            </svg>
            &nbsp;Events
          </mat-card-title>
          <mat-card-content>

            <table style="text-align: left!important;width: 100%;">
              <tr>
                <th style="padding-top:10px;">
                  Event profile:
                </th>
                <td style="display: flex;">
                  <mat-form-field style="margin-left: auto;width: 300px;">
                    <input matInput readonly  value="{{vehicleForm['controls'].eventProfile.value}}">

                    <mat-icon style="color: #606060!important;vertical-align: bottom!important;margin-left: auto;cursor:pointer;margin-bottom:-3px;"
                              matSuffix mat-flat-button (click)="openEventProfileModal()">
                      settings
                    </mat-icon>
                  </mat-form-field>
                </td>
              </tr>
              <tr>
                <th style="padding-top:10px;">
                  Disable G events:
                </th>
                <td style="margin-left:auto;">
                  <form [formGroup]="vehicleForm">
                    <mat-slide-toggle formControlName="preventGSensorEvents"></mat-slide-toggle>
                  </form>
                </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>
    </form>
  </mat-tab>
  <!--- raw tab--->
  <mat-tab *ngIf="usersPermission == 'superuser'">
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">code</mat-icon>
      &nbsp;&nbsp;Raw
    </ng-template>
    <br>

    <app-raw [dn]="dn" [cameras]="cameras" [deviceParameters]="deviceParameters" [configsMatch]="configsMatch"
    [deviceStatus]="deviceStatus" [vehicle]="vehicle" [calendar]="calendar" [config]="config"></app-raw>

  </mat-tab>
  <!---history tab--->
  <mat-tab *ngIf="usersPermission == 'superuser' && deviceStatus">
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">history</mat-icon>
      &nbsp;&nbsp;History
    </ng-template>
    <br>
    <mat-card>
      <mat-card-title>
        Notes
      </mat-card-title>
      <mat-card-content>
        <div style="overflow-y: scroll; height:60vh">
          <div *ngFor="let note of notes">
            {{note.time | date: 'dd/MM/yy, h:mm a'}} - {{note.user}}<br>
            {{note.text}}
            <hr>
          </div>
          <form class="note-form">
            <mat-form-field class="note-full-width">
              <mat-label>Add note</mat-label>
              <textarea matInput placeholder="Notes" [formControl]="note"></textarea>
            </mat-form-field>
            <button mat-flat-button (click)="addNote()">Add note</button>
          </form>
        </div>


      </mat-card-content>
    </mat-card>
  </mat-tab>
  <!--- health chart tab--->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">monitor_heart</mat-icon>
      &nbsp;&nbsp;Health
    </ng-template>
    <br>
    <ng-template matTabContent>
      <app-health-chart [cameras]="cameras" [healthGraphRawData]="healthGraphRawData" [calendar]="calendar"></app-health-chart>
    </ng-template>
  </mat-tab>
</mat-tab-group>


<button (click)="scrollToTop()" class="scrollTopButton" id="scrollTopButtonEle">
  <mat-icon>arrow_circle_up</mat-icon>
</button>




<!-------------------------------------COMMISSIONING MODAL------------------------------------------------------------>
<ng-template #commissoningDialog>
  <app-commissioning [commissioning]="commissioning" [testedForm]="testedForm" [camerasTestedFormArray]="camerasTestedFormArray"
  [changeStatusForm]="changeStatusForm" [commissionForm]="commissionForm" [vehicleForm]="vehicleForm"
  [usersGroup]="usersGroup" [deviceConnected]="deviceConnected" [camerasFormArray]="camerasFormArray" [disableCalibrateGButton]="disableCalibrateGButton"
  [theVehicleSettings]="theVehicleSettings" [vehicleRegistration]="vehicleRegistration" [engineers]="engineers"
  [selfCertifyDeviceSensorChecks]="selfCertifyDeviceSensorChecks" [vehicleFleet]="vehicleFleet" [allFleets]="allFleets"
  [cameras]="cameras" [calibrateGSensor]="calibrateGSensor"  [triggerUnderSpeedAlarm]="triggerUnderSpeedAlarm"
  [deviceStatus]="deviceStatus" [vehicle]="vehicle"
                     (refreshDevice)="refreshDevice($event)" [snaps]="snaps"

  ></app-commissioning>
</ng-template>


<form [formGroup]="vehicleForm" style="margin-top:10px;">

  <!--- bench testing modal--->
  <ng-template #internalTestingDialog>
    <div style="display: flex;">
      <h1 style="font-size: 28px!important;">Device Testing</h1>
      <div style="flex: 1;text-align: right;">
        <button mat-raised-button type="button" (click)="markDeviceTested()" [disabled]="benchTestDisabled()">
          Install ready
        </button>
      </div>
    </div>
    <div style="display: flex;width:100%; justify-content: space-evenly;flex-wrap: wrap;font-size:16px;">
      <div style="text-align: center;padding:10px;flex:1;">
        <div [class]="{'deviceActive': deviceStatus, 'deviceWarning': !deviceStatus}">
          <svg style="width:24px;height:24px;vertical-align: middle" viewBox="0 0 24 24">
            <path fill="currentColor" d="M17,17H7V7H17M21,11V9H19V7C19,5.89 18.1,5 17,5H15V3H13V5H11V3H9V5H7C5.89,5 5,5.89 5,7V9H3V11H5V13H3V15H5V17A2,2 0 0,0 7,19H9V21H11V19H13V21H15V19H17A2,2 0 0,0 19,17V15H21V13H19V11M13,13H11V11H13M15,9H9V15H15V9Z" />
          </svg>
          <br>
          Connection<br>
          <span *ngIf="deviceStatus">{{deviceStatus.networkTypeReadable}}</span>
          <span *ngIf="!deviceStatus">Not connected</span>
        </div>
      </div>
      <div style="text-align: center;padding:10px;flex:1;">
        <div [class]="{'deviceActive': deviceStatus && (deviceStatus.satellitesQuantity > 4),'deviceWarning': !deviceStatus || (deviceStatus.satellitesQuantity < 4)}">
          <svg viewBox="0 0 24 24" style="width: 24px; height: 24px; vertical-align: middle;">
            <path fill="currentColor" d="M11.62,1L17.28,6.67L15.16,8.79L13.04,6.67L11.62,8.09L13.95,10.41L12.79,11.58L13.24,12.04C14.17,11.61 15.31,11.77 16.07,12.54L12.54,16.07C11.77,15.31 11.61,14.17 12.04,13.24L11.58,12.79L10.41,13.95L8.09,11.62L6.67,13.04L8.79,15.16L6.67,17.28L1,11.62L3.14,9.5L5.26,11.62L6.67,10.21L3.84,7.38C3.06,6.6 3.06,5.33 3.84,4.55L4.55,3.84C5.33,3.06 6.6,3.06 7.38,3.84L10.21,6.67L11.62,5.26L9.5,3.14L11.62,1M18,14A4,4 0 0,1 14,18V16A2,2 0 0,0 16,14H18M22,14A8,8 0 0,1 14,22V20A6,6 0 0,0 20,14H22Z"></path>
          </svg>
          <br>
          GPS<br>
          {{deviceStatus.satellitesQuantity}}
        </div>
      </div>
      <div style="text-align: center;padding:10px;flex:1;">
        <div [class]="{'deviceActive': vehicle && validFirmware(vehicle.appVersion), 'deviceWarning' : !vehicle || !validFirmware(vehicle.appVersion) }">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M6,2C4.89,2 4,2.89 4,4V12C4,13.11 4.89,14 6,14H18C19.11,14 20,13.11 20,12V4C20,2.89 19.11,2 18,2H6M6,4H18V12H6V4M4,15C2.89,15 2,15.89 2,17V20C2,21.11 2.89,22 4,22H20C21.11,22 22,21.11 22,20V17C22,15.89 21.11,15 20,15H4M8,17H20V20H8V17M9,17.75V19.25H13V17.75H9M15,17.75V19.25H19V17.75H15Z" />
          </svg>
          <br>
          Firmware<br>
          <span *ngIf="appVersion">{{appVersion}}</span>
          <span *ngIf="!appVersion">Unknown</span>
        </div>
      </div>
      <div style="text-align: center;padding:10px;flex:1;">
        <div [class]="{'deviceActive': deviceStatus && (deviceStatus.HardDiskSizeMb > 1000), 'deviceWarning' : !deviceStatus || (deviceStatus.HardDiskSizeMb < 1000) }">
          <svg style="width:24px;height:24px;vertical-align: middle;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M12,3C7.58,3 4,4.79 4,7C4,9.21 7.58,11 12,11C16.42,11 20,9.21 20,7C20,4.79 16.42,3 12,3M4,9V12C4,14.21 7.58,16 12,16C16.42,16 20,14.21 20,12V9C20,11.21 16.42,13 12,13C7.58,13 4,11.21 4,9M4,14V17C4,19.21 7.58,21 12,21C16.42,21 20,19.21 20,17V14C20,16.21 16.42,18 12,18C7.58,18 4,16.21 4,14Z" />
          </svg>
          <br>
          Memory<br>
          ~ {{(deviceStatus.HardDiskSizeMb / 1000) | number:'1.0-0'}} GB
        </div>
      </div>
      <div style="text-align: center;padding:10px;flex:1;">
        <div [class]="{'deviceActive': configsMatch, 'deviceWarning': !configsMatch}">
          <svg style="width:24px;height:24px;vertical-align: middle;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />          </svg>
          <br>
          Configs<br>
          <span *ngIf="!configsMatch">Don't match</span>
          <span *ngIf="configsMatch">Match</span>
        </div>
      </div>
    </div>
    <br>
    <div class="alert alert-warning" style="text-align: center">Data last updated at {{deviceStatus.deviceTime | date: 'd/M/yy, h:mm a'}}</div>
  </ng-template>
  <!--- clone device modal--->
  <ng-template #cloneDeviceModal>


    <h1 style="font-size: 28px!important;">Cloning device</h1>




    Are you sure you want to clone this device's settings? Doing so<br>
    will create a new device with the same settings as this device and<br>
    will open in a new tab. If you want to go ahead with this enter the new
    device number below and click clone<br><br>

    New device dn:<input  [(ngModel)]="theCloneDn" [ngModelOptions]="{standalone: true}" >

    <br /><br/>

    <ng-container *ngIf="!theCloneDnError">
      <div style="text-align: left;display: inline-block;width:50%;" >
        <button mat-flat-button (click)="dialog.closeAll()">No</button>
      </div>
      <div style="text-align: right;display: inline-block;width:50%;">
        <button mat-raised-button (click)="confirmCloning()"><mat-icon style="color:white!important;">content_copy</mat-icon>&nbsp;clone</button>
      </div>
    </ng-container>

    <div class="alert-warning" *ngIf="theCloneDnError" style="text-align: center;">
      Please ensure a device number has been entered
    </div>


  </ng-template>
</form>


<ng-template #confirmChangesModal>
    <h1 style="font-size: 28px!important;">Confirm changes</h1>


    <ng-container *ngFor="let item of Object.keys(vehicleForm.value);">
      <div *ngIf="compareSettings(item) && !['mflGroup', 'dvrUser', 'dvrUserPwd', 'commissioning', 'vehicleDvlaData'].includes(item)">
        {{item}}: {{vehicle[item]}} -> {{vehicleForm.value[item]}}
      </div>
    </ng-container>


  <div>
    any changes made will overwrite any pending changes waiting to take effect on the device
  </div>

  <button (click)="submitSettingsForm()">save</button>


</ng-template>






