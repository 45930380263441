
<div style="display: flex;">
  <h1 style="font-size: 28px!important;font-weight: bold;">Fleets</h1>
  <div style="flex:1;"></div>
  <button mat-flat-button (click)="showHideSearch()" style="margin-right:5px;height: 40px !important;" matTooltip="Search fleets">
    <mat-icon>search</mat-icon>
  </button>
  <button mat-raised-button (click)="editFleet($event, null)"
          style="margin-right:5px;height: 40px !important;background-color:#5C8459 !important;color:white!important;box-shadow: 2px 2px 0 0 rgb(0 0 0 / 20%)">
    + Add Fleet
  </button>
</div>
<br>
<div [hidden]="!showSearch" style="text-align: center;">
  <form>
    <mat-form-field style="width:300px;">
      <mat-label>Search By Fleet Id</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Filter" [(ngModel)]="searchValue" [ngModelOptions]="{standalone: true}">
    </mat-form-field>
    <button mat-button   mat-flat-button  color="primary"  aria-label="Clear" (click)="cancelFilter()" style="margin-left:20px;">
      <mat-icon>close</mat-icon>
      Reset Filter
    </button>
  </form>
</div>



<div style="max-height: 75vh!important;overflowY:scroll;">
<table mat-table [dataSource]="dataSource" >
  <ng-container matColumnDef="fleetId">
    <th mat-header-cell *matHeaderCellDef style="text-align: left!important;">
      Fleet ID
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="The ID of the fleet">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: left!important;"> {{element.fleetId}} </td>
  </ng-container>

  <ng-container matColumnDef="fleetName">
    <th mat-header-cell *matHeaderCellDef style="text-align: left!important;">
      Fleet Name
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="The name of the fleet">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: left!important;"> {{element.fleetName}} </td>
  </ng-container>


  <ng-container matColumnDef="budgetSecondsPerMonthPerVehicle">
    <th mat-header-cell *matHeaderCellDef>
      Video Budget
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Video budget allocated per vehicle">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element">
      {{element.budgetSecondsPerMonthPerVehicle}}
    </td>
  </ng-container>
  <ng-container matColumnDef="totalVehicles">
    <th mat-header-cell *matHeaderCellDef>
      Total Devices
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Total devices allocated to the fleet">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="allDevices">
        {{deviceStatusCount(element?.fleetId, 'ALL')}}
      </span>
      <mat-progress-bar *ngIf="!allDevices" mode="indeterminate"  style="width:75%!important;"></mat-progress-bar>

    </td>
  </ng-container>
  <ng-container matColumnDef="totalActiveVehicles">
    <th mat-header-cell *matHeaderCellDef>
      Active Devices
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Total active devices for the fleet">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="allDevices">
        {{deviceStatusCount(element?.fleetId, 'active')}}
      </span>
      <mat-progress-bar *ngIf="!allDevices" mode="indeterminate"  style="width:75%!important;"></mat-progress-bar>
    </td>
  </ng-container>
  <ng-container matColumnDef="totalStatusTestedVehicles">
    <th mat-header-cell *matHeaderCellDef>
      Pending Install
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Devices pending installation for the fleet">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="allDevices">
        {{deviceStatusCount(element?.fleetId, 'tested')}}
      </span>
      <mat-progress-bar *ngIf="!allDevices" mode="indeterminate"  style="width:75%!important;"></mat-progress-bar>
    </td>
  </ng-container>
  <ng-container matColumnDef="mflGroup">
    <th mat-header-cell *matHeaderCellDef>
      Group
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Group the fleet belongs to">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element">
      <span *ngIf="element.mflGroup">
        {{element.mflGroup}}
      </span>
      <span *ngIf="!element.mflGroup || element.mflGroup === 'undefined'">
        -
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef>Edit</th>
    <td mat-cell *matCellDef="let element" style="text-align: center;">
      <button mat-flat-button (click)="editFleet($event, element)" style="line-height: 30px;" [disabled]="authorisation === 'Fleet'">
        <mat-icon style="font-size: 22px;">edit</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="fleetsDisplayedColumns;sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: fleetsDisplayedColumns;"></tr>
</table>
<mat-paginator [pageSizeOptions]="[25, 50, 75]" showFirstLastButtons style="margin-right:10px;"></mat-paginator>
</div>




<mat-card *ngIf="tableLoading" style="display: flex; justify-content: center; align-items: center">
  <mat-progress-spinner
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
</mat-card>



<button (click)="scrollToTop()" class="scrollTopButton" id="scrollTopButtonEle">
  <mat-icon>arrow_circle_up</mat-icon>
</button>







<ng-template #zeroBlock><span>0</span></ng-template>

<ng-template #editFleetDialog>
  <h1 *ngIf="selectedFleet" style="font-size: 28px!important;">Editing Fleet</h1>
  <h1 *ngIf="!selectedFleet" style="font-size: 28px!important;">Adding Fleet</h1>



  <form  [formGroup]="fleetForm"  (ngSubmit)="onFormSubmit()">
    <div style="display:flex;gap:10px;height:100%;width:100%; flex-flow: wrap;">
      <div style="flex:1; flex-basis: 40%">
        <mat-card style="height:100%">
          <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;background:#000000d4;color:white;">
            <!--        <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">-->
            <!--          <path fill="currentColor" d="M19.07,4.93L17.66,6.34C19.1,7.79 20,9.79 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12C4,7.92 7.05,4.56 11,4.07V6.09C8.16,6.57 6,9.03 6,12A6,6 0 0,0 12,18A6,6 0 0,0 18,12C18,10.34 17.33,8.84 16.24,7.76L14.83,9.17C15.55,9.9 16,10.9 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12C8,10.14 9.28,8.59 11,8.14V10.28C10.4,10.63 10,11.26 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12C14,11.26 13.6,10.62 13,10.28V2H12A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,9.24 20.88,6.74 19.07,4.93Z" />                </svg>-->
            <!--        -->
            &nbsp;Fleet
          </mat-card-title>
          <mat-card-content style="text-align: center;">
            <table style="text-align: left!important;width: 100%;">

              <tr>
                <th style="background:transparent;text-align:left!important;padding-left:30px;">
                  Fleet Name:
                </th>
                <td style="display:flex;">
                  <mat-form-field style="width:300px;margin-left: auto;">
                    <input matInput  formControlName="fleetName" (input)="createFleetId()" [disabled]="authorisation !== 'superuser'">
                    <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="The fleet name" matSuffix>
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </mat-form-field>
                </td>
              </tr>
<!--              <tr>-->
<!--                <th style="background:transparent;text-align:left!important;padding-left:30px;">-->
<!--                  Fleet ID:-->
<!--                </th>-->
<!--                <td style="display:flex;">-->
<!--                  <mat-form-field style="width:300px;margin-left: auto;">-->
<!--                    <input matInput disabled formControlName="fleetId" readonly>-->
<!--                    <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="The unique fleet Id" matSuffix>-->
<!--                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />-->
<!--                    </svg>-->
<!--                  </mat-form-field>-->
<!--                </td>-->
<!--              </tr>-->
              <tr>
                <th style="background:transparent;text-align:left!important;padding-left:30px;">
                  Fleet Group:
                </th>
                <td style="display:flex;">
                  <mat-form-field style="width:300px;margin-left: auto;">
                    <mat-select formControlName="mflGroup" [disabled]="authorisation !== 'superuser'">
                      <mat-option *ngFor="let group of groups" [value]="group">{{group}}</mat-option>
                    </mat-select>
                    <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="The group the fleet is a part of " matSuffix>
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </mat-form-field>
                </td>
              </tr>
              <tr>
                <th style="background:transparent;padding-left:30px;text-align:left!important;">
                  Budget:
                </th>
                <td style="display:flex;">
                  <mat-form-field style="width:300px;margin-left: auto;">
                    <input matInput   formControlName="budgetSecondsPerMonthPerVehicle">
                    <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="Upload budget in seconds, per month, per vehicle" matSuffix>
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </mat-form-field>
                </td>
              </tr>



<!--              <tr>-->
<!--                <th style="background:transparent;padding-left:30px;text-align:left!important;">-->
<!--                  Days Before Missing:-->
<!--                </th>-->
<!--                <td style="display:flex;">-->
<!--                  <mat-form-field style="width:300px;margin-left: auto;">-->
<!--                    <input matInput type="number" min="1" max="30" formControlName="daysMissingAfter">-->
<!--                    <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="Days a device is offline before deemed missing" matSuffix>-->
<!--                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />-->
<!--                    </svg>-->
<!--                  </mat-form-field>-->
<!--                </td>-->
<!--              </tr>-->
<!--              <tr>-->
<!--                <th style="background:transparent;padding-left:30px;text-align:left!important;">-->
<!--                  Health Notification Sensitivity:-->
<!--                </th>-->
<!--                <td style="display:flex;align-items:center;justify-content:flex-end">-->
<!--                  <mat-slider [value]="fleetForm?.value?.notificationSensitivity" (change)="fleetForm.patchValue({notificationSensitivity:$event.value })" max="5" min="0"></mat-slider>-->
<!--                  {{fleetForm?.value?.notificationSensitivity}}-->
<!--                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="Sensitivity for health alerts for devices">-->
<!--                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />-->
<!--                  </svg>-->

<!--                </td>-->
<!--              </tr>-->


              <tr *ngIf="!selectedFleet?.fleetId">
                <th style="background:transparent;text-align:left!important;padding-left:30px;">Create user:</th>
                <td>
                  <mat-slide-toggle [(ngModel)]="createUser" [ngModelOptions]="{standalone: true}"
                                    style="transform: scale(1.2);float: right;">
                  </mat-slide-toggle>
                </td>
              </tr>
              <tr>
                <th style="background:transparent;text-align:left!important;padding-left:30px;">Delete Fleet:</th>
                <td>
                  <mat-slide-toggle [(ngModel)]="deleteFleet" [ngModelOptions]="{standalone: true}"
                                    style="transform: scale(1.2);float: right;" [disabled]="authorisation !== 'superuser'">
                  </mat-slide-toggle>
                </td>
              </tr>
            </table>
          </mat-card-content>
        </mat-card>
      </div>
      <div style="flex:1; flex-basis: 40%">
        <mat-card style="height:100%">
          <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;justify-content:space-between;background:#000000d4;color:white;">
            <!--        <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">-->
            <!--          <path fill="currentColor" d="M19.07,4.93L17.66,6.34C19.1,7.79 20,9.79 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12C4,7.92 7.05,4.56 11,4.07V6.09C8.16,6.57 6,9.03 6,12A6,6 0 0,0 12,18A6,6 0 0,0 18,12C18,10.34 17.33,8.84 16.24,7.76L14.83,9.17C15.55,9.9 16,10.9 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12C8,10.14 9.28,8.59 11,8.14V10.28C10.4,10.63 10,11.26 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12C14,11.26 13.6,10.62 13,10.28V2H12A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,9.24 20.88,6.74 19.07,4.93Z" />                </svg>-->
            <!--        -->
            &nbsp;Email alerts&nbsp;
            <button type="button" mat-flat-button (click)="addEmailToFleet()" style="line-height:20px" [disabled]="newFleet">+</button>
          </mat-card-title>
          <mat-card-content style="text-align: center;">
            <table style="text-align: left!important;width: 100%;">
              <tr *ngFor="let emailSetting of fleetEmailsArray.controls; index as i">
                <ng-container [formGroup]="emailSetting">
                <td>
                  <mat-form-field style="width:300px;">
                    <mat-select formControlName="email" placeholder="Email address">
                      <mat-option value="support@myfleetlive.co.uk">support@myfleetlive.co.uk</mat-option>
                      <mat-option *ngFor="let email of usersEmails" [value]="email">
                        {{email}}
                      </mat-option>

                    </mat-select>


                    <div *ngIf="!usersEmails?.includes(emailSetting?.value.email) && emailSetting?.value.email?.length > 5" style="color:red;">
                      <small>
                        {{ usersEmails?.includes(emailSetting?.value.email) }} might not have access to this fleet, are you sure you want to let them
                      receive emails
                      </small>
                    </div>


<!--                    <input matInput  formControlName="email" placeholder="Email address">-->
                    <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="Email address to send fleet alerts to" matSuffix>
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </mat-form-field>
                </td>
                <td style="display:flex">
                  <mat-form-field style="width:300px;margin-top:-4px;">
                    <mat-select formControlName="list" placeholder="Message type">
                      <mat-option value="health">Health</mat-option>
                      <mat-option value="alerts">Alerts</mat-option>
                    </mat-select>
                    <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="Type of messages that will be sent to the supplied email" matSuffix>
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </mat-form-field>

                  <div>
                    <button mat-icon-button (click)="removeEmail(i)" ><mat-icon style="color: #b1b3b2">delete</mat-icon></button>

                  </div>
                </td>
                </ng-container>
              </tr>
<!--              <tr>-->
<!--                <th style="background:transparent;padding-left:30px;text-align:left!important;">-->
<!--                  Country:-->
<!--                </th>-->
<!--                <td style="display:flex;">-->
<!--                  <mat-form-field style="width:300px;margin-left: auto;">-->
<!--                    <mat-select formControlName="country">-->
<!--                      <input (keyup)="filterCountries($event.target.value)" style="width:100%;">-->
<!--                      <mat-option *ngFor="let country of countries" [value]="country.code">{{country.name}}</mat-option>-->
<!--                    </mat-select>-->
<!--                    <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="Email address to send fleet alerts to" matSuffix>-->
<!--                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />-->
<!--                    </svg>-->
<!--                  </mat-form-field>-->
<!--                </td>-->
<!--              </tr>-->
            </table>



          </mat-card-content>
        </mat-card>
      </div>

      <div style="flex:1;flex-basis:100%;" *ngIf="createUser">
        <mat-card style="height:100%">
          <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;background:#000000d4;color:white;">
            <!--        <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">-->
            <!--          <path fill="currentColor" d="M19.07,4.93L17.66,6.34C19.1,7.79 20,9.79 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12C4,7.92 7.05,4.56 11,4.07V6.09C8.16,6.57 6,9.03 6,12A6,6 0 0,0 12,18A6,6 0 0,0 18,12C18,10.34 17.33,8.84 16.24,7.76L14.83,9.17C15.55,9.9 16,10.9 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12C8,10.14 9.28,8.59 11,8.14V10.28C10.4,10.63 10,11.26 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12C14,11.26 13.6,10.62 13,10.28V2H12A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,9.24 20.88,6.74 19.07,4.93Z" />                </svg>-->
            <!--        -->
            &nbsp;New user details
          </mat-card-title>
          <mat-card-content>

          <mat-form-field class="example-full-width" >
            <mat-label>Email</mat-label>
            <input matInput [(ngModel)]="newUserEmail" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
          <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="User email address and login">
            <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
          </svg>

          <mat-form-field class="example-full-width">
            <mat-label>Name</mat-label>
            <input matInput  [(ngModel)]="newUserName" [ngModelOptions]="{standalone: true}">
          </mat-form-field>
          <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="Full user name">
            <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
          </svg>

          <mat-form-field class="example-full-width">
            <mat-label>Authorisation</mat-label>
            <mat-select  [(ngModel)]="newUserAuth" [ngModelOptions]="{standalone: true}">
              <mat-option value="superuser" *ngIf="authorisation =='superuser'">admin</mat-option>
              <mat-option value="mflGroup">Group Admin</mat-option>
              <!--        <mat-option value="media">media</mat-option>-->
              <mat-option value="telematics">Telematics</mat-option>
            </mat-select>
          </mat-form-field>
          <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="User authorisation">
            <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
          </svg>

          <button *ngIf="!newUserPass"  mat-flat-button  color="primary" (click)="generatePassword()">Generate password</button>


          <br>


          <mat-card *ngIf="newUserPass" style="text-align: center;">
            A new password has been generated for the user, and is shown below.<br>
            You must copy this to a safe place, or cancel the new password via the button below.
            <br><br>
            <div *ngIf="newUserPass" style="display: inline-block;margin-right: 5px;">
              <h4>New password: <strong>{{newUserPass}}</strong></h4>
            </div>
            <button type="button" mat-flat-button [cdkCopyToClipboard]="newUserPass" >
              <mat-icon>content_copy</mat-icon>
              Copy
            </button>

            <br>
            <button *ngIf="newUserPass" mat-flat-button  color="primary" (click)="newUserPass = null" style=" margin-bottom: 5px;">
              I don't want a new password
            </button>



          </mat-card>

          </mat-card-content>
        </mat-card>
      </div>

    </div>









    <br>
    <div class="alert alert-warning" style="text-align: center;" *ngIf="warningMsg">
      {{warningMsg}}
    </div>
    <div style="text-align: center;">
      <button mat-flat-button color="primary" type="button" (click)="formSubmitChecker()" *ngIf="!showSubmit && fleetForm.value.fleetId">
        <mat-icon>save</mat-icon>
        Save changes
      </button>
      <button mat-flat-button color="primary" type="submit" *ngIf="showSubmit || !fleetForm.value.fleetId">
        <mat-icon>save</mat-icon>
        Save changes
      </button>
    </div>
  </form>

  <br>

</ng-template>
