import {Component, Input, OnInit} from '@angular/core';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-health-chart',
  templateUrl: './health-chart.component.html',
  styleUrls: ['./health-chart.component.css']
})
export class HealthChartComponent implements OnInit {

  @Input() healthGraphRawData;
  @Input() cameras;
  @Input() calendar;

  constructor() { }

  ngOnInit(): void {

  }

  checkCameraHealth(alarm, camChannel): boolean {
    let videoLossArrayString = alarm.alarmData.statusData.videoLoss;
    // pads string to make string length of number of cameras
    while (videoLossArrayString.length < 8) {
      videoLossArrayString = '0' + videoLossArrayString;
    }
    // split str to array
    const videoLossArray = videoLossArrayString.split('');
    // read left to right
    videoLossArray.reverse();
    // if error and camera exists return  false for camhealthOk check
    return videoLossArray[parseInt(camChannel) - 1] === '0';
  }

  checkRecording(alarm, camChannel): boolean {
    let recordingArrayString = alarm.alarmData.statusData.recordingStatus;
    while (recordingArrayString.length < 8) {
      recordingArrayString = '1' + recordingArrayString;
    }
    const recordingArray = recordingArrayString.split('');
    recordingArray.reverse();
    return recordingArray[parseInt(camChannel) - 1] === '1';
  }



  checkStorageHealth(alarm): boolean {

    const timeStamp = new Date(alarm?.alarmData?.statusData?.deviceTime);
    let recsFound = 0;

    if (this.calendar?.modRecordingHistory){
      this.calendar.modRecordingHistory.forEach(rec => {
        if (new Date(rec.st) >= timeStamp && timeStamp <= new Date(rec.et)){
          recsFound ++;
        }
      });
    }

    return recsFound > 0;
  }

  getNetworkTypeReadable(networkType): string{
    switch (networkType){
      case '?': {
        return '?';
      }
      case 1: {
        return 'wired';
      }
      case 2: {
        return 'WI-FI';
      }
      case 3: {
        return '2G';
      }
      case 4: {
        return '3G';
      }
      case 5: {
        return '4G';
      }
      case 6: {
        return '5G';
      }
      default: {
        return '?';
      }
    }
  }

  dateTimeFormat(str): string {
    if (!isNaN(new Date(str).getTime())){
      return formatDate(new Date(str), 'HH:mm:ss', 'en_US') + '<br>' +
        formatDate(new Date(str), 'MMM d', 'en_US');
    } else {
      return '';
    }
  }

  dateTimeFormatTooltip(str): string {
    if (!isNaN(new Date(str).getTime())){
      return ' at ' + formatDate(new Date(str), 'medium', 'en_US');
    } else {
      return '';
    }
  }



  getCamHealthTooltip(bool, date): string {
    if (bool){
      return 'The camera was detected' + this.dateTimeFormatTooltip(date);
    } else {
      return 'The camera was not detected' + this.dateTimeFormatTooltip(date);

    }
  }

  getCamRecTooltip(bool, date): string {
    if (bool){
      return 'The camera was recording at ' + this.dateTimeFormatTooltip(date);
    } else {
      return 'The camera was not recording ' + this.dateTimeFormatTooltip(date);

    }
  }



}
