<h1 style="font-size: 28px!important;font-weight: bold;">Notifications</h1>
<mat-dialog-content class="mat-typography">

  <div [hidden]="notificationsLoaded" style="height:500px;">
    <mat-spinner style="margin: auto;position: absolute;top: 0; left: 0; bottom: 0; right: 0;"></mat-spinner>
  </div>

  <div [hidden]="!notificationsLoaded">
    <mat-tab-group (selectedTabChange)="confirmMarkRead = false" [selectedIndex]="tabsIndex" class="notiTabs">

      <mat-tab></mat-tab>

        <mat-tab (click)="confirmMarkRead = false" *ngFor="let cat of notiCategories">
          <ng-template mat-tab-label>
            <span [matBadge]="getNotiByCat(cat)?.length > 0 ? getNotiByCat(cat)?.length : null" matBadgeOverlap="false" class="notiBadge">
              {{cat.label}}
            </span>
          </ng-template>

          <br>

          <div *ngIf="getNotiByCat(cat)?.length < 1" style="text-align: center;">
            <h1 style="font-size: 22px!important;font-weight: bold;">
              No {{cat.label}} notifications
            </h1>
          </div>


          <mat-form-field matTooltip="Filter by fleet" *ngIf="getNotiByCat(cat)?.length > 0">
            <mat-label>Filter by fleet</mat-label>
            <mat-select [(ngModel)]="filter">
              <mat-option value="all">All</mat-option>
              <mat-option *ngFor="let fleet of fleetsDropdown" [value]="fleet.fleetId"
              [innerHTML]="!allFleets || !allFleets[fleet?.fleetId]?.fleetName ? fleet.fleetId : allFleets[fleet?.fleetId]?.fleetName">
              </mat-option>
            </mat-select>
          </mat-form-field>


          <div style="float:right"  *ngIf="getNotiByCat(cat)?.length > 0">

            <div *ngIf="confirmMarkRead">

                <button mat-raised-button  color="primary" (click)="markAsRead(getNotiByCat(cat))"
                        style="margin-right:5px;height: 40px !important;background-color:#5C8459 !important;box-shadow: 2px 2px 0 0 rgb(0 0 0 / 20%)">
                  Confirm mark all as read
                </button>

                <button mat-flat-button (click)="confirmMarkRead = false">Cancel</button>

            </div>

            <button mat-flat-button *ngIf="!confirmMarkRead" (click)="confirmMarkRead = true">
              <mat-icon>mark_as_unread</mat-icon>
              Mark all as read
            </button>
          </div>


          <table mat-table multiTemplateDataRows [dataSource]="tableDatasource(getNotiByCat(cat))" *ngIf="getNotiByCat(cat)?.length > 0">

            <ng-container matColumnDef="registration">
              <th mat-header-cell *matHeaderCellDef>Registration</th>
              <td mat-cell *matCellDef="let noti">
                  {{noti.registration}}
              </td>
            </ng-container>

            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef>Notification</th>
              <td mat-cell *matCellDef="let noti">
                {{noti.notificationDetails.title}}
              </td>
            </ng-container>

            <ng-container matColumnDef="fleetId">
              <th mat-header-cell *matHeaderCellDef>Fleet</th>
              <td mat-cell *matCellDef="let noti">
                <span [innerHTML]="!allFleets || !allFleets[noti?.fleetId]?.fleetName ? noti.fleetId : allFleets[noti?.fleetId]?.fleetName"></span>
              </td>
            </ng-container>

            <ng-container matColumnDef="dateTime">
              <th mat-header-cell *matHeaderCellDef>Date</th>
              <td mat-cell *matCellDef="let noti">{{noti.notificationCreatedTimestamp | date: 'medium'}}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let noti" style="text-align: right;">

                <button mat-flat-button (click)="markNotificationRead(noti)"  style="margin-right:5px;">
                  <mat-icon style="vertical-align: middle">mark_as_unread</mat-icon>
                  Mark Read
                </button>

                <a mat-flat-button *ngIf="viewDevice(noti)"
                   target="_blank" style="margin-right:5px;" href="/device/{{noti.dn}}">
                  <mat-icon style="vertical-align: middle">router</mat-icon>
                  &nbsp;{{noti.dn}}
                </a>

                <a mat-flat-button *ngIf="noti.notificationType.includes('videoUploadComplete')" target="_blank"
                   href="/video-player/{{noti.notificationDetails.videoRequest.mediaRecord.filename}}">
                  <mat-icon style="vertical-align: middle">ondemand_video</mat-icon>
                  View Media
                </a>


                <a mat-flat-button *ngIf="noti.notificationType.includes('hyperlapseUploadComplete')" target="_blank"
                   href="/video-player/{{noti.notificationDetails.videoRequest.hyperlapse.file}}">
                  <mat-icon style="vertical-align: middle">ondemand_video</mat-icon>
                  View Media
                </a>
              </td>
            </ng-container>


            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="example-element-detail"
                     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div style="padding:15px 15px 15px 0;text-align: center;font-weight: bold">
                    {{element.notificationDetails.description}}
                  </div>
                </div>
              </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
            <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay" class="example-element-row clickRow"
                [class.example-expanded-row]="expandedElement === myRowData"
                (click)="expandedElement = expandedElement === myRowData ? null : myRowData"></tr>

            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
          </table>
        </mat-tab>

        <mat-tab *ngIf="mediaQueue.length >= 1">
          <ng-template mat-tab-label>
            <span [matBadge]="mediaQueue.length" matBadgeOverlap="false" class="notiBadge">
              Pending uploads
            </span>
          </ng-template>


          REFRESH UPLOADS ON MEDIA REQUEST



          <table mat-table multiTemplateDataRows [dataSource]="tableDatasource(mediaQueue)">

            <ng-container matColumnDef="registration">
              <th mat-header-cell *matHeaderCellDef>Registration</th>
              <td mat-cell *matCellDef="let upload">
                {{upload.registration}}
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let upload">
                <span *ngIf="upload?.uploadedFrames?.length === 0">
                  Request recieved
                </span>
                <span *ngIf="upload?.uploadedFrames?.length > 0">
                  Uploading
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="fleetId">
              <th mat-header-cell *matHeaderCellDef>Fleet</th>
              <td mat-cell *matCellDef="let upload">
                {{upload.fleetId}}
              </td>
            </ng-container>

            <ng-container matColumnDef="progress">
              <th mat-header-cell *matHeaderCellDef>Upload Progress</th>
              <td mat-cell *matCellDef="let upload">
                  <mat-progress-bar mode="determinate" [value]="(upload.uploadedFrames[0] / upload.totalFrames) * 100" style="width: calc(100% - 30px);display:inline-block"></mat-progress-bar>
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;display: inline-block;margin-left: 5px;" viewBox="0 0 24 24" [matTooltip]="percentTooltip((upload.uploadedFrames[0] / upload.totalFrames) * 100)">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
              </td>
            </ng-container>


            <ng-container matColumnDef="expandedDetail">
              <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="example-element-detail"
                     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                  <div style="padding:15px 15px 15px 0;font-weight: bold">
                    <pre>{{element | json}}</pre>
                  </div>
                </div>
              </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="columnsToDisplayMediaQueue"></tr>
            <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplayMediaQueue"
                [class.example-expanded-row]="expandedElement === myRowData"
                (click)="showUploadDetails(myRowData)"></tr>

            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
          </table>
        </mat-tab>


    </mat-tab-group>
  </div>
</mat-dialog-content>
