import {Component, Input, OnInit, TemplateRef, ViewChild, EventEmitter} from '@angular/core';
import {MultiLivestreamComponent} from '../../multiLivestream/multiLivestream.component';
import {MatStepper} from '@angular/material/stepper';
import {DatePipe} from '@angular/common';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {AdminService} from '../../admin.service';
import {NotificationsService} from '../../notifications.service';
import {MatDialog} from '@angular/material/dialog';
import * as CryptoJS from 'crypto-js';
import { Output } from '@angular/core';
import {noOfChannels} from "../../../lib";

@Component({
  selector: 'app-commissioning',
  templateUrl: './commissioning.component.html',
  styleUrls: ['./commissioning.component.css'],
  providers: [DatePipe, {provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}}]
  })
export class CommissioningComponent implements OnInit {

  @ViewChild(MultiLivestreamComponent ) child: MultiLivestreamComponent ;
  @ViewChild('stepper', {static: false}) myStepper: MatStepper;
  @ViewChild('selfCertModal', { static: true }) selfCertModal: TemplateRef<any>;
  @Input() commissioning;
  @Input() testedForm;
  @Input() commissionForm;
  @Input() vehicleForm;
  @Input() camerasTestedFormArray;
  @Input() camerasFormArray;
  @Input() changeStatusForm;
  @Input() vehicle;
  @Input() deviceStatus;
  @Input() cameras;
  @Input() selfCertifyDeviceSensorChecks;
  @Input() vehicleFleet;
  @Input() allFleets;
  @Input() theVehicleSettings;
  @Input() vehicleRegistration;
  @Input() engineers;
  @Input() usersGroup;
  @Input() deviceConnected;
  @Input() snaps;
  @Input() disableCalibrateGButton;
  @Input() calibrateGSensor: () => void;
  @Input() getDeviceStatus: () => void;
  @Input() triggerUnderSpeedAlarm: () => void;
  @Output() refreshDevice = new EventEmitter<boolean>();

  commissioningFinalNotes: any;
  commissioningCameraCheckCompleteValue = true;

  vehicleData: any;
  commissionRef: string;
  playing: any;
  eventProfiles: any;
  recommendedProfiles: any;
  permission = localStorage.getItem('authorisation');



  constructor(private adminService: AdminService,  private notificationService: NotificationsService,
              public dialog: MatDialog, private datePipe: DatePipe) { }

  ngOnInit(): void {
    // {mflGroup: localStorage.getItem('mflGroup')}
      this.adminService.eventProfiles({}).subscribe(r => {
        this.eventProfiles = r;
      });




      // this.eventProfileSelected = this.theVehicleSettings?.eventProfile;
    // console.log(CryptoJS.MD5('16-02-2023 12:42 1000903').toString().slice(0, 4));
  }


  startStopAll(param): void {
    this.child.startStopAll(param);
    this.playing = param;
  }

  testedVehicle(): void {
    this.testedForm.value.commissionStatus = 'tested';

    const data = {
      dn: this.vehicle.dn,
      commissioning: this.testedForm.value
    };


    this.adminService.commissionVehicle(data).subscribe(result => {
      this.dialog.closeAll();
      this.ngOnInit();
    });

  }

  goBack(stepper: MatStepper): void {
    stepper.previous();
  }



  goForward(stepper: MatStepper): void{
    console.log(stepper.selectedIndex)

    if (stepper.selectedIndex === 0){

      const data = this.vehicleRegistration.trim().toLocaleLowerCase().split(' ').join('');

      this.adminService.getVehicleData(data).subscribe(result => {

        if (result.Response.StatusCode === "Success"){
          this.vehicleData = result.Response.DataItems.VehicleRegistration;
        } else {
          this.vehicleData = 'noData'
        }

        const profileShortlist = [];
        const channels = noOfChannels(this.vehicle.device);

        this.eventProfiles.forEach(profile => {
          if (profile?.vehicleType?.toUpperCase() === this.vehicleData?.DoorPlanLiteral &&
            profile?.mflDevice === (channels + 'CN') && profile?.mflGroup === localStorage.getItem('mflGroup')){
            profileShortlist.push(profile);
          }
        });

        this.recommendedProfiles = profileShortlist;

        if (this.vehicleForm.controls.vehicleDvlaData) {
          this.vehicleForm.patchValue({vehicleDvlaData:result.Response.DataItems});
        }
      });
      stepper.next();
    } else if (this.commissioningSystemCheckComplete() && !this.selfCertifyDeviceSensorChecks && stepper.selectedIndex === 3){
      const options = { closeOnNavigation: true, width: '60%', height: '95%', hasBackdrop: true };
      this.dialog.open(this.selfCertModal, options);
    } else {
      stepper.next();
    }
  }

  commissioningCameraCheckComplete(event): void {
    this.commissioningCameraCheckCompleteValue =  !event.checked;
  }

  // commissioning system checks form validation
  commissioningSystemCheckComplete(): boolean{
    let disabled = false;
    if (!this.commissionForm.value.gpsPass ||  !this.commissionForm.value.gSensorCalibratePass || !this.deviceConnected){
      disabled = true;
    }
    if (this.selfCertifyDeviceSensorChecks){
      disabled = false;
    }
    return disabled;
  }


  selfCertify(): void {
    this.selfCertifyDeviceSensorChecks = true;
    this.myStepper.next();
  }





  // commissioning install details checks form validation
  commissioningInstallDataCheckComplete(): boolean {
    let disabled = false;
    if (!this.vehicleRegistration || this.commissionForm.value.installDate === '' || this.commissionForm.value.installEngineer.trim() === '' ||
      this.vehicleRegistration.trim() === '' || this.vehicleFleet.trim() === ''
    ){
      disabled = true;
    }
    return disabled;
  }


  changeCommissionStatus(): void {

    // this.changeStatusForm.value.commissionStatus = 'tested';

    const data = {
      dn: this.vehicle.dn,
      commissioning : {commissionStatus: this.changeStatusForm.value.commissionStatus}
    };


    if (this.changeStatusForm.value.commissionStatus !== 'tested') {
      this.notificationService.commissionVehicle({
        deviceModeChanged: {
          mode: this.changeStatusForm.value.commissionStatus,
          dn: this.vehicle.dn,
          fleet: this.vehicle.fleetId,
          installDate: new Date().getTime(),
          registration: this.vehicle.registration,
          signedOffBy: localStorage.getItem('email'),
          mflGroup: this.vehicle.mflGroup
        }
      }, this.vehicle, {
        commissioning: {
          commissionStatus: this.changeStatusForm.value.commissionStatus,
          prevStatus: this.commissioning.commissionStatus,
          dn: this.vehicle.dn,
          fleet: this.vehicle.fleetId,
          installDate: new Date().getTime(),
          registration: this.vehicle.registration,
          signedOffBy: localStorage.getItem('email'),
          mflGroup: this.vehicle.mflGroup,
          installEngineer: this.changeStatusForm.value.installEngineer
        }
      }).subscribe(r => console.log(r));
    }


    console.log(data)

    this.adminService.commissionVehicle(data).subscribe(result => {
      console.log(result);
    });

    const data2 =
      {
        id: this.vehicle._id,
        note: 'Commissioning status changed from ' + this.commissioning.commissionStatus + ' to '
          + this.changeStatusForm.value.commissionStatus
      };


    this.adminService.updateVehicleNotes(data2).subscribe(result => {
      this.closeModal();
    });

    //TODO Log changes here

  }





  commissionVehicle(stepper: MatStepper): void {


    const email = localStorage.getItem('email');
    const dateString = this.datePipe.transform( new Date(), 'dd-MM-yyyy HH:mm');
    this.commissionRef = CryptoJS.MD5(dateString + ' ' + this.vehicle.dn).toString().slice(0, 4);


    const data2 = {
      id: this.vehicle._id,
      note: 'The device has been commissioned by ' + email + ' on ' + dateString
    };

    if (this.commissioningFinalNotes?.length > 1) {
      data2.note += ' with the following notes being left "'
        + this.commissioningFinalNotes + '"';
    }

    if (!this.commissionForm.value.gpsPass){
      data2.note += ' GPS checks failed (' + this.deviceStatus?.satellitesQuantity + ' satellites)';
    }

    if (!this.commissionForm.value.ignitionPass){
      data2.note += ' wiring checks failed';
    }

    if (!this.commissionForm.value.gSensorCalibratePass){
      data2.note += ' G-force sensor checks failed';
    }

    data2.note += ` -> Ref: ${this.commissionRef}`;



    this.adminService.updateVehicleNotes(data2).subscribe(() => {});

    this.commissionForm.value.commissionStatus = 'active';

    const data = {
      dn: this.vehicle.dn,
      commissioning: this.commissionForm.value
    };

    this.adminService.commissionVehicle(data).subscribe(res => {

      data.commissioning.snaps = this.snaps;

      this.notificationService.commissionVehicle({
        deviceModeChanged: {
          mode: 'active',
          dn: this.vehicle.dn,
          fleet: this.vehicle.fleetId,
          installEngineer: data.commissioning.installEngineer,
          installDate: new Date().getTime(),
          registration: this.vehicle.registration,
          signedOffBy: localStorage.getItem('email'),
          mflGroup: this.vehicle.mflGroup,
          selfCertify: this.selfCertifyDeviceSensorChecks,
          sensorNotes: data2.note,
          commissioning: data.commissioning,
          commissioningNotes: this.commissioningFinalNotes
        }
      }, this.vehicle, data).subscribe(r => console.log(r));

// TODO Log changes here

      // if (this.theVehicleSettings) {
      //
      //   // ADD EVENT PROFILE CHANGES HERE
      //   // ADD CAM FLIP HERE ALSO??
      //
      //   const theUpdatedVehicleData = {
      //     cameras: this.theVehicleSettings.cameras,
      //     device: this.theVehicleSettings.device,
      //     deviceBarCode: this.theVehicleSettings.deviceBarCode,
      //     deviceDob: this.theVehicleSettings.deviceDob,
      //     deviceIEMI: this.theVehicleSettings.deviceIEMI,
      //     deviceSIM: this.theVehicleSettings.deviceSIM,
      //     deviceSerial: this.theVehicleSettings.deviceSerial,
      //     dn: this.theVehicleSettings.dn,
      //     driver: this.theVehicleSettings.driver,
      //     dvrUser: this.theVehicleSettings.dvrUser,
      //     dvrUserPwd: this.theVehicleSettings.dvrUserPwd,
      //     fleetId: this.vehicleFleet,
      //     id: this.theVehicleSettings._id,
      //     overrun: this.theVehicleSettings.overrun,
      //     overrunRecording: this.theVehicleSettings.overrunRecording,
      //     registration: this.vehicleRegistration,
      //     vehicleClass: this.vehicleData.Class,
      //     vehicleFuelType: this.vehicleData.FuelType,
      //     vehicleMake: this.vehicleData.Make,
      //     vehicleModel: this.vehicleData.Model
      //   };
      // }
      stepper.next();
    });
  }

  closeModal(): void {
    this.dialog.closeAll();
    this.refreshDevice.emit(true);
  }

  stopAllAndNext(stepper: MatStepper): void{
    if (this.playing === 'play'){
      this.startStopAll('stop');
    }
    this.goForward(stepper);
  }


  selectedProfileJson(): any {
    let data;

    for (let i = 0; i < this.eventProfiles?.length; i++) {
      if (this.eventProfiles[i]?.profileName === this.vehicleForm.value.eventProfile){
        data = this.eventProfiles[i];
        break;
      }
    }
    return data;
  }


}
