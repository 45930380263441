<div class="loadingOverlay" *ngIf="pageLoading">
  <div class="loader"></div>
</div>


<div style="display: flex;margin-bottom: 10px;">
  <div style="width:100%;">
    <h1 style="font-size: 28px!important;font-weight: bold;">
      Device Setup
    </h1>

    <div style="display: flex;flex-wrap: wrap;">
      <div>
        <h3 style="font-size: 21px!important;" *ngIf="deviceParameters">
          <span style="font-weight: bold">{{vehicleForm.controls.registration.value}}</span> ({{vehicleForm.controls.dn.value}})
        </h3>
        <p style="margin-top: -10px;">
          <span *ngIf="deviceConnected" class="onlineChip">Online</span>
          <span *ngIf="!deviceConnected" class="offlineChip">Offline</span>
          &nbsp;| <span style="font-weight: 500;">Mode:</span>&nbsp;
          <span *ngIf="this.vehicle?.commissioning && this.vehicle?.commissioning.commissionStatus == 'tested'">Install</span>
          <span *ngIf="this.vehicle?.commissioning && this.vehicle?.commissioning.commissionStatus != 'tested'">{{this.vehicle.commissioning.commissionStatus | titlecase}}</span>

        </p>
      </div>
      <div style="text-align: right;flex:1;">

        <button mat-flat-button type="button" (click)="getAllParameters()" [disabled]="!deviceConnected" style="margin-right:10px;">
          <mat-icon *ngIf="deviceConnected" style="color:black!important;">sync</mat-icon>
          &nbsp;Sync
        </button>


        <button mat-flat-button type="button" (click)="beginInternalTesting()" style="margin-right:10px;"
                [disabled]="!vehicle || !deviceConnected" *ngIf="usersPermission === 'superuser' || usersEmail === 'sbudd@amsgroupusa.com' ||
                    usersEmail ==='jack.howlett@fleetfocus.co.uk'">
          <mat-icon *ngIf="vehicle && deviceConnected" style="color:black!important;">network_check</mat-icon>
          Bench
        </button>

        <button mat-flat-button type="button" (click)="openRawDataModal()" [disabled]="!deviceConnected" style="margin-right:10px;">
          <mat-icon *ngIf="deviceConnected" style="color:black!important;">sync</mat-icon>
          &nbsp;Raw
        </button>

        <button (click)="openCloneDeviceModal()" mat-flat-button style="margin-right:10px;">
          <mat-icon style="color:black!important;">content_copy</mat-icon>
          &nbsp;Clone
        </button>


        <button mat-flat-button  type="submit" (click)="onFormSubmit()" [disabled]="vehicleForm.invalid"
                style="background:#5C8459!important;color:white!important;">
          <mat-icon *ngIf="!vehicleForm.invalid" style="color:white!important;">save</mat-icon>
          Save
        </button>
      </div>
    </div>
<!--<div style="display: flex">-->
<!--  <div style="text-align: center;flex:1;align-items: center">-->
<!--    <div *ngIf="errorsDisplay"-->
<!--         style="position: relative;padding: .50rem 1.25rem;margin-bottom: 1rem;border: 1px solid transparent;border-radius: .25rem;color: #856404;min-width: 64px;line-height: 36px;-->
<!--                background-color: #fff3cd;border-color: #ffeeba;width:60%;margin:0 auto;">-->
<!--      {{errorsDisplay}}-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->



<form [formGroup]="vehicleForm" style="margin-top:10px;" autocomplete="off">
<div style="display: flex;flex-wrap: wrap;width:100%;height: 75vh;overflow-y: scroll;" [formGroup]="vehicleForm">


  <mat-card style="flex: 1;margin-right:10px;">
    <mat-card-title style="background: #2b2b2b!important;color:white;padding:21px 10px!important;display: flex;align-content: center;align-items: center;">
      <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">
        <path fill="currentColor" d="M2,6H4V18H2V6M5,6H6V18H5V6M7,6H10V18H7V6M11,6H12V18H11V6M14,6H16V18H14V6M17,6H20V18H17V6M21,6H22V18H21V6Z" />
      </svg>
      &nbsp;Binding
    </mat-card-title>
    <mat-card-content>
      <table style="text-align: left!important;width: 100%;">
        <tr>
          <th>
            Model number:
          </th>
          <td style="display:flex;;">
            <mat-form-field style="width:300px;margin-left: auto;">
              <mat-select formControlName="device">
                <mat-option *ngFor="let modelNo of environment.MODEL_NUMBER_ARRAY" [value]="modelNo.modelNoDb">{{modelNo.modelNoDisplay}}</mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <th>
            Device barcode:
          </th>
          <td style="display: flex;">
            <mat-form-field style="width:300px;margin-left: auto;" *ngIf="!vehicle || !vehicle.deviceBarCode">
              <!--              <mat-label>Device bar code</mat-label>-->
              <input matInput formControlName="deviceBarCode" oninput="this.value = this.value.toUpperCase().replace(' ', '')">
            </mat-form-field>
            <div *ngIf="vehicle && vehicle.deviceBarCode" class="disabledInput">
              {{vehicle.deviceBarCode}}
            </div>
          </td>
        </tr>
        <tr>
          <th>
            Device number:
          </th>
          <td style="display: flex;">
            <mat-form-field style="width:300px;margin-left: auto;" *ngIf="!vehicle || !vehicle.dn">
              <!--              <mat-label>Device Number</mat-label>-->
              <input matInput formControlName="dn">
            </mat-form-field>
            <div *ngIf="vehicle && vehicle.dn" class="disabledInput">
              {{vehicle.dn}}
            </div>
          </td>
        </tr>
        <tr>
          <th>
            Device DOB:
          </th>
          <td style="display: flex;">
            <mat-form-field style="width:300px;margin-left: auto;" *ngIf="!vehicle || !vehicle.deviceDob">
              <!--              <mat-label>Device DOB</mat-label>-->
              <input matInput formControlName="deviceDob">
            </mat-form-field>
            <div *ngIf="vehicle && vehicle.deviceDob" class="disabledInput">
              {{vehicle.deviceDob}}
            </div>
          </td>
        </tr>
        <tr>
          <th>
            Device serial number:
          </th>
          <td style="display: flex;">
            <mat-form-field style="width:300px;margin-left: auto;" *ngIf="!vehicle || !vehicle.deviceSerial">
              <!--              <mat-label>Device Serial Number</mat-label>-->
              <input matInput formControlName="deviceSerial">
            </mat-form-field>
            <div *ngIf="vehicle && vehicle.deviceSerial" class="disabledInput">
              {{vehicle.deviceSerial}}
            </div>
          </td>
        </tr>
        <tr>
          <th>
            Device SIM number:
          </th>
          <td style="display: flex;">
            <mat-form-field style="width:300px;margin-left: auto;">
              <!--              <mat-label>Device SIM Number</mat-label>-->
              <input matInput formControlName="deviceSIM">
            </mat-form-field>
          </td>
        </tr>
      </table>
    </mat-card-content>
  </mat-card>



  <mat-card style="margin-right:10px;flex: 1;">
    <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;background: #2b2b2b!important;color:white;">
      <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">
        <path fill="currentColor" d="M19 15H17V13H19M20 20V22H16V20H17V18H16V16H19V20M18.92 5A1.5 1.5 0 0 0 17.5 4H6.5A1.5 1.5 0 0 0 5.08 5L3 11V19A1 1 0 0 0 4 20H5A1 1 0 0 0 6 19V18H11A7 7 0 0 1 18 11A6.84 6.84 0 0 1 21 11.68V11M6.5 15A1.5 1.5 0 1 1 8 13.5A1.5 1.5 0 0 1 6.5 15M5 10L6.5 5.5H17.5L19 10Z" />
      </svg>
      &nbsp;Vehicle
    </mat-card-title>
    <mat-card-content>
      <table style="text-align: left!important;width: 100%;">
        <tr>
          <th>
            Fleet name:
          </th>
          <td style="display: flex;">
            <mat-form-field style="margin-left: auto;width: 300px;">
<!--              <mat-label>The Fleet ID The Vehicle Belongs To</mat-label>-->
              <mat-select formControlName="fleetId">
                <mat-option *ngFor="let fleet of allFleets" [value]="fleet.fleetId">
                  {{fleet.fleetId}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </tr>
        <tr>
          <th>
            Registration:
          </th>
          <td style="display: flex;">
            <mat-form-field style="width:300px;margin-left: auto;">
<!--              <mat-label>Vehicle Registration</mat-label>-->
              <input matInput formControlName="registration" oninput="this.value = this.value.toUpperCase()">
                <mat-icon style="color: #606060!important;vertical-align: bottom!important;margin-left: auto;cursor:pointer;margin-bottom:-3px;"
                          (click)="lookupRegistration()" matSuffix>
                  search
                </mat-icon>

              <mat-icon style="color: #606060!important;vertical-align: bottom!important;margin-left: auto;cursor:pointer;margin-bottom:-3px;"
                        (click)="saveRegistrationDetails()"  *ngIf="showVehicleSaveButton" matSuffix>
                save
              </mat-icon>

            </mat-form-field>
          </td>
        </tr>
        <tr *ngIf="vehicleForm.controls.vehicleDvlaData">
          <th>
            Vehicle make:
          </th>
          <td style="display:flex;">
            <span  class="disabledInput">{{vehicleForm['controls'].vehicleDvlaData?.value?.VehicleRegistration?.Make}}</span>
          </td>
        </tr>
        <tr *ngIf="vehicleForm.controls.vehicleDvlaData">
          <th>
            Vehicle model:
          </th>
          <td style="display: flex">
            <span  class="disabledInput">{{vehicleForm['controls'].vehicleDvlaData?.value?.VehicleRegistration?.Model}}</span>
          </td>
        </tr>
        <tr *ngIf="vehicleForm.controls.vehicleDvlaData">
          <th>
            Vehicle class:
          </th>
          <td style="display: flex;">
            <span  class="disabledInput">{{vehicleForm['controls'].vehicleDvlaData?.value?.VehicleRegistration?.VehicleClass}}</span>
          </td>
        </tr>
        <tr *ngIf="vehicleForm.controls.vehicleDvlaData">
          <th>
            Fuel type:
          </th>
          <td style="display: flex">
            <span  class="disabledInput">{{vehicleForm['controls'].vehicleDvlaData?.value?.VehicleRegistration?.FuelType}}</span>
          </td>
        </tr>
        <tr *ngIf="mflGroup == 'undefined'">
          <th style="padding-top:10px;">
            Event profile:
          </th>
          <td style="display: flex;">
            <mat-form-field style="margin-left: auto;width: 300px;">
              <input matInput readonly  [value]="vehicleForm['controls'].eventProfile.value">
              <button matSuffix mat-flat-button (click)="openEventProfileModal()" style="margin-left: auto;font-size: 10px;">
                <mat-icon style="color: #606060!important;vertical-align: bottom!important;">settings</mat-icon>
              </button>
            </mat-form-field>
          </td>
        </tr>

      </table>
      <span *ngIf="!vehicleForm['controls'].vehicleDvlaData?.value?.VehicleRegistration?.Make" style="text-align: center;">
        No vehicles found for that registration in the DVLA database
      </span>
    </mat-card-content>
  </mat-card>

  <mat-card style="margin-right:10px;flex: 1;">
    <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;background: #2b2b2b!important;color:white;">
      <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">
        <path fill="currentColor" d="M20.2,5.9L21,5.1C19.6,3.7 17.8,3 16,3C14.2,3 12.4,3.7 11,5.1L11.8,5.9C13,4.8 14.5,4.2 16,4.2C17.5,4.2 19,4.8 20.2,5.9M19.3,6.7C18.4,5.8 17.2,5.3 16,5.3C14.8,5.3 13.6,5.8 12.7,6.7L13.5,7.5C14.2,6.8 15.1,6.5 16,6.5C16.9,6.5 17.8,6.8 18.5,7.5L19.3,6.7M19,13H17V9H15V13H5A2,2 0 0,0 3,15V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V15A2,2 0 0,0 19,13M8,18H6V16H8V18M11.5,18H9.5V16H11.5V18M15,18H13V16H15V18Z" />
      </svg>
      &nbsp;Settings
    </mat-card-title>
    <mat-card-content>
      <table style="text-align: left!important;width:100%;">
        <tr>
          <th>
            <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M13,3H11V13H13V3M17.83,5.17L16.41,6.59C18.05,7.91 19,9.9 19,12A7,7 0 0,1 12,19C8.14,19 5,15.88 5,12C5,9.91 5.95,7.91 7.58,6.58L6.17,5.17C2.38,8.39 1.92,14.07 5.14,17.86C8.36,21.64 14.04,22.1 17.83,18.88C19.85,17.17 21,14.65 21,12C21,9.37 19.84,6.87 17.83,5.17Z" />
            </svg>
            Power standby time:
          </th>
          <td style="display: flex;align-items: center">
            <mat-form-field style="width:300px;margin-left: auto;">
<!--              <mat-label>Overrun power</mat-label>-->
              <mat-select formControlName="overrun">
                <mat-option [value]="'10'">10 minutes</mat-option>
                <mat-option [value]="'20'">20 minutes</mat-option>
                <mat-option [value]="'30'">30 minutes</mat-option>
                <mat-option [value]="'40'">40 minutes</mat-option>
                <mat-option [value]="'50'">50 minutes</mat-option>
                <mat-option [value]="'60'">1 hour</mat-option>
                <mat-option [value]="'90'">1 hour 30 minutes</mat-option>
                <mat-option [value]="'120'">2 hours</mat-option>
                <mat-option [value]="'360'">6 hours</mat-option>
              </mat-select>
            </mat-form-field>
            <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Time device remains on after vehicle switched off">
              <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
            </svg>
          </td>
        </tr>
        <tr>
          <th>
            <mat-icon style="color: black!important;">more_time</mat-icon>
            Camera overrun time:
          </th>
          <td style="display:flex;align-items: center">
            <mat-form-field style="width:300px;margin-left: auto;">
<!--              <mat-label>Overrun recording time</mat-label>-->
              <mat-select formControlName="overrunRecording">
                <mat-option [value]="'10'">10 minutes</mat-option>
                <mat-option [value]="'20'">20 minutes</mat-option>
                <mat-option [value]="'30'">30 minutes</mat-option>
                <mat-option [value]="'40'">40 minutes</mat-option>
                <mat-option [value]="'50'">50 minutes</mat-option>
                <mat-option [value]="'60'">1 hour</mat-option>
                <mat-option [value]="'90'">1 hour 30 minutes</mat-option>
                <mat-option [value]="'120'">2 hours</mat-option>
                <mat-option [value]="'360'">6 hours</mat-option>
              </mat-select>
            </mat-form-field>
            <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Time camera continues to record after vehicle switched off">
              <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
            </svg>
          </td>
        </tr>

        <tr *ngIf="camerasFormArray.controls?.length < 1">
          <th>
            <mat-icon style="color:black;">videocam</mat-icon>
            Add cameras:
          </th>
          <td style="text-align:right;">
            <button mat-flat-button style="line-height:30px!important;" (click)="addDefaultCameras()">
              Add default cameras
            </button>
          </td>
        </tr>

        <ng-container *ngFor="let cam of camerasFormArray.controls">
          <tr>
            <th>
              <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
                <path fill="currentColor" d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
              </svg>
              {{cam.value.camPosition}} camera profile:
            </th>
            <td>
              <mat-form-field style="width:300px;margin-left: auto;">
                <input matInput readonly [value]="readableConfig(cam.value)">
                <mat-icon style="color: #606060!important;vertical-align: bottom!important;margin-left: auto;cursor:pointer;margin-bottom:-3px;"
                          matSuffix (click)="showCameraForm()">
                  settings
                </mat-icon>
              </mat-form-field>
              <svg *ngIf="cam.value.camConfig == 'bestStorage'" style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Reduced video quality for maximum storage time">
                <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
              </svg>
              <svg *ngIf="cam.value.camConfig == 'bestQuality'" style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Best video quality and shortest storage time">
                <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
              </svg>
              <svg *ngIf="cam.value.camConfig == 'balanced'" style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Balanced video quality and storage time">
                <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
              </svg>
            </td>
          </tr>
        </ng-container>
        <tr *ngIf="deviceParameters && deviceParameters.DIALUP && deviceParameters.DIALUP.apn">
          <th>
            <mat-icon style="color: black!important;">sim_card</mat-icon>
            SIM APN:
          </th>
          <td style="display:flex;align-items: center">
            <mat-form-field style="width:300px;margin-left: auto;">
              <input matInput readonly value="{{deviceParameters.DIALUP.apn}}">
            </mat-form-field>
            <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Data gateway set on the device">
              <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
            </svg>
          </td>
        </tr>
      </table>

    </mat-card-content>
  </mat-card>

</div>

  <ng-template #camerasModal>
    <div style="display: flex;">
      <h1>Camera settings</h1>&nbsp;
<!--      <span style="margin-left: auto;">-->
<!--            <button mat-raised-button (click)="addCamera()">+ Add camera</button>-->
<!--      </span> -->
      <span style="margin-left: auto;">
            <button mat-raised-button (click)="addCamera()">+ Add camera</button>
      </span>
    </div>
    <br>
    <ng-container formArrayName="cameras">
      <div *ngFor="let camerasGroup of camerasFormArray.controls; index as i">

        <div [formGroup]="camerasGroup">
          <mat-icon style="color:#7318EC!important;">videocam</mat-icon>
          &nbsp;&nbsp;
          <mat-form-field class="short-width">
            <mat-label>Channel</mat-label>
            <mat-select formControlName="channel">
              <mat-option  *ngFor="let ch of ['1','2','3','4','5','6','7','8']" [value]="ch">{{ch}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Camera position</mat-label>
            <mat-select formControlName="camPosition">
              <mat-option [value]="'Front'">Front</mat-option>
              <mat-option [value]="'Driver'">Driver</mat-option>
              <mat-option [value]="'Nearside'">Nearside</mat-option>
              <mat-option [value]="'Offside'">Offside</mat-option>
              <mat-option [value]="'Load1'">Load 1</mat-option>
              <mat-option [value]="'Load2'">Load 2</mat-option>
              <mat-option [value]="'Load3'">Load 3</mat-option>
              <mat-option [value]="'Load4'">Load 4</mat-option>
              <mat-option [value]="'RearLoad'"> Rear Load</mat-option>
              <mat-option [value]="'Rear'">Rear</mat-option>
              <mat-option [value]="'Chute'">Chute</mat-option>
              <mat-option [value]="'nsvru'">NS-VRU</mat-option>
              <mat-option [value]="'osvru'">OS-VRU</mat-option>
              <mat-option [value]="'osvru'">OS-VRU</mat-option>
              <mat-option [value]="'rvru'">R-VRU</mat-option>
              <mat-option [value]="'fvru'">F-VRU</mat-option>
              <mat-option [value]="'inCab'">In-Cab</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <mat-label>Camera config</mat-label>
            <mat-select formControlName="camConfig">
              <mat-option [value]="'bestStorage'">Best storage</mat-option>
              <mat-option [value]="'bestQuality'">Best quality</mat-option>
              <mat-option [value]="'balanced'">Balanced</mat-option>
              <mat-option [value]="'legacy'">Legacy</mat-option>
            </mat-select>
          </mat-form-field>

<!--          <mat-form-field class="example-full-width">-->
<!--            <mat-label>Camera model</mat-label>-->
<!--            <mat-select formControlName="model">-->
<!--              <mat-option [value]="'C60S0V42F-2M'">MFL FRONT + DRIVER CAM V1</mat-option>-->
<!--              <mat-option [value]="'C60S0V42-2MR2'">MFL FRONT CAM V1</mat-option>-->
<!--              <mat-option [value]="'UT3-SIDE 1080'">MFL SIDE CAM V1</mat-option>-->
<!--              <mat-option [value]="'UT3-REAR 1080'">MFL REAR CAM V1</mat-option>-->
<!--            </mat-select>-->
<!--          </mat-form-field>-->

          <mat-form-field class="example-full-width">
            <mat-label>Recording</mat-label>
            <mat-select formControlName="isRec">
              <mat-option [value]="'0'">No</mat-option>
              <mat-option [value]="'1'">Yes</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="example-full-width" *ngIf="camerasGroup.value.camPosition == 'Driver'">
            <mat-label>Audio</mat-label>
            <mat-select formControlName="HaveAudio">
              <mat-option [value]="'0'">No</mat-option>
              <mat-option [value]="'1'">Yes</mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field class="example-full-width" *ngIf="camerasGroup.value.camPosition != 'Driver'">
            <mat-label>Audio</mat-label>
            <input matInput value="No audio available" disabled>
          </mat-form-field>

          <mat-form-field class="example-full-width" *ngIf="deviceParameters && deviceParameters.RECORD && deviceParameters.RECORD.MainChn">
            <mat-label>Image flip</mat-label>
            <mat-select [(ngModel)]="deviceParameters.RECORD.MainChn['chn' + camerasGroup.value.channel].Mirror" [ngModelOptions]="{standalone: true}"
                        (selectionChange)="updateMirror(deviceParameters.RECORD.MainChn['chn' + camerasGroup.value.channel].Mirror, camerasGroup.value.channel);">
              <mat-option [value]="'0'">None</mat-option>
              <mat-option [value]="'1'">Horizontally</mat-option>
              <mat-option [value]="'2'">Vertically</mat-option>
              <mat-option [value]="'3'">All</mat-option>
            </mat-select>
          </mat-form-field>

          <input type="hidden" formControlName="HaveAudio" [ngModel]="'0'" *ngIf="camerasGroup.value.camPosition != 'Driver'">

          <button mat-icon-button (click)="removeCamera(i)" ><mat-icon style="color: #b1b3b2">delete</mat-icon></button>
        </div>

      </div>
    </ng-container>
  </ng-template>

  <ng-template #internalTestingDialog>
    <div style="display: flex;">
      <h1 style="font-size: 28px!important;">Device Testing</h1>
      <div style="flex: 1;text-align: right;">
        <button mat-raised-button type="button" (click)="markDeviceTested()" [disabled]="benchTestDisabled()">
          Install ready
        </button>
      </div>
    </div>
    <div style="display: flex;width:100%; justify-content: space-evenly;flex-wrap: wrap;font-size:16px;">
      <div style="text-align: center;padding:10px;flex:1;">
        <div [class]="{'deviceActive': deviceStatus, 'deviceWarning': !deviceStatus}">
          <svg style="width:24px;height:24px;vertical-align: middle" viewBox="0 0 24 24">
            <path fill="currentColor" d="M17,17H7V7H17M21,11V9H19V7C19,5.89 18.1,5 17,5H15V3H13V5H11V3H9V5H7C5.89,5 5,5.89 5,7V9H3V11H5V13H3V15H5V17A2,2 0 0,0 7,19H9V21H11V19H13V21H15V19H17A2,2 0 0,0 19,17V15H21V13H19V11M13,13H11V11H13M15,9H9V15H15V9Z" />
          </svg>
          <br>
          Connection<br>
          <span *ngIf="deviceStatus">{{deviceStatus.networkTypeReadable}}</span>
          <span *ngIf="!deviceStatus">Not connected</span>
        </div>
      </div>
      <div style="text-align: center;padding:10px;flex:1;">
        <div [class]="{'deviceActive': deviceStatus && (deviceStatus.satellitesQuantity > 4),'deviceWarning': !deviceStatus || (deviceStatus.satellitesQuantity < 4)}">
          <svg viewBox="0 0 24 24" style="width: 24px; height: 24px; vertical-align: middle;">
            <path fill="currentColor" d="M11.62,1L17.28,6.67L15.16,8.79L13.04,6.67L11.62,8.09L13.95,10.41L12.79,11.58L13.24,12.04C14.17,11.61 15.31,11.77 16.07,12.54L12.54,16.07C11.77,15.31 11.61,14.17 12.04,13.24L11.58,12.79L10.41,13.95L8.09,11.62L6.67,13.04L8.79,15.16L6.67,17.28L1,11.62L3.14,9.5L5.26,11.62L6.67,10.21L3.84,7.38C3.06,6.6 3.06,5.33 3.84,4.55L4.55,3.84C5.33,3.06 6.6,3.06 7.38,3.84L10.21,6.67L11.62,5.26L9.5,3.14L11.62,1M18,14A4,4 0 0,1 14,18V16A2,2 0 0,0 16,14H18M22,14A8,8 0 0,1 14,22V20A6,6 0 0,0 20,14H22Z"></path>
          </svg>
          <br>
          GPS<br>
          {{deviceStatus.satellitesQuantity}}
        </div>
      </div>
      <div style="text-align: center;padding:10px;flex:1;">
        <div [class]="{'deviceActive': vehicle && validFirmware(vehicle.appVersion), 'deviceWarning' : !vehicle || !validFirmware(vehicle.appVersion) }">
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M6,2C4.89,2 4,2.89 4,4V12C4,13.11 4.89,14 6,14H18C19.11,14 20,13.11 20,12V4C20,2.89 19.11,2 18,2H6M6,4H18V12H6V4M4,15C2.89,15 2,15.89 2,17V20C2,21.11 2.89,22 4,22H20C21.11,22 22,21.11 22,20V17C22,15.89 21.11,15 20,15H4M8,17H20V20H8V17M9,17.75V19.25H13V17.75H9M15,17.75V19.25H19V17.75H15Z" />
          </svg>
          <br>
          Firmware<br>
          <span *ngIf="vehicle.appVersion">{{vehicle.appVersion}}</span>
          <span *ngIf="!vehicle.appVersion">Unknown</span>
        </div>
      </div>
      <div style="text-align: center;padding:10px;flex:1;">
        <div [class]="{'deviceActive': deviceStatus && (deviceStatus.HardDiskSizeMb > 1000), 'deviceWarning' : !deviceStatus || (deviceStatus.HardDiskSizeMb < 1000) }">>
          <svg style="width:24px;height:24px;vertical-align: middle;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M12,3C7.58,3 4,4.79 4,7C4,9.21 7.58,11 12,11C16.42,11 20,9.21 20,7C20,4.79 16.42,3 12,3M4,9V12C4,14.21 7.58,16 12,16C16.42,16 20,14.21 20,12V9C20,11.21 16.42,13 12,13C7.58,13 4,11.21 4,9M4,14V17C4,19.21 7.58,21 12,21C16.42,21 20,19.21 20,17V14C20,16.21 16.42,18 12,18C7.58,18 4,16.21 4,14Z" />
          </svg>
          <br>
          Memory<br>
          ~ {{(deviceStatus.HardDiskSizeMb / 1000) | number:'1.0-0'}} GB
        </div>
      </div>
      <div style="text-align: center;padding:10px;flex:1;">
        <div [class]="{'deviceActive': configsMatch, 'deviceWarning': !configsMatch}">
          <svg style="width:24px;height:24px;vertical-align: middle;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M12,15.5A3.5,3.5 0 0,1 8.5,12A3.5,3.5 0 0,1 12,8.5A3.5,3.5 0 0,1 15.5,12A3.5,3.5 0 0,1 12,15.5M19.43,12.97C19.47,12.65 19.5,12.33 19.5,12C19.5,11.67 19.47,11.34 19.43,11L21.54,9.37C21.73,9.22 21.78,8.95 21.66,8.73L19.66,5.27C19.54,5.05 19.27,4.96 19.05,5.05L16.56,6.05C16.04,5.66 15.5,5.32 14.87,5.07L14.5,2.42C14.46,2.18 14.25,2 14,2H10C9.75,2 9.54,2.18 9.5,2.42L9.13,5.07C8.5,5.32 7.96,5.66 7.44,6.05L4.95,5.05C4.73,4.96 4.46,5.05 4.34,5.27L2.34,8.73C2.21,8.95 2.27,9.22 2.46,9.37L4.57,11C4.53,11.34 4.5,11.67 4.5,12C4.5,12.33 4.53,12.65 4.57,12.97L2.46,14.63C2.27,14.78 2.21,15.05 2.34,15.27L4.34,18.73C4.46,18.95 4.73,19.03 4.95,18.95L7.44,17.94C7.96,18.34 8.5,18.68 9.13,18.93L9.5,21.58C9.54,21.82 9.75,22 10,22H14C14.25,22 14.46,21.82 14.5,21.58L14.87,18.93C15.5,18.67 16.04,18.34 16.56,17.94L19.05,18.95C19.27,19.03 19.54,18.95 19.66,18.73L21.66,15.27C21.78,15.05 21.73,14.78 21.54,14.63L19.43,12.97Z" />          </svg>
          <br>
          Configs<br>
          <span *ngIf="!configsMatch">Don't match</span>
          <span *ngIf="configsMatch">Match</span>
        </div>
      </div>
    </div>
    <br>
    <div class="alert alert-warning" style="text-align: center">Data last updated at {{deviceStatus.deviceTime | date: 'd/M/yy, h:mm a'}}</div>
  </ng-template>

  <ng-template #cloneDeviceModal>
    <h1 style="font-size: 28px!important;">Cloning device</h1>
    Are you sure you want to clone this device's settings? Doing so<br>
    will create a new device with the same settings as this device and<br>
    will open in a new tab.<br><br>
    <div style="text-align: left;display: inline-block;width:50%;">
      <button mat-flat-button (click)="dialog.closeAll()">No</button>
    </div>
    <div style="text-align: right;display: inline-block;width:50%;">
      <button mat-raised-button (click)="confirmCloning()"><mat-icon style="color:white!important;">content_copy</mat-icon>&nbsp;Yes</button>
    </div>
  </ng-template>

  <ng-template #rawDataModal>
    <div *ngIf="deviceParameters">
      <div *ngFor="let p of deviceParameters  | keyvalue">
        <strong>{{p.key}}</strong><br>
        {{p.value | json}}
        <br><br>
      </div>
    </div>
  </ng-template>


  <ng-template #eventProfileModal>
      <ng-container *ngIf="usersPermission !== 'superuser'">
        <mat-form-field>
          <mat-label>Event profile</mat-label>
          <mat-select formControlName="eventProfile">
            <mat-option value="">-- Please select --</mat-option>
            <mat-option value="default">MFL-2CN-Default</mat-option>
            <mat-option  *ngIf="mflGroup == 'RAM' || mflGroup == 'undefined'" value="RAM_default">RAM-2CN-Default</mat-option>
            <mat-option value="ch5_default">MFL-5CN-Default</mat-option>
            <mat-option value="ch5_no_video">MFL-5CN-NoVideo</mat-option>
            <mat-option value="5CN-AHD-TRU-DEFAULT">5CN-AHD-TRU-DEFAULT</mat-option>
            <mat-option value="8CA-AHD-VAN-CHOWELL">8CA-AHD-VAN-CHOWELL</mat-option>
            <mat-option value="8CA-AHD-TRU-DEFAULT">8CA-AHD-TRU-DEFAULT</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="usersPermission === 'superuser'">
        <mat-form-field>
          <mat-label>Event profile</mat-label>
          <mat-select formControlName="eventProfile" >
            <mat-option value="">-- Please select --</mat-option>
            <mat-option *ngFor="let profile of eventProfiles" [value]="profile.profileName">{{profile.profileName}}</mat-option>
          </mat-select>
        </mat-form-field>
      </ng-container>

  </ng-template>

</form>
  </div>
</div>
