import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AdminService} from "../admin.service";
import {Chart} from 'chart.js';

import { isDeviceOnline, isDeviceParked, modelNumberDisplay } from 'src/lib';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatDialog} from "@angular/material/dialog";
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-devices-summary',
  templateUrl: './devices-summary.component.html',
  styleUrls: ['./devices-summary.component.css']
})
export class DevicesSummaryComponent implements OnInit {

  @ViewChild('secondDialog', { static: true }) secondDialog: TemplateRef<any>;
  @ViewChild(MatSort) sort: MatSort;
  isDeviceOnline = isDeviceOnline;
  isDeviceParked = isDeviceParked;
  modelNumberDisplay = modelNumberDisplay;

  userLevel = localStorage.getItem('authorisation');
  showCharts = false;
  pageLoading = true;
  devicesToViewRaw: any;
  devices: any;
  groups: any;
  today: any;
  yesterday: any;
  dayBeforeYesterday: any;
  tenDaysAgo: any;
  pieChart: any;
  barChart: any;
  devicesToView = "all";
  dataSource: any;
  start: number = 0;
  limit: number = 15;
  end: number = this.limit + this.start;
  selectedRowIndex: number = null;
  displayedColumns = ['dn', 'registration', 'fleet', 'group', 'device' ,'lastHeartbeat', 'mode'];
  map = ["service", "active", "Not Commissioned"];
  modeDropdown = ['setup', 'tested', 'active', 'service', 'disabled']
  serviceMode: any;
  nonCommissioned: any;
  quickNavDn: string;
  fleetsDropdown: any;
  fleetFilter: any;
  modeFilter: any;
  groupFilter: any;
  allFleets;
  newId: any;
  active: any;
  activeDevicesGroups: any = {};
  nonCommissionedDevicesGroups: any = {};
  serviceModeDevicesGroups: any = {};
  missingActiveDevicesGroups: any = {};
  rawFleets: any;
  deviceModels = environment.MODEL_NUMBER_ARRAY;


  todaysActiveDevices = [];
  yesterdaysActiveDevices = [];
  dayBeforeYesterdayActiveDevices = [];
  overTwoDaysAgoActiveDevices = [];

  todaysInstallDevices = [];
  yesterdaysInstallDevices = [];
  dayBeforeYesterdayInstallDevices = [];
  overTwoDaysAgoInstallDevices = [];

  todaysServiceDevices = [];
  yesterdaysServiceDevices = [];
  dayBeforeYesterdayServiceDevices = [];
  overTwoDaysAgoServiceDevices = [];

  missingActiveDevices = [];
  missingInstallDevices = [];
  missingServiceDevices = [];

  dnToCreate: any;



  constructor(private adminService: AdminService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.start = 0;
    this.end = this.start + this.limit;

    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);

    this.today = new Date(today).getTime()
    this.yesterday = new Date(today.setDate(today.getDate() - 1)).getTime();
    this.dayBeforeYesterday = new Date(today.setDate(today.getDate() - 1)).getTime();
    this.tenDaysAgo = new Date(today.setDate(today.getDate() - 8)).getTime();

    this.adminService.fleets({}).subscribe(result => {
      const array = [];
      result.forEach(fleet => {
        array[fleet.fleetId] = fleet;
      });

      this.rawFleets = result;
      this.allFleets = array;
      this.fleetsDropdown = result;
    });


    this.adminService.vehicles({'commissioning.commissionStatus':
        { $in: ['tested', 'service', 'active', 'disabled', 'expired', 'setup']}
    }).subscribe(result => {

      this.devices = result

      // this.dataSource = this.getTableData(this.start, this.end);

      this.updateDatasource(this.getTableData(this.start, this.end));


      this.serviceMode = [];
      this.nonCommissioned = [];
      this.missingActiveDevices = [];
      this.active = [];


      for (let i = 0; i < result?.length; i++){
        let addBar = true
        // devices connected over days data

        if (result[i]?.lastHeartbeat && new Date(result[i]?.lastHeartbeat).getTime() > this.today ){
          if (result[i]?.commissioning?.commissionStatus.toLowerCase() == 'active'){
            this.todaysActiveDevices.push(result[i]);
          } else if (result[i]?.commissioning?.commissionStatus.toLowerCase() == 'tested'){
            this.todaysInstallDevices.push(result[i]);
          } else if (result[i]?.commissioning?.commissionStatus.toLowerCase() == 'service'){
            this.todaysServiceDevices.push(result[i]);
          }
          addBar = false;
        }

        if (result[i]?.lastHeartbeat && new Date(result[i]?.lastHeartbeat).getTime() > this.yesterday && addBar){
          if (result[i]?.commissioning?.commissionStatus.toLowerCase() == 'active'){
            this.yesterdaysActiveDevices.push(result[i]);
          } else if (result[i]?.commissioning?.commissionStatus.toLowerCase() == 'tested'){
            this.yesterdaysInstallDevices.push(result[i]);
          } else if (result[i]?.commissioning?.commissionStatus.toLowerCase() == 'service'){
            this.yesterdaysServiceDevices.push(result[i]);
          }
          addBar = false;
        }

        if (result[i]?.lastHeartbeat && new Date(result[i]?.lastHeartbeat).getTime() > this.dayBeforeYesterday && addBar){

          if (result[i]?.commissioning?.commissionStatus.toLowerCase() == 'active'){
            this.dayBeforeYesterdayActiveDevices.push(result[i]);
          } else if (result[i]?.commissioning?.commissionStatus.toLowerCase() == 'tested'){
            this.dayBeforeYesterdayInstallDevices.push(result[i]);
          } else if (result[i]?.commissioning?.commissionStatus.toLowerCase() == 'service'){
            this.dayBeforeYesterdayServiceDevices.push(result[i]);
          }
          addBar = false;
        }

        if (result[i]?.lastHeartbeat && new Date(result[i]?.lastHeartbeat).getTime() < this.dayBeforeYesterday &&
          new Date(result[i]?.lastHeartbeat).getTime() > this.tenDaysAgo && addBar){

          if (result[i]?.commissioning?.commissionStatus.toLowerCase() == 'active'){
            this.overTwoDaysAgoActiveDevices.push(result[i]);
          } else if (result[i]?.commissioning?.commissionStatus.toLowerCase() == 'tested'){
            this.overTwoDaysAgoInstallDevices.push(result[i]);
          } else if (result[i]?.commissioning?.commissionStatus.toLowerCase() == 'service'){
            this.overTwoDaysAgoServiceDevices.push(result[i]);
          }
          addBar = false;
        }

        if (new Date(result[i]?.lastHeartbeat).getTime() < this.tenDaysAgo || !result[i]?.lastHeartbeat) {
          if (result[i]?.commissioning?.commissionStatus.toLowerCase() == 'active'){
            this.missingActiveDevices.push(result[i]);
          } else if (result[i]?.commissioning?.commissionStatus.toLowerCase() == 'tested'){
            this.missingInstallDevices.push(result[i]);
          } else if (result[i]?.commissioning?.commissionStatus.toLowerCase() == 'service') {
            this.missingServiceDevices.push(result[i]);
          }
          addBar = false;
        }




        // pie chart data
        if (result[i]?.commissioning?.commissionStatus.toLowerCase() === 'service') {
          this.serviceMode.push(result[i]);
          continue;
        } else if (result[i]?.commissioning?.commissionStatus.toLowerCase() !== 'active') {
          if (this.nonCommissionedDevicesGroups[result[i].mflGroup] >= 0){
            this.nonCommissionedDevicesGroups[result[i].mflGroup]++;
          } else {
            this.nonCommissionedDevicesGroups[result[i].mflGroup] = 0;
          }
          this.nonCommissioned.push(result[i]);
          continue;
        } else {
          this.active.push(result[i]);
        }




      }

      this.adminService.groups({}).subscribe(r =>{
        this.groups = r;
      });

      this.pageLoading = false;


      setTimeout(() => {
        this.createChart();
      }, 500)
    });

  }


  createChart(){
    //only way to position legend on left, traditional way kept bugging out :/
    Chart.defaults.global.legend.position = "left";

    const pieOptions = {
      responsive: true,
      maintainAspectRatio: true,
      layout: {
        padding: {
          bottom: 50,
          right: 50,
          top: 20
        }
      },

      onClick: (event, item) => {


        this.pieChart.update();

        if (this.map[this.pieChart.getElementsAtEvent(event)?.[0]?._index]){
          this.devicesToView = this.map[this.pieChart.getElementsAtEvent(event)?.[0]?._index];
        }

        this.start = 0;
        this.end = 15;
        document.getElementById('tableDiv').scroll(0,0);
        this.updateDatasource(this.getTableData(this.start, this.end));
        item[0]._model.outerRadius += 10;
        this.showCharts = false;

        // this.barChart.data.datasets[0].backgroundColor = barLabels.map(l => 'gray');
        // this.barChart.update();
        return;
      }
    };

    const pieData = {
      labels: ["Service Mode Devices", "Active Devices", "Not Commissioned Devices"],
      datasets: [
        {
          label: "Devices",
          data: [this.serviceMode?.length,this.active?.length, this.nonCommissioned?.length],
          backgroundColor: ['#F24141', '#5C8459', 'orange'],
        }
      ]
    }

    this.pieChart = new Chart("MyPieChart", {
      type: 'pie',
      data: pieData,
      options: pieOptions
    });


    const barLabels = ['Today', 'Yesterday', '2 days ago', 'Between 2-10 days ago', '10 days or more ago'];

    const barData = {
      labels: barLabels,
      datasets: [
        {
          label: "Number of Active Devices",
          data: [this.todaysActiveDevices?.length,this.yesterdaysActiveDevices?.length,this.dayBeforeYesterdayActiveDevices?.length,
            this.overTwoDaysAgoActiveDevices?.length, this.missingActiveDevices?.length],
          backgroundColor: barLabels.map(l => '#5C8459')
        },
        {
          label: "Number of Install Devices",
          data: [this.todaysInstallDevices?.length,this.yesterdaysInstallDevices?.length,this.dayBeforeYesterdayInstallDevices?.length,
            this.overTwoDaysAgoInstallDevices?.length, this.missingInstallDevices?.length],
          backgroundColor: barLabels.map(l => 'orange')
        },
        {
          label: "Number of Service Devices",
          data: [this.todaysServiceDevices?.length,this.yesterdaysServiceDevices?.length,this.dayBeforeYesterdayServiceDevices?.length,
            this.overTwoDaysAgoServiceDevices?.length, this.missingServiceDevices?.length],
          backgroundColor: barLabels.map(l => '#F24141')
        }
      ]
    }

    const barOptions = {
      legend: {
        display: false
      },
      responsive: true,
      maintainAspectRatio: true,
      layout: {
        padding: {
          bottom: 50,
          right: 50,
          top: 20
        }
      },
      scales: {
        yAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'No. of Devices'
          },
          stacked: true
        }],
        xAxes: [{
          scaleLabel: {
            display: true,
            labelString: 'Last time devices connected'
          },
          stacked: true
        }]
      },
      onClick: (event, item) => {

        var activePoint = this.barChart.getElementAtEvent(event)[0];
        var data = activePoint._chart.data;
        var datasetIndex = activePoint._datasetIndex;
        var label = data.datasets[datasetIndex].label;


        const str = barLabels[item[0]?._index] + " " + label
        const str2 = str.replace(" Number of ", "-").replace("Devices", "");
        this.devicesToView = str2
        this.start = 0;
        this.end = 15;
        document.getElementById('tableDiv').scroll(0,0);
        this.updateDatasource(this.getTableData(this.start, this.end));
        this.barChart.update();
        this.pieChart.update();
        this.showCharts = false;




        // if (barLabels[item[0]?._index]){
        //
        //   console.log(event)
        //   console.log(item)
        //   this.devicesToView = barLabels[item[0]?._index];
        //   this.barChart.data.datasets[0].backgroundColor = barLabels.map(l => 'gray');
        //   this.barChart.data.datasets[0].backgroundColor[item[0]?._index] = '#7318ec'
        //

        // }
        return;
      }

    }

    this.barChart = new Chart("MyBarChart", {
      type: 'bar',
      data: barData,
      options: barOptions
    });
  }


  showDevicesToView(): any{
    switch (this.devicesToView.trim()){
      case "all": return this.devices;
      case "service": return this.serviceMode;
      case "active": return this.active;
      case "Not Commissioned": return this.nonCommissioned;

      case "Today-Active": return this.todaysActiveDevices;
      case "Yesterday-Active": return this.yesterdaysActiveDevices;
      case "2 days ago-Active": return this.dayBeforeYesterdayActiveDevices;
      case "Between 2-10 days ago-Active": return this.overTwoDaysAgoActiveDevices;
      case "10 days or more ago-Active": return this.missingActiveDevices;

      case "Today-Install": return this.todaysInstallDevices;
      case "Yesterday-Install": return this.yesterdaysInstallDevices;
      case "2 days ago-Install": return this.dayBeforeYesterdayInstallDevices;
      case "Between 2-10 days ago-Install": return this.overTwoDaysAgoInstallDevices;
      case "10 days or more ago-Install": return this.missingInstallDevices;

      case "Today-Service": return this.todaysServiceDevices;
      case "Yesterday-Service": return this.yesterdaysServiceDevices;
      case "2 days ago-Service": return this.dayBeforeYesterdayServiceDevices;
      case "Between 2-10 days ago-Service": return this.overTwoDaysAgoServiceDevices;
      case "10 days or more ago-Service": return this.missingServiceDevices;




      default: return this.missingActiveDevices;
    }

  }

  getTableData(start, end) {
    const data = this.showDevicesToView().filter((value, index) => index >= start && index < end);
    this.devicesToViewRaw = data;
    return data;
  }

  selectedRow(row) {
    window.open('/device/' + row.dn, '_blank')
  }

  onTableScroll(e) {

    const tableViewHeight = e.target.offsetHeight // viewport
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 300;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {

      this.start = this.end;
      this.end = this.start + 15;
      let data = this.getTableData(this.start, this.end);

      if (data?.[0] && this.dataSource?.data[this.dataSource?.data.length - 1]._id !== data[0]._id){

        this.updateDatasource(this.dataSource.data?.concat(data));

      }
    }
  }

  showAllDevices(): void {
    this.devicesToView = "all"
    this.pieChart.update();
    this.barChart.update();
    this.start = 0;
    this.end = 15;
    this.updateDatasource(this.getTableData(this.start, this.end));
    this.fleetFilter = null;
    this.modeFilter = null;
    this.groupFilter = null;
  }

  formatStr(str): string {
    return str?.trim()?.toLowerCase()
  }


  applyFilter(event: Event): void {
    const filterValue = this.formatStr((event.target as HTMLInputElement).value);

    if (filterValue?.length >= 1 ){

      this.updateDatasource(this.devices.filter(ele => {
        const toSearchFor = this.formatStr(filterValue)
        return this.formatStr(ele?.registration)?.includes(toSearchFor) || this.formatStr(ele?.dn)?.includes(toSearchFor) ||
          this.formatStr(ele?.fleetId)?.includes(toSearchFor) || this.formatStr(ele?.mflGroup)?.includes(toSearchFor)
      }));


    } else if (filterValue?.length === 0){
      this.showAllDevices();
    }

  }

  toggleCharts() : void {
    this.showCharts = !this.showCharts
  }

  keyFunc(event): void {
    event.stopPropagation();
    if (event?.code === 'Enter' && this.quickNavDn?.length > 5){
      window.open('/device/' + this.quickNavDn, '_blank')
    }
  }

  filterByFleet(event): void {
    this.updateDatasource(this.devices.filter(ele => {
      return ele.fleetId === event.value;
    }));
    this.devicesToView = 'fleet'
  }

  filterByMode(event): void {
    this.updateDatasource(this.devices.filter(ele => {
      return ele.commissioning.commissionStatus === event.value;
    }))
    this.devicesToView = 'mode'
  }

  filterByGroup(event): void {
    this.updateDatasource(this.devices.filter(ele => {
      return ele.mflGroup === event.value;
    }));
    this.devicesToView = 'group'
  }

  fleetClick(event, element): void {
    event.stopPropagation();
    this.filterByFleet({value:element?.fleetId})
  }

  groupClick(event, element): void {
    event.stopPropagation();
    this.filterByGroup({value:element?.mflGroup})
  }

  updateDatasource(data): void {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
  }

  addNewVehicle() {
    this.dialog.open(this.secondDialog,  { disableClose: true, closeOnNavigation: true, hasBackdrop: true, data: {newId: this.newId} });
  }

  confirmNewVehicle() {
    this.adminService.addVehicle({dn: this.dnToCreate}).subscribe(result => {
      window.open('/device/' + this.dnToCreate, '_blank');
      this.dialog.closeAll()
    });
  }


}
