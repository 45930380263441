<table style="width:100%;">
  <tr>
    <th style="text-align:left!important;">
      <svg style="width:24px;height:24px;vertical-align: middle;" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" />
      </svg>
      Last tracking received:
    </th>
    <td style="text-align:right">
      <span *ngIf="isADate(deviceDetails?.lastHeartbeat)">
        {{deviceDetails?.lastHeartbeat | date: 'dd/MM/yy, HH:mm'}}
      </span>
      <span *ngIf="!isADate(deviceDetails?.lastHeartbeat)">
        Some time ago
      </span>
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Last GPS position received from device">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </td>
  </tr>
  <tr>
    <th style="text-align:left!important">
      <svg style="width:24px;height:24px;vertical-align: middle;" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12,16A3,3 0 0,1 9,13C9,11.88 9.61,10.9 10.5,10.39L20.21,4.77L14.68,14.35C14.18,15.33 13.17,16 12,16M12,3C13.81,3 15.5,3.5 16.97,4.32L14.87,5.53C14,5.19 13,5 12,5A8,8 0 0,0 4,13C4,15.21 4.89,17.21 6.34,18.65H6.35C6.74,19.04 6.74,19.67 6.35,20.06C5.96,20.45 5.32,20.45 4.93,20.07V20.07C3.12,18.26 2,15.76 2,13A10,10 0 0,1 12,3M22,13C22,15.76 20.88,18.26 19.07,20.07V20.07C18.68,20.45 18.05,20.45 17.66,20.06C17.27,19.67 17.27,19.04 17.66,18.65V18.65C19.11,17.2 20,15.21 20,13C20,12 19.81,11 19.46,10.1L20.67,8C21.5,9.5 22,11.18 22,13Z" />
      </svg>
      Vehicle speed:
    </th>
    <td style="text-align:right">
      {{((deviceDetails?.lastLocation?.speed / 100) * 0.621371) |  number : '1.0-0' }} Mph
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Vehicle Speed At Last Track ">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </td>
    <td *ngIf="!deviceDetails">
      0
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Vehicle Speed At Last Track">
        <pat  h fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </td>

  </tr>
</table>
<div id="deviceStatusMapElement" style="min-width:350px;min-height:350px;width:100%;background-color:#e9e9e9;border-radius: 5px;position: relative">
  <h1 class="mapLoadingText" *ngIf="deviceDetails && this.deviceDetails?.lastLocation?.lng && this.deviceDetails?.lastLocation?.lat">
    Map loading...
    <mat-spinner></mat-spinner>
  </h1>
  <h1 class="mapLoadingText" *ngIf="!deviceConnected && !deviceDetails">Device offline</h1>
</div>
<small *ngIf="this.deviceDetails?.lastLocation?.lat && this.deviceDetails?.lastLocation?.lng">
  Latitude: {{this.deviceDetails?.lastLocation?.lat | number: '1.2-2' }},
   Longitude: {{this.deviceDetails?.lastLocation?.lng | number: '1.2-2' }}
</small>
