<div class="loadingOverlay" *ngIf="deviceStatusLoading || pageLoading">
  <div class="loader"></div>
</div>

<h1 style="font-size: 28px!important;">
  Diagnostics
</h1>
<div style="display: inline-block;">
  <p>Device: <b>{{dn}}</b></p>
  <p style="margin-top:-15px;">
    Vehicle:
    <span *ngIf="deviceParameters">{{deviceParameters.JTBASE.license}}</span>
  </p>
  <p style="margin-top:-15px;" *ngIf="theVehicleSettings">
    Model:
    <span *ngIf="theVehicleSettings.device === 'ME41-02'">
      MFL HUB-2CN4H01
    </span>
  </p>

</div>
<img *ngIf="theVehicleSettings && theVehicleSettings.device === 'ME41-02'" src="././assets/deviceImages/MFL_HUB-2CN4H01.jpg" style="height: 50px;margin-left:5px;">
<p>
  Status:&nbsp;
  <span *ngIf="deviceConnected && deviceStatus && deviceStatus.identifierBit1Array[0]=='1'" style="color:#5C8459;font-weight:bold;">Online</span>
  <span *ngIf="deviceStatus && deviceStatus.identifierBit1Array[0]=='0' && deviceConnected" style="color:#FFBF00;font-weight:bold;">Standby</span>
  <span *ngIf="!deviceConnected" style="color:#F24141;font-weight:bold;">Offline</span>
  &nbsp;| Mode:&nbsp;
  <span *ngIf="commissioning && commissioning.commissionStatus == 'tested'">Install</span>
  <span *ngIf="commissioning && commissioning.commissionStatus != 'tested'">{{commissioning.commissionStatus | titlecase}}</span>
  <span *ngIf="videoBudget && videoBudget.totalFleetBudget > 0">
    &nbsp;| Budget: {{(((videoBudget.totalFleetBudget - videoBudget.usedSeconds) / videoBudget.totalFleetBudget) *100) | number: '1.0-0'}}%
  </span>
</p>


<mat-tab-group style="margin-top: -10px;" *ngIf="deviceStatus">
  <!----STATUS TAB--->
  <mat-tab label="Status">
    <br>
    <div class="invertBackground flex-container">
      <mat-card style="margin-right:10px;flex: 1;">
        <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;">
          <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">
            <g><rect fill="none" height="24" width="24"/></g><g><g><path d="M15.11,12.45L14,10.24l-3.11,6.21C10.73,16.79,10.38,17,10,17s-0.73-0.21-0.89-0.55L7.38,13H2v5c0,1.1,0.9,2,2,2h16 c1.1,0,2-0.9,2-2v-5h-6C15.62,13,15.27,12.79,15.11,12.45z"/><path d="M20,4H4C2.9,4,2,4.9,2,6v5h6c0.38,0,0.73,0.21,0.89,0.55L10,13.76l3.11-6.21c0.34-0.68,1.45-0.68,1.79,0L16.62,11H22V6 C22,4.9,21.1,4,20,4z"/></g></g>
          </svg>
          <div matTooltip="Updated {{deviceStatus.timeSinceUpdateDetail}} ago">
            &nbsp;Status
            <small *ngIf="deviceStatus.timeSinceUpdateShort">
              (Updated {{deviceStatus.timeSinceUpdateShort}} ago)
            </small>
          </div>
          <span matTooltip="Requests an update from the device" style="margin-left:auto;">
            <button mat-flat-button type="button" style="font-size:10px;color:#606060!important;" (click)="getDeviceStatus(dn); triggerUnderSpeedAlarm()" [disabled]="!deviceConnected">
              <mat-icon *ngIf="deviceConnected">refresh</mat-icon>
            </button>
          </span>
        </mat-card-title>
        <mat-card-content>
          <table>
            <tr>
              <th></th>
              <th style="text-align: center">Health</th>
              <th style="text-align: right">Live Status</th>
            </tr>
            <tr>
              <th style="text-align: left">
                <svg style="width:24px;height:24px;vertical-align: middle" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M17,17H7V7H17M21,11V9H19V7C19,5.89 18.1,5 17,5H15V3H13V5H11V3H9V5H7C5.89,5 5,5.89 5,7V9H3V11H5V13H3V15H5V17A2,2 0 0,0 7,19H9V21H11V19H13V21H15V19H17A2,2 0 0,0 19,17V15H21V13H19V11M13,13H11V11H13M15,9H9V15H15V9Z" />
                </svg>
                Device connection:
              </th><!---online/offline/standby (standby if diagnostics on and ignition off)--->
              <td style="text-align: right;">
                <div *ngIf="deviceHealth.connection == 'green'" class="onlineCircle"></div>
                <div *ngIf="deviceHealth.connection == 'yellow'" class="yellowCircle"></div>
                <div *ngIf="deviceHealth.connection == 'red'" class="offlineCircle"></div>

                <div *ngIf="deviceHealth.connection == ''" class="greyCircle"></div>
              </td>
              <td style="text-align: right;padding:5px;">
                <div *ngIf="deviceConnected && deviceStatus.identifierBit1Array[0]=='1'">
                  Online
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device online and connected">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="!deviceConnected || deviceConnected == null || deviceConnected == undefined || deviceConnected == 'undefined'">
                  Device Offline
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device offline">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="deviceStatus.identifierBit1Array[0]=='0' && deviceConnected">
                  Standby
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device in standby">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <svg style="width:24px;height:24px;vertical-align: middle;" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,3C7.58,3 4,4.79 4,7C4,9.21 7.58,11 12,11C16.42,11 20,9.21 20,7C20,4.79 16.42,3 12,3M4,9V12C4,14.21 7.58,16 12,16C16.42,16 20,14.21 20,12V9C20,11.21 16.42,13 12,13C7.58,13 4,11.21 4,9M4,14V17C4,19.21 7.58,21 12,21C16.42,21 20,19.21 20,17V14C20,16.21 16.42,18 12,18C7.58,18 4,16.21 4,14Z" />
                </svg>
                Memory status:
              </th>
              <td style="text-align: center">
                <div class="onlineCircle" *ngIf="deviceHealth.storage == 'green'"></div>
                <div class="yellowCircle" *ngIf="deviceHealth.storage == 'yellow'"></div>
                <div class="offlineCircle" *ngIf="deviceHealth.storage == 'red'"></div>

                <div *ngIf="deviceHealth.storage == ''" class="greyCircle"></div>

              </td>
              <td style="text-align: right;padding: 5px;">
                <div *ngIf="deviceConnected && deviceStatus && (deviceStatus.HardDiskSizeMb / 1000) >= 75">
                  Good
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Extended memory">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="deviceConnected && deviceStatus && (deviceStatus.HardDiskSizeMb / 1000) > 35 && (deviceStatus.HardDiskSizeMb / 1000) < 75">
                  Average
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Standard memory">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="deviceConnected && deviceStatus && (deviceStatus.HardDiskSizeMb / 1000) < 35">Weak</div>
                <div *ngIf="!deviceConnected">
                  Device Offline
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device offline">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M11.62,1L17.28,6.67L15.16,8.79L13.04,6.67L11.62,8.09L13.95,10.41L12.79,11.58L13.24,12.04C14.17,11.61 15.31,11.77 16.07,12.54L12.54,16.07C11.77,15.31 11.61,14.17 12.04,13.24L11.58,12.79L10.41,13.95L8.09,11.62L6.67,13.04L8.79,15.16L6.67,17.28L1,11.62L3.14,9.5L5.26,11.62L6.67,10.21L3.84,7.38C3.06,6.6 3.06,5.33 3.84,4.55L4.55,3.84C5.33,3.06 6.6,3.06 7.38,3.84L10.21,6.67L11.62,5.26L9.5,3.14L11.62,1M18,14A4,4 0 0,1 14,18V16A2,2 0 0,0 16,14H18M22,14A8,8 0 0,1 14,22V20A6,6 0 0,0 20,14H22Z" />
                </svg>
                GPS signal strength:
              </th><!---satellites connected to NOT ACCURACY--->
              <td>
                <div class="onlineCircle" *ngIf="deviceHealth.gps == 'green'"></div>
                <div class="yellowCircle" *ngIf="deviceHealth.gps == 'yellow'"></div>
                <div class="offlineCircle" *ngIf="deviceHealth.gps == 'red'"></div>

                <div *ngIf="deviceHealth.gps == ''" class="greyCircle"></div>

              </td>
              <td style="text-align: right;padding: 5px;">
                <div *ngIf="deviceStatus.satellitesQuantity > 5 && deviceConnected">
                  Good
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Good GPS signal">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="deviceStatus.satellitesQuantity < 3 && deviceConnected">
                  Poor
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Poor GPS signal">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="deviceStatus.satellitesQuantity == 3 || deviceStatus.satellitesQuantity == 4 && deviceConnected">
                  Average
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Average GPS signal">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="!deviceConnected">
                  Device Offline
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device offline">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <svg style="width:24px;height:24px;vertical-align: middle;" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M21,4H16V20H21V4M14,9H9V20H14V9M7,14H2V20H7V14Z" />
                </svg>
                4G signal strength:
              </th><!---network tpye not signal strength--->
              <td>
                <div class="onlineCircle" *ngIf="deviceHealth.cellular == 'green'"></div>
                <div class="yellowCircle" *ngIf="deviceHealth.cellular == 'yellow'"></div>
                <div class="offlineCircle" *ngIf="deviceHealth.cellular == 'red'"></div>

                <div *ngIf="deviceHealth.cellular == ''" class="greyCircle"></div>

              </td>
              <td style="text-align: right;padding: 5px;">
                <span *ngIf="deviceConnected && deviceStatus.signalTrafficLight == 'weak'">
                  Poor
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Media will not upload">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </span>
                <span *ngIf="deviceConnected && deviceStatus.signalTrafficLight == 'average'">
                  Average
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Slow media uploads">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </span>
                <span *ngIf="deviceConnected && deviceStatus.signalTrafficLight == 'good'">
                  Good
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Fast media uploads">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </span>
                <span *ngIf="!deviceConnected || !deviceStatus.signalTrafficLight">
                  Device Offline
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device offline">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </span>
              </td>
            </tr>
            <tr>
              <th>
                <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M13,19.92C14.8,19.7 16.35,18.95 17.65,17.65C18.95,16.35 19.7,14.8 19.92,13H16.92C16.7,14 16.24,14.84 15.54,15.54C14.84,16.24 14,16.7 13,16.92V19.92M10,8H14L17,11H19.92C19.67,9.05 18.79,7.38 17.27,6C15.76,4.66 14,4 12,4C10,4 8.24,4.66 6.73,6C5.21,7.38 4.33,9.05 4.08,11H7L10,8M11,19.92V16.92C10,16.7 9.16,16.24 8.46,15.54C7.76,14.84 7.3,14 7.08,13H4.08C4.3,14.77 5.05,16.3 6.35,17.6C7.65,18.9 9.2,19.67 11,19.92M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12C22,14.75 21,17.1 19.05,19.05C17.1,21 14.75,22 12,22C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z" />
                </svg>
                G-force sensor:
              </th>
              <td>
                <div *ngIf="deviceHealth.gforce == 'red'" class="offlineCircle"></div>
                <div *ngIf="deviceHealth.gforce == 'green'" class="onlineCircle"></div>

                <div *ngIf="deviceHealth.gforce == ''" class="greyCircle"></div>

              </td>
              <td style="text-align: right;padding: 5px;">
                <div *ngIf="deviceHealth.gforce == 'red'">
                  Needs calibration
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="G-force sensor needs calibrating">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="deviceHealth.gforce == 'green'">
                  Calibrated
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="G-force sensor is calibrated">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="deviceHealth.gforce == ''">
                  Unknown
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="No device G-force sensor data">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
                </svg>
                Front camera status:
              </th>
              <td>
                <div *ngIf="cameraProfiles.front && deviceConnected && deviceStatus.identifierBit1Array[0]=='1'">
                  <!--                    online-->
                  <!--- NOT RECORDING = RED --->
                  <div *ngIf="deviceStatus.recordingStatusArray[cameraProfiles.front.ch - 1] === '0'" class="offlineCircle"></div>
                  <!--- RECORDING--->
                  <div *ngIf="deviceStatus.recordingStatusArray[cameraProfiles.front.ch - 1] === '1'" class="onlineCircle"></div>
                </div>
                <div *ngIf="cameraProfiles.front && deviceStatus.identifierBit1Array[0]=='0' && deviceConnected" class="onlineCircle"></div>
                <div *ngIf="cameraProfiles.front && !deviceConnected" class="onlineCircle"></div>
              </td>
              <td style="text-align: right;padding: 5px;">
                <div *ngIf="cameraProfiles.front && deviceStatus.recordingStatusArray[cameraProfiles.front.ch - 1] !== '0' && deviceConnected">
                  Recording
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Camera is recording">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="cameraProfiles.front && (deviceStatus.recordingStatusArray[cameraProfiles.front.ch - 1] === '0' || !deviceConnected)">
                  Not recording
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Camera is ready to record">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="!cameraProfiles.front">
                  N/A
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Driver camera is not installed">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
              </td>
            </tr>
            <tr *ngIf="cameraProfiles.driver || cameraProfiles.rear">
              <th>
                <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
                </svg>
                <span *ngIf="(cameraProfiles.driver && !cameraProfiles.rear) || (cameraProfiles.driver && cameraProfiles.rear)">Driver camera status:</span>
                <span *ngIf="cameraProfiles.rear && !cameraProfiles.driver">Rear camera status:</span>
              </th>
              <td *ngIf="(cameraProfiles.driver && !cameraProfiles.rear) || (cameraProfiles.driver && cameraProfiles.rear)">
                <div *ngIf="cameraProfiles.driver && deviceConnected && deviceStatus.identifierBit1Array[0]=='1'">
                  <!--                    online-->
                  <!--- NOT RECORDING = RED --->
                  <div *ngIf="deviceStatus.recordingStatusArray[cameraProfiles.driver.ch - 1] === '0'" class="offlineCircle"></div>
                  <!--- RECORDING--->
                  <div *ngIf="deviceStatus.recordingStatusArray[cameraProfiles.driver.ch - 1] === '1'" class="onlineCircle"></div>
                </div>
                <div *ngIf="cameraProfiles.driver && deviceStatus.identifierBit1Array[0]=='0' && deviceConnected" class="onlineCircle"></div>
                <div *ngIf="cameraProfiles.driver && !deviceConnected" class="onlineCircle"></div>
              </td>
              <td *ngIf="(cameraProfiles.driver && !cameraProfiles.rear) || (cameraProfiles.driver && cameraProfiles.rear)" style="text-align: right;padding: 5px;">
                <div *ngIf="cameraProfiles.driver && deviceStatus.recordingStatusArray[cameraProfiles.driver.ch - 1] !== '0' && deviceConnected">
                  Recording
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Camera is recording">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="cameraProfiles.driver && (deviceStatus.recordingStatusArray[cameraProfiles.driver.ch - 1] === '0' || !deviceConnected)">
                  Not recording
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Camera is ready to record">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
              </td>
              <td *ngIf="cameraProfiles.rear && !cameraProfiles.driver">
                <div *ngIf="cameraProfiles.rear && deviceConnected && deviceStatus.identifierBit1Array[0]=='1'">
                  <!--                    online-->
                  <!--- NOT RECORDING = RED --->
                  <div *ngIf="deviceStatus.recordingStatusArray[cameraProfiles.rear.ch - 1] === '0'" class="offlineCircle"></div>
                  <!--- RECORDING--->
                  <div *ngIf="deviceStatus.recordingStatusArray[cameraProfiles.rear.ch - 1] === '1'" class="onlineCircle"></div>
                </div>
                <div *ngIf="cameraProfiles.rear && deviceStatus.identifierBit1Array[0]=='0' && deviceConnected" class="onlineCircle"></div>
                <div *ngIf="cameraProfiles.rear && !deviceConnected" class="onlineCircle"></div>
              </td>
              <td *ngIf="cameraProfiles.rear && !cameraProfiles.driver" style="text-align: right;padding: 5px;">
                <div *ngIf="cameraProfiles.rear && deviceStatus.recordingStatusArray[cameraProfiles.rear.ch - 1] !== '0' && deviceConnected">
                  Recording
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Camera is recording">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="cameraProfiles.rear && (deviceStatus.recordingStatusArray[cameraProfiles.rear.ch - 1] === '0' || !deviceConnected)">
                  Not recording
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Camera is ready to record">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
              </td>
            </tr>
<!--            <tr *ngIf="calendar">-->
<!--              <th>-->
<!--                <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">-->
<!--                  <path fill="currentColor" d="M7,12H9V14H7V12M21,6V20A2,2 0 0,1 19,22H5C3.89,22 3,21.1 3,20V6A2,2 0 0,1 5,4H6V2H8V4H16V2H18V4H19A2,2 0 0,1 21,6M5,8H19V6H5V8M19,20V10H5V20H19M15,14V12H17V14H15M11,14V12H13V14H11M7,16H9V18H7V16M15,18V16H17V18H15M11,18V16H13V18H11Z" />-->
<!--                </svg>-->
<!--                Hours of video stored:-->
<!--              </th>-->
<!--              <td>-->
<!--                <div class="offlineCircle" *ngIf="calendar.hours < 10"></div>-->
<!--                <div class="yellowCircle" *ngIf="calendar.hours < 20 && calendar.hours > 10"></div>-->
<!--                <div class="onlineCircle" *ngIf="calendar.hours >= 20"></div>-->
<!--              </td>-->
<!--              <td style="text-align: right;padding: 5px;">-->
<!--                {{calendar.hours}}-->
<!--                <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Total video stored on the device-setup">-->
<!--                  <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />-->
<!--                </svg>-->
<!--              </td>-->
<!--            </tr>-->
          </table>
        </mat-card-content>
      </mat-card>
      <mat-card style="margin-right:10px;flex: 1;">
        <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;">
          <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M20.2,5.9L21,5.1C19.6,3.7 17.8,3 16,3C14.2,3 12.4,3.7 11,5.1L11.8,5.9C13,4.8 14.5,4.2 16,4.2C17.5,4.2 19,4.8 20.2,5.9M19.3,6.7C18.4,5.8 17.2,5.3 16,5.3C14.8,5.3 13.6,5.8 12.7,6.7L13.5,7.5C14.2,6.8 15.1,6.5 16,6.5C16.9,6.5 17.8,6.8 18.5,7.5L19.3,6.7M19,13H17V9H15V13H5A2,2 0 0,0 3,15V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V15A2,2 0 0,0 19,13M8,18H6V16H8V18M11.5,18H9.5V16H11.5V18M15,18H13V16H15V18Z" />
          </svg>
          &nbsp;Device settings
          <span matTooltip="Edit device settings" style="margin-left:auto;">
                <a *ngIf="theVehicleSettings" href="/device/{{theVehicleSettings._id}}" mat-flat-button target="_blank">
                  <mat-icon style="color:#606060!important;">settings</mat-icon>
                </a>
                <a *ngIf="!theVehicleSettings" disabled mat-flat-button target="_blank">Settings</a>
              </span>
        </mat-card-title>
        <mat-card-content>
          <table>
            <tr>
              <th></th>
              <th style="text-align: right;">Setting</th>
            </tr>
            <tr *ngIf="vehicle.config.POWER">
              <th>
                <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M13,3H11V13H13V3M17.83,5.17L16.41,6.59C18.05,7.91 19,9.9 19,12A7,7 0 0,1 12,19C8.14,19 5,15.88 5,12C5,9.91 5.95,7.91 7.58,6.58L6.17,5.17C2.38,8.39 1.92,14.07 5.14,17.86C8.36,21.64 14.04,22.1 17.83,18.88C19.85,17.17 21,14.65 21,12C21,9.37 19.84,6.87 17.83,5.17Z" />
                </svg>
                Power standby time:
              </th>
              <td>
                {{vehicle.config.POWER.delay}} minutes
                <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Time device remains on after vehicle switched off">
                  <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                </svg>
              </td>
            </tr>
            <tr *ngIf="vehicle.config.POWER">
              <th>
                <mat-icon>more_time</mat-icon>
                Front camera overrun time:
              </th>
              <td>
                {{vehicle.config.POWER.AccOffRecTime}} minutes
                <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Time camera continues to record after vehicle switched off">
                  <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                </svg>
              </td>
            </tr>
            <tr *ngIf="cameraProfiles.front">
              <th>
                <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
                </svg>
                Front camera setting:
              </th>
              <td>
                <span *ngIf="cameraProfiles.front.config">
                  <span *ngIf="cameraProfiles.front.config == 'bestStorage'">
                    Best storage
                    <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Reduced video quality for maximum storage time">
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </span>
                  <span *ngIf="cameraProfiles.front.config == 'balanced'">
                    Balanced
                    <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Balanced video quality and storage time">
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </span>
                  <span *ngIf="cameraProfiles.front.config == 'bestQuality'">
                    Best quality
                    <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Best video quality and shortest storage time">
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </span>
                </span>
                <span *ngIf="!cameraProfiles.front.config">N/A</span>
              </td>
            </tr>
            <tr *ngIf="cameraProfiles.driver || cameraProfiles.rear">
              <th>
                <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
                </svg>
                <span *ngIf="(cameraProfiles.driver && !cameraProfiles.rear) || (cameraProfiles.driver && cameraProfiles.rear) ">Driver camera setting:</span>
                <span *ngIf="cameraProfiles.rear && !cameraProfiles.driver">Rear camera setting:</span>
              </th>
              <td *ngIf="(cameraProfiles.driver && !cameraProfiles.rear) || (cameraProfiles.driver && cameraProfiles.rear)">
                    <span *ngIf="cameraProfiles.driver.config">
                      <span *ngIf="cameraProfiles.driver.config == 'bestStorage'">
                        Best storage
                        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Reduced video quality for maximum storage time">
                          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                        </svg>
                      </span>
                      <span *ngIf="cameraProfiles.driver.config == 'balanced'">
                        Balanced
                        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Balanced video quality and storage time">
                          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                        </svg>
                      </span>
                      <span *ngIf="cameraProfiles.driver.config == 'bestQuality'">
                        Best quality
                        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Best video quality and shortest storage time">
                          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                        </svg>
                      </span>
                    </span>
                <span *ngIf="!cameraProfiles.driver.config">N/A</span>
              </td>

              <td *ngIf="cameraProfiles.rear && !cameraProfiles.driver">
                    <span *ngIf="cameraProfiles.rear.config">
                      <span *ngIf="cameraProfiles.rear.config == 'bestStorage'">
                        Best storage
                        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Reduced video quality for maximum storage time">
                          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                        </svg>
                      </span>
                      <span *ngIf="cameraProfiles.rear.config == 'balanced'">
                        Balanced
                        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Balanced video quality and storage time">
                          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                        </svg>
                      </span>
                      <span *ngIf="cameraProfiles.rear.config == 'bestQuality'">
                        Best quality
                        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Best video quality and shortest storage time">
                          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                        </svg>
                      </span>
                    </span>
                <span *ngIf="!cameraProfiles.rear.config">N/A</span>
              </td>
            </tr>
            <tr *ngIf="cameraProfiles.driver && !cameraProfiles.rear">
              <th>
                <mat-icon style="color: black!important;">mic</mat-icon>
                Driver camera audio:
              </th>
              <td>
                <span *ngIf="cameraProfiles.driver.HaveAudio == '0' && cameraProfiles.driver">
                  Disabled
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Driver camera will not record sound">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </span>
                <span *ngIf="cameraProfiles.driver.HaveAudio == '1' && cameraProfiles.driver">
                  Enabled
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Driver camera will record sound">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </span>
                <span *ngIf="cameraProfiles.driver.HaveAudio != '0' && cameraProfiles.driver.HaveAudio != '1' && cameraProfiles.driver">
                  Not set
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Driver camera audio not set up">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </span>
              </td>
            </tr>
            <!--              <tr>-->
            <!--                <th>-->
            <!--                  <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">-->
            <!--                    <path fill="currentColor" d="M13,19.92C14.8,19.7 16.35,18.95 17.65,17.65C18.95,16.35 19.7,14.8 19.92,13H16.92C16.7,14 16.24,14.84 15.54,15.54C14.84,16.24 14,16.7 13,16.92V19.92M10,8H14L17,11H19.92C19.67,9.05 18.79,7.38 17.27,6C15.76,4.66 14,4 12,4C10,4 8.24,4.66 6.73,6C5.21,7.38 4.33,9.05 4.08,11H7L10,8M11,19.92V16.92C10,16.7 9.16,16.24 8.46,15.54C7.76,14.84 7.3,14 7.08,13H4.08C4.3,14.77 5.05,16.3 6.35,17.6C7.65,18.9 9.2,19.67 11,19.92M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12C22,14.75 21,17.1 19.05,19.05C17.1,21 14.75,22 12,22C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z" />-->
            <!--                  </svg>-->
            <!--                  Monitoring Profile:-->
            <!--                </th>-->
            <!--                <td>-->
            <!--                  <span *ngIf="device-setup.eventProfile">{{device-setup.eventProfile}}</span>-->
            <!--                  <span *ngIf="!device-setup.eventProfile">N/A</span>-->
            <!--                </td>-->
            <!--              </tr>-->
            <!--                <tr>-->
            <!--                  <th>-->
            <!--                    <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">-->
            <!--                      <path fill="currentColor" d="M13,19.92C14.8,19.7 16.35,18.95 17.65,17.65C18.95,16.35 19.7,14.8 19.92,13H16.92C16.7,14 16.24,14.84 15.54,15.54C14.84,16.24 14,16.7 13,16.92V19.92M10,8H14L17,11H19.92C19.67,9.05 18.79,7.38 17.27,6C15.76,4.66 14,4 12,4C10,4 8.24,4.66 6.73,6C5.21,7.38 4.33,9.05 4.08,11H7L10,8M11,19.92V16.92C10,16.7 9.16,16.24 8.46,15.54C7.76,14.84 7.3,14 7.08,13H4.08C4.3,14.77 5.05,16.3 6.35,17.6C7.65,18.9 9.2,19.67 11,19.92M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12C22,14.75 21,17.1 19.05,19.05C17.1,21 14.75,22 12,22C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z" />-->
            <!--                    </svg>-->
            <!--                    Calibrate Driving Sensor:-->
            <!--                  </th>-->
            <!--                  <td>-->
            <!--                    <span matTooltip="Calibrate the diagnostics's G-Sensor if the device-setup's ignition is off and the device-setup is stationary">-->
            <!--                    <button mat-flat-button (click)="calibrateGSensor()" [disabled]="!deviceConnected || (deviceStatus.identifierBit1Array[0] =='1' && deviceStatus.speed > 0) "-->
            <!--                            style="width:125px;padding:0px!important;line-height: 30px!important;">-->
            <!--                      Calibrate-->
            <!--                    </button>-->
            <!--                  </span>-->
            <!--                  </td>-->
            <!--                </tr>-->
            <!--                      <tr>-->
            <!--                        <th>-->
            <!--                          <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">-->
            <!--                            <path fill="currentColor" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" />-->
            <!--                          </svg>-->
            <!--                          Wipe Video Storage:-->
            <!--                        </th>-->
            <!--                        <td>value</td>-->
            <!--                      </tr>-->

<!--            <tr>-->
<!--              <th>-->
<!--                <mat-icon>sim_card</mat-icon>-->
<!--                4G SIM APN setting:-->
<!--              </th>-->
<!--              <td>-->
<!--                {{deviceParameters.DIALUP.apn}}-->
<!--                <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Data gateway set on the device-setup">-->
<!--                  <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />-->
<!--                </svg>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <th>-->
<!--                <mat-icon>device-settings</mat-icon>-->
<!--                Sync device-setup device-settings:-->
<!--              </th>-->
<!--              <td>-->
<!--                <button mat-flat-button (click)="getAllParameters(dn)" [disabled]="!deviceConnected || configsMatch"-->
<!--                        style="width:125px;padding:0px!important;line-height: 30px!important;">-->
<!--                  Sync-->
<!--                </button>-->
<!--                <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-left: 5px;" viewBox="0 0 24 24" matTooltip="Force device-setup device-settings to sync now">-->
<!--                  <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />-->
<!--                </svg>-->

<!--              </td>-->
<!--            </tr>-->
            <tr>
              <th>
                <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M22.7,19L13.6,9.9C14.5,7.6 14,4.9 12.1,3C10.1,1 7.1,0.6 4.7,1.7L9,6L6,9L1.6,4.7C0.4,7.1 0.9,10.1 2.9,12.1C4.8,14 7.5,14.5 9.8,13.6L18.9,22.7C19.3,23.1 19.9,23.1 20.3,22.7L22.6,20.4C23.1,20 23.1,19.3 22.7,19Z" />
                </svg>
                Device commissioning:
              </th>
              <td>
                <button mat-flat-button (click)="openCommissioningForm()" style="width:125px;padding:0px!important;line-height: 30px!important;">
                  Start now
                </button>
                <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-left: 5px;" viewBox="0 0 24 24" matTooltip="Commission device now">
                  <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                </svg>
              </td>
            </tr>
          </table>
        </mat-card-content>
      </mat-card>



      <mat-card style="margin-right:10px;flex: 1;">
        <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;">
          <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M19.07,4.93L17.66,6.34C19.1,7.79 20,9.79 20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12C4,7.92 7.05,4.56 11,4.07V6.09C8.16,6.57 6,9.03 6,12A6,6 0 0,0 12,18A6,6 0 0,0 18,12C18,10.34 17.33,8.84 16.24,7.76L14.83,9.17C15.55,9.9 16,10.9 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12C8,10.14 9.28,8.59 11,8.14V10.28C10.4,10.63 10,11.26 10,12A2,2 0 0,0 12,14A2,2 0 0,0 14,12C14,11.26 13.6,10.62 13,10.28V2H12A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,9.24 20.88,6.74 19.07,4.93Z" />                </svg>
          &nbsp;Tracking
        </mat-card-title>
        <mat-card-content style="text-align: center;">
          <table>
            <tr>
              <th>
                <svg style="width:24px;height:24px;vertical-align: middle;" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,8A4,4 0 0,1 16,12A4,4 0 0,1 12,16A4,4 0 0,1 8,12A4,4 0 0,1 12,8M3.05,13H1V11H3.05C3.5,6.83 6.83,3.5 11,3.05V1H13V3.05C17.17,3.5 20.5,6.83 20.95,11H23V13H20.95C20.5,17.17 17.17,20.5 13,20.95V23H11V20.95C6.83,20.5 3.5,17.17 3.05,13M12,5A7,7 0 0,0 5,12A7,7 0 0,0 12,19A7,7 0 0,0 19,12A7,7 0 0,0 12,5Z" />
                </svg>
                Last tracking received:
              </th>
              <td>
                <span *ngIf="isADate(deviceStatus.deviceTime)">
                  {{deviceStatus.deviceTime | date: 'dd/MM/yy, HH:mm'}}
                </span>
                <span *ngIf="!isADate(deviceStatus.deviceTime)">
                  Some time ago
                </span>


                <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Last GPS position received from device">
                  <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                </svg>
              </td>
            </tr>
            <tr>
              <th>
                <svg style="width:24px;height:24px;vertical-align: middle;" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,16A3,3 0 0,1 9,13C9,11.88 9.61,10.9 10.5,10.39L20.21,4.77L14.68,14.35C14.18,15.33 13.17,16 12,16M12,3C13.81,3 15.5,3.5 16.97,4.32L14.87,5.53C14,5.19 13,5 12,5A8,8 0 0,0 4,13C4,15.21 4.89,17.21 6.34,18.65H6.35C6.74,19.04 6.74,19.67 6.35,20.06C5.96,20.45 5.32,20.45 4.93,20.07V20.07C3.12,18.26 2,15.76 2,13A10,10 0 0,1 12,3M22,13C22,15.76 20.88,18.26 19.07,20.07V20.07C18.68,20.45 18.05,20.45 17.66,20.06C17.27,19.67 17.27,19.04 17.66,18.65V18.65C19.11,17.2 20,15.21 20,13C20,12 19.81,11 19.46,10.1L20.67,8C21.5,9.5 22,11.18 22,13Z" />
                </svg>
                Vehicle speed:
              </th>
              <td *ngIf="deviceStatus">
                {{((deviceStatus.speed / 100) * 0.621371) |  number : '1.0-0' }} Mph
                <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Vehicle Speed At Last Track">
                  <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                </svg>
              </td>
              <td *ngIf="!deviceStatus">
                0
                <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Vehicle Speed At Last Track">
                  <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                </svg>
              </td>

            </tr>
          </table>
          <div id="deviceStatusMapElement" style="min-width:350px;min-height:350px;width:100%;background-color:#e9e9e9;border-radius: 5px;position: relative">
            <h1 class="mapLoadingText" *ngIf="deviceStatus && deviceStatus.longitude && deviceStatus.latitude">
              Map loading...
              <mat-spinner></mat-spinner>
            </h1>
            <h1 class="mapLoadingText" *ngIf="!deviceConnected && !deviceStatus">Device offline</h1>
          </div>
          <small *ngIf="deviceStatus.latitude && deviceStatus.longitude">
            Latitude: {{deviceStatus.latitude | number: '1.2-2' }}, Longitude: {{deviceStatus.longitude | number: '1.2-2' }}
          </small>
        </mat-card-content>
      </mat-card>
    </div>
  </mat-tab>


  <!-----MEDIA TAB---->
  <mat-tab label="Media uploaded">
    <br>
    <mat-card class="invertBackground">
      <mat-card-title style="display: flex;justify-content: space-evenly;align-items: center!important;">

          <mat-form-field appearance="fill" style="margin-top:25px;">
            <mat-label>Choose a date for events</mat-label>
            <input matInput [matDatepicker]="picker" (dateChange)="requestEvents('change', $event)" [max]="maxDateForEvents" [(ngModel)]="eventsDatePicker">
            <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
            <mat-datepicker [startAt]="eventsDatePicker" #picker></mat-datepicker>
          </mat-form-field>

        <div style="display: flex;flex-wrap: wrap;text-align: right;padding-left:25px;font-weight: normal;">
          <div>
            <div>
              Show journey snapshots
              <mat-slide-toggle [(ngModel)]="eventsFilters.journey" (change)="filterEvents(null)" style="margin-left: 25px;"></mat-slide-toggle>
            </div>
            <div style="margin-top: 10px;">
              Show severe events only
              <mat-slide-toggle [(ngModel)]="eventsFilters.severeOnly" (change)="filterEvents('severeOnly')" style="margin-left: 25px;"></mat-slide-toggle>
            </div>
          </div>
          <div style="padding-left:25px;">
            <div style="text-align: left;">
              Show automatic uploads
              <mat-slide-toggle [(ngModel)]="eventsFilters.automatic" (change)="filterEvents('automatic')" style="margin-left: 25px;"></mat-slide-toggle>
            </div>
            <div style="text-align: right;margin-top: 10px;">
              Show user uploads
              <mat-slide-toggle [(ngModel)]="eventsFilters.user" (change)="filterEvents('user')" style="margin-left: 25px;"></mat-slide-toggle>
            </div>
          </div>
          <div style="padding-left:25px;">
            <div style="text-align: left;">
              Show events with video only
              <mat-slide-toggle [(ngModel)]="eventsFilters.videoOnly" (change)="filterEvents('videoOnly')" style="margin-left: 25px;"></mat-slide-toggle>
            </div>
            <div style="text-align: right;margin-top: 10px;">
              Show hyperlapse only
              <mat-slide-toggle [(ngModel)]="eventsFilters.hyperlapseOnly" (change)="filterEvents('hyperlapseOnly')" style="margin-left: 25px;"></mat-slide-toggle>
            </div>
          </div>
          <div style="padding-left:25px;">
            <div style="text-align: left;">
              Queued video uploads
              <mat-slide-toggle [(ngModel)]="eventsFilters.videoQueueOnly" (change)="filterEvents('videoQueueOnly')" style="margin-left: 25px;"></mat-slide-toggle>
            </div>
          </div>
        </div>
        <div style="flex:1;"></div>


      </mat-card-title>

      <mat-card-content>
        <div *ngIf="eventsLoading" class="noEventsToShow">
          <mat-spinner class="noEventsToShowText"></mat-spinner>
        </div>

        <div *ngIf="(!partnerEventsDisplay && !eventsLoading) || (partnerEventsDisplay.length < 1 && !eventsLoading)" class="noEventsToShow">
          <br><br>
          <h1>No events to show for the selected date, with the applied filters</h1>
        </div>

        <div class="parent" *ngIf="partnerEventsDisplay">
          <div class="child" *ngFor="let e of partnerEventsDisplay" style="padding:5px;">
            <div style="display: flex;min-height: 80px;width:85%;margin:0 auto;">
              <div style="text-align: left;">
                <span><b>Time:</b> {{e.startTime  | date : 'HH:mm:ss '}}</span><br>
                <b>Location:</b>
                <span *ngIf="e.locationStreetName">
                  {{e.locationStreetName}}
                </span>
                <span *ngIf="!e.locationStreetName">
                  -
                </span>
              </div>
              <div style="flex:1;">
                <div style="width: 100px;height: 40px;border-radius: 40px;text-align: center;position: relative;border:1px solid lightgray;padding:5px;margin-left: auto;">
                  <div style="position: absolute;top:-12px;left:25px;margin-left: auto;margin-right: auto;width: 60px;background-color: #f5f5f5;font-size: 12px;font-weight: bold">Analysis</div>
                    <img *ngIf="e.analysis && (e.analysis.eventType == 'gSensor' || e.analysis.eventType == 'bump')" src="././assets/svgs/pin-shock.svg" matTooltip="G-Force"
                         style="height:32px;vertical-align:middle;display: inline-block;margin-top: 5px;">
                    <img *ngIf="e.analysis && e.analysis.eventType == 'braking'" src="././assets/svgs/pin-brake.svg" matTooltip="Braking"
                         style="height:34px;vertical-align:middle;display: inline-block;">
                    <img *ngIf="e.analysis && e.analysis.eventType == 'cornering'" src="././assets/svgs/pin-turning.svg" matTooltip="Cornering"
                         style="height:32px;vertical-align:middle;display: inline-block;">
                    <img *ngIf="e.analysis && e.analysis.eventType == 'acceleration'" src="././assets/svgs/pin-acceleration.svg" matTooltip="Acceleration"
                         style="height:32px;vertical-align:middle;display: inline-block;">
                    <img *ngIf="!e.analysis && (e.eventSource == 'videoUpload' || e.eventSource == 'hyperlapse')" src="././assets/user-pin.png" matTooltip="User requested
                        video upload" style="height:32px;vertical-align:middle;display: inline-block;margin-top: 5px;">

                    <img *ngIf="!e.analysis && (e.eventSource == 'videoUploadPending' || e.eventSource == 'hyperlapsePending')" src="././assets/user-pin.png" matTooltip="User requested
                    video upload pending" style="height:32px;vertical-align:middle;display: inline-block;margin-top: 5px;">
                    <img *ngIf="!e.analysis && e.eventSource == 'user'" src="././assets/user-pin.png" matTooltip="User requested upload"
                         style="height:32px;vertical-align:middle;display: inline-block;margin-top: 5px;">
                    <img *ngIf="!e.analysis && e.eventSource == 'journeyStart'" src="././assets/journeyFlag.png" matTooltip="Journey start"
                         style="height:32px;vertical-align:middle;display: inline-block;margin-top: 5px;">
                    <img *ngIf="!e.analysis && e.eventSource == 'journeyEnd'" src="././assets/journeyFlag.png" matTooltip="Journey end"
                         style="height:32px;vertical-align:middle;display: inline-block;margin-top: 5px;">

                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <svg *ngIf="e.analysis && e.analysis.severity === 'severe'" matTooltip="Severe"
                         style="width:38px;height:38px;color:#F24141;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                    </svg>
                    <svg *ngIf="e.analysis && e.analysis.severity === 'moderate'" matTooltip="Moderate"
                         style="width:38px;height:38px;color:#FFBF00;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                    </svg>
                    <svg *ngIf="e.analysis && e.analysis.severity === 'mild'" matTooltip="Mild"
                         style="width:38px;height:38px;color:#5C8459;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                    </svg>
                    <svg *ngIf="!e.analysis && (e.eventSource == 'videoUpload' || e.eventSource == 'hyperlapse')" matTooltip="User requested video upload"
                         style="width:38px;height:38px;color:#5C8459;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                    </svg>
                    <svg *ngIf="!e.analysis && (e.eventSource == 'videoUploadPending' || e.eventSource == 'hyperlapsePending')" matTooltip="User requested video upload"
                         style="width:38px;height:38px;color:#5C8459;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                    </svg>
                    <svg *ngIf="!e.analysis && e.eventSource == 'user'" matTooltip="User created event"
                         style="width:38px;height:38px;color:#5C8459;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                    </svg>
                    <svg *ngIf="!e.analysis && e.eventSource == 'journeyStart'" matTooltip="Journey start"
                         style="width:38px;height:38px;color:#5C8459;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                    </svg>
                    <svg *ngIf="!e.analysis && e.eventSource == 'journeyEnd'" matTooltip="Journey end"
                         style="width:38px;height:38px;color:#5C8459;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                    </svg>
                </div>
              </div>
            </div>
            <div *ngIf="e.snapshots" style="padding-bottom: 10px;position: relative;">
               <img class="eventImage"  [src]="API_URL + '/angFetchSnapshot/'+ e.snapshots[0].file + '_sm'" (click)="openDialog(e, 'image')"
                    onerror="this.onerror=null; this.src='././assets/imageNotFound.png'" style="width:85%;margin-top:5px;">

              <img src="././assets/video_play.png" *ngIf="e.videos2 && e.videos2.length >= 1 && e.videoType == 'video'"
                   style="position: absolute;text-align: center;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 50px;cursor: pointer;"
                   (click)="openDialog(e, 'video')">
<!--------->
              <img src="././assets/hyperlapse_play.png" *ngIf="e.videos2 && e.videos2.length >= 1 && e.videoType == 'hyperlapse'"
                   style="position: absolute;text-align: center;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 50px;cursor: pointer;"
                   (click)="openDialog(e, 'video')">
<!--------->
            </div>
            <div *ngIf="!e.snapshots && e.eventSource != 'videoUploadPending' && e.eventSource != 'hyperlapsePending'" style="padding-bottom: 10px;position:relative">
              <img class="eventImage"  src="././assets/imageNotFound.png" style="width:85%;margin-top:5px;">

<!--              <img src="././assets/hyperlapse_play.png" *ngIf="e.videos2 && e.video2.length >= 1 && e.videoType == 'hyperlapse'"-->
<!--                   style="position: absolute;text-align: center;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 50px;cursor: pointer;"-->
<!--                   (click)="openDialog(e, 'video')">-->
            </div>
            <div *ngIf="!e.snapshots && (e.eventSource == 'videoUploadPending' || e.eventSource == 'hyperlapsePending')"
                 style="padding-bottom: 10px; position: relative">

              <img class="eventImage"  src="././assets/eventPlaceholder.jpg" style="width:85%;margin-top:5px;">




                <svg height="100" width="100"  style="position: absolute;text-align: center;top: 50%;left: 50%;transform: translate(-50%, -50%);">
                  <circle cx="50%" cy="50%" r="45" stroke="#d9d9d9" stroke-width="10%" fill="#fff" />
                </svg>

                <mat-spinner [value]="e.percentComplete" mode="determinate" style="position: absolute;text-align: center;top: 50%;left: 50%;transform: translate(-50%, -50%);"></mat-spinner>
                <div style="position: absolute;text-align: center;top: 50%;left: 50%;transform: translate(-50%, -50%);">
                  {{e.percentComplete}}%
                </div>

            </div>




            <div style="display: flex;width:85%;margin:0 auto;">
              <div style="flex:1;text-align: left;">
<!--                <a mat-raised-button style="background-color: #3f51b5!important;" [disabled]="!e.lat || !e.lng"-->
<!--                   href="https://www.openstreetmap.org/?mlat={{e.lat}}&mlon={{e.lng}}&zoom=16"-->
<!--                   target="_blank">-->
<!--                  <mat-icon style="font-size: 1.5em;" *ngIf="e.lat && e.lng">map</mat-icon>-->
<!--                  See map-->
<!--                </a>-->

                <button mat-raised-button style="background-color: #3f51b5!important;" (click)="viewEventOnMap(e)" [disabled]="!e.lat || !e.lng">
                  <mat-icon style="font-size: 1.5em;" *ngIf="e.lat && e.lng">map</mat-icon>
                  See map
                </button>

              </div>
              <div style="flex:1;text-align: right">
                <button type="button" mat-flat-button   *ngIf="!e.videos2 || e.videos2.length < 1" (click)="requestEventVideo(e)">
                  <mat-icon *ngIf="!e.videos2 || e.videos2.length > 1">videocam</mat-icon>
                  Get video
                </button>

                <div style="display: flex;align-items: center;justify-content: center;" *ngIf="e.videos2 && e.videos2.length == 1 && !anyEventsViewable(e)">
                  <span style="flex:1;text-align:right;font-weight:bold;padding-right:5px;">Uploading</span>
                  <mat-spinner [diameter]="36" style="margin-left:auto;display: inline-block;" ></mat-spinner>
                </div>

                <button type="button" mat-raised-button style="background-color:#37ad4c!important" (click)="openDialog(e, 'video')" *ngIf="e.videos2 && e.videos2.length >= 1 && anyEventsViewable(e)">
                  <mat-icon *ngIf="e.videos2 && e.videos2.length >=1">videocam</mat-icon>
                  <span *ngIf="e.videos2.length > 1">View videos</span>
                  <span *ngIf="e.videos2.length == 1">View video</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-tab>



  <mat-tab label="Raw"  *ngIf="usersGroup == 'undefined'">
    <div>
      <br>
      <div *ngFor="let p of deviceParameters  | keyvalue">
        <strong>{{p.key}}</strong><br>
        {{p.value | json}}
        <br><br>
      </div>
    </div>
    <div *ngIf="deviceStatus">
      deviceTime   {{deviceStatus.deviceTime}}<br>
      acquisitionTime    {{deviceStatus.acquisitionTime}}    <br>
      <br>
      ModuleWorkingStatusidentifierBit    {{deviceStatus.ModuleWorkingStatusidentifierBit}}     <br>
      mobileNetworkModule: {{deviceStatus.mobileNetworkModule}} <br>
      locationModule: {{deviceStatus.locationModule}}<br>
      wifiModule: {{deviceStatus.wifiModule}}<br>
      gSensorModule: {{deviceStatus.gSensorModule}}<br>
      recording: {{deviceStatus.recording}}<br><br>
      mobileNetwork    {{deviceStatus.mobileNetwork}}    <br>
      GPSLocation    {{deviceStatus.GPSLocation}}     <br>
      <br>
      gSensor    {{deviceStatus.gSensor}}    <br>
      recordingStatus    {{deviceStatus.recordingStatus}}     <br>
      recordingStatusArray: {{this.deviceStatus.recordingStatusArray}} <br><br>
      HardDiskStatusBit    {{deviceStatus.HardDiskStatusBit}}     <br>
      HardDiskStatusBitArray    {{deviceStatus.HardDiskStatusBitArray}}<br>
      HardDiskId    {{deviceStatus.HardDiskId}}     <br>
      HardDiskStatus    {{deviceStatus.HardDiskStatus}}     <br>
      HardDiskSizeMb    {{deviceStatus.HardDiskSizeMb}}     <br>
      HardDiskBalanceCapacity    {{deviceStatus.HardDiskBalanceCapacity}}     <br><br>
      alarmStatusIdBit    {{deviceStatus.alarmStatusIdBit}}     <br>
      <hr>
      videoLoss      {{deviceStatus.videoLossAny}} <br>
      motionDetection      {{deviceStatus.motionDetectionAny}} <br>
      videoBlind      {{deviceStatus.videoBlindAny}} <br>
      alarmInputTrigger      {{deviceStatus.alarmInputTriggerAny}} <br>
      overSpeedAlarm      {{deviceStatus.overSpeedAlarm}} <br>
      lowSpeedAlarm      {{deviceStatus.lowSpeedAlarm}} <br>
      emergencyAlarm      {{deviceStatus.emergencyAlarm}} <br>
      overTimeStop      {{deviceStatus.overTimeStop}} <br>
      vibrationAlarm      {{deviceStatus.vibrationAlarm}} <br>
      outGEOFencingAlarm      {{deviceStatus.outGEOFencingAlarm}} <br>
      enterGEOFencingAlarm      {{deviceStatus.enterGEOFencingAlarm }} <br>
      exitLineAlarm      {{deviceStatus.exitLineAlarm }} <br>
      enterLineAlarm      {{deviceStatus.enterLineAlarm }} <br>
      fuelLevelAlarm      {{deviceStatus.fuelLevelAlarm }} <br>
      <hr>
      <br>
      videoLoss    {{deviceStatus.videoLoss}}    <br>
      videoLossArray    {{deviceStatus.videoLossArray}}   <br><br>
      motionDetection    {{deviceStatus.motionDetection}}     <br>
      motionDetectionArray    {{deviceStatus.motionDetectionArray}}   <br><br>
      videoBlind    {{deviceStatus.videoBlind}}     <br>
      videoBlindArray    {{deviceStatus.videoBlindArray}}   <br><br>
      alarmInputTrigger    {{deviceStatus.alarmInputTrigger}}     <br>
      alarmInputTriggerArray    {{deviceStatus.alarmInputTriggerArray}}   <br>
      <br><br>
      <div *ngIf="calendar">
        <div *ngFor="let c of calendar.modRecordingHistory">
          {{c.st}} - {{c.et}}
        </div>
      </div>
      <div *ngIf="deviceParameters">
        {{deviceParameters | json}}
      </div>
    </div>
  </mat-tab>

  <mat-tab label="History"  *ngIf="usersGroup == 'undefined'">
    <br>
    <mat-card>
      <mat-card-title>
        Notes
      </mat-card-title>
      <mat-card-content>
        <div *ngFor="let note of notes">
          {{note.time | date: 'dd/MM/yy, h:mm a'}} - {{note.user}}<br>
          {{note.text}}
          <hr>
        </div>
        <form class="note-form">
          <mat-form-field class="note-full-width">
            <mat-label>Add note</mat-label>
            <textarea matInput placeholder="Notes" [formControl]="note"></textarea>
          </mat-form-field>
          <button mat-flat-button (click)="addNote()">Add note</button>
        </form>
      </mat-card-content>
    </mat-card>
  </mat-tab>

  <mat-tab label="Health" *ngIf="usersGroup == 'undefined'">
    <br>
    <div style="text-align: center;">
      <mat-form-field appearance="fill">
        <mat-label>Health updates</mat-label>
        <mat-select [(ngModel)]="numberOfStatusUpdatesForHealth" [ngModelOptions]="{standalone: true}" (ngModelChange)="healthGraphUpdate()">
          <mat-option [value]="10">10</mat-option>
          <mat-option [value]="20">20</mat-option>
          <mat-option [value]="30">30</mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <div class="chart-wrapper" style="max-width: 95%;margin:0 auto;">
      <canvas baseChart
              [datasets]="deviceHealthChartData"
              [labels]="deviceHealthChartLabels"
              [options]="deviceHealthChartOptions"
              [legend]="deviceHealthChartLegend"
              [chartType]="deviceHealthChartType"
              [plugins]="deviceHealthChartPlugins">
      </canvas>
    </div>
  </mat-tab>
</mat-tab-group>





<!-------------------------------------COMMISSIONING MODAL------------------------------------------------------------>
<ng-template #commissoningDialog>
  <form *ngIf="commissioning.commissionStatus == 'setup'" [formGroup]="testedForm">
    <mat-form-field>
      <input
        matInput
        [matDatepicker]="dp"
        placeholder="Test bench date"
        formControlName="testBenchDate">
      <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
      <mat-datepicker #dp></mat-datepicker>
    </mat-form-field>
    &nbsp;
    <span class="pass-status">
        <span *ngIf="testedForm.value.gpsPass" class="pass">GPS<mat-icon>check</mat-icon></span>
        <span *ngIf="!testedForm.value.gpsPass" class="engineer-issue">
            GPS
          <mat-icon color="warn">close</mat-icon>
        </span>
      </span>

    <ng-container formArrayName="cameras">
      <strong>Cameras:</strong>
      <span *ngFor="let camerasGroup of camerasTestedFormArray.controls; index as i">
          <span [formGroup]="camerasGroup">
            {{testedForm.value.cameras[i].ch}}
            <span class="pass-status">
              <span *ngIf="testedForm.value.cameras[i].recording" class="pass">
                Recording
                <mat-icon color="primary">check</mat-icon></span>
                <span *ngIf="!testedForm.value.cameras[i].recording" class="engineer-issue">Error<mat-icon>close</mat-icon></span>
              </span>
            </span>
        </span>
    </ng-container>

    <div>
      <button mat-button color="primary" (click)="testedVehicle()" [disabled]="!testedForm.valid">
        Tested
      </button>
      &nbsp;&nbsp;&nbsp; Signed off by: <strong> {{testedForm.value.signedOffBy }}</strong> <br>
    </div>
    <!--{{commissionForm.value | json}}-->
  </form>
  <!--    <hr>-->


  <!--  </button>-->

  <!--</mat-card>-->


  <form [formGroup]="changeStatusForm" *ngIf="commissioning.commissionStatus == 'active'">
    <h1>Change Commissioning Status</h1>
    <mat-form-field class="example-full-width">
      <mat-label>Change status</mat-label>
      <mat-select formControlName="commissionStatus">
        <mat-option [value]="'active'">active</mat-option>
        <mat-option [value]="'setup'">setup</mat-option>
        <mat-option [value]="'tested'">Install</mat-option>
        <mat-option [value]="'service'">service</mat-option>
        <mat-option [value]="'expired'">expired</mat-option>
        <mat-option [value]="'disabled'">disabled</mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-button color="primary" (click)="changeCommissionStatus()" [disabled]="!changeStatusForm.valid">
      Change status
    </button>
    <br>
    &nbsp;&nbsp;&nbsp;
    <button *ngIf="commissioning.commissionStatus == 'active'" mat-button color="primary" (click)="dialog.closeAll()" style="float:right;margin-top: 5px;">
      Close
    </button>
  </form>

  <div *ngIf="frontCamOrientationHelp">
    <h1 style="text-align: center">Front Camera Positioning Help</h1>
  </div>
  <div *ngIf="driverCamOrientationHelp">
    <h1 style="text-align: center">Driver Camera Positioning Help</h1>
  </div>

  <form [formGroup]="commissionForm" *ngIf="commissioning.commissionStatus == 'tested'">
    <h1 style="font-size: 28px!important;">Device commissioning</h1>
    <mat-horizontal-stepper linear>
      <mat-step label="Installation details" state="engineer">
        <br>
        <div style="display: flex;flex-wrap: nowrap;">
          <div>
            Select the date of installation and the engineer that attended from the dropdown boxes below. Check with the<br>
            engineer onsite that the vehicle registration and fleet name (customer name ) is correct and update it if required.
          </div>
          <div style="flex:1;text-align: right!important">
            <button mat-raised-button matStepperNext style="width:150px;"
            [disabled]="commissioningInstallDataCheckComplete()"
            [ngClass]="{'greenCommissioningButton': !commissioningInstallDataCheckComplete(), 'blackCommissioningButton': commissioningInstallDataCheckComplete()}">
              <mat-icon style="vertical-align: center;" *ngIf="!commissioningInstallDataCheckComplete()">chevron_right</mat-icon>
              &nbsp;Next
            </button>
          </div>
        </div>
        <br>
        <mat-card>
          <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;">
            Installation details
          </mat-card-title>
          <mat-card-content>
            <div style="width:100%;justify-content: center;display: flex;flex-wrap: wrap;">

              <mat-form-field style="padding:5px;">
                <mat-label>Install date</mat-label>
                <input
                  matInput
                  [matDatepicker]="dp"
                  placeholder="Install date"
                  formControlName="installDate">
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>
              </mat-form-field>

              <mat-form-field style="padding:5px;" *ngIf="usersGroup !== 'undefined'">
                <mat-label>Install engineer</mat-label>
                <input matInput formControlName="installEngineer" readonly>
                <!--              <input  matInput formControlName="installEngineer">-->
              </mat-form-field>

              <mat-form-field style="padding:5px;" *ngIf="usersGroup === 'undefined'">
                <mat-label>Install engineer</mat-label>
                <mat-select formControlName="installEngineer">
                  <mat-option *ngFor="let eng of engineers" [value]="eng">
                    {{eng}}
                  </mat-option>
                </mat-select>
                <!--              <input  matInput formControlName="installEngineer">-->
              </mat-form-field>



              <mat-form-field style="padding:5px;" *ngIf="theVehicleSettings">
                <mat-label>Vehicle registration</mat-label>
                <input matInput [(ngModel)]="vehicleRegistration" [ngModelOptions]="{standalone: true}">
                <!--              <input  matInput formControlName="installEngineer">-->
              </mat-form-field>

              <mat-form-field style="padding:5px;" *ngIf="theVehicleSettings">
                <mat-label>Vehicle fleet</mat-label>
                <mat-select [(ngModel)]="vehicleFleet" [ngModelOptions]="{standalone: true}">
                  <mat-option *ngFor="let fleet of allFleets" [value]="fleet.fleetId">
                    {{fleet.fleetId}}
                  </mat-option>
                </mat-select>
                <!--              <input  matInput formControlName="installEngineer">-->
              </mat-form-field>

            </div>
          </mat-card-content>
        </mat-card>
      </mat-step>


      <mat-step label="Systems checks" state="device">
        <br>
        <div style="display: flex;flex-wrap: nowrap;">
          <div>
            To confirm the correct installation of the device automatic checks on the wiring, antenna positioning, calibration<br>
            and cameras are performed. Where a check fails (shown in red) hover over the tooltip icon (?) for tips and guidance on<br> how to solve.
          </div>
          <div style="flex:1;text-align: right!important">
            <button mat-raised-button matStepperNext style="width:150px;"
                    [ngClass]="{'greenCommissioningButton': !commissioningSystemCheckComplete(), 'blackCommissioningButton': commissioningSystemCheckComplete()}"
                    [disabled]="commissioningSystemCheckComplete()">
              <!--- [disabled]="commissioningSystemCheckComplete()" --->
              <mat-icon style="vertical-align: center;" *ngIf="!commissioningSystemCheckComplete()">chevron_right</mat-icon>
              &nbsp;Next
            </button>
          </div>
        </div>
        <br>
        <mat-card>
          <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;">
            Systems checks
          </mat-card-title>
          <mat-card-content>

            <button style="float:right;" mat-flat-button (click)="getDeviceStatus(dn); triggerUnderSpeedAlarm()">
              <mat-icon>refresh</mat-icon>
              Refresh
            </button>

            <div style="display: flex;width:100%; justify-content: space-evenly;flex-wrap: wrap;font-size:16px;">

              <div style="text-align: center;padding:10px;flex:1;">
                <div *ngIf="commissionForm.value.ignitionPass" class="deviceActive">
                  <svg style="width:24px;height:24px;vertical-align: middle" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M9.5,0A3,3 0 0,0 6.5,3A3,3 0 0,0 9.5,6C10.81,6 11.92,5.17 12.33,4H14.5V6H16.5V4H17.5V2H12.33C11.92,0.83 10.81,0 9.5,0M9.5,2A1,1 0 0,1 10.5,3A1,1 0 0,1 9.5,4A1,1 0 0,1 8.5,3A1,1 0 0,1 9.5,2M6.5,8C5.84,8 5.28,8.42 5.08,9L3,15V23A1,1 0 0,0 4,24H5A1,1 0 0,0 6,23V22H18V23A1,1 0 0,0 19,24H20A1,1 0 0,0 21,23V15L18.92,9C18.72,8.42 18.16,8 17.5,8H6.5M6.5,9.5H17.5L19,14H5L6.5,9.5M6.5,16A1.5,1.5 0 0,1 8,17.5A1.5,1.5 0 0,1 6.5,19A1.5,1.5 0 0,1 5,17.5A1.5,1.5 0 0,1 6.5,16M17.5,16A1.5,1.5 0 0,1 19,17.5A1.5,1.5 0 0,1 17.5,19A1.5,1.5 0 0,1 16,17.5A1.5,1.5 0 0,1 17.5,16Z" />
                  </svg>
                  <br>
                  Wiring<br>
                  <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="The vehicle wiring test has passed.">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="!commissionForm.value.ignitionPass" class="deviceWarning">
                  <svg style="width:24px;height:24px;vertical-align: middle" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M9.5,0A3,3 0 0,0 6.5,3A3,3 0 0,0 9.5,6C10.81,6 11.92,5.17 12.33,4H14.5V6H16.5V4H17.5V2H12.33C11.92,0.83 10.81,0 9.5,0M9.5,2A1,1 0 0,1 10.5,3A1,1 0 0,1 9.5,4A1,1 0 0,1 8.5,3A1,1 0 0,1 9.5,2M6.5,8C5.84,8 5.28,8.42 5.08,9L3,15V23A1,1 0 0,0 4,24H5A1,1 0 0,0 6,23V22H18V23A1,1 0 0,0 19,24H20A1,1 0 0,0 21,23V15L18.92,9C18.72,8.42 18.16,8 17.5,8H6.5M6.5,9.5H17.5L19,14H5L6.5,9.5M6.5,16A1.5,1.5 0 0,1 8,17.5A1.5,1.5 0 0,1 6.5,19A1.5,1.5 0 0,1 5,17.5A1.5,1.5 0 0,1 6.5,16M17.5,16A1.5,1.5 0 0,1 19,17.5A1.5,1.5 0 0,1 17.5,19A1.5,1.5 0 0,1 16,17.5A1.5,1.5 0 0,1 17.5,16Z" />
                  </svg>
                  <br>
                  Wiring<br>
                  <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="The vehicle wiring test has failed, please ask engineer to check yellow wire is connected to a switched live and to cycle ignition off for 60 seconds and then back on.">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
              </div>
              <div style="text-align: center;padding:10px;flex:1;">
                <div *ngIf="commissionForm.value.gpsPass" class="deviceActive">
                  <svg viewBox="0 0 24 24" style="width: 24px; height: 24px; vertical-align: middle;">
                    <path fill="currentColor" d="M11.62,1L17.28,6.67L15.16,8.79L13.04,6.67L11.62,8.09L13.95,10.41L12.79,11.58L13.24,12.04C14.17,11.61 15.31,11.77 16.07,12.54L12.54,16.07C11.77,15.31 11.61,14.17 12.04,13.24L11.58,12.79L10.41,13.95L8.09,11.62L6.67,13.04L8.79,15.16L6.67,17.28L1,11.62L3.14,9.5L5.26,11.62L6.67,10.21L3.84,7.38C3.06,6.6 3.06,5.33 3.84,4.55L4.55,3.84C5.33,3.06 6.6,3.06 7.38,3.84L10.21,6.67L11.62,5.26L9.5,3.14L11.62,1M18,14A4,4 0 0,1 14,18V16A2,2 0 0,0 16,14H18M22,14A8,8 0 0,1 14,22V20A6,6 0 0,0 20,14H22Z"></path>
                  </svg>
                  <br>
                  GPS<br>
                  <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="GPS signal strength test has passed.">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="!commissionForm.value.gpsPass" class="deviceWarning">
                  <svg viewBox="0 0 24 24" style="width: 24px; height: 24px; vertical-align: middle;">
                    <path fill="currentColor" d="M11.62,1L17.28,6.67L15.16,8.79L13.04,6.67L11.62,8.09L13.95,10.41L12.79,11.58L13.24,12.04C14.17,11.61 15.31,11.77 16.07,12.54L12.54,16.07C11.77,15.31 11.61,14.17 12.04,13.24L11.58,12.79L10.41,13.95L8.09,11.62L6.67,13.04L8.79,15.16L6.67,17.28L1,11.62L3.14,9.5L5.26,11.62L6.67,10.21L3.84,7.38C3.06,6.6 3.06,5.33 3.84,4.55L4.55,3.84C5.33,3.06 6.6,3.06 7.38,3.84L10.21,6.67L11.62,5.26L9.5,3.14L11.62,1M18,14A4,4 0 0,1 14,18V16A2,2 0 0,0 16,14H18M22,14A8,8 0 0,1 14,22V20A6,6 0 0,0 20,14H22Z"></path>
                  </svg>
                  <br>
                  GPS<br>
                  <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="The GPS performance test has failed, please ask engineer to check the connnection of the GPS antenna and reposition it if required to a position with a clear view of the sky.">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
              </div>
              <div style="text-align: center;padding:10px;flex:1;">
                <div *ngIf="commissionForm.value.gSensorCalibratePass" class="deviceActive" >
                  <svg style="width:24px;height:24px;vertical-align: middle;" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M13,19.92C14.8,19.7 16.35,18.95 17.65,17.65C18.95,16.35 19.7,14.8 19.92,13H16.92C16.7,14 16.24,14.84 15.54,15.54C14.84,16.24 14,16.7 13,16.92V19.92M10,8H14L17,11H19.92C19.67,9.05 18.79,7.38 17.27,6C15.76,4.66 14,4 12,4C10,4 8.24,4.66 6.73,6C5.21,7.38 4.33,9.05 4.08,11H7L10,8M11,19.92V16.92C10,16.7 9.16,16.24 8.46,15.54C7.76,14.84 7.3,14 7.08,13H4.08C4.3,14.77 5.05,16.3 6.35,17.6C7.65,18.9 9.2,19.67 11,19.92M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12C22,14.75 21,17.1 19.05,19.05C17.1,21 14.75,22 12,22C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z" />
                  </svg>
                  <br>
                  G-Sensor<br>
                  <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="The G-Force sensor is correctly calibrated.">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="!commissionForm.value.gSensorCalibratePass" class="deviceWarning" (click)="calibrateGSensor()" style="cursor: pointer;">
                  <svg style="width:24px;height:24px;vertical-align: middle;color: white;" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M13,19.92C14.8,19.7 16.35,18.95 17.65,17.65C18.95,16.35 19.7,14.8 19.92,13H16.92C16.7,14 16.24,14.84 15.54,15.54C14.84,16.24 14,16.7 13,16.92V19.92M10,8H14L17,11H19.92C19.67,9.05 18.79,7.38 17.27,6C15.76,4.66 14,4 12,4C10,4 8.24,4.66 6.73,6C5.21,7.38 4.33,9.05 4.08,11H7L10,8M11,19.92V16.92C10,16.7 9.16,16.24 8.46,15.54C7.76,14.84 7.3,14 7.08,13H4.08C4.3,14.77 5.05,16.3 6.35,17.6C7.65,18.9 9.2,19.67 11,19.92M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12C22,14.75 21,17.1 19.05,19.05C17.1,21 14.75,22 12,22C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z" />
                  </svg>
                  <br>
                  G-Sensor<br>
                  <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="The G-Force sensor is out of calibration, click the status box to calibrate the sensor now.">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
              </div>
              <div *ngFor="let camera of cameras index as i" style="text-align: center;padding:10px;flex:1;">
                <div *ngIf="deviceStatus.recordingStatusArray[camera.channel] !== '0' && deviceConnected" class="deviceActive">
                  <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
                  </svg>
                  <br>
                  {{cameras[i].camPosition}} cam<br>
                  <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="{{cameras[i].camPosition}} camera is connected correctly and recording.">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </div>
                <div *ngIf="deviceStatus.recordingStatusArray[camera.channel] == '0' || !deviceConnected" class="deviceWarning">
                  <svg style="width:24px;height:24px;vertical-align:middle;color: white;" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
                  </svg>
                  <br>{{cameras[i].camPosition}} cam<br>

                  <span *ngIf="cameras[i].camPosition == 'Front'">
                    <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="The front camera is not recording, please ask the engineer to ensure the vehicle ignition is switched on and the camera is connected correctly to CH1.">
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </span>
                  <span *ngIf="cameras[i].camPosition == 'Driver'">
                    <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="The driver camera is not recording, please ask the engineer to ensure the vehicle ignition is switched on and the camera is connected correctly to CH2.">
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </span>
                  <span *ngIf="cameras[i].camPosition !== 'Driver' && cameras[i].camPosition !== 'Front'">
                    <svg style="width:16px;height:16px;vertical-align: middle;color:white;" viewBox="0 0 24 24" matTooltip="{{cameras[i].camPosition}} camera is not recording, ask engineer to ensure ignition is on and the camera is wired correctly.">
                      <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>

            <div *ngIf="!commissionForm.value.gSensorCalibratePass" style="text-align: center">
              <button mat-raised-button (click)="calibrateGSensor()" [disabled]="!deviceConnected || deviceStatus.speed > 0">
                Calibrate G-Sensor
              </button>
              <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-left:5px;" viewBox="0 0 24 24" matTooltip="Click here to calibrate the G-Sensor. The ignition must be on and the vehicle must be stationary.">
                <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
              </svg>
            </div>




          </mat-card-content>
        </mat-card>
      </mat-step>


      <mat-step label="Camera alignment checks" state="camera">
        <br>
        <div style="display: flex;flex-wrap: nowrap;">
          <div>
            Use the snapshots below to confirm the correct installation and orientation of cameras, ask the engineer to adjust<br>
            the camera if required and request an updated snapshot. Use the alignment confirmation toggle to proceed to <br>
            commission the device.
          </div>
          <div style="flex:1;text-align: right!important">
            <button mat-raised-button matStepperNext style="width:150px;" [disabled]="commissioningCameraCheckCompleteValue"
                    [ngClass]="{'greenCommissioningButton': !commissioningCameraCheckCompleteValue, 'blackCommissioningButton': commissioningCameraCheckCompleteValue}">
              <mat-icon style="vertical-align: center;" *ngIf="!commissioningCameraCheckCompleteValue">chevron_right</mat-icon>
              &nbsp;Next
            </button>
          </div>
        </div>
        <br>
        <mat-card>
          <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;">
            Camera alignment checks (Ensure ignition is on)


            <span *ngIf="deviceStatus && deviceStatus.signalTrafficLight == 'good'" style="margin-left: auto;" class="statusActive">
              Device has good signal, uploads should be prompt
            </span>
            <span *ngIf="deviceStatus && deviceStatus.signalTrafficLight == 'average'" style="margin-left: auto;" class="statusTested">
              Device has average signal, uploads may take some time
            </span>
            <span *ngIf="deviceStatus && deviceStatus.signalTrafficLight == 'weak'" style="margin-left: auto;" class="statusService">
              Device has weak signal, uploads will be slow
            </span>



          </mat-card-title>
          <mat-card-content formArrayName="cameras" style="display: flex;flex-wrap: wrap;justify-content: space-evenly;">
            <div *ngFor="let camerasGroup of camerasFormArray.controls; index as i" style="flex:1;">
              <div [formGroup]="camerasGroup" style="padding-left:5px;padding-right: 5px;" >

                <div style="display: flex;align-items: center;margin-bottom: 5px;">
                  <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
                  </svg>
                  &nbsp;<b>{{cameras[i].camPosition}} camera</b>
                  <button type="button" mat-flat-button (click)="snapshotRequest(cameras[i].channel)"
                          [disabled]="deviceStatus.recordingStatusArray[cameras[i].channel] == '0' || !deviceStatus || !deviceConnected || requestSnapshotFlag"
                          style="line-height: 30px!important;margin-left: auto;">
                    <mat-icon *ngIf="deviceStatus.recordingStatusArray[cameras[i].channel] != '0' && deviceStatus && deviceConnected && !requestSnapshotFlag">photo_camera</mat-icon>
                    New snapshot
                  </button>
                </div>

                <img *ngIf="snapshotsPerChannel && snapshotsPerChannel[(i+1)] && snapshotsPerChannel[(i+1)].length >= 1 && snapshotChannelRequested != (i+1)"
                     class="eventImage" [src]="API_URL + '/angFetchSnapshot/'+ snapshotsPerChannel[(i+1)][0].theUrl" style="width:100%;"
                     onError="this.error = null; this.src='././assets/snapLoading2.gif'">

                <img *ngIf="!snapshotsPerChannel || (!snapshotsPerChannel[(i+1)] || snapshotsPerChannel[(i+1)].length < 1) && snapshotChannelRequested != (i+1)"
                     class="eventImage"  src='././assets/reqSnap2.png' style="width:100%;">

                <img *ngIf="snapshotChannelRequested == (i+1)" src="././assets/snapLoading2.gif" class="eventImage" style="width:100%;">

                <mat-form-field style="width: 100%;">
                  <mat-label>Image flip</mat-label>
                  <mat-select [(ngModel)]="deviceParameters.RECORD.MainChn['chn' + cameras[i].channel].Mirror" [ngModelOptions]="{standalone: true}"
                              (selectionChange)="updateMirror(deviceParameters.RECORD.MainChn['chn' + cameras[i].channel].Mirror, cameras[i].channel)">
                    <mat-option [value]="'0'">None</mat-option>
                    <mat-option [value]="'1'">Horizontally</mat-option>
                    <mat-option [value]="'2'">Vertically</mat-option>
                    <mat-option [value]="'3'">All</mat-option>
                  </mat-select>
                </mat-form-field>
                <div style="display: flex">
                  <div style="display: inline-block;">
                    The camera image is correctly aligned:
                  </div>
                  <div style="margin-left: auto;display: inline-block;text-align: right!important;">
                    <mat-slide-toggle formControlName="orientation" style="transform: scale(1.2);float: right;"></mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-step>

      <mat-step label="Commission device" state="finshLine">
        <br>
        <div style="width: 100%;text-align: right!important">
            <button mat-raised-button (click)="commissionVehicle()" [disabled]="commissioningFinalCheckComplete()" style="width: 150px;vertical-align: center;"
                    [ngClass]="{'greenCommissioningButton': !commissioningFinalCheckComplete(), 'blackCommissioningButton': commissioningFinalCheckComplete()}">
              <mat-icon *ngIf="!commissioningFinalCheckComplete()">done</mat-icon>
              &nbsp;Commission
            </button>
        </div>
        <br>
        <mat-card>
          <mat-card-title style="padding:21px 10px!important;display: flex;align-content: center;align-items: center;">
            Final checks for commissioning:
          </mat-card-title>
          <mat-card-content>
            <div style="font-size: 18px;">
              <mat-icon style="color: black!important;">done</mat-icon>	Check device status lights, SD should be flashing, all three others should be on.<br><br>
              <mat-icon style="color: black!important;">done</mat-icon>	Check device has been installed securely and does not move around.<br><br>
              <mat-icon style="color: black!important;">done</mat-icon>	Check engineer has tightened the camera locking screws.<br><br>
              <mat-icon style="color: black!important;">done</mat-icon>	Remind engineer to take photos of both the device and camera positions.
            </div>

            <br><br>

            <mat-form-field style="width: 100%;">
              <mat-label>Additional notes from commissioning (optional)</mat-label>
              <textarea matInput [(ngModel)]="commissioningFinalNotes" [ngModelOptions]="{standalone: true}"></textarea>
            </mat-form-field>


          </mat-card-content>
        </mat-card>
      </mat-step>

      <!-- Icon overrides. -->
      <ng-template matStepperIcon="engineer">
        <mat-icon>account_circle</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="device">
        <mat-icon>router</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="camera">
        <mat-icon>videocam</mat-icon>
      </ng-template>
      <ng-template matStepperIcon="finshLine">
        <mat-icon>done</mat-icon>
      </ng-template>
    </mat-horizontal-stepper>
  </form>
</ng-template>




<!---------------------------------------EVENT LOCATION MAP MODAL--------------->
<ng-template #eventLocationMapModal>
  <h1 style="font-size: 28px;">Viewing event on map</h1>
  <div style="width:600px;height:600px;" id="eventMap"></div>
</ng-template>


<!---------------------------------------REQUEST VIDEO RESULT MODAL --------------->
<ng-template #videoRequestResultModal>
  <h1 style="font-size: 28px;" id="videoRequestResultModalTitle"></h1>
  <center>
    <mat-spinner id="videoRequestResultModalLoading"></mat-spinner>
  </center>
  <div id="videoRequestResultModalContentSuccess" class="alert alert-success" style="display: none;text-align: center;"></div>
  <div id="videoRequestResultModalContentWarn" class="alert alert-warning" style="display: none;text-align: center;"></div>
  <div id="videoRequestResultModalContentError" class="alert alert-danger" style="display: none;text-align: center;"></div>
</ng-template>


<!-----------------------------------------------EVENT MEDIA MODAL---------------------------------------------->
<ng-template #viewEventMedia let-data>
  <div style="text-align: center;" *ngIf="data['fileType'] == 'image'" >
    <h1 style="font-size: 28px;">Snapshot media for event</h1>

    <div  *ngIf="data['event'].snapshots.length > 1">
      <mat-form-field appearance="fill">
        <mat-label>Snapshots's Available</mat-label>
        <mat-select [(ngModel)]="eventSnapshotSelected">
          <mat-option *ngFor="let snapshot of data['event'].snapshots; index as i" [value]="snapshot.file">
            Snapshot {{(i + 1)}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>


    <img src="{{API_URL}}/angFetchSnapshot/{{eventSnapshotSelected}}_lrg" style="width: 90%;"
           onerror="this.onerror=null; this.src='././assets/imageNotFound.png'"/>

      <a mat-flat-button href="{{API_URL}}/angFetchSnapshot/{{eventSnapshotSelected}}_lrg" target="_blank" download >
        Download Snapshot
      </a>
  </div>

  <div style="text-align: center;" *ngIf="data['fileType'] == 'video'" >
    <h1 style="font-size: 28px;">Video media for event</h1>

    <div  *ngIf="data['event'].videos2.length > 1">
      <mat-form-field appearance="fill">
        <mat-label>Video's Available</mat-label>
        <mat-select [(ngModel)]="eventVideoSelected" (selectionChange)="changeEventVideoToView()">
          <mat-option *ngFor="let video of data['event'].videos2; index as i" [value]="video.filename">
            Video {{(i + 1)}} - Channel: {{video.ch}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-spinner *ngIf="eventVideoSelectedLoading" style="margin:0 auto!important;"></mat-spinner>

    <video controls="controls"  style="width:90%;" type="video/mp4" id="EventVideo" [hidden]="eventVideoSelectedLoading"></video>

  </div>
</ng-template>

