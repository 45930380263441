<div #videoContainer id="videoContainer" style="padding:5px;width:100%;position:relative;">
  <div style="text-align:center;">

    <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
      <path fill="currentColor" d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
    </svg>
    {{cam?.camPosition}} ({{cam?.channel}})
  </div>


  <div *ngIf="loading && !playing">loading</div>

  <video #videoPlayer id="videoPlayer" style="width: 100%;max-width: 100%;"></video>

  <div *ngIf="!playing && !loading" style=" position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);text-align: center;">camera ready to stream</div>
</div>

