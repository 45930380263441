import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators, UntypedFormBuilder} from '@angular/forms';
import {AdminService} from '../admin.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute} from '@angular/router';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDialog} from '@angular/material/dialog';
import {map, switchMap} from 'rxjs/operators';
// import {SocketioService} from '../socketio.service';
import { Title } from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import {noOfChannels} from "../../lib";


@Component({
  selector: 'app-vehicle',
  templateUrl: './device-setup.component.html',
  styleUrls: ['./device-setup.component.css']
})
export class DeviceSetup implements OnInit {

  // @Input() device-setup: any;
  // @Input() allFleets: any;
  @ViewChild('camerasModal', { static: true }) camerasModal: TemplateRef<any>;
  @ViewChild('cloneDeviceModal', { static: true }) cloneDeviceModal: TemplateRef<any>;
  @ViewChild('internalTestingDialog', { static: true }) internalTestingDialog: TemplateRef<any>;
  @ViewChild('rawDataModal', { static: true }) rawDataModal: TemplateRef<any>;
  @ViewChild('eventProfileModal', { static: true }) eventProfileModal: TemplateRef<any>;

  deviceConnected: string;
  cameras: any;
  message: string;
  configsMatch: boolean;
  vehicle: any;
  deviceStatus: any;
  id: string;
  allFleets: any;
  messageClass: string;
  deviceParameters: any;
  eventProfiles: any;
  environment = environment;
  usersPermission = localStorage.getItem('authorisation');
  usersEmail = localStorage.getItem('email');
  firmwares: any;


  defaultCameraArray: any = [{channel: '1', camPosition: 'Front', model: 'C60S0V42F-2M', camConfig: 'balanced', HaveAudio: '0', isRec: '1'},
    {channel: '2', camPosition: 'Driver', model: 'C60S0V42F-2M', camConfig: 'bestStorage', HaveAudio: '0', isRec: '1'}];

  vehicleData: any;
  mflGroup = localStorage.getItem('mflGroup');
  pageLoading = true;
  showVehicleSaveButton = false;


  vehicleForm = new UntypedFormGroup({
    id: new UntypedFormControl('', [Validators.required]),
    fleetId: new UntypedFormControl('',  [Validators.required]),
    mflGroup: new UntypedFormControl(''),
    registration: new UntypedFormControl('TBC', [Validators.required]),
    driver: new UntypedFormControl(''),
    device: new UntypedFormControl('',  [Validators.required]),
    overrun: new UntypedFormControl('30',  [Validators.required]),
    overrunRecording: new UntypedFormControl('10',  [Validators.required]),
    dn: new UntypedFormControl(null),
    deviceDob: new UntypedFormControl(''),
    deviceSerial: new UntypedFormControl(''),
    deviceSIM: new UntypedFormControl(''),
    dvrUser: new UntypedFormControl('121212'),
    dvrUserPwd: new UntypedFormControl('121212'),
    deviceBarCode: new UntypedFormControl('', [Validators.required]),
    cameras: new UntypedFormArray([]),
    vehicleDvlaData: new UntypedFormControl(''),
    eventProfile: new UntypedFormControl('', [Validators.required]),
    timezone: new UntypedFormControl('london_time', [Validators.required])
  });

  // Validators.pattern('^[0-9]{7}-[0-9]{4}[A-Z]$')]




  errorsDisplay: string;

  get camerasFormArray(): UntypedFormArray {
    return this.vehicleForm.get('cameras') as UntypedFormArray;
  }




  constructor(private adminService: AdminService, private _snackBar: MatSnackBar, private route: ActivatedRoute,
              private _formBuilder: UntypedFormBuilder, private matStepper: MatStepperModule, private dialog: MatDialog,
               private titleService: Title) {}


  openSnackBar(message: string, action: string): void {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }







  ngOnInit(): void {


    this.adminService.firmwares({}).subscribe(r => {
      this.firmwares = []
      r.forEach(fw => {
        this.firmwares.push(fw.appVersion)
      });
    })



    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.vehicleForm.controls.id.setValue(this.id);

    });

    this.adminService.eventProfiles({}).subscribe(r => {
      this.eventProfiles = r;
    });

    this.adminService.fleets({}).subscribe(result => {
      this.allFleets = result;
    });

    this.getVehicle();
    this.vehicleForm.controls.deviceBarCode.valueChanges.subscribe(value => {

      // console.log(value + ' ' + this.vehicleForm.controls.deviceBarCode.valid);

      if ( this.vehicleForm.controls.deviceBarCode.valid ) {

        const str = this.vehicleForm.controls.deviceBarCode.value;

        if (str.trim().length > 1){
          this.vehicleForm.controls.dn.setValue(value.split('-')[0]);
          this.vehicleForm.controls.deviceDob.setValue(value.split('-')[1]);
        }

      }
    });

    this.titleService.setTitle('Device Setup');

    this.onChanges();


    setTimeout(() => this.pageLoading = false, 0);


  }
  ngAfterViewInit(): void{

  }



  getVehicle() {
    this.adminService.vehicle({id: this.id}).pipe(
      map(result => {

        this.deviceParameters = result.deviceParameters;
        this.vehicle = result;
        this.camerasFormArray.clear();

        if (this.vehicle.dvrUser === '') {
              this.vehicle.dvrUser = '121212';
            }

        if (this.vehicle.dvrUserPwd === '') {
              this.vehicle.dvrUserPwd = '988889';
            }

        if (typeof this.vehicle.device === 'undefined') {
              this.vehicle.device = 'ME41-02';
            }

        this.vehicleForm.patchValue(this.vehicle);



        if (typeof this.vehicle.cameras !== 'undefined' && this.vehicle.cameras.length > 0) {
          this.vehicle.cameras.forEach(cam => {

            if (!cam.HaveAudio){
              cam.HaveAudio  = '0';
            }

            if (!cam.isRec){
               cam.isRec = '1';
            }




            this.camerasFormArray.push( new UntypedFormGroup({
              channel: new UntypedFormControl(cam.channel, [Validators.required]),
              camPosition: new UntypedFormControl(cam.camPosition, [Validators.required]),
              model: new UntypedFormControl(cam.model, [Validators.required]),
              camConfig: new UntypedFormControl(cam.camConfig, [Validators.required]),
              HaveAudio: new UntypedFormControl(cam.HaveAudio, [Validators.required]),
              isRec: new UntypedFormControl(cam.isRec, [Validators.required])
            }));

          });
        } else {
          this.addDefaultCameras();
        }
        if (typeof this.vehicle.deviceParameters !== 'undefined' && typeof this.vehicle.config !== 'undefined') {
              // console.log(this.isSubset(this.vehicle.deviceParameters, this.vehicle.config));
              this.configsMatch = this.isSubset(this.vehicle.deviceParameters, this.vehicle.config);
        }
      })).subscribe(() => {

        if (this.vehicle.dn){
          this.adminService.deviceStatus({dn: this.vehicle.dn}).subscribe(result2 => {
            if (result2.length > 0){
              this.deviceStatus = result2[0].alarmData.statusData;
            }
          });
          this.adminService.isDeviceConnected({dn: this.vehicle.dn}).subscribe(result3 => {
            this.deviceConnected = result3;
          });
        }
    });
   }

  readableConfig(obj): string {
    const txt = obj.camConfig.replace(/([a-z])([A-Z])/g, '$1 $2');

    let returnString = txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();

    if (obj.HaveAudio === '1'){
      returnString += ' - Audio';
    } else {
      returnString += ' - No Audio';
    }
    return returnString;

  }

  addCamera(): void {
    const newChan = (this.camerasFormArray.controls.length + 1);

    if (newChan <= parseInt(noOfChannels(this.vehicle?.device))) {
      this.camerasFormArray.push( new UntypedFormGroup({
        channel: new UntypedFormControl(newChan.toString(), Validators.required),
        camPosition: new UntypedFormControl('', [Validators.required]),
        camConfig: new UntypedFormControl('', [Validators.required]),
        HaveAudio: new UntypedFormControl('', [Validators.required]),
        isRec: new UntypedFormControl('', [Validators.required])
      }));
    }
  }

  removeCamera(index: number): void {
    this.camerasFormArray.removeAt(index);
  }



  onFormSubmit(): void {
    this.pageLoading = true;

    this.vehicleForm.value.id = this.id;
    this.vehicleForm.value.registration = this.vehicleForm.value.registration.toUpperCase();
    this.vehicleForm.value.deviceBarCode.toUpperCase().replace(' ', '');
    this.vehicleForm.value.dn.replace(' ', '');

    this.allFleets.forEach(fleet => {
      if (fleet.fleetId === this.vehicleForm.value.fleetId){
        this.vehicleForm.patchValue({mflGroup: fleet.mflGroup});
      }
    });



    this.adminService.updateVehicle(this.vehicleForm.value).subscribe(result => {
        console.log(result);

        if (result.modifiedCount === 1){
          this.message = 'Device updated';
          this.messageClass = 'good';
        } else if (result.upsertedCount === 1) {
          this.message = 'Device created';
          this.messageClass = 'good';
        } else if (result.name === 'MongoError') {
          this.message = 'Device number already exists';
          this.messageClass = 'warning';
        } else {
          this.message = 'Device not updated';
          this.messageClass = 'warning';
        }

        this.openSnackBar(this.message, '');

        this.getVehicle();
        this.pageLoading = false;
    });
  }

  lookupRegistration(): void {
    const data = this.vehicleForm.value.registration.trim().toLocaleLowerCase().split(' ').join('');

    this.adminService.getVehicleData(data).subscribe(result => {
      if (result.Response.StatusCode == 'Success'){
        this.vehicleData = result.Response.DataItems;
        if (this.vehicleForm.controls.vehicleDvlaData) {
          this.vehicleForm.controls.vehicleDvlaData.patchValue(this.vehicleData);
          this.showVehicleSaveButton = true;
        } else {
          this.vehicleForm.controls.vehicleDvlaData.patchValue({});
        }
      }
    });
  }


  showCameraForm(): void {
    this.dialog.open(this.camerasModal,  { closeOnNavigation: true, hasBackdrop: true});

  }

  getAllParameters(): void {
    this.adminService.getAllParameters({Dn: this.vehicle.dn}).subscribe();
  }


  beginInternalTesting(): void {
    this.adminService.triggerUnderSpeedAlarm({dn: this.vehicle.dn}).pipe(
      map(result => {
        if (result){
          return this.vehicle.dn;
        }
      }),
      switchMap(dn => this.adminService.deviceStatus({dn})),
      map(result2 => {
        if (result2.length > 0){
          this.deviceStatus = result2[0].alarmData.statusData;

          switch (this.deviceStatus.networkType){
            case '?': {
              this.deviceStatus.networkTypeReadable = '?';
              break;
            }
            case 1: {
              this.deviceStatus.networkTypeReadable = 'wired';
              break;
            }
            case 2: {
              this.deviceStatus.networkTypeReadable = 'WI-FI';
              break;
            }
            case 3: {
              this.deviceStatus.networkTypeReadable = '2G';
              break;
            }
            case 4: {
              this.deviceStatus.networkTypeReadable = '3G';
              break;
            }
            case 5: {
              this.deviceStatus.networkTypeReadable = '4G';
              break;
            }
            case 6: {
              this.deviceStatus.networkTypeReadable = '5G';
              break;
            }
          }
        }
        console.log(result2);
        return this.id;

      }), switchMap(id => this.adminService.vehicle({id})),
      map(result3 => {
        if (result3){
          this.vehicle = result3;
        }
      })).subscribe();


    this.dialog.open(this.internalTestingDialog, { closeOnNavigation: true, width: '60%', hasBackdrop: true});
  }

  markDeviceTested(): void {

    const data = {
      dn: this.vehicle.dn,
      'commissioning.commissionStatus': 'tested'
    };

    this.adminService.commissionVehicle(data).subscribe(result => {});

    const data2 =
      {
        id: this.id,
        note: 'Device has been tested and marked as ready for install'
      };

    this.adminService.updateVehicleNotes(data2).subscribe(result => {
      this.dialog.closeAll();
      this.ngOnInit();

    });

  }

  saveRegistrationDetails(): void {
    this.pageLoading = true;
    this.vehicleForm.value.id = this.id;
    this.vehicleForm.value.registration = this.vehicleForm.value.registration.toUpperCase();

    this.adminService.updateVehicle(this.vehicleForm.value).subscribe(result => {
      this.pageLoading = false;
    });
  }

  onChanges(): void {
    this.vehicleForm.valueChanges.subscribe(val => {

      const invalid = [];
      const controls = this.vehicleForm.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
      if (invalid.length >= 1){
        if (invalid.includes('cameras')){
          this.errorsDisplay = 'Check all camera fields are completed';
        }
        if (invalid.includes('deviceDob')){
          this.errorsDisplay = 'Check device-setup Dob is completed';
        }
        if (invalid.includes('deviceSerial')){
          this.errorsDisplay = 'Check device-setup serial is completed';
        }
      } else {
        this.errorsDisplay = '';
      }
      console.log(invalid);
    });
  }
  openCloneDeviceModal(): void {
    this.dialog.open(this.cloneDeviceModal, {hasBackdrop: true});
  }

  openRawDataModal(): void{
    this.dialog.open(this.rawDataModal, {hasBackdrop: true, maxHeight: '80vh', maxWidth: '80vw', height: '80vh', width: '80vw'});
  }
  openEventProfileModal(): void{
    this.dialog.open(this.eventProfileModal, {hasBackdrop: true});
  }


  confirmCloning(): void {

    this.adminService.addVehicle({}).subscribe(result => {
      const theClone = {
        dvrUser: '121212',
        dvrUserPwd: '988889',
        device: this.vehicleForm.controls.device.value,
        fleetId: this.vehicleForm.controls.fleetId.value,
        overrun: this.vehicleForm.controls.overrun.value,
        overrunRecording: this.vehicleForm.controls.overrunRecording.value,
        cameras: this.vehicleForm.controls.cameras.value,
        id: result.insertedId
      };
      this.adminService.updateVehicle(theClone).subscribe( result2 => {
        window.open('/device-setup/' + theClone.id, '_blank').focus();
        this.dialog.closeAll();
      });
    });
  }


  updateMirror(mirror, ch): void {
    const data =
      {
        dn: this.vehicleForm.controls.dn.value,
        ch: ch.toString(),
        mirror: mirror
      };


    this.adminService.updateMirror(data).subscribe(result => {
      console.log(result);
    });
  }

  addDefaultCameras(): void {
    this.defaultCameraArray.forEach(cam => {
      this.camerasFormArray.push( new UntypedFormGroup({
        channel: new UntypedFormControl(cam.channel, [Validators.required]),
        camPosition: new UntypedFormControl(cam.camPosition, [Validators.required]),
        camConfig: new UntypedFormControl(cam.camConfig, [Validators.required]),
        HaveAudio: new UntypedFormControl(cam.HaveAudio, [Validators.required]),
        isRec: new UntypedFormControl(cam.isRec, [Validators.required])
      }));
    });
  }


  isSubset = (superObj, subObj) => {
    try {
      return Object.keys(subObj).every(ele => {
        if (typeof subObj[ele] === 'object') {
          return this.isSubset(superObj[ele], subObj[ele]);
        }
        return subObj[ele] === superObj[ele];
      });
    } catch (err){
      console.log(err);
    }
  }

  validFirmware(firmware) {
    return this.firmwares.includes(firmware);
  }

  benchTestDisabled(){
    let disabled = false;
    if (!this.firmwares.includes(this.vehicle.appVersion) || (this.deviceStatus.HardDiskSizeMb < 1000) || (this.deviceStatus.satellitesQuantity < 4)){
      disabled = true;
    }
    return disabled;
  }

  modelNumberDisplay(no){
    let display;
    if (no === 'ME41-02'){
      display = 'MFL-2CN4H01-EU';
    } else {
      environment.MODEL_NUMBER_ARRAY.forEach(model => {
        if (model.modelNoDb === no){
          display = model.modelNoDisplay;
        }
      });
    }
    return display;
  }



}




