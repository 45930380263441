import {
  trigger,
  state,
  style,
  animate,
  transition, animateChild, query, group,
  // ...
} from '@angular/animations';


export const Animations = {


  animeTrigger: trigger('openClose', [
    state('open', style({
      left: '-9px'
    })),
  state('closed', style({
                         left: '-310px'
                        })),
transition('open => closed',
  group([
    animate('0.1s ease'),
    // query('@inner', animateChild(), {optional: true})
  ])),
  transition('* => open',
    group([
      animate('0.5s ease'),
      // query('@openClose', animateChild(), {optional: true})
    ])),
]),

  animeTrigger2: trigger('inner', [
  state('open', style({
   transform: 'rotate(0)'
  })),
  state('closed', style({
    transform: 'rotate(180deg)'
  })),
  transition('open => closed', [
    animate('1s')
  ]),
  transition('closed => open', [
    animate('1s')
  ]),
]),



};

