import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as mapboxgl from 'mapbox-gl';
import {Observable} from 'rxjs';
// import {TestPostType} from './multiLivestream/testPostType';
import {formatDate} from '@angular/common';
import { environment } from '../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class VehiclesService {


  constructor(private http: HttpClient) {
    mapboxgl.accessToken = environment.mapbox.accessToken;

  }

  getJourney(journey: any): Observable<any> {
    // return this.http.post<any[]>('http://howenfront.tcstaging.co.uk/api/MapBoxMapMatch/mapMatchRecPointTest', journey, httpOptions)
    return this.http.get<any[]>(`${environment.API_URL}/journey/${journey._id}`);

  }





  reverseGeocode(coords: any): Observable<any> {
    return this.http.post<any[]>('https://a2.tcstaging.co.uk/api/MapBoxMapMatch/reverseGeocodeResult', coords, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );



    // return  this.http.get<any[]>('https://a2.tcstaging.co.uk/api/test.json');

  }








  getJourneys(vehicleData: any): Observable<any> {

    // return this.http.post<any[]>('http://a2.tcstaging.co.uk/api/Vehicles/vehicleJourneys', vehicleDate, httpOptions)

    // return this.http.post<any[]>('http://howenfront.tcstaging.co.uk/api/Vehicles/vehicleJourneys', vehicleData, httpOptions)
      //  .pipe(
            // catchError(this.handleError('addHero', hero))
      //  );

    console.log(vehicleData);

    const format = 'yyyy-MM-dd';
    const locale = 'en-US';
    const date = formatDate(vehicleData.date, format, 'en-US');

    console.log(date);

    return  this.http.get<any[]>(`${environment.API_URL}/vehicleJourneys/${vehicleData.dn}/${date}`);

  }



  getVideoRequest(vehicleDate: any): Observable<any> {
    // ************** https://www.myfleetlive.org:8000/playbackRequest  !!!! need to move over some node to node request maybe ???
    return this.http.post<any[]>('http://howen.tcstaging.co.uk:8081/playbackRequest', vehicleDate, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }



  generateEvent(vehicleDate: any): Observable<any> {
    return this.http.post<any[]>(`${environment.API_URL}/generateEvent`, vehicleDate, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }






/*  getEvents(vehicleDate: any): Observable<any> {
    return this.http.post<any[]>('http://howenfront.tcstaging.co.uk/api/Vehicles/GetEvents', vehicleDate, httpOptions)
        .pipe(
            // catchError(this.handleError('addHero', hero))
        );
  }*/


  getEvents(data: any): Observable<any> {

    return this.http.post<any[]>(`${environment.API_URL}/events-plus-telematics`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }






/*  getTestEventsFromJson(): Observable<any[]> {
    return  this.http.get<any[]>('http://a2.tcstaging.co.uk/api/test.json');
  }*/


/*  getVideosByTimeByDevice(): Observable<any[]> {
    return  this.http.get<any[]>('http://a2.tcstaging.co.uk/api/test.json');
  }*/




  getVehicles(): Observable<any[]> {
    // return  this.http.get<any[]>('http://vtapi-live.vision-tdx.com/api/GetJourneyDetails/V2MA41600040/2019-03-27/2019-03-28',
    // return  this.http.get<any[]>('http://a2.tcstaging.co.uk/api/testGoogle2.json');
    // return  this.http.get<any[]>('http://a2.tcstaging.co.uk/api/Vehicles/getVehicles');
    // http://howenfront.tcstaging.co.uk/api/Vehicles/getVehicles
    // TODO This returns all vehicles - need fleet by fleet only for certain users
    return this.http.get<any[]>(`${environment.API_URL}/vehicles`);



/*    for (let i = 0; i < vehicles.length; i++) {

      const feature = {type: 'Feature',
        properties: {
          head: data.tracks[i].head,
          colour: '#8b0000',
          speed: Math.round(data.tracks[i].speed  / 100 * 0.621371),    // to mph,
          distance: cumDistance,
          time: data.tracks[i].at
        },
        geometry: {
          type: 'Point',
          coordinates: [data.tracks[i].lng, data.tracks[i].lat]
        }

      };

      result.features.push(feature);
    }*/





  }

/*  getAllCurrencies(): Observable<CurrencyInfo[]> {
    return this.allCurrencies$;
  }
}*/



  showTodayDate(): Observable<any[]> {
    return  this.http.get<any[]>('http://a2.tcstaging.co.uk/api/test.json');
  }


  //////////////////////////////////////


/*  public sendSubscriptionToTheServer(subscription: PushSubscription, id: any) {
    return this.http.post(`${environment.API_URL}/subscription`, {subscription, id});
  }*/



  //////////////////////////////////



}
