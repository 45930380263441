import {Component, Input, AfterViewInit} from '@angular/core';
import { isADate } from 'src/lib';
import mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-tracking-summary',
  templateUrl: './tracking-summary.component.html',
  styleUrls: ['./tracking-summary.component.css']
})
export class TrackingSummaryComponent implements AfterViewInit {

  @Input() deviceDetails: any;
  @Input() deviceConnected: any;
  environment = environment;
  isADate = isADate;
  map2: mapboxgl.Map;


  constructor() { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.deviceDetails && this.deviceDetails.lastLocation?.lng && this.deviceDetails?.lastLocation?.lat){
        if (document.getElementById('deviceStatusMapElement')){
          this.showDeviceStatusMap();
        }
      }
    }, 1000);
  }

  showDeviceStatusMap(): void {

    mapboxgl.accessToken = environment.mapbox.accessToken;

    this.map2 = new mapboxgl.Map({
      container: 'deviceStatusMapElement',
      style: 'mapbox://styles/fleetfocus/cjvxkhjay5ti71dqa1lldx82p?optimize=true',
      zoom: 12,
      center: [this.deviceDetails.lastLocation?.lng, this.deviceDetails.lastLocation?.lat]
    });
    // Add map controls
    this.map2.addControl(new mapboxgl.NavigationControl());

    new mapboxgl.Marker()
      .setLngLat([this.deviceDetails.lastLocation?.lng, this.deviceDetails.lastLocation?.lat])
      .addTo(this.map2);
  }

}
