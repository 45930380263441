<div class="loginPageContainer">

    <mat-card class="loginForm">

      <form [formGroup]="loginForm" (ngSubmit)="loginUser()">
        <img class="loginPageBrandLogo" src="assets/svgs/logo-myfleetlive.svg"/>
        <h2 class="loginFormTitle">Please Log In</h2>
        <mat-icon> email</mat-icon>&nbsp;
        <mat-form-field class="loginFormInput">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Enter email" type="email" formControlName="email" required>
        </mat-form-field>
        <br>
        <mat-icon>lock</mat-icon>&nbsp;
        <mat-form-field class="loginFormInput">
          <mat-label>Password</mat-label>
          <input matInput placeholder="Enter password" type="password" formControlName="password" required>
        </mat-form-field>
        <br>
        <button type="submit" mat-raised-button color="primary">
          <mat-icon>login</mat-icon>
          &nbsp;Login
        </button>

      </form>

      <br>
      <mat-error *ngIf="msg">
        {{msg}}
      </mat-error>
    </mat-card>
</div>
