import {Component, OnInit, Input, AfterViewInit} from '@angular/core';
import {AdminService} from "../admin.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-view-snapshot',
  templateUrl: './view-snapshot.component.html',
  styleUrls: ['./view-snapshot.component.css']
})
export class ViewSnapshotComponent implements AfterViewInit {

  @Input() filename: any;
  base64Image: any;

  constructor(private adminService: AdminService, private sanitizer: DomSanitizer) { }

  ngAfterViewInit(): void {
    if (this.filename){
      this.adminService.viewSnapshot(this.filename + '_sm').then(image => {

        this.base64Image = URL.createObjectURL(image);
        // console.log(image)
        // if (image) {
        //   const reader = new FileReader();
        //   reader.readAsDataURL(image);
        //   reader.onloadend  = () => {
        //     const base64data = reader.result;
        //
        //     if (base64data.toString().startsWith('data:text/plain')) {
        //       this.base64Image = '././assets/eventPlaceholder.jpg';
        //     } else {
        //       this.base64Image = base64data;
        //     }
        //   };
        // }
      });
    }
  }

  getSantizeUrl(url: string): any {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}
