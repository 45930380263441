import {Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {AdminService} from '../admin.service';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import { environment } from 'src/environments/environment';
// import {SocketioService} from '../socketio.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import mapboxgl from 'mapbox-gl';
import {forkJoin} from 'rxjs';
import { LocationStrategy } from '@angular/common';
import { NotificationsService } from '../notifications.service';
import {DataSharingService} from '../data-sharing.service';
import { EventProfileModalComponent } from './event-profile-modal/event-profile-modal.component';
import {
  getNetworkTypeReadable,
  isSubset,
  modelNumberDisplay,
  validFirmware,
  isADate,
  isDeviceOnline,
  isDeviceParked,
  noOfChannels
} from '../../lib';


@Component({
  selector: 'app-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.css'],
  providers: [DatePipe]
})
export class DeviceComponent implements OnInit {

  @ViewChild('commissoningDialog', { static: true }) commissoningDialog: TemplateRef<any>;
  @ViewChild('settingsDialog', { static: true }) settingsDialog: TemplateRef<any>;
  @ViewChild('eventLocationMapModal') eventLocationMapModal: TemplateRef<any>;
  @ViewChild('cloneDeviceModal', { static: true }) cloneDeviceModal: TemplateRef<any>;
  @ViewChild('internalTestingDialog', { static: true }) internalTestingDialog: TemplateRef<any>;
  @ViewChild('confirmChangesModal', { static: true }) confirmChangesModal: TemplateRef<any>;

  isDeviceOnline = isDeviceOnline;
  isDeviceParked = isDeviceParked;
  Object = Object;
  dn: string;
  deviceStatus: any;
  config: any;
  deviceParameters: any;
  deviceParametersRaw: any;
  allFleets: any;
  cameras: any;
  cameraProfiles: any;
  appVersion: string;
  deviceConnected = false;
  vehicle: any;
  configsMatch: boolean;
  gSensorCalibratePass: boolean;
  commissioning: any;
  calendar: any;
  events: any;
  videoBudget; any;
  notes: any;
  deviceStatusLoading = true;
  usersGroup = localStorage.getItem('mflGroup');
  usersPermission = localStorage.getItem('authorisation');
  usersEmail = localStorage.getItem('email');
  // style = 'mapbox://styles/fleetfocus/cjvxkhjay5ti71dqa1lldx82p?optimize=true';
  map: mapboxgl.Map;
  map2: mapboxgl.Map;
  pageLoading = true;
  API_URL = environment.API_URL;
  frontCamOrientationHelp = false;
  driverCamOrientationHelp = false;
  theVehicleSettings: any;
  vehicleRegistration: string;
  vehicleFleet: string;
  requestSnapshotFlag = false;
  snapshotMessageToListen: any;
  snapshotChannelRequested: any[];
  numberOfStatusUpdatesForHealth = 20;
  healthGraphRawData: any;
  showButtons: 0;
  disableUpdateButton: boolean;
  disableCalibrateGButton: boolean;
  eventProfiles: any;
  note = new UntypedFormControl();
  message: string;
  errorsDisplay: any;
  environment = environment;
  baseHref: string;
  selfCertifyDeviceSensorChecks = false;
  vehicleData: any;
  mflGroup = localStorage.getItem('mflGroup');
  showVehicleSaveButton = false;
  ignitionPass: boolean;
  theCloneDnError = false;
  initalFormValue: any;
  trackChanges: any;
  snaps: any;
  availableChannels: any;
  firmwares: any;

  engineers = [
    'A Customer', 'LEX', 'NOVA', 'AUTO AIR',  'CBS',
    'QUARTIX ENGINEER', 'RAM ENGINEER', 'BANLAW SYSTEMS', 'AJT', 'Motormatics',
     'Cornwall Fleet Solutions',  'The FFS Group', 'Vanguard Automotive installations',
    'Intellistall ', 'MobileValley ',
    'Commcare',   'Brooks Installations ',
    'Trade Security', 'Autotech Installations', 'C H Sounds and Security',
    'E Tell Installations', 'PVI LTD', 'A.S Vehicle Installation Solutions', 'BETTER CALL JOHN LTD',
    'A S Vehicle Installation Solutions', 'Vision Fleet Auto Installations', 'A&M Auto Installation Ltd',
    'SOUTH MANCHESTER INSTALLATIONS', 'Gloucester Auto Electrical Service'
  ];

  defaultCameraArray: any;
  theCloneDn: any;


  get camerasFormArray(): UntypedFormArray {
    return this.commissionForm.get('cameras') as UntypedFormArray;
  }

  get camerasSettingsFormArray(): UntypedFormArray {
    return this.vehicleForm.get('cameras') as UntypedFormArray;
  }

  get camerasTestedFormArray(): UntypedFormArray {
    return this.testedForm.get('cameras') as UntypedFormArray;
  }

  changeStatusForm = new UntypedFormGroup({
    commissionStatus: new UntypedFormControl('', [Validators.required]),
  });

  commissionForm = new UntypedFormGroup({
    installEngineer: new UntypedFormControl('', [Validators.required]),
    installDate: new UntypedFormControl(new Date(),  [Validators.required]),
    testBenchDate: new UntypedFormControl(new Date(),  [Validators.required]),
    signedOffBy: new UntypedFormControl(localStorage.getItem('email')),
    gSensorCalibratePass: new UntypedFormControl(false, [Validators.requiredTrue]),
    ignitionPass: new UntypedFormControl(false, [Validators.requiredTrue]),
    gpsPass: new UntypedFormControl(false, [Validators.requiredTrue]),
    cameras: new UntypedFormArray([
    ])
  });

  testedForm = new UntypedFormGroup({
    testBenchDate: new UntypedFormControl(new Date(),  [Validators.required]),
    signedOffBy: new UntypedFormControl(localStorage.getItem('email')),
    gpsPass: new UntypedFormControl(false, [Validators.requiredTrue]),
    cameras: new UntypedFormArray([
    ])
  });

  vehicleForm = new UntypedFormGroup({
    fleetId: new UntypedFormControl('',  [Validators.required]),
    mflGroup: new UntypedFormControl(''),
    driver: new UntypedFormControl(''),
    device: new UntypedFormControl('',  [Validators.required]),
    dn: new UntypedFormControl(null),
    // deviceDob: new UntypedFormControl(''),
    deviceSerial: new UntypedFormControl(''),
    deviceSIM: new UntypedFormControl(''),
    dvrUser: new UntypedFormControl('121212'),
    dvrUserPwd: new UntypedFormControl('121212'),
    deviceBarCode: new UntypedFormControl(''),
    cameras: new UntypedFormArray([]),
    vehicleDvlaData: new UntypedFormControl(),
    commissioning: new UntypedFormGroup({
      installEngineer: new UntypedFormControl(''),
      commissionStatus: new UntypedFormControl(''),
      installDate: new UntypedFormControl(new Date()),
      testBenchDate: new UntypedFormControl(new Date()),
      signedOffBy: new UntypedFormControl(localStorage.getItem('email')),
      gSensorCalibratePass: new UntypedFormControl(false),
      ignitionPass: new UntypedFormControl(false),
      gpsPass: new UntypedFormControl(false),
      cameras: new UntypedFormArray([])
    }),
    eventProfile: new UntypedFormControl('', [Validators.required]),
    preventGSensorEvents: new UntypedFormControl(false),
    eventProfileModifier: new UntypedFormControl(100),
    // registration: new UntypedFormControl('', [Validators.required, Validators.pattern("^(?!\s)[a-z\\d ]{1,10}$")]),
    registration: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]{1,11}$")]),
    overrun: new UntypedFormControl('',  [Validators.required]),
    overrunRecording: new UntypedFormControl('',  [Validators.required]),
    timezone: new UntypedFormControl('', [Validators.required])
  });

  modelNumberDisplay = modelNumberDisplay;
  validFirmware = validFirmware;
  isADate = isADate;

  constructor(private route: ActivatedRoute, private adminService: AdminService, public dialog: MatDialog,
              private datePipe: DatePipe, private snackBar: MatSnackBar, private notificationsService: NotificationsService,
              private titleService: Title, private router: Router, private locationStrategy: LocationStrategy) {
  }

  ngOnInit(): void {
      this.baseHref = this.locationStrategy.getBaseHref();
      window.addEventListener('scroll', this.onPageScroll);

      this.requestSnapshotFlag = false;

      // watch for camera commissioning checks changes to validate form
      // this.camerasFormArray.valueChanges.subscribe(() => {
      //   this.commissioningCameraCheckComplete();
      // });

      if (this.usersGroup !== 'undefined'){
        this.commissionForm.patchValue({installEngineer: this.usersGroup + ' Engineer'});
      }

      this.engineers.sort();
      this.camerasFormArray.clear();
      this.camerasTestedFormArray.clear();
      this.camerasSettingsFormArray.clear();

      this.route.paramMap.subscribe(params => {

        this.dn = params.get('dn');
        this.titleService.setTitle('Connect - Device ' + this.dn);

        forkJoin([
          this.adminService.fleets({fleetId: {$ne: null}, active: {$in: [1, null]}}),
          this.adminService.calendar({dn: this.dn}),
          this.adminService.deviceHealth({dn: this.dn, limit: this.numberOfStatusUpdatesForHealth}),
          this.adminService.eventProfiles({}),
          this.adminService.firmwares({})
        ]).subscribe(r => {
          this.allFleets = r[0];

          if (r[2]){
            this.calendar = r[1];
          }

          this.healthGraphRawData = r[2];

          if (r[2]?.length > 0){
            this.ignitionPass = true;
            this.commissionForm.patchValue({ignitionPass: true})
          }
          this.eventProfiles = r[3];

          this.firmwares = []
          r[3].forEach(fw => {
            this.firmwares.push(fw.appVersion)
          });



        });
        this.getVehicle();

        setTimeout(() => {
          this.pageLoading = false;
        }, 0);


        this.vehicleForm.valueChanges.subscribe(val => {

          //update available chans
          const chnArr = Array.from(Array(parseInt(noOfChannels(this.vehicle.device))).keys()).map(function(entry) { return (entry+1).toString(); });
          const chnsUsed = this.vehicleForm?.value.cameras.reduce((acc, curVal) => acc.concat(curVal?.channel), []);
          const chnsFree = chnArr.filter(function( el ) {return chnsUsed.indexOf( el ) < 0;});
          this.availableChannels = chnsFree;


          if (this.vehicleForm?.value && this.initalFormValue){
            const diff = Object.entries(this.vehicleForm?.value)
              .filter(([key, val]) => (JSON.stringify(this.initalFormValue[key]) !== JSON.stringify(val) && key in this.initalFormValue))
              .reduce((a, [key, v]) => ({...a, [key]: v}), {});

            let changes = {
              dn: this.dn,
              changedBy: localStorage.getItem('email'),
              changedDate: new Date(),
              changes: []
            }

            let keys = Object.keys(diff);

            keys.forEach(key => {
              changes.changes.push({
                field: key,
                oldVal: this.initalFormValue[key],
                newVal: this.vehicleForm?.value[key]
              })
            })

            this.trackChanges = changes;

            this.checkForErrors();
          }

        });
      });

  }





  requestAllSnapshots(): void {
    // const dateString = this.datePipe.transform( new Date(), 'yyyy-MM-dd HH:mm:ss');
    // const snapsTest = [];
    // this.cameras.forEach(cam => {
    //   console.log(cam)
    //   const data = {
    //     callback: '',
    //     ch: cam.channel,
    //     dn: this.dn,
    //     st: dateString
    //   };
    //   const re = new RegExp('-', 'g');
    //   const re2 = new RegExp(':', 'g');
    //   const re3 = new RegExp(' ', 'g');
    //   const test = dateString.replace(re, '').replace(re2, '').replace(re3, '').trim();
    //   const filename = this.dn + '_' + test + '_' + cam.channel;
    //   this.adminService.snapshotRequest(data).subscribe((data) => {
    //     console.log(data)
    //     snapsTest.push({status: 'uploaded', file: filename})
    //   });
    // });
    // setTimeout(() => {this.snaps = snapsTest;console.log(snapsTest)}, 1000)
  }

  snapshotRequest(ch): void {
    this.requestSnapshotFlag = true;
    this.snapshotChannelRequested = [ch];
    const dateString = this.datePipe.transform( new Date(), 'yyyy-MM-dd HH:mm:ss');

    const data = {
      callback: '',
      ch,
      dn: this.dn,
      st: dateString
    };

    const re = new RegExp('-', 'g');
    const re2 = new RegExp(':', 'g');
    const re3 = new RegExp(' ', 'g');
    const test = dateString.replace(re, '').replace(re2, '').replace(re3, '').trim();
    const filename = this.dn + '_' + test + '_' + ch + '.h265';
    this.snapshotMessageToListen.push('media/' + this.dn + '/snapshot/' + filename);
    this.adminService.snapshotRequest(data).subscribe(result => {});
  }

  showOrientationHelp(camPos, $event): void {
    $event.stopPropagation();
    if (camPos === 'Front'){
      this.frontCamOrientationHelp === true ? this.frontCamOrientationHelp = false : this.frontCamOrientationHelp = true;
    } else if (camPos === 'Driver' ){
      this.driverCamOrientationHelp === true ? this.driverCamOrientationHelp = false : this.driverCamOrientationHelp = true;
    }
  }


  getAllParameters(dn): void {
    this.adminService.getAllParameters({dn}).subscribe();
  }

  getDeviceStatus(dn): void {
    this.adminService.deviceHealth({dn, limit: 1}).subscribe(result => {
      this.deviceStatusLoading = false;

      if (typeof result[0] !== 'undefined') {
        if (typeof result[0].alarmData !== 'undefined'  ) {
          this.deviceStatus = result[0].alarmData.statusData;
          this.interpretModuleWorkingStatus();
        }
      }
    });
  }

  requestUpdate(dn): void{
    this.disableUpdateButton = true;
    this.getDeviceStatus(dn);
    this.openSnackBar('Update requested from device', '');
    setTimeout(() => {this.disableUpdateButton = false; }, 2000);
  }

  restart(): void {
    const data = {dn: this.dn};
    this.adminService.restart(data).subscribe(result => {
    });
  }

  triggerUnderSpeedAlarm(): void {
    this.deviceStatusLoading = true;

    this.adminService.triggerUnderSpeedAlarm({dn: this.dn}).subscribe(result => {
      // console.log(result);
      setTimeout(() => {
        this.deviceStatusLoading = false;
      }, 2000);
    });
  }

  calibrateGSensor(): void {
    this.disableCalibrateGButton = true;
    this.adminService.calibrateGSensor({dn: this.dn}).subscribe(result => {
      // console.log(result);
      this.triggerUnderSpeedAlarm();

    });
    this.openSnackBar('G-Sensor calibrated', '');
    setTimeout(() => {this.disableCalibrateGButton = false; }, 2000);
  }

  openSnackBar(message: string, action: string): void {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }


  interpretModuleWorkingStatus(): void {

    this.gSensorCalibratePass = Math.abs(this.deviceStatus.gSensorX) < 10 &&
      Math.abs(this.deviceStatus.gSensorY) < 10 && Math.abs(this.deviceStatus.gSensorZ) < 10 &&
      this.deviceStatus.gSensorX !== null && this.deviceStatus.gSensorY !== null && this.deviceStatus.gSensorZ !== null;

    this.commissionForm.patchValue({gSensorCalibratePass: this.gSensorCalibratePass});
    this.commissionForm.patchValue({gpsPass:  this.deviceStatus.satellitesQuantity > 4 });
    this.testedForm.patchValue({gpsPass:  this.deviceStatus.satellitesQuantity > 4 });
    const moduleBits = this.deviceStatus.ModuleWorkingStatusidentifierBit .split('').reverse();
    this.deviceStatus.mobileNetworkModule = moduleBits[0];
    this.deviceStatus.locationModule  = moduleBits[1];
    this.deviceStatus.wifiModule = moduleBits[2];
    this.deviceStatus.gSensorModule = moduleBits[3];
    this.deviceStatus.recording = moduleBits[4];
    this.deviceStatus.recordingStatusArray = this.deviceStatus.recordingStatus.split('').reverse();
    this.deviceStatus.HardDiskStatusBitArray =  this.deviceStatus.HardDiskStatusBit.split('').reverse();
    this.deviceStatus.identifierBit1Array =  this.deviceStatus.identifierBit1.split('').reverse();
    const alarmBits = this.deviceStatus.alarmStatusIdBit.split('').reverse();
    this.deviceStatus.videoLossAny = alarmBits[0];
    this.deviceStatus.motionDetectionAny = alarmBits[1];
    this.deviceStatus.videoBlindAny = alarmBits[2];
    this.deviceStatus.alarmInputTriggerAny = alarmBits[3];
    this.deviceStatus.overSpeedAlarm = alarmBits[4];
    this.deviceStatus.lowSpeedAlarm = alarmBits[5];
    this.deviceStatus.emergencyAlarm = alarmBits[6];
    this.deviceStatus.overTimeStop = alarmBits[7];
    this.deviceStatus.vibrationAlarm = alarmBits[8];
    this.deviceStatus.outGEOFencingAlarm = alarmBits[9];
    this.deviceStatus.enterGEOFencingAlarm = alarmBits[10];
    this.deviceStatus.exitLineAlarm = alarmBits[11];
    this.deviceStatus.enterLineAlarm = alarmBits[12];
    this.deviceStatus.fuelLevelAlarm = alarmBits[13];
    this.deviceStatus.videoLossArray   = this.deviceStatus.videoLoss.split('').reverse();
    this.deviceStatus.motionDetectionArray   = this.deviceStatus.motionDetection.split('').reverse();
    this.deviceStatus.videoBlindArray   = this.deviceStatus.videoBlind.split('').reverse();
    this.deviceStatus.alarmInputTriggerArray   = this.deviceStatus.alarmInputTrigger.split('').reverse();

    if (Object.prototype.toString.call(new Date(this.deviceStatus.deviceTime)) === '[object Date]') {
      if (isNaN(new Date(this.deviceStatus.deviceTime).getTime())) {  // d.valueOf() could also work
        this.deviceStatus.timeSinceUpdateShort = 'some time';
      } else {
        const secondsSinceUpdate = ((new Date().getTime() - new Date(this.deviceStatus.deviceTime).getTime()) / 1000);
        const d = Math.floor(secondsSinceUpdate / (3600 * 24));
        const h = Math.floor(secondsSinceUpdate % (3600 * 24) / 3600);
        const m = Math.floor(secondsSinceUpdate % 3600 / 60);
        const s = Math.floor(secondsSinceUpdate % 60);
        const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : '';
        const hDisplay = h > 0 ? h + (h === 1 ? ' hour and ' : ' hours and ') : '';
        const mDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes ') : '';
        this.deviceStatus.timeSinceUpdateDetail = dDisplay + hDisplay + mDisplay;
        // this.deviceStatus.timeSinceUpdateShort = this.deviceStatus.timeSinceUpdateDetail.match('[a-z0-9]+(\\s+[a-z0-9]+)?')[0];
      }
    }


    if (this.deviceStatus.networkType === '?' || this.deviceStatus.networkType === 3){
      // ? or 2g
      this.deviceStatus.signalTrafficLight = 'weak';
    } else if (this.deviceStatus.networkType === 4) {
      // 3g
      if (this.deviceStatus.signalIntensity <= 5){
        this.deviceStatus.signalTrafficLight = 'weak';
      } else {
        this.deviceStatus.signalTrafficLight = 'average';
      }
    } else {
      // all others
      if (this.deviceStatus.signalIntensity < 5){
        this.deviceStatus.signalTrafficLight = 'weak';
      } else if (this.deviceStatus.signalIntensity >= 5 && this.deviceStatus.signalIntensity < 7) {
        this.deviceStatus.signalTrafficLight = 'average';
      } else {
        this.deviceStatus.signalTrafficLight = 'good';
      }
    }


    this.deviceStatus.networkTypeReadable = getNetworkTypeReadable(this.deviceStatus.networkType);



    // this.commissionForm.patchValue({camerasFormArray: {orientation: 'balls'}});
    this.camerasFormArray.controls.forEach(c => {
      const ch = (parseInt(c.value.channel) - 1);
      let recordingValue = false;

      if (this.deviceStatus.recordingStatusArray[ch] === '1' && this.deviceStatus.videoLossArray[ch] !== '1') {
        recordingValue = true;
      }

      c.patchValue({recording: recordingValue});
    });
    this.commissionForm.markAllAsTouched();


    this.camerasTestedFormArray.controls.forEach(c => {
      const ch = (parseInt(c.value.ch) - 1);
      let recordingValue = false;

      if (this.deviceStatus.recordingStatusArray[ch]  === '1' && this.deviceStatus.videoLossArray[ch] !== '1') {
        recordingValue = true;
      }

      c.patchValue({recording: recordingValue});
    });


    this.camerasTestedFormArray.markAllAsTouched();
    this.deviceStatusLoading = false;
  }


  openCommissioningForm(): void {
    this.selfCertifyDeviceSensorChecks = false;

    this.requestAllSnapshots();

    let options = {};
    if (this.commissioning.commissionStatus === 'active'){
      options = { closeOnNavigation: true, hasBackdrop: true };
    } else {
      options = { closeOnNavigation: true, width: '60%', height: '95%', hasBackdrop: true };
    }
    this.dialog.open(this.commissoningDialog, options);
  }

  // commissioning validation for submission
  commissioningFinalCheckComplete(): boolean {
    let disabled = false;
    if (!this.commissionForm.valid){
      disabled = true;
    } else {
      // console.log(this.commissionForm);
    }

    return disabled;
  }

  addNote(): void {

    const data =
      {
        dn: this.vehicle.dn,
        note: this.note.value
      };


    this.note.reset();

    this.adminService.updateVehicleNotes(data).subscribe(result => {
      this.ngOnInit();
    });
  }

  lookupRegistration(): void {
    const data = this.vehicleForm.value.registration.trim().toLocaleLowerCase().split(' ').join('');

    this.adminService.getVehicleData(data).subscribe(result => {
      if (result.Response.StatusCode === 'Success'){
        this.vehicleData = result.Response.DataItems;
        if (this.vehicleForm.controls.vehicleDvlaData) {
          this.vehicleForm.controls.vehicleDvlaData.patchValue(this.vehicleData);
          this.showVehicleSaveButton = true;
        } else {
          this.vehicleForm.controls.vehicleDvlaData.patchValue({});
        }
      }

    });

  }



  beginInternalTesting(): void {
    this.dialog.open(this.internalTestingDialog, { closeOnNavigation: true, width: '60%', hasBackdrop: true});
  }

  markDeviceTested(): void {

    const data = {
      dn: this.vehicle.dn,
      commissioning : {commissionStatus: 'tested'}
    };

    this.adminService.commissionVehicle(data).subscribe();

    const data2 =
      {
        dn: this.vehicle.dn,
        note: 'Device has been tested and marked as ready for install'
      };

    this.adminService.updateVehicleNotes(data2).subscribe(result => {
      this.dialog.closeAll();
      this.ngOnInit();

    });

  }

  closeModal(): void {
    this.dialog.closeAll();
  }


  // saveRegistrationDetails(): void {
  //   this.pageLoading = true;
  //   this.vehicleForm.value.registration = this.vehicleForm.value.registration.toUpperCase();
  //
  //   this.adminService.updateVehicle(this.vehicleForm.value).subscribe(result => {
  //     this.pageLoading = false;
  //   });
  // }


  openCloneDeviceModal(): void {
    this.dialog.open(this.cloneDeviceModal, {hasBackdrop: true});
  }

  openEventProfileModal(): void{

    const theData = {
      vehicleForm: this.vehicleForm,
      eventProfiles: this.eventProfiles
    };

    this.dialog.open(EventProfileModalComponent, {hasBackdrop: true, data: theData});
  }

  confirmCloning(): void {


    if (this.theCloneDn?.length > 5){
      this.adminService.addVehicle({dn: this.theCloneDn}).subscribe(result => {

        this.submitSettingsForm(this.theCloneDn);
        setTimeout(() => {
          window.open('/device/' + this.theCloneDn, '_blank').focus();
          this.dialog.closeAll();
        }, 1000)

      });
    } else {
      this.theCloneDnError = true;
      setTimeout(() => this.theCloneDnError = false, 3000)
    }
  }


  getVehicle = () => {
    this.camerasSettingsFormArray.clear();

    forkJoin([
      this.adminService.deviceParameters({dn: this.dn}),
      this.adminService.deviceDetails({dn: this.dn})
    ]).subscribe(r => {
      this.deviceParameters = r[0][0].deviceParameters;
      this.deviceParametersRaw = r[0][0]
      this.config = r[0][0].config;
      this.deviceConnected = isDeviceOnline(r[1][0]?.lastHeartbeat);
      this.appVersion = r[1][0]?.appVersion;
      this.cameras = r[0][0].cameras;
      this.vehicle = r[1][0];
      this.theVehicleSettings = r[1][0];
      this.vehicleRegistration = r[1][0].registration;
      this.vehicleFleet = r[1][0].fleetId;
      this.commissioning = r[1][0].commissioning;
      this.notes = r[1][0].notes;


      if (this.vehicle.fleetId){
        this.adminService.videoBudget({dn: this.dn}).subscribe(r => {
          this.videoBudget = r;
        });
      }


      if (this.cameras?.length > 0){
        const chnArr = Array.from(Array(parseInt(noOfChannels(r[0][0].device))).keys()).map(function(entry) { return (entry+1).toString(); });
        const chnsUsed = this.cameras.reduce((acc, curVal) => acc.concat(curVal?.channel), []);
        const chnsFree = chnArr.filter(function( el ) {return chnsUsed.indexOf( el ) < 0;});
        this.availableChannels = chnsFree;
      }


      if (this.vehicle.dvrUser === '') {
        this.vehicle.dvrUser = '121212';
      }

      if (this.vehicle.dvrUserPwd === '') {
        this.vehicle.dvrUserPwd = '988889';
      }

      // if (typeof this.vehicle.device === 'undefined') {
      //   this.vehicle.device = 'ME41-02';
      // }

      if (typeof this.deviceParameters !== 'undefined' && typeof this.config !== 'undefined') {
        this.configsMatch = isSubset(this.deviceParameters, this.config);
      }

      this.vehicleForm.patchValue(r[0][0]);
      this.vehicleForm.patchValue({dn: this.dn});
      this.vehicleForm.patchValue({deviceBarCode: r[1][0].deviceBarCode});
      // this.vehicleForm.patchValue({deviceDob: r[1][0].deviceDob});
      this.vehicleForm.patchValue({deviceSIM: r[1][0].deviceSIM});
      this.vehicleForm.patchValue({preventGSensorEvents: r[1][0].preventGSensorEvents});
      this.vehicleForm.patchValue({device: r[1][0].device === 'ME41-02' ? '2CN4H01EU' : r[1][0].device });
      this.vehicleForm.patchValue({vehicleDvlaData: r[1][0].vehicleDvlaData });

      if (!this.vehicleForm.value.registration){
        this.vehicleForm.patchValue({registration: r?.[0]?.[0]?.config?.JTBASE?.license});
      }

      this.checkForErrors()



      // if (!this.vehicleForm.value.overrun){
      //   this.vehicleForm.patchValue({overrun: r?.[0]?.[0]?.config?.POWER?.delay});
      // }
      // if (!this.vehicleForm.value.overrunRecording){
      //   this.vehicleForm.patchValue({overrunRecording: r?.[0]?.[0]?.config?.POWER?.AccOffRecTime});
      // }


      // this.vehicleForm.patchValue({overrunRecording: this.deviceParameters?.POWER?.AccOffRecTime});
      // this.vehicleForm.patchValue({overrun: this.deviceParameters?.POWER?.delay});


      if (typeof this.commissioning === 'undefined') {
        this.commissioning = {
          commissionStatus: 'tested'
        };
      }
      if (typeof this.deviceParameters !== 'undefined' && typeof this.config !== 'undefined') {
        this.configsMatch = isSubset(this.deviceParameters, this.config);
      }

      this.getDeviceStatus(this.dn);

      this.cameraProfiles = [];
      this.cameras.forEach(cam => {
        if (cam.camPosition === 'Front'){
          this.cameraProfiles.front = {};
          this.cameraProfiles.front.config = cam.camConfig;
          this.cameraProfiles.front.ch = cam.channel;
        }
        if (cam.camPosition === 'Driver'){
          this.cameraProfiles.driver = {};
          this.cameraProfiles.driver.config = cam.camConfig;
          this.cameraProfiles.driver.ch = cam.channel;
          this.cameraProfiles.driver.HaveAudio = cam.HaveAudio;
        }

        if (cam.camPosition === 'Rear'){
          this.cameraProfiles.rear = {};
          this.cameraProfiles.rear.config = cam.camConfig;
          this.cameraProfiles.rear.ch = cam.channel;
        }

        if (!cam.HaveAudio){
          cam.HaveAudio  = '0';
        }

        if (!cam.isRec){
          cam.isRec = '1';
        }

        const formGroupEntry = new UntypedFormGroup ({
          orientation: new UntypedFormControl(false),
          recording: new UntypedFormControl(false),
          // model: new UntypedFormControl(cam.model, [Validators.required]),
          channel: new UntypedFormControl(cam.channel, [Validators.required]),
          camPosition: new UntypedFormControl(cam.camPosition, [Validators.required]),
          camConfig: new UntypedFormControl(cam.camConfig, [Validators.required]),
          HaveAudio: new UntypedFormControl(cam.HaveAudio, [Validators.required]),
          isRec: new UntypedFormControl(cam.isRec, [Validators.required]),
          Mirror: new UntypedFormControl(cam?.Mirror ? cam?.Mirror  : '0')
        })

        this.camerasFormArray.push(formGroupEntry);

        if (cam?.crop){
          formGroupEntry.addControl("crop", new UntypedFormControl(cam?.crop))
        }

        if (cam?.camPosition === "Driver"){
          formGroupEntry.addControl("driverRecognition", new UntypedFormControl(cam?.driverRecognition))
        }

        this.camerasSettingsFormArray.push(formGroupEntry)

        this.camerasTestedFormArray.push( new UntypedFormGroup({
          recording: new UntypedFormControl(false, [Validators.requiredTrue]),
          channel: new UntypedFormControl(cam.channel),
        }));

      });

      if (this.vehicle?.device && noOfChannels(this.vehicle.device) !== this.cameras?.length){
        // add cameras

        let iterations = parseInt(noOfChannels(this.vehicle.device)) - this.cameras?.length;

        const items = noOfChannels(this.vehicle?.device)

        const channelsToAssign = Array.from(Array(parseInt(items)).keys());

        this.cameras.forEach(cam => {

          const index = channelsToAssign.indexOf((parseInt(cam?.channel)-1))
          if (index > -1){
            channelsToAssign.splice(index, 1)
          }
        })

        // channelsToAssign.forEach(ch => {
        //   this.camerasSettingsFormArray.push( new UntypedFormGroup({
        //
        //     // model: new UntypedFormControl(cam.model, [Validators.required]),
        //     channel: new UntypedFormControl((ch+1).toString(), [Validators.required]),
        //     camPosition: new UntypedFormControl('None', [Validators.required]),
        //     camConfig: new UntypedFormControl('balanced', [Validators.required]),
        //     HaveAudio: new UntypedFormControl('0', [Validators.required]),
        //     isRec: new UntypedFormControl('0', [Validators.required]),
        //     Mirror: new UntypedFormControl('0')
        //   }));
        // });
      }

      this.initalFormValue = this.vehicleForm.value;
    });

  }

  tabChanged(event): void {
    this.showButtons = event.index;

  }

  arrayContains(item, array): boolean{
    if (array && array?.length > 0){
      return array.includes(item.toString());
    } else {
      return false;
    }
  }


  submitSettingsForm(dn = this.dn): void {
    this.pageLoading = true;
    this.vehicleForm.value.registration = this.vehicleForm.value.registration.toUpperCase().trim();

    if (this.vehicle.fleetId !== this.vehicleForm.value.fleetId){
      this.allFleets?.forEach(fleet => {
        if (fleet.fleetId === this.vehicleForm.value.fleetId){
          this.vehicleForm.patchValue({mflGroup: fleet.mflGroup});
        }
      });
    }



    this.dialog.closeAll();

    let data = {dn:dn}

    if (dn !== this.dn){
      data['overrunRecording'] = this.vehicleForm.value.overrunRecording;
      data['overrun'] = this.vehicleForm.value.overrun;
      data['registration'] = 'TBC';
      data['timezone'] = this.vehicleForm.value.timezone;
      data['cameras'] = this.vehicleForm.value.cameras.map(({ camPosition,model, ...rest }) => rest);


      this.vehicleForm.patchValue({dn:dn});
      this.vehicleForm.patchValue({driver:''});
      this.vehicleForm.patchValue({deviceBarCode:''});
      this.vehicleForm.patchValue({deviceSIM:''});
      this.vehicleForm.patchValue({deviceSerial:''});

    } else {
      if (this.vehicleForm.controls.overrunRecording.touched){
        data['overrunRecording'] = this.vehicleForm.value.overrunRecording
      }
      if (this.vehicleForm.controls.overrun.touched){
        data['overrun'] = this.vehicleForm.value.overrun
      }
      if (this.vehicleForm.controls.registration.touched){
        data['registration'] = this.vehicleForm.value.registration
      }
      if (this.vehicleForm.controls.timezone.touched){
        data['timezone'] = this.vehicleForm.value.timezone
      }

      if (this.vehicleForm.controls.cameras.touched){
        const changed = [];
        this.vehicleForm.value.cameras.forEach(cam => {
          const theOgCam = this.cameras.filter(og => cam.channel === og.channel)?.[0];
          if (theOgCam){
            delete theOgCam.Quality;
            const same = JSON.stringify(cam) === JSON.stringify(theOgCam);
            if (!same){
              changed.push(cam)
            }
          }
        });
        if (changed?.length > 0){
          data['cameras'] = changed.map(({ camPosition,model, recording, orientation, driverRecognition,  ...rest }) => rest);

        }
      }



      if (data?.['overrun'] || data?.['overrunRecording']){
        if (this.vehicleForm.controls.overrunRecording.touched){
          data['overrunRecording'] = this.vehicleForm.value.overrunRecording
        } else {
          data['overrunRecording'] = this.deviceParameters.POWER.AccOffRecTime;
        }
        if (this.vehicleForm.controls.overrun.touched){
          data['overrun'] = this.vehicleForm.value.overrun
        } else {
          data['overrun'] = this.deviceParameters?.POWER?.delay
        }
      }

      if (this.trackChanges?.changes?.length > 0){
        this.notificationsService.logChanges(this.trackChanges).subscribe()
      }
    }
    delete this.vehicleForm.value.overrunRecording;
    delete this.vehicleForm.value.overrun;
    delete this.vehicleForm.value.timezone;


      if (Object.keys(data)?.length > 1){
        this.adminService.updateDevice(data).subscribe(res => {
        });
      }

      this.adminService.updateVehicle(this.vehicleForm.value).subscribe((result) => {

        let message = 'Device not updated'
        if (result.modifiedCount === 1){
          message = 'Device updated';
        } else if (result.upsertedCount === 1) {
          message = 'Device created';
        } else if (result.name === 'MongoError') {
          message = 'Device number already exists';
        }


        this.getVehicle();
        this.vehicleForm.markAsUntouched();

        setTimeout(() => {
          this.pageLoading = false;
          this.openSnackBar(message, '');
          }, 1000);


      });




  }

  benchTestDisabled(): boolean{
    let disabled = false;
    if (!this.firmwares.includes(this.vehicle.appVersion || (this.deviceStatus.HardDiskSizeMb < 1000) ||
      (this.deviceStatus.satellitesQuantity < 4))){
      disabled = true;
    }
    return disabled;
  }



  scrollToTop(): void {
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  onPageScroll(): void {
    const vh = window.innerHeight;
    const y = window.scrollY;

    if (y > (vh / 4)) {
      (document.getElementById('scrollTopButtonEle') as HTMLElement).style.display = 'block';
    } else {
      (document.getElementById('scrollTopButtonEle') as HTMLElement).style.display = 'none';
    }
  }

  refreshDevice(event: boolean): void {
    this.ngOnInit();
  }

  openConfirmChangesDialog(): void {


    this.submitSettingsForm()
    // let options = {};
    // if (this.commissioning.commissionStatus === 'active'){
    //   options = { closeOnNavigation: true, hasBackdrop: true };
    // } else {
    //   options = { closeOnNavigation: true, width: '60%', height: '95%', hasBackdrop: true };
    // }
    //
    // this.dialog.open(this.confirmChangesModal, options);

  }

  // compareSettings(item) {
  //   //todo get the config property of the given property as they are not the same name
  //   //so can compare if config is different to form value
  //   let returnData;
  //
  //   if (typeof this.vehicle[item] === 'object' ){
  //     // cameras
  //     returnData = JSON.stringify(this.vehicle[item]) != JSON.stringify(this.vehicleForm.value[item])
  //   } else {
  //     switch (item){
  //       case 'overrun': returnData = this.deviceParameters?.POWER?.delay.toString() !== this.config?.POWER?.AccOffRecTime.toString(); break;
  //       case 'overrunRecording': returnData = this.deviceParameters?.POWER?.delay.toString() !== this.config?.POWER?.AccOffRecTime.toString(); break;
  //       default: returnData = this.vehicle[item].toString() !== this.vehicleForm.value[item].toString()
  //     }
  //   }
  //   return returnData;
  // }

  checkForErrors(): void {
    const invalid = []

    const controls = this.vehicleForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    this.errorsDisplay = invalid;
  }




}

