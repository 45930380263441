
<style>
  .dot {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .container {
    position: relative;
    padding-left: 20px;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: bolder;
  }

</style>


<!--<img src="././assets/img/licence-plate-arrow.png" />-->

<div *ngFor="let v of model" class="container">
  <img class="dot" src="././assets/img/lpDot.png" /> <strong>{{v.properties.reg}}</strong>

</div>


<!--
{{v.properties.drivingStatus}}
{{v.properties.head}}-->
