import { QueryList } from '@angular/core';
import { ViewChildren } from '@angular/core';
import {Component, OnInit, Input} from '@angular/core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-multiLivestream',
  templateUrl: './multiLivestream.component.html',
  styleUrls: ['./multiLivestream.component.css']
})
export class MultiLivestreamComponent implements OnInit {

  @ViewChildren('playerComponent') mycomponents: QueryList<any>;

  @Input() device;
  enviroment = environment;


  // device = {
  //   dn: '1000903',
  //   cameras: [
  //     {
  //       model: 'UT3-SIDE 1080',
  //       channel: '1',
  //       camPosition: 'Nearside',
  //       camConfig: 'legacy',
  //       HaveAudio: '0',
  //       isRec: '1'
  //     },
  //     {
  //       model: 'UT3-SIDE 1080',
  //       channel: '2',
  //       camPosition: 'Offside',
  //       camConfig: 'legacy',
  //       HaveAudio: '0',
  //       isRec: '1'
  //     },
  //     {
  //       model: 'UT3-REAR 1080',
  //       channel: '3',
  //       camPosition: 'Rear',
  //       camConfig: 'legacy',
  //       HaveAudio: '0',
  //       isRec: '1'
  //     },
  //     {
  //       model: 'C60S0V42-2MR2',
  //       channel: '4',
  //       camPosition: 'Front',
  //       camConfig: 'legacy',
  //       HaveAudio: '0',
  //       isRec: '1'
  //     }
  //   ]
  // };

  constructor() { }

  ngOnInit(): void {
  }

  startStopAll(playStop): void {
    this.mycomponents.forEach((element) => {
      if (playStop === 'play'){
        element.startStopStream(1);
      } else {
        element.startStopStream(0);
      }
    });
  }



}
