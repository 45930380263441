import { environment } from 'src/environments/environment';


// tslint:disable-next-line:typedef
export function getNetworkTypeReadable(networkType) {
  switch (networkType) {
    case '?': {
      return '?';
    }
    case 1: {
      return 'wired';
    }
    case 2: {
      return 'WI-FI';
    }
    case 3: {
      return '2G';
    }
    case 4: {
      return '3G';
    }
    case 5: {
      return '4G';
    }
    case 6: {
      return '5G';
    }
  }
}

// tslint:disable-next-line:typedef
export function isSubset(superObj, subObj){
  try {
    return Object.keys(subObj).every(ele => {
      if (typeof subObj[ele] === 'object') {
        return isSubset(superObj[ele], subObj[ele]);
      }
      return subObj[ele] === superObj[ele];
    });
  } catch (err){
    console.log(err);
  }
}


export function modelNumberDisplay(no): string{
  let display;
  if (no === 'ME41-02'){
    display = 'MFL-2CN4H01-EU';
  } else {
    environment.MODEL_NUMBER_ARRAY.forEach(model => {
      if (model.modelNoDb === no){
        display = model.modelNoDisplay;
      }
    });
  }
  return display;
}

export function noOfChannels(no): string {
  let channels;
  if (no === 'ME41-02'){
    channels = '2';
  } else {
    environment.MODEL_NUMBER_ARRAY.forEach(model => {
      if (model.modelNoDb === no){
        channels = model.channels;
      }
    });
  }
  return channels;
}

export function validFirmware(firmware): any {

  return environment.FIRMWARE_ARRAY.includes(firmware);
}

export function isADate(date): boolean {
  if (Object.prototype.toString.call(new Date(date)) === '[object Date]') {
    if (isNaN(new Date(date).getTime())) {  // d.valueOf() could also work
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function isDeviceOnline(heartbeat): boolean{
  return(new Date().getTime() - new Date(heartbeat).getTime()) < 60000;
}

export function isDeviceParked(gps): boolean{
  return gps?.ac === "0";
}

