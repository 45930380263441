

<div style="text-align: center;" *ngIf="data['fileType'] == 'image'" >
<!--  <img src="{{API_URL}}/angFetchSnapshot/{{data['filename']}}_lrg" style="width: 90%;"-->
<!--       onerror="this.onerror=null; this.src='././assets/imageNotFound.png'"/>-->

<!--  <a mat-flat-button href="{{API_URL}}/angFetchSnapshot/{{data['filename']}}_lrg" target="_blank" download >-->
<!--    Download Snapshot-->
<!--  </a>-->

  snapshots
</div>

<div style="text-align: center;" *ngIf="data['fileType'] == 'video'" >
  <div  *ngIf="data['event'].videos2.length > 1">
    <h1 style="font-size: 28px;"></h1>
      Videos for event

    <mat-form-field appearance="fill">
      <mat-label>Cars</mat-label>
      <select [(ngModel)]="eventVideoSelected" (change)="changeEventVideoToView()">
        <option *ngFor="let video of data['event'].videos2" [value]="video.filename">{{video.filename}}</option>

      </select>
    </mat-form-field>


    <video controls="controls" style="width:90%;" type="video/mp4" id="testing123">
    </video>



<!--  </div>-->


  <h1 style="font-size: 28px;" *ngIf="data['event'].videos2.length == 1">
    Video for event
  </h1>





<!--  <a mat-flat-button href="{{API_URL}}/angFetch/{{data['filename']}}" target="_blank" download >-->
<!--    Download Video-->
<!--  </a>-->

  {{data['event'].videos.length}}
</div>


