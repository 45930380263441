<div *ngIf="devices?.length < 1">
  loading...
</div>


<div style="height:100%;width:100%;padding-top: 100px;" *ngIf="devices?.length > 0">
  <h1 style="text-align:center;font-size: 28px!important;">Device Search</h1>
  <div style="display:flex;justify-content: center;align-items: center;gap:50px;padding: 0px 20px">
    <mat-form-field style="font-size: 14px;flex:1">
      <mat-label>Filter by DN</mat-label>
      <input matInput placeholder="Filter" [(ngModel)]="dnSearchParams" [ngModelOptions]="{standalone: true}"  [matAutocomplete]="autoDn"
             (ngModelChange)="dnChanges()">

      <mat-autocomplete #autoDn="matAutocomplete">
        <mat-option *ngFor="let option of filteredDnOptions" [value]="option?.dn" (click)="handleClick(option)">
          {{option?.dn}}
        </mat-option>
      </mat-autocomplete>


      <svg matSuffix style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Filter by Device Number">
        <path fill="currentColor"  d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </mat-form-field>
    <h3>Or</h3>
    <mat-form-field style="font-size: 14px;flex:1">
      <mat-label>Filter by Registration</mat-label>
      <input matInput placeholder="Filter" [(ngModel)]="regSearchParams" [ngModelOptions]="{standalone: true}" [matAutocomplete]="autoReg"
             (ngModelChange)="regChanges()">


      <mat-autocomplete #autoReg="matAutocomplete">
        <mat-option *ngFor="let option of filteredRegOptions" [value]="option?.registration"  (click)="handleClick(option)">
          {{option?.registration}}
        </mat-option>
      </mat-autocomplete>


      <svg matSuffix style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Filter by Registration">
        <path fill="currentColor"  d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </mat-form-field>


    <h3>Or</h3>



    <mat-form-field style="font-size: 14px;flex:1">
      <mat-label>Filter by Fleet</mat-label>
      <input matInput placeholder="Filter" [(ngModel)]="fleetSearchParams" [ngModelOptions]="{standalone: true}" [matAutocomplete]="autoFleet"
      (ngModelChange)="fleetChanges()">
      <mat-autocomplete #autoFleet="matAutocomplete">
        <mat-option *ngFor="let option of filteredFleetOptions" [value]="option?.fleetId" (click)="search()">
          {{option?.fleetName}}
        </mat-option>
      </mat-autocomplete>



      <svg matSuffix style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Filter by Fleet">
        <path fill="currentColor"  d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </mat-form-field>
  </div>
  <div style="text-align: center;margin-bottom: 10px;">
    <button mat-flat-button (click)="search()" style="color:white!important;margin-right:5px;height: 40px !important;background-color:#5C8459!important;">
      Search
    </button>
  </div>

  <div [style.opacity]="datasource?.data?.length > 0 ? '1' : '0'" style="max-height: 55vh!important;overflowY:auto;" >

    <table mat-table [dataSource]="datasource" style="width:100%" matSort >

      <ng-container matColumnDef="dn">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:16%;">Device No.</th>
        <td mat-cell *matCellDef="let element" style="text-align: left!important;" >
          <span *ngIf="element?.dn">{{element?.dn}}</span>
          <span *ngIf="!element?.dn">NO DN ->  {{element?._id}}</span>
        </td>
      </ng-container>



      <ng-container matColumnDef="registration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Registration</th>
        <td mat-cell *matCellDef="let element" style="text-align: left!important;" >
          {{element.registration}}
        </td>
      </ng-container>

      <ng-container matColumnDef="fleet">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Fleet</th>
        <td mat-cell *matCellDef="let element" style="text-align: left!important;" >

          {{element?.fleetId}}

        </td>
      </ng-container>


      <ng-container matColumnDef="group">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Group</th>
        <td mat-cell *matCellDef="let element" style="text-align: left!important;" >
          {{element?.mflGroup}}
        </td>
      </ng-container>

      <ng-container matColumnDef="device">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Device</th>
        <td mat-cell *matCellDef="let element" style="text-align: left!important;" >
          {{modelNumberDisplay(element?.device)}}
        </td>
      </ng-container>

      <ng-container matColumnDef="lastHeartbeat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Heartbeat</th>
        <td mat-cell *matCellDef="let element" style="text-align: left!important;" >
          {{element?.lastHeartbeat | date : 'dd/MM/yy, HH:mm '}}
        </td>
      </ng-container>

      <ng-container matColumnDef="mode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:16%;">Vehicle Mode</th>
        <td mat-cell *matCellDef="let element" style="text-align: left!important;width:16%;" >
          <div *ngIf="element.commissioning">
            <div *ngIf="element.commissioning.commissionStatus=='active'" class="statusActive">{{element.commissioning.commissionStatus | titlecase}}</div>
            <div *ngIf="element.commissioning.commissionStatus=='setup'"  class="statusSetup">{{element.commissioning.commissionStatus | titlecase}}</div>
            <div *ngIf="element.commissioning.commissionStatus=='tested'"  class="statusTested" matTooltip="Device ready for install">Install</div>
            <div *ngIf="element.commissioning.commissionStatus=='service'"  class="statusService" matTooltip="Device in service mode">{{element.commissioning.commissionStatus | titlecase}}</div>
            <div *ngIf="element.commissioning.commissionStatus=='expired'"  class="statusExpired" matTooltip="Device contract expired">{{element.commissioning.commissionStatus | titlecase}}</div>
            <div *ngIf="element.commissioning.commissionStatus=='disabled'"  class="statusDisabled" matTooltip="Device disabled">{{element.commissioning.commissionStatus | titlecase}}</div>
          </div>
        </td>
      </ng-container>




      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; let i= index" (click)="handleClick(row)"
          style="cursor:pointer;"></tr>
    </table>

  </div>


</div>
