  // This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  MOBILE_BREAKPOINT: 420,


    API_URL: "https://api.mfldev.co.uk",

    NOTIFICATIONS_API_URL: 'https://notifications.mflstaging.co.uk',

    LIVESTREAM_URL: "https://stream.mflstaging.co.uk/stream/",

    NOTIFICATIONS_LIVE_STAGING: 'staging',

    NOTIFICATIONS_WEBHOOK: 'MflConnectWebhook',

    NOTI_KEY: 'Frankenstein',

    API_URL_NO_PORT: 'https://myfleetlive.org',

    APP_URL: 'https://mflappstaging.fleetfocus.co.uk/main',

    dvlaDataApiKey: '5f7fa68b-6188-4396-bea5-ad92e4a60a2c',

    mapbox: {
      accessToken: 'pk.eyJ1IjoiZmxlZXRmb2N1cyIsImEiOiJjanVpZWxnc28xNG9wNDNwZ28xOGp1Z2FjIn0.BZhS_6-SMPGqkOaX8mpoSw'
    },

    FIRMWARE_ARRAY: ['V20111701', 'P21110101.302', 'P21052801.146', 'P21103001.295', 'P21110902-101', 'P22033101.533', 'P22041101.562',
    'P22082502.370', 'V22080101.1078', 'P22041401.569', 'P2206280P.580', 'P230306F0.1357', 'P22122901.1263',
      'T23033002.450',  'P2206280C.580', 'P23080301.1468', 'T23071310.1464', 'P23052001.1417', 'P23062801.1452',
      'P23101701.1537', 'T23101201.1541', 'T23122201', 'T24010802.1595', 'P24030604.1627', 'P23091201.1517',
    'P24052203.1695', 'P23121501.1588', 'T24071901', 'P24062901.1716', 'P24081701.685', 'P24072601.1768',
    'T240829GP.1788'],

    MODEL_NUMBER_ARRAY: [
      {
        modelNoDb: '2CN4H01EU',
        modelNoDisplay: 'MFL-2CN4H01-EU',
        channels: 2
      },
      {
        modelNoDb: '2CN4H02EU',
        modelNoDisplay: 'MFL-2CN4H02-EU',
        channels: 2
      },
      {
        modelNoDb: '2CN4H02US',
        modelNoDisplay: 'MFL-2CN4H02-US',
        channels: 2
      },
      {
        modelNoDb: '5CN4H02EU',
        modelNoDisplay: 'MFL-5CN4H02-EU',
        channels: 5
      },
      {
        modelNoDb: '5CN4H02US',
        modelNoDisplay: 'MFL-5CN4H02-US',
        channels: 5
      },
      {
        modelNoDb: '8CA4H01EU',
        modelNoDisplay: 'MFL-8CA4H01-EU',
        channels: 8
      },
      {
        modelNoDb: '8CA4H01US',
        modelNoDisplay: 'MFL-8CA4H01-US',
        channels: 8
      },
      {
        modelNoDb: '2DN4H02EU',
        modelNoDisplay: 'MFL-2DN4H02-EU',
        channels: 2
      },
      {
        modelNoDb: '3DN4H03EU',
        modelNoDisplay: 'MFL-3DN4H03-EU',
        channels: 3
      },
      {
        modelNoDb: '3DA4H03EU',
        modelNoDisplay: 'MFL-3DA4H03-EU',
        channels: 3
      }
    ],

    TIMEZONES: ['eastern_time', 'pacific_time', 'central_time', 'mountain_time', 'london_time', 'gmt_time'],

    TIMEZONEDATA: [
    {
      eMonth: '10',
      eWeek: '0',
      sMonth: '2',
      sWeek: '1',
      timezone: '70.000000',
      onoff: '1',
      switch: '1'
    },
    {
      eMonth: '10',
      eWeek: '0',
      sMonth: '2',
      sWeek: '1',
      timezone: '40.000000',
      onoff: '1',
      switch: '1'
    },
    {
      eMonth: '10',
      eWeek: '0',
      sMonth: '2',
      sWeek: '1',
      timezone: '60.000000',
      onoff: '1',
      switch: '1'
    },
    {
      eMonth: '10',
      eWeek: '0',
      sMonth: '2',
      sWeek: '1',
      timezone: '50.000000',
      onoff: '1',
      switch: '1'
    },
    {
      eMonth: '9',
      eWeek: '4',
      sMonth: '2',
      sWeek: '4',
      timezone: '120.000000',
      onoff: '1',
      switch: '1'
    },
    {
      eMonth: '9',
      eWeek: '0',
      sMonth: '2',
      sWeek: '4',
      timezone: '120.000000',
      onoff: '0',
      switch: '1'
    }
  ]
};

/*


 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


