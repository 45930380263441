
<h1 style="font-size: 28px!important;font-weight: bold;">Reports</h1>

<mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
  <mat-tab label="Driver Cam Health">
    <app-driver-health-camera-report></app-driver-health-camera-report>
  </mat-tab>
<!--  <mat-tab label="Second">Content 2</mat-tab>-->
<!--  <mat-tab label="Third">Content 3</mat-tab>-->
</mat-tab-group>
