import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-event-profile-modal',
  templateUrl: './event-profile-modal.component.html',
  styleUrls: ['./event-profile-modal.component.css']
})
export class EventProfileModalComponent implements OnInit {

  vehicleForm: any;
  mflGroup =  localStorage.getItem('mflGroup');
  authorisation =  localStorage.getItem('authorisation');
  eventProfiles: any;
  currentProfile: any;
  modValue: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.vehicleForm = this.data.vehicleForm;
    this.eventProfiles = this.data.eventProfiles;
    this.changeEventProfile();


    this.modValue = this.data.vehicleForm.value.eventProfileModifier;
  }

  changeEventProfile(): void {
    for (const profile of this.eventProfiles){
      if (profile.profileName === this.vehicleForm.value.eventProfile){
        this.currentProfile = profile;
        break;
      }
    }
  }

  updateEventProfileModifier(value): void {
    this.vehicleForm.patchValue({eventProfileModifier: value});
  }



}
