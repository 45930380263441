import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { AuthService } from '../auth.service';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  msg: string;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public authService: AuthService,
    public router: Router
  ) {
    this.loginForm = this.formBuilder.group({
      email: [''],
      password: ['']
    });
  }


  ngOnInit(): void {
    localStorage.clear();
  }

  loginUser(): any {

    this.authService.login(this.loginForm.value).pipe(catchError(
      err => {
        console.log('Handling error locally and rethrowing it...', err);
        console.log(err);
        this.msg = err.error.message;
        return throwError(err);
      }
    ))  // postTest
      .subscribe(  (res: any) => {
        localStorage.setItem('access_token', res.access_token);
        localStorage.setItem('_id', res.result._id);
        localStorage.setItem('email', res.result.email);
        localStorage.setItem('authorisation', res.result.authorisation);
        localStorage.setItem('mflGroup', res.result.mflGroup);
        this.router.navigate(['device-search']);
      });


  }

  handleError(error: HttpErrorResponse): any {
    console.log('handleError from login');
    if (error.error instanceof ErrorEvent) {
      // client-side error
      this.msg = error.error.message;
    } else {
      // server-side error
      this.msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(this.msg);
    return throwError(this.msg);
  }




}
