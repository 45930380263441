import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  NOTIFICATIONS_API_URL = environment.NOTIFICATIONS_API_URL;
  NOTIFICATIONS_KEY = environment.NOTI_KEY;
  NOTIFICATIONS_WEBHOOK = environment.NOTIFICATIONS_WEBHOOK;

  constructor(private http: HttpClient) { }


  fetchNotificationsParams(): any {

    let data;

    switch (localStorage.getItem('authorisation')) {
      case 'fleet':
        data = {
          mflGroup: localStorage.getItem('mflGroup'),
          'notificationRead.email': { "$not": { "$all": [localStorage.getItem('email')] } }
        };
        break;
      case 'mflGroup':
        data = {
          mflGroup: localStorage.getItem('mflGroup'),
          'notificationRead.email': { "$not": { "$all": [localStorage.getItem('email')] } }
        };
        break;
      case 'superuser':
        data = {
          'notificationRead.email': { "$not": { "$all": [localStorage.getItem('email')] } }
        };
        break;
    }

    return data;
  }






  compareObjects(obj1, obj2): any{
    const result = [];
    const keys = Object.keys(obj1);

    for (const key in obj2) {
      if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])){
        result.push({
          keyName: key,
          newVal: obj2[key],
          oldVal: obj1[key]
        });
      }
    }
    return result;
  }




  fetchNotifications(): Observable<any> {
    return this.http.post<any[]>(`${this.NOTIFICATIONS_API_URL}/getNotifications`, this.fetchNotificationsParams(), httpOptions)
      .pipe();
  }




  addNotification(data: any): Observable<any> {
    return this.http.post<any[]>(`${this.NOTIFICATIONS_API_URL}/addNotification`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }


  markNotificationRead(notification): Observable<any> {
    return this.http.post<any[]>(`${this.NOTIFICATIONS_API_URL}/markNotificationRead`, notification, httpOptions)
      .pipe();
  }

  mflWebhook(data: any): Observable<any> {
    return this.http.post<any[]>(`${this.NOTIFICATIONS_API_URL}/${this.NOTIFICATIONS_WEBHOOK}`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }




  newUser(data: any): Observable<any> {
    const notificationData = {
      mflGroup: data.mflGroup,
      notificationRead: [],
      notificationCreatedTimestamp: new Date(),
      dn: null,
      fleetId: null,
      notificationType: ['userCreated'],
      notificationDetails: {
        title: 'User created',
        description: `A user has been created for the email ${data.email}`,
        eventCreatedBy: localStorage.getItem('email'),
      },
      changes: null
    };
    return this.addNotification(notificationData);
  }

  updateUser(newUser: any, originalUser: any): Observable<any> {
    let differences = this.compareObjects(originalUser, newUser);
    if (differences && differences.length === 0){
      differences = null;
    }
    const notificationData = {
      mflGroup: localStorage.getItem('mflGroup'),
      notificationRead: [],
      notificationCreatedTimestamp: new Date(),
      dn: null,
      fleetId: null,
      notificationType: ['userUpdated'],
      notificationDetails: {
        title: 'User updated',
        description: `The user ${newUser.email} has been updated`,
        eventCreatedBy: localStorage.getItem('email'),
        changes: differences
      },
    };
    return this.addNotification(notificationData);
  }




  requestVideo(videoObj: any, vehicle: any): Observable<any> {
    const notificationData = {
      mflGroup: localStorage.getItem('mflGroup'),
      notificationRead: [],
      notificationCreatedTimestamp: new Date(),
      dn: vehicle.dn,
      registration: vehicle.registration,
      fleetId: vehicle.fleetId,
      notificationType: ['videoRequested'],
      notificationDetails: {
        title: 'Video requested',
        description: 'A new video upload has been requested',
        eventCreatedBy: localStorage.getItem('email'),
        videoRequest: videoObj
      },
    };
    console.log(notificationData);

    // add to db table to manage uploads
    // callback to update this table
    // add tabs into notifications to show uploads in progress
    return this.addNotification(notificationData);
  }

  requestHyperlapse(videoObj: any, vehicle: any): Observable<any> {
    const notificationData = {
      mflGroup: localStorage.getItem('mflGroup'),
      notificationRead: [],
      notificationCreatedTimestamp: new Date(),
      dn: vehicle.dn,
      registration: vehicle.registration,
      fleetId: vehicle.fleetId,
      notificationType: ['hyperlapseRequested'],
      notificationDetails: {
        title: 'Hyperlapse requested',
        description: 'A new Hyperlapse upload has been requested',
        eventCreatedBy: localStorage.getItem('email'),
        videoRequest: videoObj
      },
    };
    console.log(notificationData);

    // add to db table to manage uploads
    // callback to update this table
    // add tabs into notifications to show uploads in progress
    return this.addNotification(notificationData);
  }


  commissionVehicle(webhookData: any, vehicle: any, data: any): Observable<any> {

    let emailTitle;
    let subject;

    switch (data.commissioning.commissionStatus) {
      case 'active':  emailTitle = 'Device commissioned';
                      subject = 'Device ' + webhookData.dn + ' has been commissioned';
                      break;
      case 'tested':  emailTitle = 'Device ready for install';
                      subject = 'Device ' + webhookData.dn + ' is ready for install';
                      break;
      case 'service': emailTitle = 'Device set to service mode';
                      subject = 'Device ' + webhookData.dn + ' has been set to service mode';
                      break;
      case 'expired': emailTitle = 'Device set to expired mode';
                      subject = 'Device ' + webhookData.dn + ' has been set to expired';
                      break;
      case 'disabled': emailTitle = 'Device Disabled';
                       subject = 'Device ' + webhookData.dn + ' has been set to disabled';
                       break;
    }

    const notificationData = {
      instance: this.NOTIFICATIONS_KEY,
      mflGroup: localStorage.getItem('mflGroup'),
      notificationRead: [],
      notificationCreatedTimestamp: new Date(),
      dn: vehicle.dn,
      registration: vehicle.registration,
      fleetId: vehicle.fleetId,
      notificationType: ['modeChanged'],
      notificationDetails: {
        title: emailTitle,
        description: subject,
        eventCreatedBy: localStorage.getItem('email'),
        commissioning: data.commissioning
      },
    };

    this.mflWebhook(webhookData).subscribe(r => console.log(r));

    return this.addNotification(notificationData);
  }


  addToMediaQueue(data: any, vehicle: any): Observable<any> {
    data.mflGroup = localStorage.getItem('mflGroup');
    data.fleetId = vehicle.fleetId;
    data.registration = vehicle.registration;

    return this.http.post<any[]>(`${this.NOTIFICATIONS_API_URL}/addToMediaQueue`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
    );
  }

  fetchMediaQueue(): Observable<any>{

    return this.http.post<any[]>(`${this.NOTIFICATIONS_API_URL}/fetchMediaQueue`, this.fetchNotificationsParams(), httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }


  logChanges(data: any): Observable<any> {
    data.instance = this.NOTIFICATIONS_KEY;

    return this.http.post<any[]>(`${this.NOTIFICATIONS_API_URL}/logChange`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );

  }





}
