import { Component, OnInit } from '@angular/core';
import { NotificationsService } from '../../notifications.service';
import { MatDialog } from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';
import { DataSharingService } from '../../data-sharing.service';
import { MatTableDataSource} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {AdminService} from '../../admin.service';

@Component({
  selector: 'app-notifications-modal',
  templateUrl: './notifications-modal.component.html',
  styleUrls: ['./notifications-modal.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class NotificationsModalComponent implements OnInit {

  notificationsRaw: any;
  notificationsDisplay: any;
  notificationsLoaded = false;
  notiTypeFilter = new UntypedFormControl();
  expandedElement: any | null;
  filter: string;
  allFleets: any;
  fleetsDropdown: any;
  notiCategories: any;
  confirmMarkRead = false;
  tabsIndex: number;
  mediaQueue: any;

  columnsToDisplay = ['registration', 'title', 'fleetId', 'dateTime', 'actions'];
  columnsToDisplayMediaQueue = ['registration', 'status', 'fleetId', 'progress'];

  constructor(private notificationService: NotificationsService, private adminService: AdminService,
              private dialog: MatDialog, private dataShare: DataSharingService) { }

  ngOnInit(): void {
    this.dataShare.notificationsRaw.subscribe(r => {
      this.notificationsRaw = r;
      this.setCategories();
    });

    this.dataShare.mediaQueue.subscribe(r => {
     this.mediaQueue = r;
    });



    this.adminService.fleets({"fleetId": {$ne:null}, active: {'$in': [1, null]}}).subscribe(result => {
      const array = [];
      result.forEach(fleet => {
        array[fleet.fleetId] = fleet;
      });

      this.allFleets = array;
      this.fleetsDropdown = result;


      setTimeout(() => {this.notificationsLoaded = true; this.tabsIndex = 1; }, 200);
    });
  }


  setCategories(): void {
    if (localStorage.getItem('authorisation') === 'superuser') {
      this.notiCategories = [
        {
          label: 'Calibration',
          filters: ['calibration']
        },
        {
          label: 'Camera Health',
          filters: ['camHealth', 'camRec', 'camRecNoCam']
        },
        {
          label: 'Last connection 10+ days',
          filters: ['connection']
        },
        {
          label: 'Channel 1 not recording',
          filters: ['calendar']
        },
        {
          label: 'Events',
          filters: ['severeDrivingEvent', 'moderateDrivingEvent']
        },
        {
          label: 'Media',
          filters: ['videoRequested', 'videoUploadComplete', 'hyperlapseRequested', 'hyperlapseUploadComplete']
        },
        {
          label: 'System',
          filters: ['userUpdated', 'userCreated', 'deviceCommissioned']
        }
      ];
    } else {
      // pull from user obj - use this for now
        this.notiCategories = [
          {
            label: 'Health',
            filters: ['calibration', 'camHealth', 'camRec', 'camRecNoCam', 'connection', 'calendar']
          },
          {
            label: 'Events',
            filters: ['severeDrivingEvent', 'moderateDrivingEvent']
          },
          {
            label: 'Media',
            filters: ['videoRequested', 'videoUploadComplete', 'hyperlapseRequested', 'hyperlapseUploadComplete']
          },
          {
            label: 'System',
            filters: ['userUpdated', 'userCreated', 'deviceCommissioned']
          }
        ];
    }
  }




  refreshNotifications(): void {
    this.notificationService.fetchNotifications().subscribe(r => {
      this.dataShare.notificationsRaw.next(r);
    });
  }

  // filterNotifications(value): void {
  //   if (value !== null && value !== 'all'){
  //     this.notificationsDisplay = this.notificationsRaw.filter(noti => {
  //       // change to includes when notification type is array
  //       return noti.notificationType.includes(value);
  //     });
  //
  //   } else {
  //     this.notificationsDisplay = this.notificationsRaw;
  //   }
  // }


  fetchAllNotifications(): void {
    this.notiTypeFilter.reset();
    this.notificationService.fetchNotifications().subscribe(r => {
      this.dataShare.notificationsRaw.next(r);

      this.notificationsDisplay = r.sort((a, b) => {
        return new Date(b.notificationCreatedTimestamp).getTime() - new Date(a.notificationCreatedTimestamp).getTime();
      });
    });
  }

  markNotificationRead(notification): void {
    const data = {
      notification,
      user: localStorage.getItem('email')
    };

    this.notificationService.markNotificationRead(data).subscribe(r => {
      console.log(r);
      this.refreshNotifications();
    });
  }

  viewDevice(noti): boolean{
    const toLookFor = ['calibration', 'connection', 'camRecNoCam', 'camRec', 'camHealth', 'deviceCommissioned'];
    return noti.notificationType.some(r => toLookFor.indexOf(r) >= 0);
  }

  getNotiByCat(cat): any {
    const notis =  this.notificationsRaw?.filter(e => e.notificationType.some(r => cat.filters.indexOf(r) >= 0));

    if (notis && notis.length > 0){
      return notis.sort((a, b) => {
        if (a.notificationPriority === b.notificationPriority) {
          return new Date(a.notificationCreatedTimestamp).getTime() - new Date(b.notificationCreatedTimestamp).getTime();
        }
        return a.notificationPriority > b.notificationPriority ? 1 : -1;
      });
    } else {
      return [];
    }
  }

  tableDatasource(data): any {
    if (!this.filter) {
      return new MatTableDataSource(data);
    } else if (this.filter !== 'all') {
      return new MatTableDataSource(data.filter(noti => {
        return (noti.fleetId || '').toLowerCase().includes(this.filter.toLowerCase());
      }));
    } else {
      return new MatTableDataSource(data);
    }
  }

  markAsRead(obj): void {
    this.notificationsLoaded = false;

    for (const noti of obj){
      this.notificationService.markNotificationRead({
        notification: noti,
        user: localStorage.getItem('email')
      }).subscribe(r => {
        console.log(r);
      });
    }
    this.refreshNotifications();
    this.notificationsLoaded = true;

  }


  percentTooltip(percent): string {
    return isNaN(percent) ? '0% uploaded' : percent + '% uploaded';
  }

  showUploadDetails(row): void {
    if (localStorage.getItem('authorisation') === 'superuser'){
      this.expandedElement = this.expandedElement === row ? null : row;
    } else {
      this.expandedElement = null;
    }
  }



}
