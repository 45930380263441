<div class=" mat-elevation-z8">

  <mat-table #table [dataSource]="dataSource" (scroll)="onTableScroll($event)">

    <ng-container matColumnDef="deviceNo">
      <mat-header-cell *matHeaderCellDef>Device</mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element?.[0].dn}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cameras">
      <mat-header-cell *matHeaderCellDef>Cameras</mat-header-cell>
      <mat-cell *matCellDef="let element" style="display:flex;flex-direction: column;justify-content: center">
        <div *ngFor="let cam of element" style="width:100%;text-align: left;">
         {{cam?.cameraHealthWarning}} - {{cam?.count}}
        </div>
      </mat-cell>
    </ng-container>


    <ng-container matColumnDef="snapshots">
      <mat-header-cell *matHeaderCellDef>Snapshot</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div style="display: flex;flex-wrap: wrap;gap:5px;">
          <div *ngFor="let snap of element?.snapshots" style="max-width: 100px;aspect-ratio: 16/9">
            <ng-container *ngIf="showSnap(element, snap.camPosition)">
              {{snap.camPosition}}
              <app-view-snapshot [filename]="snap?.file"></app-view-snapshot>
            </ng-container>
          </div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns; let i= index" (click)="selectedRow(row)"
             [ngClass]="{'highlightTableColor': selectedRowIndex == i}"></mat-row>
  </mat-table>
</div>



