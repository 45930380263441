<div style="display:flex;flex-direction: column;height:100vh;position: relative">

  <app-menu-bar></app-menu-bar>

  <div class="clearFix"></div>
  <div  style="padding: 10px 20px 0px 20px;flex:1;overflow:hidden;">
    <router-outlet></router-outlet>
  </div>

  <app-footer></app-footer>
</div>




