import {Injectable, NgZone} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler} from '@angular/common/http';
import {AuthService} from './auth.service';
import { catchError } from 'rxjs/operators';
import {of} from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router, private ngZone: NgZone) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const accessToken = this.authService.getAccessToken();
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`
      }
    });
    return next.handle(req).pipe(
      catchError(
        (err, caught) => {
          if (err.status === 403) {
            this.handleAuthError();
            return of(err);
          }
          throw err;
        }
      )
    );
  }

  private handleAuthError() {
    console.log('handle token error');
    if (localStorage.removeItem('access_token') == null) {
      // this.router.navigate(['login']);
      this.ngZone.run(() => this.router.navigate(['login'])).then();
    }
  }

}
