


<div style="position:relative;height:100%;width: 100%;">


  <div *ngIf="pageLoading" style="height:100%;width:100%;position: absolute;background:white;z-index: 100000000">
    loading
  </div>


  <div style="display: flex;flex-wrap: wrap;">
    <div style="display:flex; align-items: center;justify-content: center">
      <h1 style="font-size: 28px!important;font-weight: bold;">{{devicesToView | titlecase}} devices</h1>
    </div>
    <div style="flex:1"></div>


    <div  *ngIf="userLevel === 'superuser'">
<!--        <button mat-raised-button  color="primary" (click)="addNewVehicle()"-->
<!--                style="margin-right:5px;height: 40px !important;background-color:#5C8459 !important;box-shadow: 2px 2px 0 0 rgb(0 0 0 / 20%)">-->
<!--          + Add Device-->
<!--        </button>-->



        <button mat-raised-button style="margin-right:5px;height: 40px !important;" (click)="toggleCharts()">
          Diagnostics
        </button>

    </div>


  </div>
  <br>

  <div  style="position:absolute;width: 100%;z-index: 999;background: white;" [style.display]="showCharts ? 'block' : 'none'">
    <div style="display:flex;width:100%;margin:0!important;flex-wrap: wrap;justify-content: space-evenly;">
      <div style="flex:1;">
        <canvas id="MyPieChart" style="max-width: 100%!important;">{{ pieChart }}</canvas>
      </div>
      <div style="flex:1;">
        <canvas id="MyBarChart" style="max-width: 100%!important;">{{ pieChart }}</canvas>
      </div>
    </div>
  </div>



<!--    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" >-->

    <div style="max-height: 80vh!important;overflow: hidden;">
      <div style="margin-bottom: 10px;text-align: center;font-size: 22px;display:flex;justify-content: center;gap:10px;">

        <mat-form-field matTooltip="Filter by group" style="font-size: 14px;">
          <mat-label>Group</mat-label>
          <mat-select [(ngModel)]="groupFilter" [ngModelOptions]="{standalone: true}" (selectionChange)="filterByGroup($event)" >
            <mat-option *ngFor="let group of groups" [value]="group.mflGroup">
              {{group.mflGroup}}
            </mat-option>
          </mat-select>
        </mat-form-field>



        <mat-form-field matTooltip="Filter by fleet" style="font-size: 14px;">
          <mat-label>Fleet</mat-label>
          <mat-select [(ngModel)]="fleetFilter" [ngModelOptions]="{standalone: true}" (selectionChange)="filterByFleet($event)" >
            <mat-option *ngFor="let fleet of fleetsDropdown" [value]="fleet.fleetId">
              {{fleet.fleetName}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field matTooltip="Filter by fleet" style="font-size: 14px;">
          <mat-label>Mode</mat-label>
          <mat-select [(ngModel)]="modeFilter" [ngModelOptions]="{standalone: true}" (selectionChange)="filterByMode($event)" >
            <mat-option *ngFor="let mode of modeDropdown" [value]="mode">
              <span *ngIf="mode === 'tested'">Install</span>
              <span *ngIf="mode !== 'tested'">{{mode | titlecase}}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field style="font-size: 14px;">
          <mat-label>Filter by DN or registration</mat-label>
          <input matInput id="searchBarInput" (keyup)="applyFilter($event)" placeholder="Filter">
          <svg matSuffix style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Filter by DN or registration">
            <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
          </svg>
        </mat-form-field>


        <mat-form-field style="font-size: 14px;">
          <mat-label>Quick Navigation</mat-label>
          <input matInput  placeholder="Quick Nav"  [(ngModel)]="quickNavDn" [ngModelOptions]="{standalone: true}"  (keyup)="keyFunc($event)">
          <svg matSuffix style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Enter a dn to quicky navigate to the device page">
            <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
          </svg>

          <a  matSuffix  color="primary"  aria-label="Clear" routerLink="/device/{{quickNavDn}}" target="_blank"
              style="border:none;background:none;cursor:pointer;">
            <mat-icon>chevron_right</mat-icon>
          </a>
        </mat-form-field>

        <span *ngIf="!devicesToView">select type of device to show</span>
        <span *ngIf="devicesToView">
          <button *ngIf="devicesToView !== 'all'" mat-flat-button (click)="showAllDevices()">
            Reset
          </button>
        </span>
      </div>

      <div style="max-height: 70vh!important;overflowY:scroll;" (scroll)="onTableScroll($event)">
        <table mat-table [dataSource]="dataSource" style="width:100%" id="tableDiv" matSort >

          <ng-container matColumnDef="dn">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:16%;">Device No.</th>
            <td mat-cell *matCellDef="let element" style="width:16%;">
              <div style="display: inline-block;margin-right: 5px">
                <div *ngIf="isDeviceOnline(element.lastHeartbeat) && !isDeviceParked(element?.lastLocation)" class="onlineCircle"
                     matTooltip="Connected: {{element.lastConnection | date : 'dd/M/yy, h:mm a'}}"></div>
                <div *ngIf="isDeviceOnline(element.lastHeartbeat) && isDeviceParked(element?.lastLocation)" class="standbyCircle"
                     matTooltip="Parked: {{element.lastHeartbeat | date : 'dd/M/yy, h:mm a'}}"></div>
                <div *ngIf="!isDeviceOnline(element.lastHeartbeat) || !element.lastHeartbeat" class="offlineCircle"
                     matTooltip="Disconnected: {{element.lastDisconnected | date : 'dd/M/yy, h:mm a'}}"></div>
              </div>


              <span *ngIf="element?.dn">{{element?.dn}}</span>
              <span *ngIf="!element?.dn">NO DN ->  {{element?._id}}</span>
            </td>
          </ng-container>



          <ng-container matColumnDef="registration">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Registration</th>
            <td mat-cell *matCellDef="let element" style="text-align: left!important;" >
              {{element.registration}}
            </td>
          </ng-container>

          <ng-container matColumnDef="fleet">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Fleet</th>
            <td mat-cell *matCellDef="let element" style="text-align: left!important;" >


                <span *ngIf="allFleets && allFleets[element?.fleetId]" (click)="fleetClick($event, element)">{{allFleets[element?.fleetId]?.fleetName}}</span>
                <span *ngIf="!allFleets || !allFleets[element?.fleetId]?.fleetName" (click)="fleetClick($event, element)">{{element?.fleetId}}</span>

            </td>
          </ng-container>


          <!--        <ng-container matColumnDef="tags">-->
          <!--          <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Tags</th>-->
          <!--          <td mat-cell *matCellDef="let element" style="text-align: left!important;" >-->
          <!--            <div *ngFor="let tag of element?.tags" class="deviceTag">-->
          <!--              {{tag}}-->
          <!--            </div>-->
          <!--          </td>-->
          <!--        </ng-container>-->

          <ng-container matColumnDef="group">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Vehicle Group</th>
            <td mat-cell *matCellDef="let element" style="text-align: left!important;" >

              <span (click)="groupClick($event, element)">{{element?.mflGroup}}</span>


            </td>
          </ng-container>

          <ng-container matColumnDef="device">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Device</th>
            <td mat-cell *matCellDef="let element" style="text-align: left!important;" >

              <span>{{modelNumberDisplay(element?.device)}}</span>


            </td>
          </ng-container>

          <ng-container matColumnDef="lastHeartbeat">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Heartbeat</th>
            <td mat-cell *matCellDef="let element" style="text-align: left!important;" >
              {{element?.lastHeartbeat | date: 'dd/MM/yy, h:mm a'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="mode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="width:16%;">Vehicle Mode</th>
            <td mat-cell *matCellDef="let element" style="text-align: left!important;width:16%;" >
              <div *ngIf="element.commissioning">
                <div *ngIf="element.commissioning.commissionStatus=='active'" class="statusActive">{{element.commissioning.commissionStatus | titlecase}}</div>
                <div *ngIf="element.commissioning.commissionStatus=='setup'"  class="statusSetup">{{element.commissioning.commissionStatus | titlecase}}</div>
                <div *ngIf="element.commissioning.commissionStatus=='tested'"  class="statusTested" matTooltip="Device ready for install">Install</div>
                <div *ngIf="element.commissioning.commissionStatus=='service'"  class="statusService" matTooltip="Device in service mode">{{element.commissioning.commissionStatus | titlecase}}</div>
                <div *ngIf="element.commissioning.commissionStatus=='expired'"  class="statusExpired" matTooltip="Device contract expired">{{element.commissioning.commissionStatus | titlecase}}</div>
                <div *ngIf="element.commissioning.commissionStatus=='disabled'"  class="statusDisabled" matTooltip="Device disabled">{{element.commissioning.commissionStatus | titlecase}}</div>
              </div>
            </td>
          </ng-container>




          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns; let i= index" (click)="selectedRow(row)"
              [ngClass]="{'highlightTableColor': selectedRowIndex == i}" style="cursor:pointer;"></tr>
        </table>
      </div>


    </div>


  <ng-template #secondDialog let-data>
    <h2 matDialogTitle>New Device</h2>
    <mat-dialog-content>
      <input  [(ngModel)]="dnToCreate" [ngModelOptions]="{standalone: true}" >
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-flat-button color="basic" (click)="confirmNewVehicle()" mat-dialog-close>
        <mat-icon>cloud</mat-icon>
        &nbsp;Setup Vehicle
      </button>
    </mat-dialog-actions>
  </ng-template>



</div>



