import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {VehiclesService} from '../vehicles.service';
import {DeviceDetectorService} from 'ngx-device-detector';
// import {CommunicationService} from '../communication-service.service';
import { Title } from '@angular/platform-browser';

import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';



import {DatePipe, formatDate} from '@angular/common';
import {GeoJson, FeatureCollection} from '../map';

import {UntypedFormControl} from '@angular/forms';
import {interval, Observable, Subscription, timer} from 'rxjs';
import {first, map, startWith, switchMap, take} from 'rxjs/operators';
import {Router} from '@angular/router';

import {EventsComponent} from '../events/events.component';

import {CustomMapboxPopupComponent} from '../custom-mapbox-popup/custom-mapbox-popup.component';
import {DynamicComponentService} from '../dynamic-component.service';
import {environment} from '../../environments/environment';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {ClusterMapboxPopupComponent} from '../cluster-mapbox-popup/cluster-mapbox-popup.component';
import {Animations} from '../animations';


// let regsToggled: any[] = [];
let regsData: any[] = [];
// let geoPointsData: any[] = [];


const popup = new mapboxgl.Popup({offset: 25, closeOnClick: false, maxWidth: '150px'});



@Component({
  selector: 'app-overview',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
  animations: [ Animations.animeTrigger, Animations.animeTrigger2],
  providers: [DatePipe]
})

export class MapComponent implements OnInit {


  @ViewChild(EventsComponent)
  public eventsComponent: EventsComponent;
  MOBILE_BREAKPOINT = environment.MOBILE_BREAKPOINT;
  API_URL = environment.API_URL


  mobileDevice: boolean;
  mapMatch = false;
  ShowAllPoints = false;

  lng = -1.664795;
  lat = 53.785255;
  data = [];
  // dataMarkers = [];
  // dataRegsFiltered = [];
  coords = [];
  gradientArray: any;

  eventsData = [];
  currentMarkers = [];

  style = 'mapbox://styles/fleetfocus/cjvxkhjay5ti71dqa1lldx82p?optimize=true';


  // style = 'mapbox://styles/mapbox/satellite-v9';

  map: mapboxgl.Map;
  loading: boolean;
  loadingJourneys: boolean;
  loadingJourney: boolean;
  loadingAddress: boolean;
  loadingMessage = '';
  journeys = [];
  journey: any;

  journeyDate = new Date();
  trueToday: boolean;
  // currentJourneysToShow = [];

  vehicleProperties: any;
  selectedRegMobile: any;
  vehicleEvent: any;
  selectedJourneyMobile: any;

  satellite = false;
  traffic = false;

  stateCtrl = new UntypedFormControl();
  // filteredRegs: Observable<any[]>;

  filteredRegs = [];

  regsCount: number;

  subscription: Subscription;

  eventDataForPopup: any;

  testtouch = 'multiLivestream: ';
  private includeStartEnd: boolean;
  // disableRollover = false;
  iCountVehicleLoads = 0;
  zoomMap: boolean;
  isOpen = true;


  popupCluster = new mapboxgl.Popup({offset: 25, closeOnClick: true, maxWidth: '150px'});
  i = 0;



  ///

  // visible = true;
  // selectable = true;
  // removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  // fruitCtrl = new FormControl();
  // filteredFruits: Observable<string[]>;
  chips =  new Set([]);
  extraChips =  new Set([]);
  // allFruits: string[] = ['Colchester', 'WU', 'Kjell', 'Test Dummy', '20 tonnes', 'Northern Site'];
  customChip;


  quickChips = [
    {selected: false, name: 'driving', icon: 'driving'},
    {selected: false, name: 'parked', icon: 'parked'},
    {selected: false, name: 'idling', icon: 'idling'},
    {selected: false, name: 'Rob'},
    {selected: false, name: 'Kjell'}
  ];



  // @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;
  // @ViewChild('auto') matAutocomplete: MatAutocomplete;

  ///
  private showJourneyDetails: boolean;


  constructor(private vehiclesService: VehiclesService, private dynamicComponentService: DynamicComponentService,
              public router: Router, private datePipe: DatePipe, private deviceService: DeviceDetectorService,
              private titleService: Title
              ) {
   /* this.filteredFruits = this.fruitCtrl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allFruits.slice()));*/

  }


  ngOnInit() {
    //show map only for mfl at the moment
    if (localStorage.getItem('mflGroup') !== 'undefined'){
      this.router.navigate(['/devices']);
    }

    this.mobileDevice = this.deviceService.isMobile();  //  .isTablet();  .isDesktop();

    this.stateCtrl.setValue('');

    this.buildMap();

    this.titleService.setTitle('MFL Connect - Map');
  }



  //////////////////////////////////////


  updateFilteredRegs() {
    this.filteredRegs = regsData.filter(v => {

      if (this.chips.size === 0) {
        return true;
      }

      const a = new Set(['driving',  'idling', 'parked']);
      // const b = new Set(this.chips);
      const intersection = new Set(
        [...a].filter(z => this.chips.has(z)));


      /***** if size is 0 no status tag selected so make true *****/
      const ds = intersection.has(v.properties.drivingStatus) || intersection.size === 0;



      const testTags = function(test) {
        let x = false;
        if ( typeof v.properties.tags !== 'undefined' ) {
          v.properties.tags.forEach(function(t) {
            if (t.toLowerCase().indexOf(test.toLowerCase()) === 0) {
              x = true;
              return x;
            }
          });
        }
        return x;
      };


      // console.log('testTags: ' + testTags() );



      const chipMatchArray = [];
      this.chips.forEach(f => {

          chipMatchArray.push(
            (v.properties.reg.toLowerCase().indexOf(f.toLowerCase()) >= 0
              || (v.properties.driver.toLowerCase().indexOf(f.toLowerCase()) === 0)
              || (v.properties.town.toLowerCase().indexOf(f.toLowerCase()) === 0)
              || (f === 'driving') || (f === 'parked') || (f === 'offline') || (f === 'idling')
              || testTags(f)
            )
          );

      });


      // console.log(this.quickChips);





      const checker = arr => arr.every(Boolean);

      const r = ds && checker(chipMatchArray);

      return (r);
    });
  }




  addChip(value) {
    if (value.length > 0) {
      this.chips.add(value);
      this.extraChips.add(value);
      this.updateFilteredRegs();
      this.updateMapMarkers(true);
    }
  }



  addXXXXX(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.chips.add(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    // this.fruitCtrl.setValue(null);
    this.updateFilteredRegs();
    this.updateMapMarkers(true);
  }

  removeXXXXXX(chip: string): void {
    const index = this.chips.delete(chip);
    this.updateFilteredRegs();
    this.updateMapMarkers(true);
  }


  addExtraChip(chip): void {
    this.chips.add(chip);
    this.extraChips.add(chip);
    this.updateFilteredRegs();
    this.updateMapMarkers(true);
  }



  removeExtraChip(chip: string): void {
    this.chips.delete(chip);
    this.extraChips.delete(chip);
    this.updateFilteredRegs();
    this.updateMapMarkers(true);
  }



  addQuickChip(chip: any): void {
    console.log(chip);
    chip.selected = true;

    this.chips.add(chip.name);


    this.updateFilteredRegs();
    this.updateMapMarkers(true);
  }

  removeQuickChip(chip: any): void {
    console.log(chip);
    chip.selected = false;


    this.chips.delete(chip.name);

    this.updateFilteredRegs();
    this.updateMapMarkers(true);
  }








  //////////////////////////////////////////////

  /**** pagination for journeys *****/
/*  onPageChange($event) {
    console.log('onPageChange');
    this.currentJourneysToShow =  this.journeys.slice($event.pageIndex * $event.pageSize, $event.pageIndex * $event.pageSize + $event.pageSize);
  }*/


  // TODO this needs to be put back in - causing some sort of event panel loop !!!!
  eventsPanelOpenResizeJourney(panelOpenState) {
    // console.log(panelOpenState);
    // const bottomBound = panelOpenState ? 300 : 100;
    // this.fitMapBoundsToJourney(bottomBound);
  }









  is_touch_device() {
    return 'ontouchstart' in window        // works on most browsers
      || navigator.maxTouchPoints;       // works on IE10/11 and Surface
  }





  buildMap() {


    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 5,
      maxZoom: 16,
      center: [this.lng, this.lat]
    });

    this.map.scrollZoom.setWheelZoomRate(2);

    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disableRotation();


    popup
      .addTo(this.map);




    this.map.on('moveend', e => {

      if (typeof this.vehicleEvent !== 'undefined') {
        if (typeof this.vehicleEvent.geometry !== 'undefined') {
          this.addCircleToSelectedEvent(this.vehicleEvent.geometry.coordinates);
        }
      }

      /*************   remove popup if map moves   *****/
      // this.popup.remove();
    });

    this.map.addControl(
      new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        countries: 'gb',
        mapboxgl
      })
    );





    // const popup = new mapboxgl.Popup({offset: 25});


    const el = document.createElement('div');
    el.className = 'markerCss';


    let clickEvent = 'click';
    if (this.is_touch_device()) {
      clickEvent = 'touchstart';
      this.testtouch += '  touch_device ';
    }


    this.map.on(clickEvent, 'dataPoints', (event) => {


      // this.disableRollover = true;
      this.loadingAddress = true;

      // popup.remove();

      this.openMapBoxPopup(event);


      this.vehiclesService.reverseGeocode(event.lngLat)  // postTest
        .subscribe(addressData => {
          console.log(addressData);
          this.eventDataForPopup.address = addressData.features[0].place_name;
          this.eventDataForPopup.loadingAddress = false;
        });


    });









    this.map.on('mousemove', 'dataPoints', (event) => {


      // if (!this.disableRollover) {
     // this.openMapBoxPopup(event);
      // }

    });


    this.map.on('mouseout', 'dataPoints', () => {
      this.map.getCanvas().style.cursor = '';
      console.log('mouse out');
     // popup.remove();
      // popup.remove();
    });


    ////////////////////

    this.map.on('load', (event) => {
      // console.log('we are loaded');



      this.loading = true;
      this.loadingMessage = '... fetching current vehicle locations';


      this.map.addSource('mapDataRegMarkers', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        },
        cluster: true,
        clusterMaxZoom: 16, // Max zoom to cluster points on
        clusterRadius: 40

      });

      this.map.addSource('mapDataEvents', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });


      this.map.addSource('mapDataSourceId', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }

      });


      this.map.addSource('mapDS', {
        type: 'geojson',
        lineMetrics: true,
        data : {
          type : 'Feature',
          properties : {},
          geometry : {
            type : 'LineString',
            coordinates : []
          }
        }
      });

      this.map.addSource('journeyLine', {
        type : 'geojson',
        data : {
          type : 'Feature',
          properties : {},
          geometry : {
            type : 'LineString',
            coordinates : []
          }
        }
      });


      this.map.addSource('geoDataPoints', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }  //   geoPointsData  regsData
      });

      this.loadIconImages();




      // this.stateCtrl.setValue('');




      interval(10000)
        .pipe(
          startWith(0),
          switchMap(() => this.vehiclesService.getVehicles())
        )
        .subscribe(data => {

          this.iCountVehicleLoads++;

          // console.log('vehicleLoad count =' + this.iCountVehicleLoads);

          this.loading = false;
          this.loadingMessage = '';


          // console.log(data);
          regsData = [];

          data.forEach((v: any) => {
            let town = '';
            if (typeof v.endAddress !== 'undefined') {
               town = v.endAddress.town;
            }

            if (typeof v.lastLocation === 'undefined') {
              /**** skip if no location ***/
              return;
            }

            const feature = {type: 'Feature',
              properties: {
                dn: v.dn,
                driver: v.driver,
                reg:  v.registration,
                deviceStatus: v.deviceStatus,
                drivingStatus: v.drivingStatus,
                head: v.lastLocation.head,
                speed: v.lastLocation.speed,
                tags: v.tags,
                town
              },
              geometry: {
                type: 'Point',
                coordinates: [v.lastLocation.lng, v.lastLocation.lat]
              }

            };

            regsData.push(feature);

          });



          regsData.sort((a, b) => (a.properties.reg > b.properties.reg) ? 1 : -1);

          // regsToggled = regsData.filter(state => (this.drivingOnlyToggle ? state.properties.drivingStatus === 'offline' : false));



          this.updateFilteredRegs();

          this.regsCount = this.filteredRegs.length;


          // this.fitDataBounds(regsData);


          /******* Only zoom map on first load ******/
          if (this.iCountVehicleLoads === 1) {
            this.updateMapMarkers(true);
          } else {
            this.updateMapMarkers(false);
          }


          // this.dataRegsFiltered = regsToggled;





/*          this.map.getSource('mapDataRegMarkers').setData({
            type: 'FeatureCollection',
            features: regsData
          });*/



        });

      ///////  out of timer loop  ///////////////////////////////////////


    });

  }





/*
  updateFilteredRegs() {
    this.filteredRegs = regsData.filter(v => {
      return ((v.properties.reg.toLowerCase().indexOf(this.stateCtrl.value.toLowerCase()) === 0)
        || (v.properties.driver.toLowerCase().indexOf(this.stateCtrl.value.toLowerCase()) === 0))
        && (this.drivingOnlyToggle ? v.properties.drivingStatus !== 'offline' : true);
    });
  }
*/








  openMapBoxPopup(event) {
    this.eventDataForPopup = event.features[0].properties;
    this.eventDataForPopup.dn = this.vehicleProperties.properties.dn;
    this.eventDataForPopup.journey_id = this.vehicleProperties.properties.journey_id;
    this.eventDataForPopup.lngLat = event.lngLat;
    this.eventDataForPopup.loadingAddress = true;
    this.eventDataForPopup.mediaType = 'none';
    // this.eventDataForPopup.deviceStatus = this.vehicleProperties.properties.deviceStatus;
    // this.eventDataForPopup.disableRollover = this.disableRollover;
    this.eventDataForPopup.registration = this.vehicleProperties.properties.reg;

    // Inject Component and Render Down to HTMLDivElement Object
    const popupContent = this.dynamicComponentService.injectComponent(
      CustomMapboxPopupComponent,
      x => x.model = this.eventDataForPopup);  // pass Model or other Properties to your Component

    //

      // popupContent

 /*   this.communicationService.componentMethodCalled$.subscribe(() => {
      console.log('(Component2) Method called!');
    });*/

    popup
      .setLngLat(event.lngLat)
       // .setHTML(popupContent)
      .setDOMContent(popupContent)
      .addTo(this.map);
  }




  openClusterPopup(aFeatures) {


    this.eventDataForPopup = aFeatures;

    // Inject Component and Render Down to HTMLDivElement Object
    const popupContent = this.dynamicComponentService.injectComponent(
      ClusterMapboxPopupComponent,
      x => x.model = this.eventDataForPopup);  // pass Model or other Properties to your Component

    this.popupCluster.on('close', (e) => {
      console.log('cluster pop up closed');
    });

    this.popupCluster
      .setLngLat(aFeatures[0].geometry.coordinates)
      // .setHTML(contentElement)
      .setDOMContent(popupContent)
      // .setText('this')
      .addTo(this.map);
  }







  loadIconImages() {

    // tslint:disable-next-line:no-shadowed-variable
    const map = this.map;

    const images = [
      {imageUrl: '././assets/img/licence-plate-1.png', id: 'whitePlate'},
      // {imageUrl: '././assets/img/licence-plate-grey.png', id: 'offline'},
      // {imageUrl: '././assets/img/licence-plate-grey2.png', id: 'Sleep'},
      {imageUrl: '././assets/img/licence-plate-cluster.png', id: 'cluster-marker'},
      {imageUrl: '././assets/img/start-flag.png', id: 'startFlag'},
      {imageUrl: '././assets/img/finish-flag.png', id: 'finishFlag'},
      {imageUrl: '././assets/img/arrow1.png', id: 'arrow'},
     // {imageUrl: '././assets/img/licence-plate-arrow.png', id: 'lpArrow'},


      /* {imageUrl: '././assets/img/pin-brake.png', id: 'pin-brake'},
       {imageUrl: '././assets/img/pin-speed.png', id: 'pin-speed'},
       {imageUrl: '././assets/img/pin-acceleration.png', id: 'pin-acceleration'},
       {imageUrl: '././assets/img/pin-emergency.png', id: 'pin-emergency'},
       {imageUrl: '././assets/img/pin-shock.png', id: 'pin-shock'},
       {imageUrl: '././assets/img/pin-user-request.png', id: 'pin-user-request'},
       {imageUrl: '././assets/img/pin-video.png', id: 'pin-video'},
       {imageUrl: '././assets/img/pin-unknown.png', id: 'pin-unknown'},
       {imageUrl: '././assets/img/pin-turning.png', id: 'pin-turning'},*/
    ];


    Promise.all(
      images.map(img => {
        return new Promise<void>((resolve, reject) => {
          map.loadImage(img.imageUrl, (error, res) => {
            map.addImage(img.id, res);
            resolve();
          });
        });
      })
    )
      .then(data => {
        // console.log('Second handler', data);

        /***** lpArrow has to be sdf to change colour ********/
        map.loadImage('././assets/img/licence-plate-arrow.png', (error, image) => {   // pin-b
          map.addImage('lpArrow', image, { sdf: true });
        });

        map.loadImage('././assets/img/lpDot.png', (error, image) => {   // pin-b
          map.addImage('lpDot', image, { sdf: true });
        });


        this.addMarkersToMap();
      });
  }


  addDataPointMarkersToMap() {


    console.log('addDataPointMarkersToMap !!!!!!!!!!!!');

    // tslint:disable-next-line:no-shadowed-variable
    const map = this.map;

    console.log(regsData);
    // console.log(geoPointsData);


    if (typeof this.map.getLayer('dataPoints') !== 'undefined') {
        this.map.removeLayer('dataPoints');
    }


    map.addLayer({
      id: 'dataPoints',
      type: 'symbol',
      source:   'geoDataPoints',   //      'geoDataPoints', mapDataRegMarkers  mapDataSourceId
      layout: {
        // 'icon-image': 'pin-brake',
        'icon-image': 'lpArrow',   // ['get', 'deviceStatus'],  lpArrow
        //  'icon-ignore-placement': false,
        'icon-allow-overlap': this.ShowAllPoints,


        'icon-size': 0.4,
        'icon-rotate': ['get', 'head'],
      },
      paint: {
        'icon-color': ['get', 'colour'],
      },
    });




  }


  addMarkersToMap() {


    // console.log('loaded images doStuff');

    // tslint:disable-next-line:no-shadowed-variable
    const map = this.map;

    map.addLayer({
      id: 'markers',
      type: 'symbol',
      source: 'mapDataRegMarkers',

       filter: ['!', ['has', 'point_count']],
      layout: {
        // 'icon-image': 'rectangle-orange-5',
        'icon-image':  'whitePlate',  // ['get', 'deviceStatus'],
        'icon-ignore-placement': false,

        // 'icon-text-fit': 'both',
        'icon-allow-overlap': true,
        'text-allow-overlap': true,

        'text-field': ['get', 'reg'],
        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],


        'text-size': 13,
        'icon-size':  1,  // 0.75,
        'icon-anchor': 'left',
        // 'icon-offset': [-15, 0],
        'icon-offset': [-10, 0],
        'text-anchor': 'bottom-left',
        'text-offset': [1, 0.6],

      },
      paint: {
        // Sleep Offline Driving
        'text-color': [
          'case',
          ['==', 'Driving', ['get', 'deviceStatus']], '#000000',
          ['==', 'Sleep', ['get', 'deviceStatus']], '#5f5f5f',
          ['==', 'Offline', ['get', 'deviceStatus']], '#5f5f5f',
          '#000000'
        ]

      }
    });

    // drivingStatus

    map.addLayer({
      id: 'markers2',
      type: 'symbol',
      source: 'mapDataRegMarkers',
      layout: {
        // 'icon-image': 'pin-brake',
        // 'icon-image': 'lpArrow',   // ['get', 'deviceStatus'],

        'icon-image': [
          'case',
          ['==', 'driving', ['get', 'drivingStatus']], 'lpArrow',
          ['==', 'idling', ['get', 'drivingStatus']], 'lpArrow',
          ['==', 'parked', ['get', 'drivingStatus']], 'lpDot',
          ['==', 'offline', ['get', 'drivingStatus']], 'lpDot',
          'lpArrow'
        ],
        'icon-ignore-placement': false,
        'icon-allow-overlap': true,
        'icon-size': 0.9,
        'icon-rotate': ['get', 'head'],
      },
      paint: {
        'icon-color': [
          'case',
          ['==', 'driving', ['get', 'drivingStatus']], '#2db317',
          ['==', 'idling', ['get', 'drivingStatus']], '#fd6804',
          ['==', 'parked', ['get', 'drivingStatus']], '#034afc',
          ['==', 'offline', ['get', 'drivingStatus']], '#cccccc',
          '#b1b0b0'
        ]
      },
    });


    map.on('click', 'markers', e => {
      // console.log('clicked marker');
      // this.router.navigateByUrl('/vehicle-view', { state: e.features[0] });

      // e.features[0].properties.address = JSON.parse(e.features[0].properties.address);

      console.log(e.features[0]);

      this.vehicleProperties = e.features[0];
      this.listJourneys();

    });


    map.addLayer({
      id: 'clusters',
      type: 'symbol',
      source: 'mapDataRegMarkers',
      filter: ['has', 'point_count'],

      layout: {
        'icon-image': 'cluster-marker',
        'text-field': '{point_count_abbreviated}',
        // 'icon-ignore-placement': true,

        'icon-allow-overlap': true,
        'text-allow-overlap': true,

        'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold'],
        'icon-size': 0.9,
        // 'icon-anchor': 'bottom-left',
        // 'icon-offset': [-20, 0],
        'text-anchor': 'bottom',
        'text-offset': [0, 0.4],
      }
    });


    // inspect a cluster on click
    // tslint:disable-next-line:only-arrow-functions
    map.on('click', 'clusters', (e) => {
      console.log('clicked cluster');
      const features = map.queryRenderedFeatures(e.point, {layers: ['clusters']});
      const clusterId = features[0].properties.cluster_id;
      console.log(clusterId);

      const point_count = features[0].properties.point_count;
      console.log(point_count);
      const clusterSource = map.getSource(/* cluster layer data source id */'mapDataRegMarkers');

      clusterSource.getClusterLeaves(clusterId, point_count, 0, (err, aFeatures) => {
        console.log('getClusterLeaves', err, aFeatures);
        console.log('zoom' + map.getZoom());
        if ( map.getZoom() === 16) {
          this.openClusterPopup(aFeatures);
        }
      });

      map.getSource('mapDataRegMarkers').getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err) {
          return;
        }

        map.easeTo({
          center: features[0].geometry.coordinates,
          zoom
        });

        console.log('zoom2: ' + map.getZoom());

      });
    });

  }


  updateMapMarkers(fitbounds) {

    // console.log('fitbounds: ' + fitbounds);

    if (this.filteredRegs.length > 0) {
      if (fitbounds) {
        this.fitDataBounds(this.filteredRegs);
      }



      this.map.getSource('mapDataRegMarkers').setData({
        type: 'FeatureCollection',
        features: this.filteredRegs
      });
    }


/*    if (this.filteredRegs.length === 1) {
      console.log('one vehicle only selected');
      this.vehicleProperties = this.filteredRegs[0];
      this.listJourneys();
    }*/
  }


  goToVehicleDetails(vehicleProperties) {
    this.vehicleProperties = vehicleProperties;
    this.listJourneys();
  }



  updateMapMarkersMobile() {
    console.log(this.selectedRegMobile);
    this.vehicleProperties = this.selectedRegMobile;
    this.listJourneys();
  }





/*  clearVehiclesFromDropdownXXXXXXXXXXXXX() {
    this.stateCtrl.setValue('');
    this.updateFilteredRegs();
    this.updateMapMarkers(true);

  }*/


// tslint:disable-next-line:no-shadowed-variable
  fitDataBounds(data) {

    const bounds = new mapboxgl.LngLatBounds();
    data.forEach(feature => {
      bounds.extend(feature.geometry.coordinates);
      // console.log('bounding');
    });

    // console.log(bounds);
    this.map.fitBounds(bounds, {
      maxZoom: 14, padding: {
        bottom: 100, left: 300, top: 100, right: 100
      }
    });

  }


  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////


  listJourneys() {


    this.trueToday = this.datePipe.transform( this.journeyDate, 'yyyy-MM-ddT00:00:00') ===
      this.datePipe.transform( new Date(), 'yyyy-MM-ddT00:00:00');


    this.loadingJourneys = true;


    this.loadingMessage = '... fetching vehicle journeys';

    this.journeys = [];
    // this.currentJourneysToShow = [];

    this.map.flyTo({
      center: this.vehicleProperties.geometry.coordinates,
      zoom: 13,
      speed: 2,
    });

    // console.log(this.vehicleProperties);
    // console.log(this.journeyDate);

    const vehicleData = {date: this.journeyDate, dn: this.vehicleProperties.properties.dn};
    // const vehicleDate = {date: this.journeyDate, device_id: 'V2MA51800447' };

    this.vehiclesService.getJourneys(vehicleData)  // postTest
      .subscribe(journeysData => {
        // console.log(journeysData);
        this.journeys = journeysData;
        this.loadingJourneys = false;
        this.loadingMessage = '';

        this.drawTail(journeysData[0]);

        /*const e = {
          pageIndex: 0,
          pageSize: 10
        };

        this.onPageChange(e);*/

      });

  }



  dateIncrement(inc) {
    /*clone Date object instance instead of just mutating it in place*/
    this.journeyDate = new Date(this.journeyDate.setDate( this.journeyDate.getDate() + inc ));
    this.listJourneys();
  }






  // draw multiLivestream tail journey: last 10 mins

  // $url = 'GetJourneyDetails/' . $postData->device_id .'/' . $postData->Start . '/' . $postData->End;

  drawTail(journey) {
    // console.log('draw tail');

    this.includeStartEnd = false;
    this.drawRoute(journey);
  }


  drawRouteFromSelected(journey) {
    console.log('drawRouteFromSelected');
    this.includeStartEnd = true;
    this.showJourneyDetails = true;
    this.journey = journey;
    console.log(this.journey);
    this.drawRoute(journey);
  }


  drawRoute(journey) {

    console.log(' draw route for journey');
    console.log(journey);

    this.vehicleProperties.properties.deviceStatus = journey.Status;
    this.vehicleProperties.properties.journey_id = journey._id;

    this.eventsData = [];
    this.coords = [];
    this.removeDrawnRoute();


    /*    if (typeof this.map.getSource('mapDataEvents') === 'undefined') {
          this.map.addSource('mapDataEvents', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: []
            }
          });
        }*/


    this.loadingJourney = true;
    this.loadingMessage = '... fetching journey details';

    // tslint:disable-next-line:no-shadowed-variable
    const map = this.map;

    map.setLayoutProperty('clusters', 'visibility', 'none');


    journey.device_id = this.vehicleProperties.properties.device_id;   // ' V2MA51800447' ND67 DCX
    journey.mapMatch = this.mapMatch;
    journey.ShowAllPoints = this.ShowAllPoints;

    console.log(journey);



    this.vehiclesService.getJourney(journey)  // postTest
      .subscribe(data => {
        this.loadingJourney = false;
        console.log(data);

        if (data === null) {
          return;
        }




        let cumDistance = 0;
        const result = {type: 'FeatureCollection', features: []};

        if (typeof this.map.getLayer('journeyLine') !== 'undefined') {
          this.map.removeLayer('journeyLine');
        }

        // tslint:disable-next-line:prefer-for-of
        if (typeof data.tracks !== 'undefined') {

          if (data.tracks !== null) {

            for (let i = 0; i < data.tracks.length; i++) {

              if (i + 1 < data.tracks.length) {
                cumDistance += this.calcDistance(data.tracks[i].lat, data.tracks[i].lng, data.tracks[i + 1].lat, data.tracks[i + 1].lng, 'M');
              }


              const feature = {
                type: 'Feature',
                properties: {
                  head: data.tracks[i].head,
                  // colour: '#ef00c6',
                  colour: '#000000',
                  speed: Math.round(data.tracks[i].speed / 100 * 0.621371),    // to mph,
                  distance: cumDistance,
                  time: data.tracks[i].at,
                  gpsAcc: data.tracks[i].gpsAcc,
                  sigInt: data.tracks[i].sigInt,
                  netType: data.tracks[i].netType,
                  maxspeed: data.tracks[i].maxspeed

                },
                geometry: {
                  type: 'Point',
                  coordinates: [data.tracks[i].lng, data.tracks[i].lat]
                }

              };


              result.features.push(feature);

            }

            map.getSource('geoDataPoints').setData(result);


            this.addDataPointMarkersToMap();


            result.features.forEach((f) => {
              this.coords.push(f.geometry.coordinates);
            });


            console.log(this.coords);


            /***add complete line*!*/
            const coordsData = {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'LineString',
                coordinates: this.coords
              }
            };

            ////////////////////////////////////////////////////


            map.getSource('journeyLine').setData(coordsData);

            this.addALayer();

            this.fitMapBoundsToJourney(100);


          }
        }



        // }





        ////////////////////////////////////////////////

        // TODO TODO
        // 2 loops here but only one needed ???? Doesn't need to be in GEOJSON     ???? !!!!!

        console.log(data.events);

        this.eventsData = data.events.filter(event => (event.snapshot && event.eventSource === 'gSensor'));




        // TODO NEEDS negative lats not filtered!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        const pinEvents = data.events.filter(event => event.lat > 0);


        pinEvents.forEach((marker) => {




          const el = document.createElement('div');
          el.className = 'marker';


          console.log(marker);



          const imageDiv = document.createElement('div');
          imageDiv.className = 'imageDiv';
          // imageDiv.style.backgroundColor = 'grey';


          /*if (typeof marker.snapshot !== 'undefined') {
            imageDiv.style.backgroundImage = `url(${API_URL}/angFetchSnapshot/${marker.snapshot[0]}_sm)`;

          } else {
            imageDiv.style.backgroundImage = `url(https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/static/${marker.lng},${marker.lat},15,0/320x180?access_token=${mapboxgl.accessToken})`;
          }*/



          imageDiv.style.width = '60px';  // 320
          imageDiv.style.height = '60px';  // 180

          imageDiv.addEventListener('mouseover', () => {
            console.log('mouseover EVENT');
            // this.disableRollover = true;
            // this.popup.remove();
          });

          imageDiv.addEventListener('click', () => {
            this.vehicleEvent = marker;
            // this.eventsComponent.openPictureBoxWithEvent(this.vehicleEvent.properties);
            this.eventsComponent.openPictureBoxWithEvent(this.vehicleEvent);
          });
          imageDiv.addEventListener('mouseout', () => {
            console.log('mouseout  EVEMT');
            // this.disableRollover = false;
          });

          el.appendChild(imageDiv);


          const div = document.createElement('div');
          div.className = 'triangle-down';
          el.appendChild(div);


          const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

          svg.setAttribute('width',  '60px' );
          svg.setAttribute('height',  '60px' );
          svg.setAttribute('viewBox', '0 0 60 60');

          const ci = document.createElementNS('http://www.w3.org/2000/svg', 'circle');


          ci.setAttribute('cx',  '30' );
          ci.setAttribute('cy',  '23' );
          ci.setAttribute('r',  '15' );
          ci.setAttribute('fill', 'white');
          ci.setAttribute('stroke-width', '4px');
          ci.setAttribute('stroke', 'red');
          ci.setAttribute('opacity', '1');

          svg.appendChild(ci);



          if (typeof marker.speed !== 'undefined') {
            const textSvg = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            textSvg.setAttribute('font-size', '1.6em');
            textSvg.setAttribute('font-weight', 'bold');
            textSvg.setAttribute('x', '20');
            textSvg.setAttribute('y', '30');
            textSvg.textContent = marker.speed;
            svg.appendChild(textSvg);
          }



          /****** needs holder or css transforms won't work *****/
          const holder = document.createElement('div');
          holder.appendChild(el);
          imageDiv.appendChild(svg);


          const oneMarker = new mapboxgl.Marker(holder, {
            // offset: [0, -30],
            anchor: 'bottom'
          })
           .setLngLat([marker.lng, marker.lat])
            // .setLngLat([0, 0])
            .addTo(map);


          this.currentMarkers.push(oneMarker);
          console.log('this.currentMarkers.length' + this.currentMarkers.length);

        });


      });


    }


  fitMapBoundsToJourney(bottom) {

    console.log('fitMapBoundsToJourney: ' + bottom);

    const bounds = this.coords.reduce(function(b, coord) {
      return b.extend(coord);
    }, new mapboxgl.LngLatBounds(this.coords[0], this.coords[0]));

    console.log(bounds);
    let padding = {};

    if (window.innerWidth <= this.MOBILE_BREAKPOINT) {
      padding = {bottom, left: 20, top: 200, right: 20};
    } else {
      padding = {bottom, left: 400, top: 100, right: 100};
    }

    this.map.fitBounds(bounds, {
      padding, maxZoom: 16
    });
  }


  addCircleToSelectedEvent(coords) {

    console.log('add circle to event');

    /****** shift circle to centre of marker *********/
    const pixelCoords = this.map.project(coords);
    coords = this.map.unproject([pixelCoords.x, pixelCoords.y - 30]);


    if (typeof this.map.getLayer('pink-event-circle') !== 'undefined') {
      this.map.removeLayer('pink-event-circle');
      this.map.removeSource('pink-event-circle');
    }


    this.map.addLayer({
      id: 'pink-event-circle',
      type: 'circle',
      source: {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: [coords.lng, coords.lat]
              }
            }
          ]
        }
      },
      paint: {
        'circle-radius': 100,
        'circle-color': 'rgba(255,11,242,0.11)',
      },
      filter: ['==', '$type', 'Point'],
    });   // , 'eventsLayer'
  }


  eventsDialogClosed() {
    console.log('eventsDialogClosed');

    if (typeof this.map.getLayer('pink-event-circle') !== 'undefined') {
      this.map.removeLayer('pink-event-circle');
      this.map.removeSource('pink-event-circle');
    }

    this.vehicleEvent = {};

    this.fitMapBoundsToJourney(100);


  }


  getEvent(event) {
    console.log('getEvent');
    console.log(event);
    this.vehicleEvent = event;

    const rect = document.getElementById('map').getBoundingClientRect();
    const viewportX = [rect.right][0];

    const coords = [  this.vehicleEvent.lng ,   this.vehicleEvent.lat];

    this.map.flyTo({
      center: coords,
      offset: [(viewportX / 3), 0],
      zoom: 15,
      speed: 2
    });
  }


  zoomJourneyStart(event) {
    console.log(event);
    console.log(this.journey);
    this.map.flyTo({
      center: [  this.journey[event].lng ,   this.journey[event].lat],
      zoom: 16,
      speed: 2
    });
  }


  reloadJourney(event) {
    console.log('reloadJourney dfsdfsdf');
    this.drawRoute(this.journey);
  }




  // tslint:disable-next-line:no-shadowed-variable
  addALayer() {

    // tslint:disable-next-line:no-shadowed-variable
    const map = this.map;
    const data = this.data;
    const coords = this.coords;


    map.addLayer({
      id: 'journeyLine',
      type: 'line',
      source: 'journeyLine',

      layout: {
        'line-join': 'round',
        'line-cap': 'round',
        visibility: 'visible'
      },
      paint: {
        // 'line-color': '#d723d4',
        'line-color': '#1b58c4',
        'line-offset': {
          stops: [
            [1, 0],
            [14, 0],
            [18, -5],
            [22, -15]
          ]
        },
        // 'line-gradient': this.gradientArray,
        // 'line-gradient': lineGradient,

        'line-width': {
          base: 8,
          stops: [
            [1, 5],
            [15, 10]
          ]
        },
        'line-opacity': {
          stops: [
            [1, 0.9],
            [16, 0.5]
          ]
        },

      }

    }, 'markers');      // 'eventsLayer');






    if (this.includeStartEnd === true) {
      /*Add start marker*/
      map.addLayer({
        id: 'startFlag',
        type: 'symbol',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Point',
                  coordinates: coords[0]
                }
              }
            ]
          }
        },
        layout: {
          'icon-image': 'startFlag',
          'icon-size': 1,
          'icon-anchor': 'bottom-left',
          'icon-offset': [-7, 0],
        }
      });

      /*Add end marker*/
      map.addLayer({
        id: 'markerEnd',
        type: 'symbol',
        source: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {
                  deviceStatus: this.vehicleProperties.properties.deviceStatus
                },
                geometry: {
                  type: 'Point',
                  coordinates: coords[coords.length - 1]
                }
              }
            ]
          }
        },
        filter: ['!=', 'deviceStatus', 'Driving'],
        layout: {
          'icon-image': 'finishFlag',
          'icon-size': 1,
          'icon-anchor': 'bottom-left',
          'icon-offset': [-7, 0],
        }
      });

    }


    // console.log(this.vehicleProperties);
    // console.log(coords[coords.length - 1]);

    /* Add current vehicle location*/
    if (coords.length > 0 && this.vehicleProperties.properties.deviceStatus === 'Driving') {
      console.log('setting mapDataRegMarkers ');
      this.map.getSource('mapDataRegMarkers').setData({
        type: 'FeatureCollection',
        features: [{
          geometry: {
            type: 'Point',
            // coordinates: [-1.8510721667, 52.4435041667]
            coordinates: coords[coords.length - 1]
          },
          properties: {
            reg: this.vehicleProperties.properties.reg,
            deviceStatus: this.vehicleProperties.properties.deviceStatus,
            icon: 'custom-marker'
          }
        }]
      });
    }


  }


  allVehicles() {
    this.eventsData = [];
    delete this.vehicleProperties;
    this.map.setLayoutProperty('markers', 'visibility', 'visible');
    this.map.setLayoutProperty('clusters', 'visibility', 'visible');
    this.removeDrawnRoute();
    this.updateMapMarkers(true);
  }


  allJourneys() {
    console.log('show all journeys');
    delete this.journey;
    this.removeDrawnRoute();
  }



  removeDrawnRoute() {


    if (typeof this.subscription !== 'undefined') {
      this.subscription.unsubscribe();
      // console.log('unsubscribing from within remove drawn route');
    }

    if (typeof this.map.getLayer('route') !== 'undefined') {
      this.map.removeLayer('route');
      this.map.removeLayer('route2');
      // this.map.removeLayer('arrow-layer');
      this.map.removeLayer('dataPoints');
    }


    if (typeof this.map.getLayer('startFlag') !== 'undefined') {
      this.map.removeLayer('startFlag');
      this.map.removeSource('startFlag');
    }

    if (typeof this.map.getLayer('markerEnd') !== 'undefined') {
      this.map.removeLayer('markerEnd');
      this.map.removeSource('markerEnd');
    }


    if (this.currentMarkers !== null) {
      for (let i = this.currentMarkers.length - 1; i >= 0; i--) {
        this.currentMarkers[i].remove();
      }
    }


    /*if (typeof this.map.getLayer('eventsLayer') !== 'undefined') {
      this.map.removeLayer('eventsLayer');
      this.map.removeSource('mapDataEvents');
      console.log('remove events layer');
    }*/

  }


  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////


  toggleSatellite() {

    console.log(this.map.getZoom() + ' x ');

    if (this.satellite) {
      this.map.setPaintProperty('mapbox-satellite', 'raster-opacity', 0);


    } else {
      this.map.setPaintProperty('mapbox-satellite', 'raster-opacity', 1);
      this.map.flyTo({
        center: this.map.getCenter(),
        zoom: 16,
        speed: 2,
      });

    }

    this.satellite = !this.satellite;
  }


  zoomIn() {
    this.map.zoomIn({duration: 500});
  }

  zoomOut() {
    this.map.zoomOut({duration: 500});
  }


  toggleTraffic() {

    // console.log(this.map.getStyle());
    //    mapbox-satellite   country-label


    if (this.traffic) {
      this.map.setPaintProperty('traffic-low', 'line-opacity', 0);
      this.map.setPaintProperty('traffic-heavy', 'line-opacity', 0);
      this.map.setPaintProperty('traffic-moderate', 'line-opacity', 0);
      this.map.setPaintProperty('traffic-severe', 'line-opacity', 0);
    } else {
      this.map.setPaintProperty('traffic-low', 'line-opacity', 1);
      this.map.setPaintProperty('traffic-heavy', 'line-opacity', 1);
      this.map.setPaintProperty('traffic-moderate', 'line-opacity', 1);
    }

    this.traffic = !this.traffic;


  }


  calcDistance(lat1, lon1, lat2, lon2, unit) {
    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    } else {
      const radlat1 = Math.PI * lat1 / 180;
      const radlat2 = Math.PI * lat2 / 180;
      const theta = lon1 - lon2;
      const radtheta = Math.PI * theta / 180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') {
        dist = dist * 1.609344;
      }
      if (unit === 'N') {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }


  toggle() {
    this.isOpen = !this.isOpen;
  }


  test() {
    this.map.fire('closeAllPopups');

  }


}
