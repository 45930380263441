<!--

<p>{{loadingMessage}}</p>


{{testtouch}}  <button (click)="drawTail()">tail</button>   includeStartEnd: {{includeStartEnd}}    loadingMessage:

mobileDevice: {{mobileDevice}}
<br>
filteredRegs: {{filteredRegs | async}}


-->
<mat-drawer-container class="side-nav-container" [hasBackdrop]="false">
  <mat-drawer #drawer [mode]="'over'" [opened]="true">
    <!--    <button mat-icon-button class="toggleDraw1" (click)="drawer.toggle()">
          <mat-icon>chevron_left</mat-icon>
        </button>-->
    <mat-progress-bar *ngIf="loading" mode="indeterminate" color="accent"></mat-progress-bar>
    <div class="directionLtr" *ngIf="!vehicleProperties">
      <h3 style="display: inline-flex;
    vertical-align: text-bottom;">Fleet overview</h3>
      <form *ngIf="filteredRegs" class="reg-form">
        <div *ngIf="!mobileDevice">
          <svg width="0" height="0">
            <symbol id="idling" viewBox="0 0 50 50">
              <circle cx="25" cy="25" r="25" fill="#e0e0e0"/>
              <polyline points="25 0 7 44 25 30 43 44" fill="orange"/>
            </symbol>
            <symbol id="driving" viewBox="0 0 50 50">
              <circle cx="25" cy="25" r="25" fill="#e0e0e0"/>
              <polyline points="25 0 7 44 25 30 43 44" fill="green"/>
            </symbol>
            <symbol id="parked" viewBox="0 0 50 50">
              <circle cx="25" cy="25" r="15" fill="blue"/>
            </symbol>
          </svg>
          <mat-chip-list [selectable]="true" [multiple]="true">
            <mat-chip
              *ngFor="let chip of quickChips"
              (click)="addQuickChip(chip)"
              [selected]="chip.selected"
              [removable]="chip.selected"
              (removed)="removeQuickChip(chip)">
              <svg *ngIf="chip.icon" width="15">
                <use [attr.xlink:href]="'#' + chip.icon" xlink:href=""></use>
              </svg>
              {{chip.name}}
              <mat-icon matChipRemove *ngIf="chip.selected">cancel</mat-icon>
            </mat-chip>
            <mat-chip
              *ngFor="let chip of extraChips"
              selected
              (removed)="removeExtraChip(chip)">
              {{chip}}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <mat-chip [matMenuTriggerFor]="tags" #menuTrigger="matMenuTrigger">
              more
              <mat-icon>more_vert</mat-icon>
            </mat-chip>
          </mat-chip-list>
          <mat-menu #tags="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="sub1">Example tags</button>
            <button mat-menu-item [matMenuTriggerFor]="sub2">Vehicle type</button>
          </mat-menu>
          <mat-menu #sub1="matMenu">
            <mat-chip (click)="addExtraChip('Flammables'); menuTrigger.closeMenu()">Flammables</mat-chip>
            <mat-chip (click)="addExtraChip('Managers'); menuTrigger.closeMenu()">Managers</mat-chip>
            <mat-chip (click)="addExtraChip('Sarah'); menuTrigger.closeMenu()">Sarah</mat-chip>
          </mat-menu>
          <mat-menu #sub2="matMenu">
            <mat-chip (click)="addExtraChip('Site 1'); menuTrigger.closeMenu()">Site 1</mat-chip>
            <mat-chip (click)="addExtraChip('Site 2'); menuTrigger.closeMenu()">Site 2</mat-chip>
            <mat-chip (click)="addExtraChip('Artic'); menuTrigger.closeMenu()">Artic</mat-chip>
            <mat-chip (click)="addExtraChip('Box'); menuTrigger.closeMenu()">Box</mat-chip>
          </mat-menu>
          <mat-form-field class="example-form-field">
            <mat-label>Search</mat-label>
            <input matInput type="text" [(ngModel)]="customChip" [ngModelOptions]="{standalone: true}" minlength="2">
            <button mat-button matSuffix mat-icon-button aria-label="Search"
                    (click)="addChip(customChip); customChip=''">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
          <div *ngIf="filteredRegs.length == 0">No results. Try removing some tags from your selection</div>
          <div *ngIf="filteredRegs.length > 0">{{filteredRegs.length}} results</div>
          <br>
          <div class="vehicleList" *ngFor="let state of filteredRegs" (click)="goToVehicleDetails(state)">
            <div class="arrow">
              <svg *ngIf="state.properties.drivingStatus == 'driving'" width="15" viewBox="0 0 50 50">
                <g>
                  <polyline points="25 0 7 44 25 30 43 44" fill="green"
                            [attr.transform]="'rotate('+ state.properties.head +' 25 25)'"/>
                </g>
              </svg>
              <svg *ngIf="state.properties.drivingStatus == 'idling'" width="15" viewBox="0 0 50 50">
                <g>
                  <polyline points="25 0 7 44 25 30 43 44" fill="orange"
                            [attr.transform]="'rotate('+ state.properties.head +' 25 25)'"/>
                </g>
              </svg>
              <svg *ngIf="state.properties.drivingStatus == 'parked' || state.properties.drivingStatus == 'offline' "
                   width="15" viewBox="0 0 50 50">
                <g>
                  <circle cx="25" cy="25" r="15" fill="blue"/>
                </g>
              </svg>
              <svg *ngIf="state.properties.drivingStatus == 'offline'" width="15" viewBox="0 0 50 50">
                <circle cx="25" cy="25" r="15" fill="#cccccc"/>
              </svg>
            </div>
            <span class="reg"
                  [ngClass]="{ 'driving':state.properties.drivingStatus == 'driving' }">{{state.properties.reg}}  </span>
            &nbsp;&nbsp;<span class="driver">{{state.properties.tags | json}}</span>
            <br>
            <span class="driver">{{state.properties.driver}}</span>
            <!-- <small>{{state.properties.deviceStatus}}</small>-->
            <span
              class="town"> <strong> {{state.properties.speed / 100 * 0.62137119223733  | number: '1.0-0' }}</strong> mph
              {{state.properties.town}}</span>
          </div>
        </div><!--ngIf end  mobileDevice -->
        <div *ngIf="mobileDevice">
          <mat-form-field>
            <mat-label>Vehicle</mat-label>
            <select matNativeControl name="state" #f [(ngModel)]="selectedRegMobile"
                    (change)="updateMapMarkersMobile()">
              <option *ngFor="let state of filteredRegs " [ngValue]="state">

                      <span
                        [ngClass]="{ 'driving':state.properties.deviceStatus == 'Driving' }">{{state.properties.reg}}</span>
                |
                <!-- <small>{{state.properties.deviceStatus}}</small>-->
                <!-- {{state.properties.speed}}
                 {{state.properties.heading}}-->
                <small>{{state.properties.town}}</small>
                |
                {{state.properties.deviceStatus}}
              </option>
            </select>
          </mat-form-field>
        </div><!--ngIf end  mobileDevice -->
      </form>
    </div>
    <div class="directionLtr" *ngIf="vehicleProperties">
      <h3>
        <button *ngIf="!journey" mat-icon-button aria-label="back" (click)="allVehicles();$event.stopPropagation()"
                class="closeJourneys"
                matTooltip="Close vehicle detail and view all vehicles">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <button *ngIf="journey" mat-icon-button aria-label="back" (click)="allJourneys();$event.stopPropagation()"
                class="closeJourneys"
                matTooltip="Close journey detail and view all journeys">
          <mat-icon>arrow_back</mat-icon>
        </button>
        {{vehicleProperties.properties.reg}}
      </h3>
      {{i}}
      <button (click)="i=journeys.length  - 1 ; drawRouteFromSelected(journeys[i]);">first journey</button>
      <button (click)="i=i-1; drawRouteFromSelected(journeys[i]); ">next journey</button>
      <!--<button (click)="i=i-1">multiLivestream button decrement</button>-->
      <button (click)="i=i+1;  drawRouteFromSelected(journeys[i]); ">previous journey</button>

      <app-journey-detail *ngIf="journey" [journey]="journey" (myEvent)="zoomJourneyStart($event)"
                          (myReloadEvent)="reloadJourney($event)"></app-journey-detail>

      <div class="eventPanel" *ngIf="journey">
        <app-events [eventsData]="eventsData" [vehicleEvent]="vehicleEvent"
                    [vehicleProperties]="vehicleProperties"
                    (getEvent)="getEvent($event)"
                    (childEvent)="eventsDialogClosed()"
                    (panelOpenStateEvent)="eventsPanelOpenResizeJourney($event)"></app-events>
        <button *ngIf="journey" mat-icon-button aria-label="back" (click)="allJourneys();$event.stopPropagation()"
                class="closeJourneys"
                matTooltip="Close journey detail and view all journeys">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <!--{{vehicleProperties.properties | json}}-->
      <!--<mat-slide-toggle (change)="mapMatch=!mapMatch"   [checked]="mapMatch"  >Map matching</mat-slide-toggle>-->
      <!--<mat-slide-toggle (change)="ShowAllPoints=!ShowAllPoints"   [checked]="mapMatch"  >Show all points</mat-slide-toggle>-->
      <div *ngIf="!journey">
        <mat-form-field appearance="standard" floatLabel="never" class="date-picker">
          <span matPrefix>
          <span>&nbsp;&nbsp;&nbsp;</span>
          </span>
          <input matInput [matDatepicker]="picker" [(ngModel)]="journeyDate" (dateChange)="listJourneys();"
                 placeholder="Select a date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker [touchUi]="mobileDevice" #picker></mat-datepicker>
        </mat-form-field>
        <button mat-stroked-button (click)="dateIncrement(-1)">Previous day</button>&nbsp;
        <button mat-stroked-button (click)="dateIncrement(1)">Next day</button>
        <div></div>
        <div class="today" *ngIf="trueToday">Today</div>
      </div>
      <div style="position: relative;">
        <mat-progress-bar *ngIf="loadingJourneys" mode="indeterminate" color="accent"></mat-progress-bar>
      </div>
      <div *ngIf="journeys.length > 0 && !journey">
        <mat-progress-bar class="bottom" *ngIf="loadingJourney"
                          mode="indeterminate" color="accent"></mat-progress-bar>
        <div *ngIf="!mobileDevice">
          <!--          <mat-paginator *ngIf="journeys.length > 5"
                                   (page)="onPageChange($event)"
                                   [length]=journeys.length
                                   [pageSize]="10"
                                   [hidePageSize]="true">
                    </mat-paginator>-->
          <div class="journeyList" *ngFor="let journey of journeys let i = index"
               (click)="drawRouteFromSelected(journey)">
            <svg id="circle" xmlns="http://www.w3.org/2000/svg" width="30" viewBox="0 0 30 30">
              <circle cx="15" cy="15" r="13" stroke="#999999" stroke-width="1" fill="white"/>
              <text text-anchor="middle" x="15" y="20">{{journeys.length - i}}</text>
            </svg>
            <span class="journeyListDetails">

              {{i}} - {{journey._id}}<br>prev: {{journey.previousId}}<br>
              <span *ngIf="journey.startTime">{{journey.startTime  | date: 'H:mm'}} -</span>
               <span *ngIf="journey.finish">  {{journey.finish.at  | date: 'H:mm'}} &nbsp; &nbsp;</span>


              <span *ngIf="!journey.completed">  In progress &nbsp; &nbsp;</span>
              <span><br>minDistance: {{journey.minDistance}}<br></span>

               <span> {{journey.distance | number: '1.0-1'}} miles</span>

                  <br>


                <span *ngIf="journey.startAddress && journey.endAddress " class="journeyAddress">

                <span *ngIf="journey.startAddress.town == journey.endAddress.town">
                  {{journey.startAddress.place}} to {{journey.endAddress.place}}, {{journey.endAddress.town}}
                </span>

                  <span *ngIf="journey.startAddress.town != journey.endAddress.town">
                    {{journey.startAddress.town}} to {{journey.endAddress.town}}
                  </span>

                </span>

                </span>
            <span class="iconBadgeAlert pull-right" *ngIf="journey.eventCount > 0">
                  <span matBadge="{{journey.eventCount}}" matBadgeColor="warn" matBadgePosition="after"></span>

                  &nbsp;&nbsp;</span>
          </div>
        </div>
        <div *ngIf="mobileDevice">
          <mat-form-field appearance="standard" floatLabel="never">
            <mat-label>Journey</mat-label>
            <select matNativeControl [(ngModel)]="selectedJourneyMobile"
                    (change)="drawRouteFromSelected(selectedJourneyMobile)">
              <option *ngFor="let journey of journeys let i = index" [ngValue]="journey">
                {{journey.StartString | slice:11:16 }}  -
                {{journey.EndString | slice:11:16 }}
                                                        &nbsp;&nbsp;
                                                        &nbsp;&nbsp;
                <span *ngIf="journey.completed === true"> {{journey.distance | number: '1.0-0'}} miles</span>
              </option>
            </select>
          </mat-form-field>
          <mat-progress-bar class="bottom" *ngIf="loadingJourney" mode="indeterminate"
                            color="accent"></mat-progress-bar>
        </div><!--ngIf end  mobileDevice -->
      </div>
      <div *ngIf="!journeys">
        No journeys on this date
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div class="map" id="map">
      <div class="toggleDraw">
        <div (click)="toggle();  drawer.toggle() " [@openClose]="isOpen ? 'open' : 'closed'"
             class="open-close-container">
          <div [@inner]="isOpen ? 'open' : 'closed'" class="open-close-container2">
            <mat-icon class="chevron">chevron_left</mat-icon>
          </div>
        </div>
      </div>
      <!--<app-loading-spinner *ngIf="loading"></app-loading-spinner>-->
      <div class="mapLayerFilterHolder">
        <button mat-icon-button aria-label="traffic" matTooltip="Zoom in"
                (click)="zoomIn()">
          <mat-icon>add</mat-icon>
        </button>
        <hr>
        <button mat-icon-button aria-label="traffic" matTooltip="zoom out"
                (click)="zoomOut()">
          <mat-icon>remove</mat-icon>
        </button>
        <hr>
        <button mat-icon-button aria-label="traffic" matTooltip="traffic" [color]="traffic ? 'accent' : null"
                (click)="toggleTraffic()">
          <mat-icon>traffic</mat-icon>
        </button>
        <hr>
        <button mat-icon-button aria-label="satellite" matTooltip="Satellite" [color]="satellite ? 'accent' : null"
                (click)="toggleSatellite()">
          <mat-icon>satellite</mat-icon>
        </button>
      </div>
      <div class="filters">
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
