
<div style="display: flex;">
  <h1 style="font-size: 28px!important;font-weight: bold;">Groups</h1>
  <div style="flex:1;text-align: center;">
      <form  [hidden]="!showSearch">
        <mat-form-field style="width:300px;">
          <mat-label>Search By Email, Name or Fleet Access</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Filter" [(ngModel)]="searchValue" [ngModelOptions]="{standalone: true}">
        </mat-form-field>
        <button mat-button   mat-flat-button  color="primary"  aria-label="Clear" (click)="cancelFilter()" style="margin-left:20px;">
          <mat-icon>close</mat-icon>
          Reset Filter
        </button>
      </form>
  </div>
  <button mat-flat-button (click)="showHideSearch()" style="margin-right:5px;height: 40px !important;" matTooltip="Search users">
    <mat-icon>search</mat-icon>
  </button>
  <button mat-raised-button (click)="editGroup($event, null)"
          style="margin-right:5px;height: 40px !important;background-color:#5C8459 !important;color:white!important;box-shadow: 2px 2px 0 0 rgb(0 0 0 / 20%)">
    + Add Group
  </button>
</div>



<div style="max-height: 75vh!important;overflowY:scroll;">
<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="group">
    <th mat-header-cell *matHeaderCellDef style="text-align: left;">
      Group
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Group name">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: left;">{{element.mflGroup}}</td>
  </ng-container>
  <ng-container matColumnDef="webhook">
    <th mat-header-cell *matHeaderCellDef style="text-align: left;">
      Webhook
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Webhook endpoint">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: left;">{{element.webhook}}</td>
  </ng-container>
  <ng-container matColumnDef="groupKey">
    <th mat-header-cell *matHeaderCellDef style="text-align:left!important;">
      Group key
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Group key">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: left;">{{element.groupKey}}</td>
  </ng-container>

  <ng-container matColumnDef="totalDevices">
    <th mat-header-cell *matHeaderCellDef style="text-align:center!important;">
      Total Devices
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Total of devices across all fleets for this group">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: center;">
      <span *ngIf="allDevices">{{deviceStatusCount(element.mflGroup, 'ALL')}}</span>
      <mat-progress-bar *ngIf="!allDevices" mode="indeterminate"  style="width:75%!important;"></mat-progress-bar>
    </td>
  </ng-container>



  <ng-container matColumnDef="active">
    <th mat-header-cell *matHeaderCellDef style="text-align:center!important;">
      Active Devices
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Total of active devices across all fleets for this group">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: center;">
      <span *ngIf="allDevices">{{deviceStatusCount(element.mflGroup, 'active')}}</span>
      <mat-progress-bar *ngIf="!allDevices" mode="indeterminate"  style="width:75%!important;"></mat-progress-bar>
    </td>
  </ng-container>

  <ng-container matColumnDef="install">
    <th mat-header-cell *matHeaderCellDef style="text-align:center!important;">
      Pending Install
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Total of devices pending installation across all fleets for this group">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: center;">
      <span *ngIf="allDevices">{{deviceStatusCount(element.mflGroup, 'tested')}}</span>
      <mat-progress-bar *ngIf="!allDevices" mode="indeterminate"  style="width:75%!important;"></mat-progress-bar>
    </td>
  </ng-container>





  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef style="text-align: center;">Edit</th>
    <td mat-cell *matCellDef="let element">
      <button mat-flat-button (click)="editGroup($event, element)" style="line-height: 30px;">
        <mat-icon style="font-size: 22px;">edit</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="groupsDisplayedColumns;sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: groupsDisplayedColumns;"></tr>
</table>

<mat-paginator [pageSizeOptions]="[25, 50, 75]" showFirstLastButtons></mat-paginator>
</div>










<ng-template #editGroupDialog>

  <h1 *ngIf="group" style="font-size: 28px!important;">Editing Group</h1>
  <h1 *ngIf="!group" style="font-size: 28px!important;">Adding Group</h1>
  <form [formGroup]="groupForm" (ngSubmit)="onFormSubmit()">


    <!-- {{user | json}}-->
    <div style="display: flex; width: 100%;flex-wrap: wrap;">
      <div style="text-align: left;flex:1;padding-right:10px;">
        <mat-form-field>
          <mat-label>Group name</mat-label>
          <input matInput formControlName="mflGroup">
          <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="Group name" matSuffix>
            <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
          </svg>
        </mat-form-field>
      </div>
      <div style="flex:1;padding: 0 10px;">
        <mat-form-field>
          <mat-label>Webhook</mat-label>
          <input matInput formControlName="webhook">
          <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="Webhook endpoint" matSuffix>
            <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
          </svg>
        </mat-form-field>
      </div>

      <div style="flex:1;padding: 0 10px;">
        <mat-form-field>
          <mat-label>Group key</mat-label>
          <input matInput formControlName="groupKey">
          <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;margin-right:5px;" viewBox="0 0 24 24" matTooltip="User authorisation" matSuffix>
            <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
          </svg>
        </mat-form-field>
      </div>

    <br>
    <center>
      <button mat-flat-button color="primary" type="submit">
        <mat-icon>save</mat-icon>
        &nbsp;Save changes
      </button>
    </center>
    </div>
  </form>


</ng-template>
