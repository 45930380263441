<div *ngIf="!healthGraphRawData || healthGraphRawData.length < 1" style="text-align: center;">
  No health data logged
</div>


<div *ngIf="healthGraphRawData && healthGraphRawData.length > 0">
  <table style="width:100%;">
    <tr>
      <th>Time</th>
      <td *ngFor="let alarm of healthGraphRawData" style="text-align:center;" [innerHTML]="dateTimeFormat(alarm?.alarmData?.statusData?.deviceTime)"></td>
    </tr>
    <tr>
      <th>Network</th>
      <td *ngFor="let alarm of healthGraphRawData">
        <div class="healthCircle" [style.background]="alarm?.alarmData?.statusData?.networkType < 3 ? '#F24141' : alarm?.alarmData?.statusData?.networkType < 4 ? 'orange' : ''"
             [matTooltip]="'Reading network connection as '+ getNetworkTypeReadable(alarm?.alarmData?.statusData?.networkType) +  dateTimeFormatTooltip(alarm?.alarmData?.statusData?.deviceTime)">
          <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);"
               [style.color]="alarm?.alarmData?.statusData?.networkType < 4 ? 'white' : '#5C8459'">
            {{getNetworkTypeReadable(alarm?.alarmData?.statusData?.networkType)}}
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <th>Satellites</th>
      <td *ngFor="let alarm of healthGraphRawData">
        <div class="healthCircle" [style.background]="alarm?.alarmData?.statusData?.satellitesQuantity < 4 ? '#F24141' : alarm?.alarmData?.statusData?.satellitesQuantity < 8 ? 'orange' : ''"
           [matTooltip]="'The device was connected to '+ alarm?.alarmData?.statusData?.satellitesQuantity + ' satellites' +  dateTimeFormatTooltip(alarm?.alarmData?.statusData?.deviceTime)">
        <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);"
               [style.color]="alarm?.alarmData?.statusData?.satellitesQuantity < 8 ? 'white' : '#5C8459'">
          {{alarm?.alarmData?.statusData?.satellitesQuantity}}
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <th>G-Sensor</th>
      <td *ngFor="let alarm of healthGraphRawData" [matTooltip]="'Reading a G-Sensor value of ' + alarm?.alarmData?.statusData?.gSensorImpact + dateTimeFormatTooltip(alarm?.alarmData?.statusData?.deviceTime)">
        <div class="healthCircle"
        [style.background]="alarm?.alarmData?.statusData?.gSensorImpact <= 10 ? '' : alarm?.alarmData?.statusData?.gSensorImpact <= 15 ? 'orange' : '#F24141'">
          <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);"
               [style.color]="alarm?.alarmData?.statusData?.gSensorImpact <= 10 ? '#5C8459' : 'white'">
            {{alarm?.alarmData?.statusData?.gSensorImpact}}
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <th>Storage</th>
      <td *ngFor="let alarm of healthGraphRawData">
        <div class="healthCircle" [matTooltip]="checkStorageHealth(alarm) ? 'The device has a recording for this time' : 'The device does not have a recording for this time'">
          <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);">
            <span *ngIf="checkStorageHealth(alarm)" style="color:#5C8459">&#10004;</span>
            <span *ngIf="!checkStorageHealth(alarm)" style="color:#F24141">&#10006;</span>
          </div>
        </div>

      </td>
    </tr>

    <tr *ngFor="let cam of cameras; let i = index">
      <th >{{cam.camPosition | titlecase}} camera ({{cam.channel}}) <br> Health</th>
      <td *ngFor="let alarm of healthGraphRawData">
        <div class="healthCircle" [style.background]="!checkCameraHealth(alarm, cam.channel) ? '#F24141' : ''"
             [matTooltip]="getCamHealthTooltip(checkCameraHealth(alarm, cam.channel), alarm?.alarmData?.statusData?.deviceTime)">
          <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);"
               [style.color]="!checkCameraHealth(alarm, cam.channel) ? 'white' : '#5C8459'">
            <span *ngIf="checkCameraHealth(alarm, cam.channel)">&#10004;</span>
            <span *ngIf="!checkCameraHealth(alarm, cam.channel)">&#10006;</span>
          </div>
        </div>
      </td>
    </tr>
    <tr *ngFor="let cam of cameras; let i = index">
      <th>{{cam.camPosition | titlecase}} camera ({{cam.channel}}) <br> Recording</th>
      <td *ngFor="let alarm of healthGraphRawData">
        <div class="healthCircle" [style.background]="!checkRecording(alarm, cam.channel) ? '#F24141' : ''"
             [matTooltip]="getCamRecTooltip(checkRecording(alarm, cam.channel), alarm?.alarmData?.statusData?.deviceTime)">
          <div style="position: absolute;top: 50%;left: 50%;transform: translate(-50%, -50%);"
               [style.color]="!checkRecording(alarm, cam.channel) ? 'white' : '#5C8459'">
            <span *ngIf="checkRecording(alarm, cam.channel)">&#10004;</span>
            <span *ngIf="!checkRecording(alarm, cam.channel)">&#10006;</span>
          </div>
        </div>
      </td>
    </tr>


  </table>
</div>
