<mat-dialog-content class="mat-typography">
<form [formGroup]="vehicleForm" style="margin-top:10px;">


  <ng-container *ngIf="authorisation !== 'superuser'">
  <mat-form-field>
    <mat-label>Event profile</mat-label>
    <mat-select formControlName="eventProfile" (selectionChange)="changeEventProfile()">
      <mat-option value="">-- Please select --</mat-option>
      <mat-option value="default">MFL-2CN-Default</mat-option>
      <mat-option  *ngIf="mflGroup == 'RAM' || mflGroup == 'undefined'" value="RAM_default">RAM-2CN-Default</mat-option>
      <mat-option value="ch5_default">MFL-5CN-Default</mat-option>
      <mat-option value="ch5_no_video">MFL-5CN-NoVideo</mat-option>
      <mat-option value="5CN-AHD-TRU-DEFAULT">5CN-AHD-TRU-DEFAULT</mat-option>
      <mat-option value="8CA-AHD-VAN-CHOWELL">8CA-AHD-VAN-CHOWELL</mat-option>
      <mat-option value="8CA-AHD-TRU-DEFAULT">8CA-AHD-TRU-DEFAULT</mat-option>
    </mat-select>
  </mat-form-field>
  </ng-container>

  <ng-container *ngIf="authorisation === 'superuser'">
    <mat-form-field>
      <mat-label>Event profile</mat-label>
      <mat-select formControlName="eventProfile" (selectionChange)="changeEventProfile()">
        <mat-option value="">-- Please select --</mat-option>
        <mat-option *ngFor="let profile of eventProfiles" [value]="profile.profileName">{{profile.profileName}}</mat-option>
      </mat-select>
    </mat-form-field>

  </ng-container>

<!--  <div>-->

<!--    <mat-slider [value]="modValue" (change)="updateEventProfileModifier($event.value)" max="200" min="0" step="20"></mat-slider>-->


<!--  </div>-->


  <div>
  </div>


</form>
</mat-dialog-content>
