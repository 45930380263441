import {AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { environment } from 'src/environments/environment';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {AdminService} from '../../admin.service';
import {noOfChannels} from "../../../lib";
import { ImageCroppedEvent } from 'ngx-image-cropper';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-device-settings',
  templateUrl: './device-settings.component.html',
  styleUrls: ['./device-settings.component.css']
})
export class DeviceSettingsComponent implements AfterViewInit {

  @ViewChild('camerasModal', { static: true }) camerasModal: TemplateRef<any>;
  @ViewChild('cropModal', { static: true }) cropModal: TemplateRef<any>;


  environment = environment;
  timeZones = environment.TIMEZONES;
  @Input() vehicleForm;
  @Input() vehicle;
  @Input() camerasSettingsFormArray;
  @Input() deviceParameters;
  @Input() dn;
  @Input() config;
  @Input() cameras;
  @Input() getAllParameters: (param) => void;
  @Input() deviceParametersRaw;
  @Input() availableChannels;
  userLevel = localStorage.getItem('authorisation');
  channels: any;
  JSON = JSON;
  cropped: any;
  cameraToCrop: any;
  originalImage: any;
  initCrop: any;

  defaultCameraArray = [{channel: '1', camPosition: 'Front', camConfig: 'balanced', isRec: '1', HaveAudio: '0', Mirror: '0'},
    {channel: '2', camPosition: 'Driver', camConfig: 'balanced', isRec: '1', HaveAudio: '0', Mirror: '0'}];


  constructor(private dialog: MatDialog, private adminService: AdminService, private sanitizer: DomSanitizer) { }

  ngAfterViewInit(): void {
  }


  theChannels(cam): any {
    return [...this.availableChannels, cam.value.channel]
  }

  showCameraForm(): void {
    console.log(this.availableChannels)

    this.dialog.open(this.camerasModal,  { closeOnNavigation: true, hasBackdrop: true});
  }

  addCamera(): void {
    const newChan = (this.camerasSettingsFormArray.controls.length + 1);

    if (newChan <= noOfChannels(this.vehicle.device)) {
      this.camerasSettingsFormArray.push( new UntypedFormGroup({
        channel: new UntypedFormControl(newChan.toString(), Validators.required),
        camPosition: new UntypedFormControl('', [Validators.required]),
        camConfig: new UntypedFormControl('', [Validators.required]),
        HaveAudio: new UntypedFormControl('', [Validators.required]),
        isRec: new UntypedFormControl('', [Validators.required]),
        Mirror: new UntypedFormControl('', [Validators.required])
      }));
    }
  }

  removeCamera(index: number): void {
    this.camerasSettingsFormArray.removeAt(index);
  }

  modelNumberDisplay(no): string{
    let display;
    if (no === 'ME41-02'){
      display = 'MFL-2CN4H01-EU';
    } else {
      environment.MODEL_NUMBER_ARRAY.forEach(model => {
        if (model.modelNoDb === no){
          display = model.modelNoDisplay;
        }
      });
    }
    return display;
  }

  // updateMirror(mirror, ch): void {
  //   const data =
  //     {
  //       dn: this.dn,
  //       ch: ch.toString(),
  //       mirror
  //     };
  //
  //
  //   this.adminService.updateMirror(data).subscribe(result => {
  //     console.log(result);
  //     this.getAllParameters(this.vehicle?.dn);
  //   });
  //
  // }


  splitStr(str): string {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  updateTimezone(value): void {
    this.vehicleForm.patchValue({timezone: value});
  }


  attributeDisplay(attribute1,attribute2) {
    if (attribute1 == attribute2) {
      return attribute1;
    } else {
      return "";
    }
  }

  haveAudioTooltip(bool): any {
    return bool ? "Audio setting pending (on)" : "Audio setting pending (off)";
  }

  haveRecTooltip(bool): any {
    return bool === "1" ? "Recording setting pending (on)" : "Recording setting pending (off)";
  }

  mirrorTooltip(bool, camerasGroup): any {
    let flip;
    switch (this.config?.RECORD?.MainChn['chn'+(camerasGroup?.value?.channel-1)]?.Mirror){
      case "0": flip = "Flip setting pending (None)";break;
      case "1": flip = "Flip setting pending (Horizontally)";break;
      case "2": flip = "Flip setting pending (Vertically)";break;
      case "3": flip = "Flip setting pending (All)";break;
    }
    return flip;
  }


  addDefaultCameras(): void {
    this.defaultCameraArray.forEach(cam => {
      this.camerasSettingsFormArray.push( new UntypedFormGroup({
        channel: new UntypedFormControl(cam.channel, [Validators.required]),
        camPosition: new UntypedFormControl(cam.camPosition, [Validators.required]),
        camConfig: new UntypedFormControl(cam.camConfig, [Validators.required]),
        HaveAudio: new UntypedFormControl(cam.HaveAudio, [Validators.required]),
        isRec: new UntypedFormControl(cam.isRec, [Validators.required]),
        Mirror: new UntypedFormControl(cam?.Mirror)
      }));
    });
  }


  cropCamera(cam, i): void {
    this.cameraToCrop = i

    this.adminService.latestSnapshots({dn: this.vehicle.dn, ch: this.camerasSettingsFormArray.value[this.cameraToCrop].channel, limit:1}).subscribe(r => {

      this.camerasSettingsFormArray.controls[this.cameraToCrop]?.addControl('crop', new UntypedFormControl(''));

      const file = r[0].snapshots?.file;
      this.adminService.viewSnapshot(file + '_lrg').then(image => {
        this.originalImage = URL.createObjectURL(image);
        this.dialog.open(this.cropModal,  { closeOnNavigation: true, hasBackdrop: true});
      });
    });
  }


  // change to save button click
  imageCropped(event: ImageCroppedEvent) {
    this.cropped = event.width + ':' + event.height + ':' + event.cropperPosition.x1 + ':' + event.cropperPosition.y1
    console.log(this.cropped)
  }

  imageLoaded() {
    console.log('loaded')
    setTimeout(() => {
      if (this.camerasSettingsFormArray.value[this.cameraToCrop].crop){
        const arr = this.camerasSettingsFormArray.value[this.cameraToCrop].crop.split(':')
        this.initCrop = {x1:parseInt(arr[2])/4, y1:parseInt(arr[3])/4, x2:parseInt(arr[0])/4, y2: parseInt(arr[1])/4};
      }
    }, 1);
  }

  saveCropping(): void {
    this.camerasSettingsFormArray.controls[this.cameraToCrop].patchValue({crop: this.cropped});
    this.dialog.closeAll();

  }


  // isSameJson(a,b): boolean {
  //   return JSON.stringify(a) == JSON.stringify(b)
  // }
  //

}
