import {Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import {AdminService} from '../admin.service';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {distinctUntilChanged, map, startWith, switchMap, filter} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';

import {MatSort} from '@angular/material/sort';
import { environment } from 'src/environments/environment';
import {MatDialog} from '@angular/material/dialog';
import { MatPaginator} from '@angular/material/paginator';import { Title } from '@angular/platform-browser';
import {isDeviceOnline, isDeviceParked, modelNumberDisplay} from '../../lib';


@Component({
  selector: 'app-vehicles',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit {


  isDeviceOnline = isDeviceOnline;
  isDeviceParked = isDeviceParked;
  modelNumberDisplay = modelNumberDisplay;

  env = environment;
  vehicle: any;
  vehicles: any;
  partnersVehicles: any;
  allFleets: any;
  groups = new Set();
  // fleetDropDown: any;
  dataSource: MatTableDataSource<any>;
  partnersDatasource: MatTableDataSource<any>;
  newId: string;
  fleetFilter = new UntypedFormControl();
  statusFilter = new UntypedFormControl();
  mflGroupControl = new UntypedFormControl();
  usersGroup = localStorage.getItem('mflGroup');
  userLevel = localStorage.getItem('authorisation');
  showFilters = true;
  tableLoading = false;
  searchParams = new UntypedFormControl('');
  filteredOptions: Observable<any>;
  quickNavDn: string;

  displayedColumnsPartner: string[] = ['dn', 'registration', 'fleetId', 'cameras', 'SIM', 'model', 'lastSeen', 'commissionStatus'];

  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('secondDialog', { static: true }) secondDialog: TemplateRef<any>;
  fleetsDropdown: any;

  /*** filter by anything*/
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.partnersDatasource.filter = filterValue.trim().toLowerCase();
  }








  constructor(private adminService: AdminService, private dialog: MatDialog, private titleService: Title) {}

  ngOnInit(): void {





    window.addEventListener('scroll', this.onPageScroll);

    this.adminService.fleets({}).subscribe(result => {
      const array = [];
      result.forEach(fleet => {
        array[fleet.fleetId] = fleet;
      });


      this.allFleets = array;

      this.fleetsDropdown = result;

    });

    this.statusFilter.valueChanges.subscribe(value => {
      if (value !== null){

        const data = {
          'commissioning.commissionStatus': value,
          fleetId: undefined
        };

        if (this.fleetFilter.value !== '' && this.fleetFilter.value !== null) {
          data.fleetId = this.fleetFilter.value;
        }
        console.log(data)
        // this.refresh(data);
      }
    });

    this.fleetFilter.valueChanges.subscribe(value => {
      console.log(value);

      // https://stackoverflow.com/questions/50794298/allow-user-to-type-in-only-the-options-in-mat-autocomplete-in-angular-6

      // TODO only if value is from dropdown !!

      const data = {
        fleetId: undefined
      };

      if (value !== null ) {
        const obj = this.fleetsDropdown.find(f => f.fleetId === value);

        data.fleetId = obj.fleetId;

        if (this.statusFilter.value !== null) {
          data['commissioning.commissionStatus'] = this.statusFilter.value;
        }
        this.refresh(data);

      }
    });


    this.mflGroupControl.valueChanges.subscribe(value => {
      console.log(this.mflGroupControl.value);

      if (value !== null){
        var data = {'mflG.mflGroup': value};
        console.log(data);
        this.refresh(data);
      }
    });







    // ram or us show install else show active

    if (this.userLevel !== 'fleet'){
      this.refresh({'commissioning.commissionStatus': { $in: ['tested', 'service', 'install']}});
    } else {
      this.refresh({'commissioning.commissionStatus': { $in: ['tested', 'service', 'active', 'disabled', 'expired', 'setup']}});
    }





    this.paginator._intl.itemsPerPageLabel = 'Devices per page';
    this.paginator._intl.getRangeLabel = (page: number, pageSize: number, length: number): string => {
      if (length === 0 || pageSize === 0) { return `0 of ${length}`; }

        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex = startIndex < length ?
          Math.min(startIndex + pageSize, length) :
          startIndex + pageSize;
      if (endIndex === length){
        return 'Showing all filtered devices';
      } else {
        return `Showing  devices ${startIndex + 1} - ${endIndex} of ${length} total devices`;
      }      };
    this.titleService.setTitle('Devices');
    }


  cancelFleetFilter() {
    this.fleetFilter.setValue(null);
    this.statusFilter.setValue(null);
    this.mflGroupControl.setValue(null);
    this.searchParams.setValue(null);
    this.refresh({'commissioning.commissionStatus': { $in: ['tested', 'service']}});  }


  addNewVehicle() {
    this.adminService.addVehicle({}).subscribe(result => {
      this.newId = result.insertedId;
      this.refresh({});
      this.dialog.open(this.secondDialog,  { disableClose: true, closeOnNavigation: true, hasBackdrop: true, data: {newId: this.newId} });
    });
  }


/*  private _filter(value: any) {
    const filterValue = value.toLowerCase();


     return this.adminService.fleets({}).pipe(
      filter(data => !!data),
      map((data) => {

        this.allFleets = data;
        return data.filter(option => option.fleetId.toLowerCase().includes(value));

      })
    )
  }*/




  refresh(data) {
    this.tableLoading = true;
    console.log('refresh');
    delete this.partnersDatasource;
    // const data = { fleetId: fleetId};
    this.adminService.vehicles(data).subscribe(result => {

      console.log(result);

      if (result.length < 1){
        this.showAllDevices();
      }

      this.vehicles = result;
      this.partnersVehicles = [];

      this.vehicles.forEach(vehicle => {

        if (typeof vehicle.lastLocation !== 'undefined') {
          vehicle.lastSeen = vehicle.lastLocation.at;
        }


        if (typeof vehicle.commissioning !== 'undefined') {
          vehicle.commissionStatus = vehicle.commissioning.commissionStatus;

          // taken out to allow internal viewing of setup devices, partners should not be able to see setup devices
          // if( device-setup.commissionStatus !== 'setup'){
            this.partnersVehicles.push(vehicle);
          // }
        }

        this.groups.add(vehicle.mflGroup);


      });




      this.partnersDatasource = new MatTableDataSource(this.partnersVehicles);
      this.partnersDatasource.sort = this.sort;


      this.partnersDatasource.filterPredicate = function(data: any, filterValue: string) {

          return (data.dn || '').toLowerCase().includes(filterValue.toLowerCase()) ||
        (data.registration || '').toLowerCase().includes(filterValue.toLowerCase()) ||
        (data.deviceSIM || '').toLowerCase().includes(filterValue.toLowerCase());
      };

      this.partnersDatasource.paginator = this.paginator;
      localStorage.removeItem('devices');


      this.tableLoading = false;


    });
  }


  isSubset = (superObj, subObj) => {
    try {
      return Object.keys(subObj).every(ele => {
        if (typeof subObj[ele] == 'object') {
          return this.isSubset(superObj[ele], subObj[ele]);
        }
        return subObj[ele] === superObj[ele]
      });
    }
    catch {
      return false;
    }
  }

  showHideFilters(): void {
    this.showFilters ? this.showFilters = false : this.showFilters = true;
    setTimeout(() => {
      (document.getElementById('searchBarInput')as HTMLElement).focus();
    }, 100);

  }

  showAllDevices(): void {
    if (localStorage.getItem('mflGroup') === 'undefined' || localStorage.getItem('authorisation') === 'superuser'){
      this.refresh({});
    } else {
      this.refresh({'commissioning.commissionStatus': { $in: ['tested', 'service', 'active', 'disabled', 'expired', 'setup']}});
    }
  }

  scrollToTop(): void {
    document.documentElement.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

   onPageScroll(): void {
    const vh = window.innerHeight;
    const y = window.scrollY;
    if (y > (vh/2)) {
      (document.getElementById('scrollTopButtonEle') as HTMLElement).style.display = 'block';
    } else {
      (document.getElementById('scrollTopButtonEle') as HTMLElement).style.display = 'none';
    }
  }

   openNewTab(data): void {
    window.open('/device/' + data, '_blank');
  }

  keyFunc(event): void {
    event.stopPropagation();
    if (event?.code === 'Enter' && this.quickNavDn?.length > 5){
      this.openNewTab(this.quickNavDn);
    }
  }




}
