<style>
  p {
    margin: 0;
    margin-left: 2px;
    padding: 0;
    font-family: Arial, serif;
    font-size: 13px;
    line-height: 14px;
  }



  .mat-stroked-button {
    transform: scale(0.8);
    left: -13px;
  }


  .cont {
    position: relative;
  }

  .message {
    color: #ea00eb;
    font-size: 11px;
    line-height: 12px;
  }


</style>
<div  style="display: flex;justify-content: space-between;background-color:#e9e9e9;padding:15px 5px 5px 5px;">
  <div style="text-align: left;">
    <h3 class="registrationPlate" *ngIf="model['registration']">{{model.registration}}</h3>
  </div>
  <svg id="circle" xmlns="http://www.w3.org/2000/svg" width="65" viewBox="0 0 65 30" *ngIf="model['speed']">
    <defs>
      <style>
        .small {
          font-size: 15px;
          font-weight: bold;
          text-anchor: middle;
          font-family: "Arial Black", serif
        }
        .mph {
          font-size: 11px;
          font-weight: normal;
          text-anchor: middle;
          font-family: "Arial", serif;
          color: #333333;
          fill:  #333333;
        }
      </style>
    </defs>

    <circle cx="15" cy="15" r="13" stroke="black" stroke-width="3" fill="white"/>
    <text class="small" x="15" y="20"> {{model["speed"]}} </text>
    <text class="mph" x="43" y="20">mph</text>
    <g *ngIf="model['maxspeed'] && model['maxspeed'] != 'null'">
      <circle cx="75" cy="15" r="13" stroke="red" stroke-width="3" fill="white"/>
      <text class="small" x="75" y="20"> {{model['maxspeed']}} </text>
      <text class="mph" x="105" y="20">zone</text>
    </g>
  </svg>
</div>


<div class="cont">
  <div *ngIf="model['loadingAddress']">
    <mat-progress-bar  mode="indeterminate"></mat-progress-bar>
  </div>



  <p>
    <span *ngIf="model['time']" class="address">
      <b>Time:</b>
      {{model['time'] | date: 'HH:mm:ss'}}
    </span><br>
    <span *ngIf="model['distance']" class="address">
      <b>Journey distance:</b>
      {{(model["distance"])?.toFixed(2) }} miles
    </span>
    <br>
    <span class="address" *ngIf="!model['loadingAddress'] && model['address']">
      <b>Address:</b>
      {{model["address"] | slice: 0:-16 }}
    </span>
    <br>
    <span *ngIf="model['gpsAcc']" class="address">
      <b>Accuracy:</b>{{model["gpsAcc"]}}m
    </span>
    <br>
    <span *ngIf="model['netType']" class="address">
      <b>
        <span *ngIf="model['netType']=='4'">3G: </span>
        <span *ngIf="model['netType']=='5'">4G: </span>
      </b>
    </span>
    <span *ngIf="model['sigInt']" class="address">{{model["sigInt"]}}</span>
  </p>

  <p *ngIf="model['mediaType'] && model['mediaType'] == 'hyperlapse'" class="address" style="text-align: center">
    <span *ngIf="model['hyperlapseEnd']"><b>Hyperlapse end point selected, check details on right and request</b></span>
    <span *ngIf="model['hyperlapseStart'] && !model['hyperlapseEnd']"><b>Hyperlapse start point selected, now select endpoint</b></span>
  </p>
  <p *ngIf="model['mediaType'] && model['mediaType'] == 'video'" class="address" style="text-align: center">
    <b>Drop a pin on the map, or key in time values on the right to request</b>
  </p>
  <p *ngIf="model['mediaType'] && model['mediaType'] == 'hyperlapse'" class="address" style="text-align: center">
    <span *ngIf="!model['hyperlapseEnd'] && !model['hyperlapseStart']">
      <b>Select start hyperlapse point</b>
    </span>
  </p>

  <p style="text-align:center;" *ngIf="!model['mediaType']" class="address">
    <b>Please select a media type above</b>
  </p>






<!--  <button mat-flat-button (click)="generateEventFromPopup(); model['requestVideo']=true" style="width: 100%;">-->
<!--    <mat-icon>warning</mat-icon>-->
<!--    &nbsp;Create an event-->
<!--  </button>-->


<!--  <button  (click)="reverseGeocodeInPopup()" >Reverse geocode</button>-->
<!--  <mat-progress-bar *ngIf="model['requestVideo'] && !message" mode="indeterminate" color = "accent"></mat-progress-bar>-->
<!--  <p  class="message" *ngIf="message">Your video request is being processed</p>-->
<!--  <button (click)="reloadJourney()">reloadJourney</button>-->


</div>
