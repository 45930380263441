<div style="display: flex;flex-wrap: wrap;">
  <div style="display:flex; align-items: center;justify-content: center">
    <h1 style="font-size: 28px!important;font-weight: bold;">Devices</h1>
  </div>
  <div style="flex:1"></div>
  <button mat-flat-button (click)="showHideFilters()" style="margin-right:5px;height: 40px !important;" matTooltip="Search or filter devices">
    <mat-icon>search</mat-icon>
  </button>
  <button mat-raised-button  color="primary" (click)="addNewVehicle()"
          style="margin-right:5px;height: 40px !important;background-color:#5C8459 !important;box-shadow: 2px 2px 0 0 rgb(0 0 0 / 20%)">
    + Add Device
  </button>

  <a routerLink="/dashboard" routerLinkActive="activeLink" mat-raised-button  *ngIf="userLevel === 'superuser'"
     style="margin-right:5px;height: 40px !important;" >
    Dashboard
  </a>



</div>
<br>
<div [hidden]="!showFilters" style="text-align: center;">
    <mat-form-field matTooltip="Filter by fleet">
      <mat-label>Fleet</mat-label>
      <mat-select [formControl]="fleetFilter">
        <mat-option *ngFor="let fleet of fleetsDropdown" [value]="fleet.fleetId">
          {{fleet.fleetName}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field matTooltip="Filter by platform status">
      <mat-label>Status</mat-label>
      <mat-select [formControl]="statusFilter">
        <mat-option [value]="'setup'" *ngIf="usersGroup === 'undefined' && userLevel !== 'fleet'">Setup</mat-option>
        <mat-option [value]="'tested'" *ngIf="userLevel !== 'fleet'">Install</mat-option>
        <mat-option [value]="'active'">Active</mat-option>
        <mat-option [value]="'service'">Service</mat-option>
        <mat-option [value]="'expired'">Expired</mat-option>
        <mat-option [value]="'disabled'">Disabled</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Filter criteria</mat-label>
      <input matInput id="searchBarInput" (keyup)="applyFilter($event)" placeholder="Filter"  [formControl]="searchParams">
      <svg matSuffix style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Filter by DN, SIM number or registration">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </mat-form-field>


    <mat-form-field>
      <mat-label>Quick Navigation</mat-label>
      <input matInput  placeholder="Quick Nav"  [(ngModel)]="quickNavDn" [ngModelOptions]="{standalone: true}"  (keyup)="keyFunc($event)">
      <svg matSuffix style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Enter a dn to quicky navigate to the device page">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>

      <a  matSuffix  color="primary"  aria-label="Clear" routerLink="/device/{{quickNavDn}}" target="_blank"
               style="border:none;background:none;cursor:pointer;">
        <mat-icon>chevron_right</mat-icon>
      </a>
    </mat-form-field>


<!--    <button mat-button   mat-flat-button  color="primary"  aria-label="Clear" (click)="showAllDevices()" style="margin-left:20px;">-->
<!--      <mat-icon>visibility</mat-icon>-->
<!--      Show all-->
<!--    </button>-->

    <button mat-button   mat-flat-button  color="primary"  aria-label="Clear" (click)="cancelFleetFilter()" style="margin-left:20px;">
      <mat-icon>close</mat-icon>
      Reset filters
    </button>
</div>

<table mat-table [dataSource]="partnersDatasource" matSort>

<!--  <ng-container matColumnDef="onlineStatus">-->
<!--    <th mat-header-cell *matHeaderCellDef style="text-align: center;">-->
<!--      Online&nbsp;-->
<!--      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Connection status">-->
<!--        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />-->
<!--      </svg>-->
<!--    </th>-->
<!--    <td mat-cell *matCellDef="let element" style="text-align: center;">-->
<!--      <div *ngIf="isDeviceOnline(element.lastHeartbeat) && !isDeviceParked(element?.lastLocation)" class="onlineCircle"-->
<!--           matTooltip="Connected: {{element.lastConnection | date : 'dd/M/yy, h:mm a'}}"></div>-->
<!--      <div *ngIf="isDeviceOnline(element.lastHeartbeat) && isDeviceParked(element?.lastLocation)" class="standbyCircle"-->
<!--           matTooltip="Parked: {{element.lastHeartbeat | date : 'dd/M/yy, h:mm a'}}"></div>-->
<!--      <div *ngIf="!isDeviceOnline(element.lastHeartbeat) || !element.lastHeartbeat" class="offlineCircle"-->
<!--      matTooltip="Disconnected: {{element.lastDisconnected | date : 'dd/M/yy, h:mm a'}}"></div>-->
<!--    </td>-->
<!--  </ng-container>-->



  <ng-container matColumnDef="dn">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Device&nbsp;
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device number (DN)">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: left!important;" >



     <div style="display: inline-block;margin-right: 5px">
       <div *ngIf="isDeviceOnline(element.lastHeartbeat) && !isDeviceParked(element?.lastLocation)" class="onlineCircle"
            matTooltip="Connected: {{element.lastConnection | date : 'dd/M/yy, h:mm a'}}"></div>
       <div *ngIf="isDeviceOnline(element.lastHeartbeat) && isDeviceParked(element?.lastLocation)" class="standbyCircle"
            matTooltip="Parked: {{element.lastHeartbeat | date : 'dd/M/yy, h:mm a'}}"></div>
       <div *ngIf="!isDeviceOnline(element.lastHeartbeat) || !element.lastHeartbeat" class="offlineCircle"
            matTooltip="Disconnected: {{element.lastDisconnected | date : 'dd/M/yy, h:mm a'}}"></div>
     </div>

      <span mat-flat-button>
        {{element?.dn}}
      </span>


<!--      <a mat-flat-button  routerLink="/device/{{element.dn}}" routerLinkActive="active" style="line-height: 30px;margin-right:20px"-->
<!--         *ngIf="element.dn">-->
<!--&lt;!&ndash;        <mat-icon style="vertical-align: middle">router</mat-icon>&ndash;&gt;-->
<!--        &nbsp;{{element.dn}}-->
<!--      </a>-->

<!--      <a mat-flat-button  routerLink="/media/{{element.dn}}" routerLinkActive="active" style="line-height: 30px; "*ngIf="element.dn">-->
<!--        <mat-icon>ondemand_video</mat-icon>-->
<!--      </a>-->

      <a mat-flat-button  routerLink="/device-setup/{{element._id}}" routerLinkActive="active" *ngIf="!element.dn">
        Device setup
      </a>
    </td>
  </ng-container>

  <ng-container matColumnDef="registration">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Vehicle Registration&nbsp;
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Vehicle registration or ID">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: left!important;" >
      {{element.registration}}
    </td>
  </ng-container>

  <ng-container matColumnDef="fleetId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Fleet Name&nbsp;
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Parent fleet">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: left!important;" >
      <span *ngIf="allFleets && allFleets[element?.fleetId]">{{allFleets[element?.fleetId]?.fleetName}}</span>
      <span *ngIf="!allFleets || !allFleets[element?.fleetId]?.fleetName">{{element?.fleetId}}</span>

    </td>
  </ng-container>

  <ng-container matColumnDef="cameras">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Cameras&nbsp;
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Camera views available">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: left!important;" >
      <span *ngFor="let camera of element.cameras" matTooltip="{{camera.camPosition}} camera"
            style="padding:4px 6px;background:#80809a;color:white;border-radius:3px;margin-right:5px;">
        <b>{{camera.camPosition | slice:0:1}}</b>
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="SIM">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      SIM No.&nbsp;
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device SIM Number">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element"  style="text-align: left!important;" >
      <span *ngIf="element.deviceSIM">
          {{element.deviceSIM}}&nbsp;
      </span>
    </td>
  </ng-container>
  <ng-container matColumnDef="model">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Device Model&nbsp;
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device Model Number">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element"  style="text-align: left!important;" >
      <span *ngIf="element.device">
          {{modelNumberDisplay(element.device)}}&nbsp;
      </span>
    </td>
  </ng-container>



  <ng-container matColumnDef="lastSeen">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Last GPS&nbsp;
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Last GPS tracking data">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element"  style="text-align: left!important;" >
      <span *ngIf="element.lastLocation">
          {{element.lastLocation.at | date : 'dd/MM/yy, HH:mm '}}&nbsp;
      </span>
    </td>
  </ng-container>

  <ng-container matColumnDef="commissionStatus">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      Mode&nbsp;
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device status on platform">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </th>
    <td mat-cell *matCellDef="let element" style="text-align: left!important;">
      <div *ngIf="element.commissioning">
        <div *ngIf="element.commissionStatus=='active'" class="statusActive">{{element.commissionStatus | titlecase}}</div>
          <div *ngIf="element.commissionStatus=='setup'"  class="statusSetup">{{element.commissionStatus | titlecase}}</div>
        <div *ngIf="element.commissionStatus=='tested'"  class="statusTested" matTooltip="Device ready for install">Install</div>
        <div *ngIf="element.commissionStatus=='service'"  class="statusService" matTooltip="Device in service mode">{{element.commissionStatus | titlecase}}</div>
        <div *ngIf="element.commissionStatus=='expired'"  class="statusExpired" matTooltip="Device contract expired">{{element.commissionStatus | titlecase}}</div>
        <div *ngIf="element.commissionStatus=='disabled'"  class="statusDisabled" matTooltip="Device disabled">{{element.commissionStatus | titlecase}}</div>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumnsPartner"></tr>
  <tr mat-row  [ngClass]="{'highlightRow': row.deviceStatus == 'online'}"   style="cursor:pointer;"
      *matRowDef="let row; columns: displayedColumnsPartner;"  (click)="openNewTab(row.dn)"></tr>
  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" colspan="6">No data matching the filter(s)</td>
  </tr>
</table>

<mat-paginator [pageSizeOptions]="[50, 75, 100]" showFirstLastButtons></mat-paginator>

<mat-card *ngIf="tableLoading" style="display: flex; justify-content: center; align-items: center">
  <mat-progress-spinner
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
</mat-card>


<ng-template #secondDialog let-data>
  <h2 matDialogTitle>New Device</h2>
  <mat-dialog-content>
    <p style="text-align: center;">A new device Id has been allocated</p>
    <p style="text-align: center;"><strong>{{data.newId}}</strong></p>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <a mat-flat-button color="basic" routerLink="/device-setup/{{data.newId}}"  routerLinkActive="active" mat-dialog-close>
      <mat-icon>cloud</mat-icon>
      &nbsp;Setup Vehicle
    </a>
  </mat-dialog-actions>
</ng-template>


<button (click)="scrollToTop()" class="scrollTopButton" id="scrollTopButtonEle">
  <mat-icon>arrow_circle_up</mat-icon>
</button>
