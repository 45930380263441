import {Component, Input, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {AdminService} from '../../admin.service';

@Component({
  selector: 'app-raw',
  templateUrl: './raw.component.html',
  styleUrls: ['./raw.component.css']
})
export class RawComponent implements OnInit {

  @Input() dn;
  @Input() cameras;
  @Input() deviceParameters;
  @Input() configsMatch;
  @Input() deviceStatus;
  @Input() vehicle;
  @Input() calendar;
  @Input() config;

  singleSnapshotChannel: any;



  constructor(private datePipe: DatePipe, private adminService: AdminService) { }

  ngOnInit(): void {
  }



  snapshotRequest(ch): void {
    console.log(ch);
    const dateString = this.datePipe.transform( new Date(), 'yyyy-MM-dd HH:mm:ss');

    const data = {
      callback: '',
      ch,
      dn: this.dn,
      st: dateString
    };

    this.adminService.snapshotRequest(data).subscribe(result => {console.log(result); });
  }


}
