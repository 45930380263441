import { Component, OnInit } from '@angular/core';
import {AdminService} from "../../admin.service";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css']
})
export class PasswordChangeComponent implements OnInit {

  curPassword: string;
  newPassword: string;
  confirmNewPassword: string;

  constructor(private adminService: AdminService, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  changePasswordValidator(): any{

    let data = true;

    if (this.newPassword?.length > 5 && this.confirmNewPassword?.length > 5){
      data = this.newPassword !== this.confirmNewPassword;
    }
    return data;
  }

  submitPasswordChange(): void {
    if (this.newPassword === this.confirmNewPassword){
      this.adminService.updatePassword({
        password: this.curPassword,
        newPwd: this.newPassword,
        email: localStorage.getItem('email')
      }).subscribe(r => {
        console.log(r);
        this.dialog.closeAll();
      });
    }
  }

}
