import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})

export class AdminService {

  API_URL = environment.API_URL;
  dvlaDataApiKey = environment.dvlaDataApiKey;


  constructor(private http: HttpClient) {
  }



  isDeviceConnected(data: any): Observable<any> {

    return this.http.get<any[]>(`${this.API_URL}/is-device-connected/${data.dn}`)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }



  calendar(data: any): Observable<any> {

    return this.http.get<any[]>(`${this.API_URL}/calendar/${data.dn}`)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }



  videoBudget(data: any): Observable<any> {

    return this.http.get<any[]>(`${this.API_URL}/video-budget/${data.dn}`)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }




  restart(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/restart`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }






  fleets(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/fleets`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }


  vehicles(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/vehicles`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }



  vehicle(data: any): Observable<any> {
      return this.http.post<any[]>(`${this.API_URL}/vehicle`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }


  deviceDetails(data: any): Observable<any> {
    return this.http.post<any[]>(`${this.API_URL}/device-details`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }


  users(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/users`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }



  deviceStatus(data: any): Observable<any> {
    return this.http.post<any[]>(`${this.API_URL}/deviceStatus`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }



  updateFleet(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/update-fleet`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }

  addFleet(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/add-fleet`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }

  fetchEventById(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/f-events-id`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }



  updateUser(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/updateUser`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }


  updateVehicle(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/update-vehicle`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }

  updateDevice(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/update-device`, data, httpOptions)

  }

  firmwares(data: any): Observable<any> {
    return this.http.post<any[]>(`${this.API_URL}/firmwares`, data, httpOptions)

  }


  updateVehicleNotes(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/update-vehicle-notes`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }


  updateMirror(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/set-mirror-parameters`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }




  addVehicle(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/addVehicle`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }





  /*push params from diagnostics to database*/
  getAllParameters(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/get-all-parameters`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }



  triggerUnderSpeedAlarm(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/trigger-under-speed-alarm`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }







  deviceParameters(data: any): Observable<any> {
    return this.http.post<any[]>(`${this.API_URL}/device-parameters`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }




  calibrateGSensor(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/g-sensor-calibrate`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }





  setParameters(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/set-parameters`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }



  latestSnapshots(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/latest-snapshot`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }



  events(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/events-media`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }

  fEvents(data: any): Observable<any> {
    return this.http.post<any[]>(`${this.API_URL}/f-events`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }


  deviceHealth(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/health`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }



  commissionVehicle(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/commission-vehicle`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }




  snapshotRequest(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/snapshot-request`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }



  report(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/report`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }


  getVehicleData(data: string): Observable<any> {

    const query = data?.length <= 7 ? 'key_VRM=' + data : 'key_VIN=' + data;


    return this.http.get('https://uk1.ukvehicledata.co.uk/api/datapackage/VehicleData?v=2&api_nullitems=1&' +
      'auth_apikey=' + this.dvlaDataApiKey + '&user_tag=&' + query)
      .pipe(

      );
  }



  videoAvailable(data: any): Observable<any> {
    return this.http.get<any[]>(`${this.API_URL}/video-available/${data.dn}`)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }


  videoRequest(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/video-request`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }



  queuedVideoForDevice(data: any): Observable<any> {

    return this.http.get<any[]>(`${this.API_URL}/video-queue/${data.dn}`)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }

  hyperlapse(data: any): Observable<any> {

    return this.http.get<any[]>(`${this.API_URL}/hyperlapse/${data.dn}`)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }
  hyperlapseDetail(data: any): Observable<any> {

    return this.http.get<any[]>(`${this.API_URL}/hyperlapse-detail/${data._id}`)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }

  hyperlapseRequest(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/hyperlapse-request`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }


  journeys(data: any): Observable<any> {

    return this.http.get<any[]>(`${this.API_URL}/journeys/${data.dn}/${data.date}`)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }

  journeyDetails(data: any): Observable<any> {

    return this.http.get<any[]>(`${this.API_URL}/journey-details/${data._id}/`)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }


  viewSnapshot(data: string): Promise<any> {

    const url = this.API_URL + '/view-snapshot/' + data;
    const token = localStorage.getItem('access_token');
    const token2 = 'Bearer ' + token.toString();
    const myHeaders = {Authorization: token2, redirect: 'follow'};

    const options = {
      method: 'get',
      headers: myHeaders
    };

    return fetch(url, options)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.blob().then(data => {
          return Promise.resolve(
            data
          );

        });
      });
  }

  viewSnapshotConnect(data: string): Promise<any> {

    const url = this.API_URL + '/angFetchSnapshot/' + data;


    const options = {
      method: 'get'
    };

    return fetch(url, options)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.blob();
      });
  }

  viewVideo(data: string): Promise<any> {

    const url = this.API_URL + '/view-video/' + data;
    const token = localStorage.getItem('access_token');
    const token2 = 'Bearer ' + token.toString();
    const myHeaders = {'Authorization': token2};

    const options = {
      method: 'get',
      headers: myHeaders
    };

    return fetch(url, options)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          return response.blob();
        }
      }).catch(e => {
        console.log(e);
      });

  }

  viewHyperThumb(data: string): Promise<any> {

    const url = this.API_URL + '/view-snapshot/' + data;
    const token = localStorage.getItem('access_token');
    const token2 = 'Bearer ' + token.toString();
    const myHeaders = {'Authorization': token2};

    const options = {
      method: 'get',
      headers: myHeaders
    };

    return fetch(url, options)
      .then(response => {
        if (response.toString() === 'Not Found') {
          // throw new Error(response.statusText);
          return null;
        }
        return response.blob();
      });
  }


  reverseGeocode(data): Observable<any>{

    return this.http.get<any[]>(`https://georeverse.myfleetlive.co.uk/reverse?format=geojson&lat=${data.lat}7&lon=${data.lng}`)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );

  }


  groups(data: any): Observable<any> {

    return this.http.get<any[]>(`${this.API_URL}/mflGroups`, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }



  updateGroup(data: any): Observable<any> {
    return this.http.post<any[]>(`${this.API_URL}/updateMflGroups`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );

  }

  eventProfiles(data: any): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/eventProfiles`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }


  setTimezone(data: any): Observable<any> {

    return this.http.post<any>(`${this.API_URL}/set-time-zone`, data, httpOptions);
  }


  startStopStream(stream, onOff): Observable<any> {

    return this.http.get<any[]>(`${this.API_URL}/live-preview/${stream}/${onOff}`, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }

  updatePassword(data): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/auth/newPwd`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }


  camHealth(data): Observable<any> {

    return this.http.post<any[]>(`${this.API_URL}/camera-health`, data, httpOptions)
      .pipe(
        // catchError(this.handleError('addHero', hero))
      );
  }


}
