<div class="loadingOverlay" *ngIf="pageLoading">
  <div class="loader"></div>
</div>

<h1 style="font-size: 28px!important;font-weight: bold;" xmlns="http://www.w3.org/1999/html">
  Video Media
</h1>
<div style="display: flex;flex-wrap: wrap;">
  <div>
    <h3 style="font-size: 21px!important;">
      <span style="font-weight: bold" *ngIf="theVehicle" matTooltip="Fleet: {{theVehicle.fleetId}}">{{theVehicle?.registration}}</span>
      <a href="{{baseHref}}device/{{dn}}" style="text-decoration:none;color:black;">
      ({{dn}})
      </a>
    </h3>


    <div style="margin: 15px 15px 15px 0;">

    </div>
    <p style="margin-top: -10px;">
      <span *ngIf="deviceConnected" class="onlineChip">Online</span>
      <span *ngIf="!deviceConnected" class="offlineChip">Offline</span>
      &nbsp;| <span style="font-weight: 500;">Mode:</span>&nbsp;
      <span *ngIf="theVehicle && theVehicle?.commissioning?.commissionStatus == 'tested'">Install</span>
      <span *ngIf="theVehicle && theVehicle?.commissioning?.commissionStatus != 'tested'">{{theVehicle?.commissioning?.commissionStatus | titlecase}}</span>
      <span *ngIf="videoBudget && videoBudget.totalFleetBudget > 0">
      &nbsp;| <span style="font-weight: 500;">Budget:</span> {{(((videoBudget.totalFleetBudget - videoBudget.usedSeconds) / videoBudget.totalFleetBudget) *100) | number: '1.0-0'}}%
    </span>
    </p>
  </div>
  <div style="flex:1;display: flex;min-width: 250px;">
    <div style="flex: 1;text-align: right;" class="header-less-tabs">
      <mat-tab-group [(selectedIndex)]="showFilters" animationDuration="500" >

        <mat-tab>
          <div style="height: 77px;padding-left:75px;padding-right:15px;padding-top:5px;display: flex;justify-content: end;">
            <div style="margin-right:10px;">
              <div style="margin-bottom:10px;">
                Show queued video uploads only
                <mat-slide-toggle [(ngModel)]="eventsFilters.videoQueueOnly" (change)="filterEvents('videoQueueOnly')" style="margin-left: 25px;"></mat-slide-toggle>
              </div>
              <div>
                Show journey snapshots
                <mat-slide-toggle [(ngModel)]="eventsFilters.journey" (change)="filterEvents(null)" style="margin-left: 25px;"></mat-slide-toggle>
              </div>
            </div>
            <div>
              <div style="margin-bottom:10px;">
                Show severe events only
                <mat-slide-toggle [(ngModel)]="eventsFilters.severeOnly" (change)="filterEvents('severeOnly')" style="margin-left: 25px;"></mat-slide-toggle>
              </div>
              <div>
                <!--              Show hidden media-->
                <!--              <mat-slide-toggle [(ngModel)]="eventsFilters.journey" (change)="filterEvents(null)" style="margin-left: 25px;"></mat-slide-toggle>-->
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <!--- journeys/recordings--->
          <div style="height: 77px;padding-left:75px;padding-right:15px;padding-top:5px;display: flex;justify-content: end;">
            <div style="margin-right:10px;">
              <div style="margin-bottom:10px;">
                Show recording files
                <mat-slide-toggle [(ngModel)]="journeyRecordingSlider"></mat-slide-toggle>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <mat-form-field appearance="outline" >



<!--      <mat-label>Choose a date for events</mat-label>-->
      <input matInput [matDatepicker]="picker" style="font-size:18px;"
             (dateChange)="dateSelectedChange()"
             [max]="maxDateForEvents"
             [(ngModel)]="eventDate"
             [matDatepickerFilter]="eventDateFilter"
             placeholder="Choose date for media">
      <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
      <mat-datepicker [startAt]="eventDate" #picker></mat-datepicker>
    </mat-form-field>
  </div>
</div>



<mat-tab-group style="margin-top: -10px;" (selectedTabChange)="showHideFilters($event)">
  <!--- media uploaded tab--->
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">cloud</mat-icon>
      &nbsp;&nbsp;Cloud Storage
    </ng-template>
    <br>

    <div *ngIf="mediaEventsLoading" style="text-align: center;padding-top: 50px;">
      <mat-spinner style="margin:0 auto;"></mat-spinner>
    </div>

    <div *ngIf="(!partnerEventsDisplay || partnerEventsDisplay.length < 1) && !mediaEventsLoading" style="height:500px;">
      <div class="floatingBox">
        <svg style="width:64px;height:64px" viewBox="0 0 24 24">
          <path fill="currentColor" d="M14.75 7.46L12 3.93L13.97 3.54L16.71 7.07L14.75 7.46M11.81 8.05L9.07 4.5L7.1 4.91L9.85 8.44L11.81 8.05M21.62 6.1L20.84 2.18L16.91 2.96L19.65 6.5L21.62 6.1M19 13C20.1 13 21.12 13.3 22 13.81V10H2V20C2 21.11 2.9 22 4 22H13.81C13.3 21.12 13 20.1 13 19C13 15.69 15.69 13 19 13M4.16 5.5L3.18 5.69C2.1 5.91 1.4 6.96 1.61 8.04L2 10L6.9 9.03L4.16 5.5M22.54 16.88L21.12 15.47L19 17.59L16.88 15.47L15.47 16.88L17.59 19L15.47 21.12L16.88 22.54L19 20.41L21.12 22.54L22.54 21.12L20.41 19L22.54 16.88Z" />
        </svg>
        <h1>No media for given day and filters</h1>
      </div>
    </div>

    <div class="parent" *ngIf="partnerEventsDisplay && partnerEventsDisplay.length > 0 && !mediaEventsLoading" style="overflowY:auto;height:60vh;">
      <div class="child" *ngFor="let e of partnerEventsDisplay" style="padding:5px;">
        <div id="{{e._id}}">
          <div style="display: flex;min-height: 80px;width:85%;margin:0 auto;align-items: center;flex-wrap: wrap">
            <div style="text-align: left;align-content: center">
              <mat-icon style="vertical-align:middle;font-size: 14px;margin-top: 10px;">timer</mat-icon>
              {{e.startTime  | date : 'HH:mm:ss '}}
            </div>
            <div style="flex:1;">
              <div style="width: 100px;height: 40px;border-radius: 40px;text-align: center;position: relative;border:1px solid lightgray;padding:5px;margin-left: auto;">
                <div style="position: absolute;top:-12px;left:25px;margin-left: auto;margin-right: auto;width: 60px;background-color: white;font-size: 12px;font-weight: bold">Analysis</div>
                <img *ngIf="e.analysis && (e.analysis.eventType == 'gSensor' || e.analysis.eventType == 'bump')" src="././assets/svgs/pin-shock.svg" matTooltip="G-Force"
                     style="height:32px;vertical-align:middle;display: inline-block;margin-top: 5px;">
                <img *ngIf="e.analysis && e.analysis.eventType == 'braking'" src="././assets/svgs/pin-brake.svg" matTooltip="Braking"
                     style="height:34px;vertical-align:middle;display: inline-block;">
                <img *ngIf="e.analysis && e.analysis.eventType == 'cornering'" src="././assets/svgs/pin-turning.svg" matTooltip="Cornering"
                     style="height:32px;vertical-align:middle;display: inline-block;">
                <img *ngIf="e.analysis && e.analysis.eventType == 'acceleration'" src="././assets/svgs/pin-acceleration.svg" matTooltip="Acceleration"
                     style="height:32px;vertical-align:middle;display: inline-block;">
                <img *ngIf="!e.analysis && (e.eventSource == 'hyperlapse')" src="././assets/user-pin.png" matTooltip="Requested
                          hyperlapse upload by {{e.video[0].email}}" style="height:32px;vertical-align:middle;display: inline-block;margin-top: 5px;">

                <img *ngIf="!e.analysis && (e.eventSource == 'videoUpload')" src="././assets/user-pin.png" matTooltip="Requested
                          video upload by {{e.video[0].email}}" style="height:32px;vertical-align:middle;display: inline-block;margin-top: 5px;">

                <img *ngIf="!e.analysis && e.eventSource == 'hyperlapsePending'" src="././assets/user-pin.png" matTooltip="Requested
                          hyperlapse upload by {{e.video[0].email}} pending" style="height:32px;vertical-align:middle;display: inline-block;margin-top: 5px;">

                <img *ngIf="!e.analysis && e.eventSource == 'videoUploadPending'" src="././assets/user-pin.png" matTooltip="Requested
                          video upload by {{e.video[0].email}} pending" style="height:32px;vertical-align:middle;display: inline-block;margin-top: 5px;">

                <img *ngIf="!e.analysis && e.eventSource == 'user'" src="././assets/user-pin.png" matTooltip="User requested upload"
                     style="height:32px;vertical-align:middle;display: inline-block;margin-top: 5px;">
                <img *ngIf="!e.analysis && e.eventSource == 'journeyStart'" src="././assets/journeyFlag.png" matTooltip="Journey start"
                     style="height:32px;vertical-align:middle;display: inline-block;margin-top: 5px;">
                <img *ngIf="!e.analysis && e.eventSource == 'journeyEnd'" src="././assets/journeyFlag.png" matTooltip="Journey end"
                     style="height:32px;vertical-align:middle;display: inline-block;margin-top: 5px;">

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <svg *ngIf="e.analysis && e.analysis.severity === 'severe'" matTooltip="Severe"
                     style="width:38px;height:38px;color:#F24141;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
                <svg *ngIf="e.analysis && e.analysis.severity === 'moderate'" matTooltip="Moderate"
                     style="width:38px;height:38px;color:#FFBF00;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
                <svg *ngIf="e.analysis && e.analysis.severity === 'mild'" matTooltip="Mild"
                     style="width:38px;height:38px;color:#5C8459;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
                <svg *ngIf="!e.analysis && e.eventSource == 'hyperlapse'" matTooltip="User requested hyperlapse"
                     style="width:38px;height:38px;color:#5C8459;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
                <svg *ngIf="!e.analysis && e.eventSource == 'videoUpload'" matTooltip="User requested video upload"
                     style="width:38px;height:38px;color:#5C8459;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
                <svg *ngIf="!e.analysis && (e.eventSource == 'videoUploadPending' || e.eventSource == 'hyperlapsePending')" matTooltip="User requested video upload pending"
                     style="width:38px;height:38px;color:#5C8459;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
                <svg *ngIf="!e.analysis && e.eventSource == 'user'" matTooltip="User created event"
                     style="width:38px;height:38px;color:#5C8459;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
                <svg *ngIf="!e.analysis && e.eventSource == 'journeyStart'" matTooltip="Journey start"
                     style="width:38px;height:38px;color:#5C8459;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
                <svg *ngIf="!e.analysis && e.eventSource == 'journeyEnd'" matTooltip="Journey end"
                     style="width:38px;height:38px;color:#5C8459;vertical-align: middle;position: relative;top: 55%;transform: translateY(-55%);" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                </svg>
              </div>
            </div>
            <div style="flex-basis: 100%;height: 0;">
            </div>
            <div (click)="viewMediaOnMap(e)" style="cursor:pointer;text-align: left;" [matTooltip]="e.locationStreetName">
              <mat-icon style="vertical-align:middle;font-size: 14px;margin-top: 10px;">map</mat-icon>
              <span *ngIf="e.locLoading">loading....</span>
              <mat-progress-bar mode="indeterminate" *ngIf="e.locLoading || !e.locationStreetName"></mat-progress-bar>
              <span *ngIf="!e.locLoading || e.locationStreetName">
                <span *ngIf="e.locationStreetName?.length >= 26">
                  {{e.locationStreetName| slice:0:26}} ...
                </span>
                <span *ngIf="e.locationStreetName?.length  < 26">
                  {{e.locationStreetName}}
                </span>

              </span>
            </div>
          </div>

          <div *ngIf="e.snapshots" style="padding-bottom: 10px;position: relative;">
            <!--            <img class="eventImage"  [src]="API_URL + '/angFetchSnapshot/'+ e.snapshots[0].file + '_sm'"-->
            <img class="eventImage"  [src]="getSantizeUrl(getFrontCamSnapFile(e.snapshots))"
                 (error)="hideEventOnImageError(e._id)" style="width:85%;margin-top:5px;cursor:pointer;" (click)="viewSnapshotModal(e)">

            <div style="position: absolute;text-align: center;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 50px;display: flex;align-items: center;justify-content: center">
              <img src="././assets/video_play.png" *ngIf="e.video && e.video.length >= 1 && e.videoType == 'video'"
                   style="cursor: pointer;width:100px;margin-right: 5px;" (click)="viewMediaInModal(e)">

              <img src="././assets/imageStack.png" *ngIf="e.snapshots && e.snapshots.length > 1"
                   style="cursor: pointer;width:100px;margin-left: 5px;" (click)="viewSnapshotModal(e)">

            </div>

          </div>
          <div *ngIf="!e.snapshots && e.eventSource != 'videoUploadPending' && e.eventSource != 'hyperlapsePending'" style="padding-bottom: 10px;position:relative">
            <div *ngIf="!e.snapshots && e.eventSource == 'hyperlapse'" style="padding-bottom: 10px;position:relative">
              <img class="eventImage"  src="{{e.thumbnail}}" style="width:85%;margin-top:5px;">
              <img src="././assets/hyperlapse_play.png"
                   style="position: absolute;text-align: center;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 100px;cursor: pointer;"
                   (click)="viewMediaInModal(e)">
            </div>
          </div>

          <div *ngIf="e.eventSource == 'videoUpload'" style="padding-bottom: 10px;position: relative;">
            <img class="eventImage"  src="{{API_URL}}/angFetchVidThumb/{{e.video[0].filename.replace('_h265', '')}}"
                 style="width:85%;margin-top:-5px;">

            <img src="././assets/video_play.png" *ngIf="e.videos2 && e.videos2.length >= 1 && e.videoType == 'video'"
                 style="position: absolute;text-align: center;top: 50%;left: 50%;transform: translate(-50%, -50%);width: 100px;cursor: pointer;"
                 (click)="viewMediaInModal(e)">
          </div>

          <div *ngIf="!e.snapshots && (e.eventSource == 'videoUploadPending' || e.eventSource == 'hyperlapsePending')"
               style="padding-bottom: 10px; position: relative">

            <img class="eventImage"  src="././assets/eventPlaceholder.jpg" style="width:85%;margin-top:5px;">

  <!--          <svg height="100" width="100"  style="position: absolute;text-align: center;top: 50%;left: 50%;transform: translate(-50%, -50%);">-->
  <!--            <circle cx="50%" cy="50%" r="45" stroke="#d9d9d9" stroke-width="10%" fill="#f5f5f5" />-->
  <!--          </svg>-->

  <!--          <mat-spinner [value]="e.percentComplete" mode="determinate" style="position: absolute;text-align: center;top: 50%;left: 50%;transform: translate(-50%, -50%);"></mat-spinner>-->
            <mat-spinner style="position: absolute;text-align: center;top: 50%;left: 50%;transform: translate(-50%, -50%);"></mat-spinner>
  <!--          <div style="position: absolute;text-align: center;top: 50%;left: 50%;transform: translate(-50%, -50%);">-->
  <!--            {{e.percentComplete | number:'1.0-0'}}%-->
  <!--          </div>-->

          </div>
        </div>
      </div>
    </div>

  </mat-tab>
  <!----request media tab--->
  <mat-tab>
  <ng-template mat-tab-label>
    <mat-icon class="example-tab-icon">cloud_upload</mat-icon>
    &nbsp;&nbsp;Upload Video
  </ng-template>
  <br>
  <div style="display:flex;flex-wrap: wrap;">
    <mat-card style="margin-right:10px;flex: 1;min-width: 300px;">
      <mat-card-title class="columnHeader">
        <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">
          <path fill="currentColor" d="M15,19L9,16.89V5L15,7.11M20.5,3C20.44,3 20.39,3 20.34,3L15,5.1L9,3L3.36,4.9C3.15,4.97 3,5.15 3,5.38V20.5A0.5,0.5 0 0,0 3.5,21C3.55,21 3.61,21 3.66,20.97L9,18.9L15,21L20.64,19.1C20.85,19 21,18.85 21,18.62V3.5A0.5,0.5 0 0,0 20.5,3Z" />
        </svg>
        &nbsp;
        <span *ngIf="dateSelected && !journeyRecordingSlider">Select journey and location</span>
        <span *ngIf="dateSelected && journeyRecordingSlider">Recording selection</span>
      </mat-card-title>
      <mat-card-content  class="header-less-tabs">
        <mat-tab-group [(selectedIndex)]="calendarJourneySlider" animationDuration="500">
          <mat-tab>
            <div *ngIf="!journeyRecordingSlider" style="height:500px;">
              <div *ngIf="journeys && journeys.length >= 1">
                <table  style="width: 100%;border-collapse: collapse;border:none;margin-top:15px;">
                  <tr class="borderedRow" style="text-align:left!important;">
                    <th style="width:50px;">#</th>
                    <th>Details</th>
                    <th>Start</th>
                    <th>Finish</th>
                  </tr>
                  <ng-container *ngFor="let journey of journeys; let i = index">
                  <tr  [class.rowSelected]="journey._id === journeySelected?._id" (click)="SelectMedia('journey', journey)"
                      class="borderedRow" *ngIf="journey.duration && journey.distance">
                    <td class="spacedTable">
                      {{(i+1)}}
                    </td>
                    <td class="spacedTable">
                      <mat-icon style="font-size:12px">timer</mat-icon>{{journey.duration}}<br>
                      <mat-icon style="font-size:12px">map</mat-icon>{{journey.distance | number: '0.0-1'}} miles
                    </td>
                    <td class="spacedTable">
                      <div>
                        <mat-icon style="font-size:12px">schedule</mat-icon>
                        Approx. {{journey?.start?.timeUTC | date: 'shortTime'}}
                      </div>
                      <div style="display:flex;">
                        <div>
                          <mat-icon style="font-size:12px;">location_on</mat-icon>
                        </div>
                        <div style="flex:1;">
                          <span *ngIf="!journey?.startPlaceLoading">{{journey?.startPlace}}</span>
                          <mat-progress-bar mode="indeterminate" *ngIf="journey.startPlaceLoading" style="margin-top:8px;"></mat-progress-bar>
                        </div>
                      </div>
                    </td>
                    <td class="spacedTable">
                      <div>
                        <mat-icon style="font-size:12px">schedule</mat-icon>
                        {{journey?.finish?.timeUTC | date: 'shortTime'}}
                      </div>
                      <div style="display:flex;">
                        <div>
                          <mat-icon style="font-size:12px;">location_on</mat-icon>
                        </div>
                        <div style="flex:1;">
                          <span *ngIf="!journey?.endPlaceLoading">{{journey?.endPlace}}</span>
                          <mat-progress-bar mode="indeterminate" *ngIf="journey.endPlaceLoading" style="margin-top:8px;"></mat-progress-bar>
                        </div>
                      </div>

                    </td>
                  </tr>
                  </ng-container>
                </table>
              </div>
              <div *ngIf="(!journeys || journeys.length < 1) && !pageLoading" class="floatingBox">
                <svg style="width:64px;height:64px;vertical-align: middle" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M6.5,8.11C5.61,8.11 4.89,7.39 4.89,6.5A1.61,1.61 0 0,1 6.5,4.89C7.39,4.89 8.11,5.61 8.11,6.5V6.5A1.61,1.61 0 0,1 6.5,8.11M6.5,2C4,2 2,4 2,6.5C2,9.87 6.5,14.86 6.5,14.86C6.5,14.86 11,9.87 11,6.5C11,4 9,2 6.5,2M17.5,8.11A1.61,1.61 0 0,1 15.89,6.5C15.89,5.61 16.61,4.89 17.5,4.89C18.39,4.89 19.11,5.61 19.11,6.5A1.61,1.61 0 0,1 17.5,8.11M17.5,2C15,2 13,4 13,6.5C13,9.87 17.5,14.86 17.5,14.86C17.5,14.86 22,9.87 22,6.5C22,4 20,2 17.5,2M17.5,16C16.23,16 15.1,16.8 14.68,18H9.32C8.77,16.44 7.05,15.62 5.5,16.17C3.93,16.72 3.11,18.44 3.66,20C4.22,21.56 5.93,22.38 7.5,21.83C8.35,21.53 9,20.85 9.32,20H14.69C15.24,21.56 16.96,22.38 18.5,21.83C20.08,21.28 20.9,19.56 20.35,18C19.92,16.8 18.78,16 17.5,16V16M17.5,20.5A1.5,1.5 0 0,1 16,19A1.5,1.5 0 0,1 17.5,17.5A1.5,1.5 0 0,1 19,19A1.5,1.5 0 0,1 17.5,20.5Z" />
                </svg>
                <br>
                <h1>No journeys for date selected</h1>
              </div>
            </div>


            <div *ngIf="journeyRecordingSlider">
              <table *ngIf="calendarDataByDate[dateSelected] && calendarDataByDate[dateSelected].length >= 1"
                     style="width: 100%;border: none;border-collapse: collapse;">
                <tr>
                  <th class="spacedTable" style="text-align: center;">#</th>
                  <th class="spacedTable" style="text-align: center;">Start</th>
                  <th class="spacedTable" style="text-align: center;">Finish</th>
                </tr>
                <tr *ngFor="let clip of calendarDataByDate[dateSelected]; index as i"  class="borderedRow"
                    [class.rowSelected]="clipSelected?.st === clip.st && clipSelected?.et === clip.et"
                    class="borderedRow" (click)="SelectMedia('recording', clip)">
                  <td class="spacedTable" style="text-align: center;">{{(i+1)}}</td>
                  <td class="spacedTable" style="text-align: center;">
                    <mat-icon style="font-size:12px">schedule</mat-icon>{{clip.st | date: 'shortTime'}}
                  </td>
                  <td class="spacedTable" style="text-align: center;">
                    <mat-icon style="font-size:12px">schedule</mat-icon>{{clip.et | date: 'shortTime'}}
                  </td>
                </tr>
              </table>
              <div *ngIf="!calendarDataByDate[dateSelected] || calendarDataByDate[dateSelected].length < 1">no recordings</div>
            </div>
          </mat-tab>
          <mat-tab style="position:relative;">
            <div style="height:500px;width:100%;" class="theJourneyMap" id="theJourneyMap" ></div>
            <button mat-flat-button (click)="setJourneyCalendar(0)" style="position: absolute;top:5px;left:5px;z-index: 3" *ngIf="showMapBackButton">
              <mat-icon>chevron_left</mat-icon>
            </button>

<!--            <div *ngIf="showMapBackButton && !footageType" class="floatingBoxMa">-->
<!--              Select media type to be requested-->
<!--            </div>-->

          </mat-tab>
        </mat-tab-group>
      </mat-card-content>
    </mat-card>

    <mat-card style="margin-right:10px;flex: 1;min-width: 300px;">
      <mat-card-title class="columnHeader">
        <svg style="width:36px;height:36px;display: block;" viewBox="0 0 24 24">
          <path fill="currentColor" d="M14,13V17H10V13H7L12,8L17,13M19.35,10.03C18.67,6.59 15.64,4 12,4C9.11,4 6.6,5.64 5.35,8.03C2.34,8.36 0,10.9 0,14A6,6 0 0,0 6,20H19A5,5 0 0,0 24,15C24,12.36 21.95,10.22 19.35,10.03Z" />
        </svg>
        &nbsp;&nbsp;Select media type and camera views
      </mat-card-title>
      <mat-card-content style="height: 500px;position: relative;">

        <!--- select journey prompt--->
        <div *ngIf="!clipSelected && !journeySelected && dateSelected && journeys && journeys.length > 0" class="floatingBox">
          <svg style="width:64px;height:64px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M15,19L9,16.89V5L15,7.11M20.5,3C20.44,3 20.39,3 20.34,3L15,5.1L9,3L3.36,4.9C3.15,4.97 3,5.15 3,5.38V20.5A0.5,0.5 0 0,0 3.5,21C3.55,21 3.61,21 3.66,20.97L9,18.9L15,21L20.64,19.1C20.85,19 21,18.85 21,18.62V3.5A0.5,0.5 0 0,0 20.5,3Z" />          </svg>
          <br>
          <h1>Select a journey <span *ngIf="mflGroup == 'undefined'">or recording&nbsp;</span></h1>
        </div>


        <div style="display: flex;margin-top:15px;" *ngIf="journeySelected">
          <div class="toggleFootageType" (click)="footageType = 'hyperlapse'; changeMediaType()" [class.toggleFootageTypeSelected]="footageType == 'hyperlapse'">
            <mat-icon style="vertical-align:middle;">fast_forward</mat-icon>
            <br>Hyperlapse Video
          </div>
          <div class="toggleFootageType" (click)="footageType = 'video'; changeMediaType()" [class.toggleFootageTypeSelected]="footageType == 'video'">
            <mat-icon style="vertical-align:middle;">videocam</mat-icon>
            <br>Video Clip
          </div>
        </div>

        <br>

        <!--- recording--->
        <div *ngIf="clipSelected " style="text-align: center;">

          <div style="display: flex;margin-top:15px;">
            <div class="toggleFootageType" (click)="footageType = 'hyperlapse';"
                 [class.toggleFootageTypeSelected]="footageType == 'hyperlapse'">
              <mat-icon style="vertical-align:middle;">fast_forward</mat-icon>
              <br>Hyperlapse Video
            </div>
            <div class="toggleFootageType" (click)="footageType = 'video';" [class.toggleFootageTypeSelected]="footageType == 'video'">
              <mat-icon style="vertical-align:middle;">videocam</mat-icon>
              <br>Video Clip
            </div>
          </div>
          <br>

          <div [formGroup]="hyperlapseRequestForm" *ngIf="footageType == 'hyperlapse'">
            <div style="font-size: 14px;font-weight: bold;text-align: center;">
              <mat-icon style="vertical-align: middle!important;">visibility</mat-icon>&nbsp;
              Select the camera views required
            </div>
            <div style="border-bottom:1px solid #e9e9e9;width:50%;margin:0 auto;margin-bottom: 10px;"></div>
            <div style="display:flex;flex-wrap:wrap;justify-content: center;margin-bottom: 20px;">
              <div *ngFor="let camera of cameras" style="margin-left: 5px;margin-right: 5px;">
                <input type="checkbox" id='c4{{camera.channel}}' class='chk-btn' [value]="camera.channel"
                       (change)="onCheckChange($event, 'hyperlapse')"/>
                <label for='c4{{camera.channel}}'>{{camera.camPosition}} camera</label>
              </div>
            </div>

            <div style="font-size: 14px;font-weight: bold;text-align: center;">
              <mat-icon style="vertical-align: middle!important;">timer</mat-icon>&nbsp;
              Footage time line
            </div>
            <div style="border-bottom:1px solid #e9e9e9;width:50%;margin:0 auto;margin-bottom: 10px;"></div>


            <dx-range-selector style="height:75px!important;"
                               [value]="[rangeSelectorStartValue, rangeSelectorEndValue]"
                               (onValueChanged)="onValueChanged($event)"
                               selectedRangeColor="#5C8459"
                               [scale]="{minRange: {minutes: 1}}">
              <dxo-scale
                [startValue]="rangeSelectorStartValue"
                [endValue]="rangeSelectorEndValue"
                [minorTickInterval]="'minute'"
                [tickInterval]="'minute'">
                <dxo-minor-tick [visible]="false"></dxo-minor-tick>
              </dxo-scale>
              <dxo-slider-marker format="shortTime"></dxo-slider-marker>
              <dxo-margin
                [top]="0"
                [bottom]="0"
                [left]="10"
                [right]="10">
              </dxo-margin>
            </dx-range-selector>


            <div *ngIf="disableHyperlapseRequestBtn.message" class="alert alert-success" style="text-align:center;">
              {{disableHyperlapseRequestBtn.message}}
            </div>
            <br>


            <div style="text-align:center;" *ngIf="!disableHyperlapseRequestBtn.message" >
              <button mat-flat-button (click)="sendHyperlapseRequest()"
                      [disabled]="disableHyperlapseRequestBtn.buttonDisabed || hyperlapseRequestForm.value.camera.length < 1">
                Upload Video
              </button>
            </div>
          </div>



          <div [formGroup]="videoRequestForm" *ngIf="footageType == 'video'">
            <div style="font-size: 14px;font-weight: bold;text-align: center;">
              <mat-icon style="vertical-align: middle!important;">visibility</mat-icon>&nbsp;
              Select the camera views required
            </div>
            <div style="border-bottom:1px solid #e9e9e9;width:50%;margin:0 auto;margin-bottom: 10px;"></div>
            <div style="display:flex;flex-wrap:wrap;justify-content: center;margin-bottom: 20px;">
              <div *ngFor="let camera of cameras" style="margin-left: 5px;margin-right: 5px;">
                <input type="checkbox" id='c1{{camera.channel}}' class='chk-btn' [value]="camera.channel" (change)="onCheckChange($event, 'video')"/>
                <label for='c1{{camera.channel}}'>{{camera.camPosition}} camera</label>
              </div>
            </div>
            <div style="font-size: 14px;font-weight: bold;text-align: center;">
              <mat-icon style="vertical-align: middle!important;">schedule</mat-icon>&nbsp;
              Event time fine adjustment
            </div>
            <div style="border-bottom:1px solid #e9e9e9;width:50%;margin:0 auto;margin-bottom: 10px;"></div>
            <mat-form-field style="width: 50%;margin-bottom: 20px;text-align:center;">
              <input matInput type="time" step="1"  formControlName="startTime" style="text-align:center;">
            </mat-form-field>


            <div style="font-size: 14px;font-weight: bold;text-align: center;">
              <mat-icon style="vertical-align: middle!important;">timer</mat-icon>&nbsp;
              Select video duration
            </div>
            <div style="border-bottom:1px solid #e9e9e9;width:50%;margin:0 auto;margin-bottom: 10px;"></div>
            <mat-form-field style="width:50%;margin:0 auto;text-align:center;">
              <mat-select formControlName="duration">
                <!--- [(ngModel)]="mediaLengthSelected" (change)="ClipAvailable()" --->
                <mat-option value="10">10 Seconds</mat-option>
                <mat-option value="20">20 Seconds</mat-option>
                <mat-option value="30">30 Seconds</mat-option>
              </mat-select>
            </mat-form-field>


            <div class="alert alert-warning"
                 *ngIf="!videoBudget || (videoBudget.totalFleetBudget - videoBudget.usedSeconds) < 10">
              Insufficient video budget remaining to make video request
            </div>

            <br>


            <button (click)="ClipAvailable()" mat-flat-button [disabled]="videoRequestForm.status == 'INVALID'" *ngIf="!confirmMessage.show">
              Upload video
            </button>

            <div *ngIf="confirmMessage.show" class="alert alert-warning" style="text-align: center;">
              {{confirmMessage.message}}
            </div>



          </div>

          <!--          <span *ngIf="mediaStartTimeSelected && (mediaStartTimeSelected + mediaLengthSelected) > (clipSelected.et | date : 'HH:mm:ss')">-->
          <!--            clip over runs end-->
          <!--          </span>-->


        </div>

        <!--- journey--->
        <div *ngIf="journeySelected">

          <!---VIDEO REQUEST--->
          <div *ngIf="footageType == 'video'" style="text-align: center;">
            <div style="width:75%;margin:0 auto;">
              <div style="font-weight:500;font-size: 16px;">
                Click a point on the journey line to request video footage, use the time control below to adjust the
                event time
              </div>
            </div>
            <br>

            <div [formGroup]="videoRequestForm">
              <div style="font-size: 14px;font-weight: bold;text-align: center;">
                <mat-icon style="vertical-align: middle!important;">visibility</mat-icon>&nbsp;
                Select the camera views required
              </div>
              <div style="border-bottom:1px solid #e9e9e9;width:50%;margin:0 auto;margin-bottom: 10px;"></div>
              <div style="display:flex;flex-wrap:wrap;justify-content: center;margin-bottom: 20px;">
                <div *ngFor="let camera of cameras" style="margin-left: 5px;margin-right: 5px;">
                  <input type="checkbox" id='c2{{camera.channel}}' class='chk-btn' [value]="camera.channel" (change)="onCheckChange($event, 'video')"/>
                  <label for='c2{{camera.channel}}'>{{camera.camPosition}} camera</label>
                </div>
              </div>
              <div style="font-size: 14px;font-weight: bold;text-align: center;">
                <mat-icon style="vertical-align: middle!important;">schedule</mat-icon>&nbsp;
                Event time fine adjustment
              </div>
              <div style="border-bottom:1px solid #e9e9e9;width:50%;margin:0 auto;margin-bottom: 10px;"></div>
              <mat-form-field style="width: 50%;margin-bottom: 20px;text-align:center;">
                <input matInput type="time" step="1"  formControlName="startTime" style="text-align:center;">
              </mat-form-field>


              <div style="font-size: 14px;font-weight: bold;text-align: center;">
                <mat-icon style="vertical-align: middle!important;">timer</mat-icon>&nbsp;
                Select video duration
              </div>
              <div style="border-bottom:1px solid #e9e9e9;width:50%;margin:0 auto;margin-bottom: 10px;"></div>
              <mat-form-field style="width:50%;margin:0 auto;text-align:center;">
                <mat-select formControlName="duration">
                  <!--- [(ngModel)]="mediaLengthSelected" (change)="ClipAvailable()" --->
                  <mat-option value="10">10 Seconds</mat-option>
                  <mat-option value="20">20 Seconds</mat-option>
                  <mat-option value="30">30 Seconds</mat-option>
                </mat-select>
              </mat-form-field>


            </div>


            <div *ngIf="disableHyperlapseRequestBtn.message" class="alert alert-success">
              {{disableHyperlapseRequestBtn.message}}
            </div>

            <button *ngIf="!disableHyperlapseRequestBtn.message" mat-flat-button (click)="requestJourneyVideo()"
                    [disabled]="videoRequestForm.status == 'INVALID' || disableHyperlapseRequestBtn.buttonDisabed">
              Upload Video
            </button>

            <!--                <div *ngIf="journeyVideoClipAvailable == 'false'" class="alert alert-danger">-->
            <!--                  The clip requested is not available at this time-->
            <!--                </div>-->
            <!--                <div *ngIf="journeyVideoClipAvailable == 'true'" class="alert alert-success">-->
            <!--                  Your request has been received successfully-->
            <!--                </div>-->
          </div>

          <!---HYPERLAPSE REQUEST--->
          <div *ngIf="footageType == 'hyperlapse'">
            <div *ngIf="journeySelected" style="text-align: center;">

              <div style="width:75%;margin:0 auto;">
                <div style="font-weight:500;font-size: 16px;">
                  To set the start and end points for your footage click the journey line or use the time line below.
                </div>
              </div>

              <br>

              <div [formGroup]="hyperlapseRequestForm">
                <div style="font-size: 14px;font-weight: bold;text-align: center;">
                  <mat-icon style="vertical-align: middle!important;">visibility</mat-icon>&nbsp;
                  Select the camera views required
                </div>
                <div style="border-bottom:1px solid #e9e9e9;width:50%;margin:0 auto;margin-bottom: 10px;"></div>
                <div style="display:flex;flex-wrap:wrap;justify-content: center;margin-bottom: 20px;">
                  <div *ngFor="let camera of cameras" style="margin-left: 5px;margin-right: 5px;">
                    <input type="checkbox" id='c3{{camera.channel}}' class='chk-btn' [value]="camera.channel"
                           (change)="onCheckChange($event, 'hyperlapse')"/>
                    <label for='c3{{camera.channel}}'>{{camera.camPosition}} camera</label>
                  </div>
                </div>

                <div style="font-size: 14px;font-weight: bold;text-align: center;">
                  <mat-icon style="vertical-align: middle!important;">timer</mat-icon>&nbsp;
                  Footage time line
                </div>
                <div style="border-bottom:1px solid #e9e9e9;width:50%;margin:0 auto;margin-bottom: 10px;"></div>


                <dx-range-selector style="height:75px!important;"
                                   id="range-selector"
                                   [value]="[hyperlapseRequestForm.value.startTime, hyperlapseRequestForm.value.endTime]"
                                   (onValueChanged)="onValueChanged($event)"
                                   selectedRangeColor="#5C8459"
                                    [scale]="{minRange: {minutes: 1}}">
                  <dxo-scale
                    [startValue]="rangeSelectorStartValue"
                    [endValue]="rangeSelectorEndValue"
                    [minorTickInterval]="rangeSelectorMinorTick"
                    [tickInterval]="rangeSelectorTick">
                    <dxo-minor-tick [visible]="false"></dxo-minor-tick>
                  </dxo-scale>
                  <dxo-slider-marker format="shortTime"></dxo-slider-marker>
                  <dxo-margin
                    [top]="0"
                    [bottom]="0"
                    [left]="10"
                    [right]="10">
                  </dxo-margin>
                </dx-range-selector>
              </div>
            </div>
            <br>

            <div *ngIf="disableHyperlapseRequestBtn.message" class="alert alert-success" style="text-align:center;">
              {{disableHyperlapseRequestBtn.message}}
            </div>
            <br>


            <div style="text-align:center;" *ngIf="!disableHyperlapseRequestBtn.message" >
              <button mat-flat-button (click)="sendHyperlapseRequest()"
                      [disabled]="disableHyperlapseRequestBtn.buttonDisabed || hyperlapseRequestForm.value.camera.length < 1">
                Upload Video
              </button>
            </div>

          </div>

          <div *ngIf="footageType !== 'hyperlapse' && footageType !== 'video'" class="floatingBox">
            <svg style="width:64px;height:64px;vertical-align: middle;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M14.75 7.46L12 3.93L13.97 3.54L16.71 7.07L14.75 7.46M21.62 6.1L20.84 2.18L16.91 2.96L19.65 6.5L21.62 6.1M19 12C20.08 12 21.09 12.25 22 12.68V10H2V20C2 21.11 2.9 22 4 22H12.68C12.25 21.09 12 20.08 12 19C12 15.13 15.13 12 19 12M11.81 8.05L9.07 4.5L7.1 4.91L9.85 8.44L11.81 8.05M4.16 5.5L3.18 5.69C2.1 5.91 1.4 6.96 1.61 8.04L2 10L6.9 9.03L4.16 5.5M23.8 20.4C23.9 20.4 23.9 20.5 23.8 20.6L22.8 22.3C22.7 22.4 22.6 22.4 22.5 22.4L21.3 22C21 22.2 20.8 22.3 20.5 22.5L20.3 23.8C20.3 23.9 20.2 24 20.1 24H18.1C18 24 17.9 23.9 17.8 23.8L17.6 22.5C17.3 22.4 17 22.2 16.8 22L15.6 22.5C15.5 22.5 15.4 22.5 15.3 22.4L14.3 20.7C14.2 20.6 14.3 20.5 14.4 20.4L15.5 19.6V18.6L14.4 17.8C14.3 17.7 14.3 17.6 14.3 17.5L15.3 15.8C15.4 15.7 15.5 15.7 15.6 15.7L16.8 16.2C17.1 16 17.3 15.9 17.6 15.7L17.8 14.4C17.8 14.3 17.9 14.2 18.1 14.2H20.1C20.2 14.2 20.3 14.3 20.3 14.4L20.5 15.7C20.8 15.8 21.1 16 21.4 16.2L22.6 15.7C22.7 15.7 22.9 15.7 22.9 15.8L23.9 17.5C24 17.6 23.9 17.7 23.8 17.8L22.7 18.6V19.6L23.8 20.4M20.5 19C20.5 18.2 19.8 17.5 19 17.5S17.5 18.2 17.5 19 18.2 20.5 19 20.5 20.5 19.8 20.5 19Z" />
            </svg>
            <br>
            <h1>Select media type to request</h1>
          </div>

        </div>


      </mat-card-content>
    </mat-card>
  </div>

</mat-tab>
</mat-tab-group>



<!-----------------------------------------------EVENT MEDIA MODAL---------------------------------------------->
<ng-template #viewMedia let-data>
  <mat-dialog-content style="height: 100%!important;min-height: 100%;">

  <div style="position: relative;height:100%;" *ngIf="!existingMediaLoaded">
    <mat-spinner style="margin: auto;position: absolute;top: 0; left: 0; bottom: 0; right: 0;"></mat-spinner>
  </div>

  <div style="width: 100%;height:100%;min-height:100%!important;margin-top:5px;text-align: center" [hidden]="!existingMediaLoaded">

    <div style="display:flex;flex-wrap:wrap;width:100%;"
         id="videosContainer" [hidden]="data.obj.video.length < 2">
    </div>

    <div [hidden]="data.obj.video.length > 1">
      <center>
        <b>{{data.obj.video[0].camPosition}} camera</b>
        <br>
        <video style="height:590px;border-radius:5px;" type="video/mp4" id="EventVideo" src="" (ended)="existingMediaEnded()"
               (timeupdate)="existingMediaUpdate($event)" (loadeddata)="videoLoaded($event)">
        </video>
      </center>
    </div>


    <div style="display: flex;justify-content: center;align-items: center;margin-top:5px;width:100%;">
      <button mat-flat-button (click)="playPauseExistingMedia(data.obj)" matTooltip="Play/pause">
        <mat-icon *ngIf="!existingMediaPlaying">play_arrow</mat-icon>
        <mat-icon *ngIf="existingMediaPlaying">pause</mat-icon>
      </button>
      <button mat-flat-button (click)="existingMediaPreviousFrame(data.obj)" *ngIf="data.obj.eventSource == 'hyperlapse'" matTooltip="Previous frame">
        <mat-icon>skip_previous</mat-icon>
      </button>

      <span style="margin-left: 5px;">{{existingMediaCurrentTime}}</span>

      <div style="display: inline-block;margin-left:10px;margin-right:10px;height:10px;flex:1;background:grey;position:relative;cursor:pointer;"
           id="videoProgressBar"
           (click)="clickVideoProgressBar($event, data.obj)">
        <div style="position: absolute;background: black;height: 100%;width: 0"></div>
        <div style="position: absolute;background: #434343;height: 20px;width: 20px;left:-5px;top:-5px;border-radius:50%;"></div>
      </div>

      <span style="margin-right: 5px;">{{existingMediaDuration}}</span>

      <button mat-flat-button (click)="existingMediaNextFrame(data.obj)" *ngIf="data.obj.eventSource == 'hyperlapse'" matTooltip="Next frame">
        <mat-icon>skip_next</mat-icon>
      </button>

      <button type="button" mat-flat-button matTooltip="Download media" *ngIf="data.obj.video.length === 1" (click)="downloadFile(data.obj.video[0])">
        <mat-icon>file_download</mat-icon>
      </button>

      <button mat-flat-button [matMenuTriggerFor]="downloadMenu"  *ngIf="data.obj.video.length > 1">
        <mat-icon>file_download</mat-icon>
      </button>

      <mat-menu #downloadMenu="matMenu">
        <button mat-menu-item *ngFor="let mediaVideoFile of data.obj.video" (click)="downloadFile(mediaVideoFile)">
          {{mediaVideoFile.camPosition}} camera
        </button>
      </mat-menu>
    </div>




    <div *ngIf="data.obj.eventSource == 'hyperlapse'" style="margin:0 auto;text-align: center;margin-top:10px;">

      <strong>
        Request 20 second high frame rate clip from this point in the hyperlapse
      </strong>
      <br>

      <div *ngIf="videoClipFromHyperlapse.message" class="alert alert-success" style="margin-top:15px;">
        {{videoClipFromHyperlapse.message}}
      </div>

      <mat-form-field *ngIf="!videoClipFromHyperlapse.message">
        <mat-label>Camera</mat-label>
        <mat-select [(ngModel)]="channelForClipFromHyper">
          <mat-option *ngFor="let cam of cameras" value="{{cam.channel}}">{{cam.camPosition}}</mat-option>
        </mat-select>
        <button type="button" mat-flat-button matSuffix (click)="calculatePointInTimeFromHyperlapse(data.obj)"
        [disabled]="videoClipFromHyperlapse.disableButton">
          <mat-icon style="vertical-align:middle!important;">cloud_upload</mat-icon>
          &nbsp;Upload
        </button>
      </mat-form-field>

    </div>
  </div>
  </mat-dialog-content>
</ng-template>
<!---------------------------------------EVENT SNAPSHOT MODAL------------------------------------------------------------>
<ng-template #viewEventSnap let-data>

  <div *ngIf="data.obj.snapshots.length > 1">
    <mat-form-field>
      <mat-label>Camera</mat-label>
      <mat-select [ngModel]="data.snapSelected" (selectionChange)="changeSnapshot($event.value)">
        <mat-option *ngFor="let snap of data.obj.snapshots" [value]="snap">
          {{snap.camPosition}} camera
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <center>
    <img src="" id="eventSnapshotToView" style="width:90%;"/>
    <br>

    <div *ngIf="disasbleVideoFromEvent.buttonDisabled" class="alert alert-success">
      {{disasbleVideoFromEvent.message}}
    </div>



    <mat-form-field *ngIf="!disasbleVideoFromEvent.buttonDisabled">
      <mat-label>Camera</mat-label>
      <mat-select [(ngModel)]="channelForClipFromSnap">
        <mat-option *ngFor="let cam of cameras" value="{{cam.channel}}">{{cam.camPosition}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-flat-button (click)="requestEventVideo(data.obj)" [disabled]="disasbleVideoFromEvent.buttonDisabled" style="margin-left:10px;">
      Request 20s video clip of event
    </button>

  </center>
</ng-template>
<!-----------------------------------------------VIEW VIDEO ON MAP---------------------------------------------->
<ng-template #viewMediaMapModal let-data>
  <h1 style="font-size: 28px!important;">Location for media</h1>

  <div style="width:600px;height:600px;" id="eventMap"></div>
</ng-template>
