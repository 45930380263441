import {Component, OnInit, ViewChild} from '@angular/core';
import { AdminService } from '../admin.service'
import { isDeviceOnline, isDeviceParked, modelNumberDisplay } from 'src/lib';
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from '@angular/material/sort';


@Component({
  selector: 'app-device-search',
  templateUrl: './device-search.component.html',
  styleUrls: ['./device-search.component.css']
})
export class DeviceSearchComponent implements OnInit {


  @ViewChild(MatSort) sort: MatSort;
  isDeviceOnline = isDeviceOnline;
  isDeviceParked = isDeviceParked;
  modelNumberDisplay = modelNumberDisplay;

  devices: any;
  fleetSearchParams: string;
  dnSearchParams: string;
  regSearchParams: string;
  datasource: any;
  displayedColumns = ['dn', 'registration', 'fleet', 'group', 'device' ,'lastHeartbeat', 'mode'];
  fleets: any;
  filteredFleetOptions: any;
  filteredDnOptions: any;
  filteredRegOptions: any;


  constructor(private adminService: AdminService) { }

  ngOnInit(): void {

    this.adminService.vehicles({'commissioning.commissionStatus':
        { $in: ['tested', 'service', 'active', 'disabled', 'expired', 'setup']}
    }).subscribe(result => {
      this.devices = result
    });


    this.adminService.fleets({}).subscribe(r => this.fleets = r)
  }

  search(): void {
    if (this.devices?.length > 0){

      const field = this.dnSearchParams?.length > 1 ? 'dn' : this.regSearchParams?.length > 1 ? 'registration' :
        'fleetId';

      const params = this.dnSearchParams?.length > 1 ? this.dnSearchParams : this.regSearchParams?.length > 1 ? this.regSearchParams :
        this.fleetSearchParams;

      if (field?.length > 1){
        const filtered = this.devices.filter(device => device?.[field]?.toLowerCase()?.includes(params.toLowerCase().trim()));
        this.datasource = new MatTableDataSource(filtered);
        this.datasource.sort = this.sort;
      }
    }
  }

  handleClick(device): void {
    window.open('/device/' + device.dn, '_blank')
  }

  fleetChanges(): void {
    const filterValue = this.fleetSearchParams.toLowerCase();
     this.filteredFleetOptions = this.fleets.filter(fleet => fleet?.fleetId?.toLowerCase()?.includes(filterValue) ||
      fleet?.fleetName?.toLowerCase()?.includes(filterValue));
  }

  dnChanges(): void {
    const filterValue = this.dnSearchParams.toLowerCase();
    this.filteredDnOptions = this.devices.filter(device => device?.dn?.toLowerCase()?.includes(filterValue));
  }

  regChanges(): void {
    const filterValue = this.regSearchParams.toLowerCase();
    this.filteredRegOptions = this.devices.filter(device => device?.registration?.toLowerCase()?.includes(filterValue));
  }



}
