import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cluster-mapbox-popup',
  templateUrl: './cluster-mapbox-popup.component.html',
  styleUrls: ['./cluster-mapbox-popup.component.css']
})
export class ClusterMapboxPopupComponent implements OnInit {

  constructor() { }

  public model: any[];   // PopupModel; // Model Property


  ngOnInit(): void {

    console.log(this.model);
    console.log('clusterPopUp');

  }

}
