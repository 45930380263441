import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { LoginComponent } from './login/login.component';
import {FleetsComponent} from './fleets/fleets.component';

import { AuthGuard } from './auth.guard';
import {DevicesComponent} from './devices/devices.component';
import {UsersComponent} from './users/users.component';
import {DiagnosticsComponent} from './diagnostics/diagnostics.component';
import {DeviceSetup} from './device-setup/device-setup.component';
import {ReportsComponent} from './reports/reports.component';
import {MediaComponent} from './media/media.component';
import {DeviceComponent} from './device/device.component';
import {GroupsComponent} from './groups/groups.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import {DevicesSummaryComponent} from "./devices-summary/devices-summary.component";
import {DeviceSearchComponent} from "./device-search/device-search.component";
import {CanaryComponent} from "./canary/canary.component";

const routes: Routes = [
  { path: '', redirectTo: '/device-search', pathMatch: 'full' },
  { path: 'login', component: LoginComponent},
  { path: 'canary', component: CanaryComponent},
  {path: 'fleets', component: FleetsComponent, canActivate: [AuthGuard]},
  {path: 'users', component: UsersComponent, canActivate: [AuthGuard]},
  {path: 'groups', component: GroupsComponent, canActivate: [AuthGuard]},
  {path: 'reports', component: ReportsComponent, canActivate: [AuthGuard]},
  {path: 'devices', component: DevicesSummaryComponent, canActivate: [AuthGuard]},
  // {path: 'devices', component: DevicesComponent, canActivate: [AuthGuard]},
  // {path: 'device-setup/:id', component: DeviceSetup, canActivate: [AuthGuard]},
  {path: 'device/:dn', component: DeviceComponent, canActivate: [AuthGuard]},
  {path: 'diagnostics/:dn', component: DiagnosticsComponent, canActivate: [AuthGuard]},
  {path: 'media/:dn', component: MediaComponent, canActivate: [AuthGuard]},
  {path: 'video-player/:id', component: VideoPlayerComponent, canActivate: [AuthGuard]},
  // {path: 'dashboard', component: DevicesSummaryComponent, canActivate: [AuthGuard]},
   {path: 'device-search', component: DeviceSearchComponent, canActivate: [AuthGuard]},

];




@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)]
})
export class AppRoutingModule { }


