import { Injectable } from '@angular/core';
// import { io } from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketioService {

  socket;

  socketSnap;

  API_URL_NO_PORT = environment.API_URL_NO_PORT;

  selectedDeviceForSnapshot: string;



  constructor() {}

  setupSocketConnection(rooms) {
    // this.socket = io(environment.SOCKET_ENDPOINT);
    // this.socket = io('http://localhost:');
    // this.socket = io('http://myfleetlive.org:8081');

   //  const token = localStorage.getItem('access_token');
   //
   // // const roomArray = [room]
   //
   //   this.socket = io(`${this.API_URL_NO_PORT}:5000`, {
   //     extraHeaders: {
   //       Authorization: token,
   //       rooms: rooms.toString()
   //     }
   //
   //   });
   //
   //
   //
   //   // this.socket = io('https://api.myfleetlive.co.uk:5000');
   //  this.socket.emit('my message', 'Starting....');
   //
   //  this.socket.on('my broadcast', (data: string) => {
   //    // console.log(data);
   //  });
   //
   //
   //  // this.socketSnap = io('https://myfleetlive.org:5001'       api.myfleetlive.co.uk);
   //
   //  this.socketSnap = io(`${this.API_URL_NO_PORT}:5001`, {
   //    extraHeaders: {
   //      Authorization: token
   //    }
   //  });
   //
   //  this.socketSnap.emit('my snaps', 'Snaps starting....');
   //
   //
   //  this.socketSnap.on('my snap broadcast', (data: string) => {
   //    // console.log(data);
   //  });

  }

  onNewMessage() {
    // return new Observable(observer => {
    //   this.socket.on('my broadcast', (msg: any) => {
    //     observer.next(msg);
    //   });
    //});
  }

  onNewSnapshotMessage() {
    // return new Observable(observer => {
    //   this.socketSnap.on('my snap broadcast', (data: string) => {
    //     observer.next(data);
    //   });
    // });
  }






}














/*

environment.ts

export const environment = {
  production: false,
  SOCKET_ENDPOINT: 'http://localhost:3000'
};*/
