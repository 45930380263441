import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {AdminService} from '../admin.service';
import { environment } from 'src/environments/environment';
import {MatDialog} from '@angular/material/dialog';
import {formatDate, KeyValue, LocationStrategy} from '@angular/common';
import { Router } from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import { CalendarOptions } from '@fullcalendar/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { VehiclesService } from '../vehicles.service';
import {DynamicComponentService} from '../dynamic-component.service';
import {CustomMapboxPopupComponent} from '../custom-mapbox-popup/custom-mapbox-popup.component';
import {DatePipe} from '@angular/common';
import { NotificationsService } from '../notifications.service';
import { DomSanitizer } from '@angular/platform-browser';


import mapboxgl from 'mapbox-gl';
import {GeoJson, FeatureCollection} from '../map';
import DevExpress from 'devextreme';
import data = DevExpress.data;
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {Title} from '@angular/platform-browser';
import {isObservable} from 'rxjs/internal-compatibility';
import {BehaviorSubject, Observable} from 'rxjs';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import {DataSharingService} from '../data-sharing.service';
import {isDeviceOnline} from "../../lib";
import dayGridPlugin from "@fullcalendar/daygrid";





@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css'],
  providers: [DatePipe, {provide: STEPPER_GLOBAL_OPTIONS, useValue: {displayDefaultIndicatorType: false}
  }]
  })
export class MediaComponent implements OnInit {

  API_URL = environment.API_URL;
  dn: string;
  deviceConnected = false;
  calendarData: any;
  calendarDataByDate: any;
  mediaStartTimeSelected: string;
  mediaLengthSelected: string;
  dateSelected: string;
  clipSelected: any;
  videoBudget: any;
  videoToRequest: any;
  cameras: any;
  cameraChannelSelected: string;
  journeys: any;
  journeySelected: any;
  journeySelectedDetails: any;
  journeyVideoClipAvailable: string;
  availableMediaByDate: any;
  disableHyperlapseRequestBtn = {
    buttonDisabed: false,
    message: ''
  };
  hyperlapseStartTimeSelected: any;
  footageType: string;
  calendarJourneySlider = 0;
  journeyRecordingSlider = false;
  mflGroup = localStorage.getItem('mflGroup');
  rangeSelectorTick: string;
  rangeSelectorMinorTick: string;
  rangeSelectorStartValue: Date;
  rangeSelectorEndValue: Date;
  timeBetweenHyperlapseFrames: string;
  startDataPointSelected = false;
  theVehicle: any;
  eventDataForPopup: any;
  mediaMap: mapboxgl.Map;
  journeyMap: mapboxgl.Map;
  eventMap: mapboxgl.Map;
  hyperlapseEndSelected: boolean;
  hyperlapseStartSelected: boolean;
  existingMediaPlaying: boolean;
  existingMediaLoaded: boolean;
  existingMediaCurrentTime: string;
  existingMediaDuration: string;
  journeyOrClip: any;
  showMapBackButton: boolean;
  hyperlapseThumbs: any[];
  maxDateForEvents = new Date();
  eventDate = new Date();
  pageLoading = true;
  partnerEvents: any[];
  mediaEventsLoading: boolean;
  numberOfVidsUploading: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  refreshingEvents: any;
  channelForClipFromHyper = '1';


  hyperlapseRequestForm = new UntypedFormGroup({
    camera: new UntypedFormArray([], [Validators.required]),
    startTime: new UntypedFormControl('', [Validators.required]),
    endTime: new UntypedFormControl('', [Validators.required]),
  });


  videoRequestForm = new UntypedFormGroup({
    camera: new UntypedFormArray([], [Validators.required]),
    startTime: new UntypedFormControl('', [Validators.required]),
    duration: new UntypedFormControl('20', [Validators.required])
  });


  confirmMessage = {
    reqButtonDisabled: true,
    fullClip: false,
    message: '',
    show: false
  };


  eventsFilters = {
    user: true,
    journey: true,
    severeOnly: false,
    automatic: true,
    videoOnly: false,
    hyperlapseOnly: false,
    videoQueueOnly: false
  };

  videoClipFromHyperlapse = {
    disableButton: false,
    message: ''
  };

  disasbleVideoFromEvent = {
    buttonDisabled: false,
    message: ''
  };






  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin],
    initialView: 'dayGridMonth',
    displayEventTime: false,
    eventClick: this.eventClick.bind(this),
    eventDisplay: 'list-item',
    validRange: {
      end: new Date()
    },
    height: 500
  };

  partnerEventsDisplay: any[];
  showFilters: number;
  hyperlapseDuration: number;



  @ViewChild('viewMedia') viewEventMedia: TemplateRef<any>;
  @ViewChild('viewMediaMapModal') viewMediaMapModal: TemplateRef<any>;
  @ViewChild('viewEventSnap') viewEventSnap: TemplateRef<any>;
  snapSelected: any;
  channelForClipFromSnap: any;
  baseHref: string;




  constructor(private route: ActivatedRoute, private adminService: AdminService, private Dialog: MatDialog, private router: Router,
              private vehiclesService: VehiclesService, private dynamicComponentService: DynamicComponentService,
              private titleService: Title, private datePipe: DatePipe, private locationStrategy: LocationStrategy,
              private cdRef: ChangeDetectorRef, private notificationService: NotificationsService,
              private sanitizer: DomSanitizer, private  dataShare: DataSharingService) {
  }

  ngOnInit(): void {
    this.baseHref = this.locationStrategy.getBaseHref();

    this.numberOfVidsUploading.subscribe(numberOfUploads => {

      // if (numberOfUploads > 0){
      //   this.refreshingEvents = setInterval(() => {
      //     this.partnerEventsDisplay = [];
      //     this.requestEvents();
      //     console.log('uploads in progress = ' + numberOfUploads);
      //   }, 30000);
      // } else {
      //   clearInterval(this.refreshingEvents);
      // }

    });

////////////////// TEMP REDIRECT TO STOP ACCESS
//     this.router.navigate(['/devices']);

    this.availableMediaByDate = [];

    this.route.paramMap.subscribe(params => {
      this.dn = params.get('dn');
    });

    this.adminService.calendar({dn: this.dn}).subscribe(result => {
      this.calendarData = [];
      this.calendarDataByDate = [];

      if (result?.modRecordingHistory?.length > 0) {
        result.modRecordingHistory.forEach(item => {

          const date = formatDate(new Date(item.st), 'yyyy-MM-dd', 'en-US');
          const date2 = formatDate(new Date(item.et), 'yyyy-MM-dd', 'en-US');

          if (date === date2) {
            if (!this.calendarDataByDate[date]) {
              this.calendarDataByDate[date] = [];
            }
            this.calendarDataByDate[date].push(item);
          } else {
            if (!this.calendarDataByDate[date2]) {
              this.calendarDataByDate[date2] = [];
            }
            this.calendarDataByDate[date2].push(item);
          }
        });
      }
    });

    this.adminService.deviceDetails({dn: this.dn}).pipe(
      map(result => {
        // console.log(result);
        if (result[0]){
          this.theVehicle = result[0];
          this.cameras = result[0].cameras;
          this.deviceConnected = isDeviceOnline(result[0]?.lastHeartbeat);
          return result[0]._id;
        }
      }),
      // switchMap(theId => this.adminService.vehicle({id: theId})),
      // map(result2 => {
      //   console.log(result2);
      //   this.theVehicle = result2;
      // }),
      switchMap(() => this.adminService.deviceHealth({dn: this.dn, limit: 1})),
      map(result3 => {
        // console.log(result3);
        if (this.theVehicle){
          this.theVehicle.health = result3;
        } else {
          this.theVehicle = {
            health: result3
          };
        }
        this.titleService.setTitle('Media - ' + this.dn);

      }),
        switchMap(() => this.adminService.videoBudget({dn: this.dn})),
        map(result4 => {
          this.videoBudget = result4;
          // console.log(result4);
        }),
    ).subscribe(() => {
        this.dateSelectedChange();
        this.pageLoading = false;
    });





  }

  getSantizeUrl(url: string): any {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }




  changeMediaType(): void {

    this.videoRequestForm.controls.startTime.reset();
    const existingMarkers = document.getElementsByClassName('selectedPoint');

    if (existingMarkers?.length > 0){
      Array.from(existingMarkers).forEach(element => {
        element.remove();
      });
    }

    if (this.footageType === 'hyperlapse'){


      const durationOfSelected = (new Date(this.journeySelected.finish.timeUTC).getTime() -
        new Date(this.journeySelected.start.timeUTC).getTime()) / 1000;

      this.rangeSelectorStartValue = new Date(this.journeySelected.start.timeUTC);
      this.rangeSelectorEndValue = new Date(this.journeySelected.finish.timeUTC);

      if (durationOfSelected <= 300){
        this.hyperlapseRequestForm.patchValue({
          startTime: new Date(this.journeySelected.start.timeUTC),
          endTime: new Date(this.journeySelected.finish.timeUTC)
        });
      } else {
        const offset = (durationOfSelected - 300 ) / 2;
        this.hyperlapseRequestForm.patchValue({
          startTime: new Date(this.journeySelected.start.timeUTC).setSeconds(new Date(this.journeySelected.start.timeUTC).getSeconds() + offset),
          endTime: new Date(this.journeySelected.finish.timeUTC).setSeconds(new Date(this.journeySelected.finish.timeUTC).getSeconds() - offset)
        });
      }

      if (((new Date(this.hyperlapseRequestForm.value.endTime).getTime() - new Date(this.hyperlapseRequestForm.value.startTime).getTime()) / 1000) < 60){
        this.disableHyperlapseRequestBtn.buttonDisabed = true;
        this.disableHyperlapseRequestBtn.message = 'Please ensure the hyperlapse period selected is greater than 1 mintue.';
      } else {
        this.disableHyperlapseRequestBtn.buttonDisabed = false;
      }


      // this was in the reset map function but didn't work, but works here
      if (typeof this.journeyMap.getLayer('selectedTimePeriodLine') !== 'undefined') {
        this.journeyMap.removeLayer('selectedTimePeriodLine');
      }

      // draw hyperlapse line
      const selectedTimePeriodLine = {type: 'LineString', coordinates: []};

      this.journeySelectedDetails.tracks.forEach(item => {

        if (new Date(item.timeUTC) >= new Date(this.hyperlapseRequestForm.value.startTime ) &&
          new Date(item.timeUTC) <= new Date(this.hyperlapseRequestForm.value.endTime)){
          selectedTimePeriodLine.coordinates.push([item.lng, item.lat]);
        }
      });

      this.journeyMap.getSource('selectedTimePeriodLine').setData(selectedTimePeriodLine);

      this.journeyMap.addLayer({
        id: 'selectedTimePeriodLine',
        type: 'line',
        source: 'selectedTimePeriodLine',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': 'orange',
          'line-width': {
            base: 8,
            stops: [
              [1, 5],
              [15, 10]
            ]
          },
          'line-offset': {
            stops: [
              [1, 0],
              [14, 0],
              [18, -5],
              [22, -15]
            ]
          },
          'line-opacity': {
            stops: [
              [1, 0.9],
              [16, 0.5]
            ]
          }
        }
      });
      /////////////////////////////////////////////////////////////////////////////////////////////////

    } else {
      // reset line for time period selected on the map
      if (typeof this.journeyMap.getLayer('selectedTimePeriodLine') !== 'undefined') {
        this.journeyMap.removeLayer('selectedTimePeriodLine');
      }
    }
  }


  //////////////////////////////////////////////////////////////////////////
  openMapBoxPopup(event): void {
    this.eventDataForPopup = event.features[0].properties;
    this.eventDataForPopup.loadingAddress = true;
    this.eventDataForPopup.dn = this.dn;
    this.eventDataForPopup.journey_id = this.journeySelected.journey_id;
    this.eventDataForPopup.lngLat = event.lngLat;
    this.eventDataForPopup.registration = this.theVehicle.registration;
    this.eventDataForPopup.mediaType = this.footageType;
    this.eventDataForPopup.hyperlapseStart = this.hyperlapseStartSelected;
    this.eventDataForPopup.hyperlapseEnd = this.hyperlapseEndSelected;



    const popupContent = this.dynamicComponentService.injectComponent(
      CustomMapboxPopupComponent,
      x => x.model = this.eventDataForPopup);

    new mapboxgl.Popup()
      .setLngLat(event.lngLat)
      // .setHTML(popupContent)
      .setDOMContent(popupContent)
      .addTo(this.journeyMap);
  }
  //////////////////////////////////////////////////////////////////////////

  eventDateFilter = (date: Date) => {


    function pad(num, size) {
      let s = "000000000" + num;
      return s.substr(s.length - size);
    }


    const keys = Object.keys(this.availableMediaByDate);
    const theDate = new Date(date);
    const theDate2 = theDate.getFullYear() + '-' + pad(theDate.getMonth() + 1, 2) + '-' + theDate.getDate();


    if (keys.includes(theDate2) && localStorage.getItem('authorisation') === 'superuser'){
      return true;
    } else {
      const d = new Date();
      d.setMonth(d.getMonth() - 3);
      const startDate = Math.max(d.getTime(), new Date(this.theVehicle?.commissioning?.installDate).getTime());

      if (new Date(date).getTime() > startDate){
        return true;
      } else {
        return false;
      }
    }
  }



  // date string to match array key values on available media and calendar data
  convertDateTimeToString(str): string {
    return formatDate(str, 'yyyy-MM-dd', 'en-US');
  }

  // calculate distance between two tracks
  calcDistance(lat1, lon1, lat2, lon2, unit): number {
    if ((lat1 === lat2) && (lon1 === lon2)) {
      return 0;
    } else {
      const radlat1 = Math.PI * lat1 / 180;
      const radlat2 = Math.PI * lat2 / 180;
      const theta = lon1 - lon2;
      const radtheta = Math.PI * theta / 180;
      let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') {
        dist = dist * 1.609344;
      }
      if (unit === 'N') {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }



// on change of hyperlapse slider redefine start and end values
  onValueChanged(arr): void{
    // console.log(arr)

    // console.log(arr);
    // reset disable hyperlapse btn error message
    this.disableHyperlapseRequestBtn.message = '';
    // calculate difference to show the time between frames
    const startTimeHyper = formatDate(arr.value[0], 'yyyy-MM-dd HH:mm:ss', 'en-US');
    setTimeout(() => {

      if (((new Date(arr.value[1]).getTime() - new Date(arr.value[0]).getTime()) / 1000) < 60){
        this.disableHyperlapseRequestBtn.buttonDisabed = true;
        this.disableHyperlapseRequestBtn.message = 'Please ensure the hyperlapse period selcted is greater than 1 mintue  .';
      } else {
        this.disableHyperlapseRequestBtn.buttonDisabed = false;
      }

      const msBetweenFrames = new Date((new Date(arr.value[1]).getTime() - new Date(arr.value[0]).getTime()) / 100);
      const date = formatDate(msBetweenFrames, 'mm:ss', 'en-US').toString();
      const dateParts = date.split(':');

      const mDisplay = parseInt(dateParts[0]) > 0 ? parseInt(dateParts[0]) +
        (parseInt(dateParts[0]) === 1 ? ' minute and ' : ' minutes and ') : '';

      const sDisplay = parseInt(dateParts[1]) > 0 ? parseInt(dateParts[1]) +
        (parseInt(dateParts[1]) === 1 ? ' seconds ' : ' seconds ') : '';


      this.timeBetweenHyperlapseFrames =  'The hyperlapse selected will start at ' + formatDate(arr.value[0], 'MMM d y, h:mm:ss a', 'en-US') +
        ' and will end at ' + formatDate(arr.value[1], 'MMM d y, h:mm:ss a', 'en-US') + ', with  there being approximately ' +
        mDisplay + sDisplay + ' between each frame of the hyperlapse.';


      const difference = (new Date(this.hyperlapseRequestForm.value.endTime).getTime() - new Date(this.hyperlapseRequestForm.value.startTime).getTime());
      this.hyperlapseDuration = Math.round(difference / 60000);

    }, 0);
    // redefine the globals
    this.hyperlapseRequestForm.patchValue({
      startTime: arr.value[0],
      endTime: arr.value[1]
    });

    if (this.journeySelected){
      // reset line for time period selected on the map
      if (typeof this.journeyMap.getLayer('selectedTimePeriodLine') !== 'undefined') {
        this.journeyMap.removeLayer('selectedTimePeriodLine');
      }

      const selectedTimePeriodLine = {type: 'LineString', coordinates: []};
      // set line for time period selected on the map
      this.journeySelectedDetails?.tracks.forEach(item => {
        if (new Date(item.timeUTC) >= new Date(this.hyperlapseRequestForm.value.startTime ) &&
          new Date(item.timeUTC) <= new Date(this.hyperlapseRequestForm.value.endTime)){
          selectedTimePeriodLine.coordinates.push([item.lng, item.lat]);
        }
      });

      this.journeyMap.getSource('selectedTimePeriodLine').setData(selectedTimePeriodLine);




      this.journeyMap.addLayer({
        id: 'selectedTimePeriodLine',
        type: 'line',
        source: 'selectedTimePeriodLine',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': 'orange',
          'line-width': {
            base: 8,
            stops: [
              [1, 5],
              [15, 10]
            ]
          },
          'line-offset': {
            stops: [
              [1, 0],
              [14, 0],
              [18, -5],
              [22, -15]
            ]
          },
          'line-opacity': {
            stops: [
              [1, 0.9],
              [16, 0.5]
            ]
          }
        }
      });
    }


  }



  // set slider to map or journeys
  setJourneyCalendar(tab): void {
    this.showMapBackButton = false;
    // console.log(this.footageType);

    if (tab === 0){
      delete this.clipSelected;
      delete this.journeySelected;
      this.journeyRecordingSlider = false;
      this.calendarJourneySlider = tab;
      // this.resetMap();


    } else if (tab === 1){
      if (this.journeyOrClip === 'journey'){
        this.calendarJourneySlider = tab;
        // delay map loading until after the mat tab animation is complete
        setTimeout(() => {
          this.showJourneyMap();
        }, 1000);
      } else {
        // do not slide in map
        // console.log(this.journeyOrClip);
      }
    } else {
      // remove map if coming from map
      if (this.calendarJourneySlider === 2 ){
        this.journeyMap?.remove();
      }
      this.calendarJourneySlider = tab;
    }
  }



  showJourneyMap(): void {

    // remove all lines/ makers here - like reset map function


    mapboxgl.accessToken = environment.mapbox.accessToken;

    this.journeyMap = new mapboxgl.Map({
      container: 'theJourneyMap',
      style: 'mapbox://styles/fleetfocus/cjvxkhjay5ti71dqa1lldx82p?optimize=true',
      zoom: 5,
      maxZoom: 16,
      center: [-1.664795, 53.785255] // no need for this as it will focus journey
    });


    this.journeyMap.on('load', () => {


      this.showMapBackButton = true;


      this.journeyMap.addSource('journeyLine', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
      this.journeyMap.addControl(new mapboxgl.NavigationControl());
      this.journeyMap.addSource('selectedTimePeriodLine', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
      this.journeyMap.addSource('journeyDataPoints', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });
      // change cursor over datapoint
      this.journeyMap.on('mouseenter', 'journeyDataPoints', e => {
        this.journeyMap.getCanvas().style.cursor = 'pointer';
        this.openMapBoxPopup(e);
        this.eventDataForPopup.loadingAddress = false;
      });
      this.journeyMap.on('mouseleave', 'journeyDataPoints', e => {
        this.journeyMap.getCanvas().style.cursor = '';
        const popUps = document.getElementsByClassName('mapboxgl-popup');
        if (popUps[0]) {
          popUps[0].remove();
        }
      });
      // onclick alert for data point
      this.journeyMap.on('click', 'journeyDataPoints', e => {
        const startTime = new Date(e.features[0].properties.time).setSeconds(new Date(e.features[0].properties.time).getSeconds() - 10);
        this.videoRequestForm.patchValue({
          startTime: formatDate(startTime, 'HH:mm:ss', 'en-US')
        });
        const existingMarkers = document.getElementsByClassName('selectedPoint');
        if (existingMarkers?.length > 0){
          Array.from(existingMarkers).forEach(element => {
            element.remove();
          });
        }
        if (this.footageType === 'video'){
          const el = document.createElement('img');
          el.src = '././assets/svgs/pin-video.svg';
          el.style.width = '25px';
          el.className = 'selectedPoint mapboxgl-marker mapboxgl-marker-anchor-center';
          new mapboxgl.Marker(el).setLngLat(e.lngLat).addTo(this.journeyMap);
        }
        this.openMapBoxPopup(e);
        this.adminService.reverseGeocode(e.lngLat).subscribe(addressData => {
          this.eventDataForPopup.address = addressData.features[0].place_name;
          this.eventDataForPopup.loadingAddress = false;
        });
        const props = e.features[0].properties;
        let message;


        if (this.footageType === 'hyperlapse') {

          if (this.startDataPointSelected) {
            if (props.time) {
              this.hyperlapseRequestForm.patchValue({
                endTime: new Date(props.time),
              });

              this.hyperlapseRequestForm.patchValue({
                endTime: new Date(props.time),
              });
              setTimeout(() => {
                this.eventDataForPopup.hyperlapseEnd = true;
                this.hyperlapseEndSelected = false;
              }, 0);
              this.startDataPointSelected = !this.startDataPointSelected;
              this.hyperlapseStartSelected = !this.hyperlapseStartSelected;
            } else {

              this.hyperlapseRequestForm.patchValue({
                startTime: new Date(this.journeySelected.finish.timeUTC),
              });

              // this.rangeSelectorSelectedStartValue = new Date(this.journeySelected.finish.timeUTC);
              message = 'DATAPOINT MISSING TIMESTAMP';
            }
            this.startDataPointSelected = false;

          } else {

            this.startDataPointSelected = true;
            if (props.time) {
              // this.rangeSelectorSelectedStartValue = new Date(props.time);
              this.hyperlapseRequestForm.patchValue({
                startTime: new Date(props.time),
              });

              setTimeout(() => {
                this.eventDataForPopup.hyperlapseStart = true;
                this.hyperlapseStartSelected = true;
              }, 0);
            } else {
              // this.rangeSelectorSelectedStartValue = new Date(this.journeySelected.start.timeUTC);
              this.hyperlapseRequestForm.patchValue({
                startTime: new Date(this.journeySelected.start.timeUTC),
              });

              message = 'DATAPOINT MISSING TIMESTAMP';
            }
          }
        }




        this.eventDataForPopup.theMessage = message;
      });
      const images = [
        {imageUrl: '././assets/img/licence-plate-1.png', id: 'whitePlate'},
        {imageUrl: '././assets/img/licence-plate-cluster.png', id: 'cluster-marker'},
        {imageUrl: '././assets/img/start-flag.png', id: 'startFlag'},
        {imageUrl: '././assets/img/finish-flag.png', id: 'finishFlag'},
        {imageUrl: '././assets/img/arrow1.png', id: 'arrow'},
        {imageUrl: '././assets/img/licence-plate-arrow.png', id: 'lpArrow'},
        {imageUrl: '././assets/img/car.png', id: 'car'},
        {imageUrl: '././assets/img/truck.png', id: 'truck'},
      ];
      Promise.all(
        images.map(img => {
          return new Promise<void>((resolve, reject) => {
            this.journeyMap.loadImage(img.imageUrl, (error, res) => {
              this.journeyMap.addImage(img.id, res);
              resolve();
            });
          });
        })
      );
    });



    // get journey details
    this.adminService.journeyDetails({_id: this.journeySelected._id}).subscribe(res => {

      setTimeout(() => {
        this.journeySelectedDetails = res;

        const theGeoJson = {type: 'LineString', coordinates: []};
        const theFeatures = {type: 'FeatureCollection', features: []};

        let cumDistance = 0;


        res.tracks.forEach((item, index) => {

          if (index + 1 < res.tracks.length) {
            cumDistance += this.calcDistance(item.lat, item.lng, res.tracks[index + 1].lat, res.tracks[index + 1].lng, 'M');
          }


          const feature = {
            type: 'Feature',
            properties: {
              head: item.head,
              // colour: '#ef00c6',
              colour: '#000000',
              speed: Math.round(item.speed / 100 * 0.621371),    // to mph,
              distance: cumDistance,
              time: item.timeUTC,
              lat: item.lat,
              lng: item.lng
            },
            geometry: {
              type: 'Point',
              coordinates: [item.lng, item.lat]
            }
          };
          // to remove tracking points with no timestamp, caused clicking them to bug out time range selected
          if (item.timeUTC){
            theFeatures.features.push(feature);
          }
          theGeoJson.coordinates.push([item.lng, item.lat]);

        });


        if (!this.journeyMap.getSource('journeyLine')){
          this.journeyMap.addSource('journeyLine');
        }

        this.journeyMap.getSource('journeyLine').setData(theGeoJson);
        this.journeyMap.getSource('journeyDataPoints').setData(theFeatures);

        this.journeyMap.addLayer({
          id: 'journeyDataPoints',
          type: 'symbol',
          source:   'journeyDataPoints',   //      'geoDataPoints', mapDataRegMarkers  mapDataSourceId
          layout: {
            // 'icon-image': 'pin-brake',
            'icon-image': 'lpArrow',   // ['get', 'deviceStatus'],  lpArrow
            //  'icon-ignore-placement': false,
            'icon-allow-overlap': false,
            'icon-size': 0.4,
            'icon-rotate': ['get', 'head'],
          },
          paint: {
            'icon-color': ['get', 'colour'],
          },
        });

        this.journeyMap.addLayer({
          id: 'journeyLine',
          type: 'line',
          source: 'journeyLine',
          layout: {
            'line-join': 'round',
            'line-cap': 'round'
          },
          paint: {
            'line-color': '#1b58c4',
            'line-width': {
              base: 8,
              stops: [
                [1, 5],
                [15, 10]
              ]
            },
            'line-offset': {
              stops: [
                [1, 0],
                [14, 0],
                [18, -5],
                [22, -15]
              ]
            },
            'line-opacity': {
              stops: [
                [1, 0.9],
                [16, 0.5]
              ]
            }
          }
        });
        // this was in reset map function but didn't work, but works here
        if (typeof this.journeyMap.getLayer('startFlag') !== 'undefined') {
          this.journeyMap.removeLayer('startFlag');
          this.journeyMap.removeSource('startFlag');
        }
        this.journeyMap.addLayer({
          id: 'startFlag',
          type: 'symbol',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  properties: {},
                  geometry: {
                    type: 'Point',
                    coordinates: theGeoJson.coordinates[0]
                  }
                }
              ]
            }
          },
          layout: {
            'icon-image': 'startFlag',
            'icon-size': 1,
            'icon-anchor': 'bottom-left',
            'icon-offset': [-7, 0],
          }
        });

        /*Add end marker*/
        this.journeyMap.addLayer({
          id: 'markerEnd',
          type: 'symbol',
          source: {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: [
                {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: theGeoJson.coordinates[theGeoJson.coordinates.length - 1]
                  }
                }
              ]
            }
          },
          filter: ['!=', 'deviceStatus', 'Driving'],
          layout: {
            'icon-image': 'finishFlag',
            'icon-size': 1,
            'icon-anchor': 'bottom-left',
            'icon-offset': [-7, 0],
          }
        });


        const bounds = theGeoJson.coordinates.reduce(function(b, coord) {
          return b.extend(coord);
        }, new mapboxgl.LngLatBounds(theGeoJson.coordinates[0], theGeoJson.coordinates[0]));



        const padding = {bottom: 150, left: 150, top: 150, right: 150};

        this.journeyMap.fitBounds(bounds, {
          padding, maxZoom: 14
        });

      }, 1000);

    });

  }



  resetMap(): void {

    this.disableHyperlapseRequestBtn.message = '';

    const existingMarkers = document.getElementsByClassName('selectedPoint');

    if (existingMarkers?.length > 0){
      Array.from(existingMarkers).forEach(element => {
        element.remove();
      });
    }

    const popUps = document.getElementsByClassName('mapboxgl-popup');

    if (popUps[0]) {
      popUps[0].remove();
    }

    if (typeof this.mediaMap.getLayer('journeyLine') !== 'undefined') {
      this.mediaMap.removeLayer('journeyLine');
    }
    if (typeof this.mediaMap.getLayer('selectedTimePeriodLine') !== 'undefined') {
      this.mediaMap.removeLayer('selectedTimePeriodLine');
    }
    if (typeof this.mediaMap.getLayer('startFlag') !== 'undefined') {
      this.mediaMap.removeLayer('startFlag');
      this.mediaMap.removeSource('startFlag');
    }
    if (typeof this.mediaMap.getLayer('markerEnd') !== 'undefined') {
      this.mediaMap.removeLayer('markerEnd');
      this.mediaMap.removeSource('markerEnd');
    }
    if (typeof this.mediaMap.getLayer('journeyDataPoints') !== 'undefined') {
      this.mediaMap.removeLayer('journeyDataPoints');
    }

    this.hyperlapseEndSelected = false;
    this.hyperlapseStartSelected = false;


// reset to hover over the last location of the device-setup
    if (this.theVehicle?.health[0]?.alarmData?.statusData?.longitude){
      this.mediaMap.flyTo({
        center: [this.theVehicle.health[0].alarmData.statusData.longitude, this.theVehicle.health[0].alarmData.statusData.latitude],
        zoom: 7,
        speed: 4
      });
    }

  }


  eventClick(event): void {

    this.setJourneyCalendar(0);

    this.dateSelected = formatDate(event.event.start, 'yyyy-MM-dd', 'en-US');

    // event.el.parentNode.parentNode.parentNode.style.backgroundColor = 'green';
    if (this.calendarDataByDate[this.dateSelected]) {
      this.calendarDataByDate[this.dateSelected].reverse().forEach((calData, index) => {

        if (index >= 1) {
          const thisClipStart = new Date(calData.st);
          const thisClipStartUnix = thisClipStart.getTime();

          const lastClipEnd = new Date(this.calendarDataByDate[this.dateSelected][(index - 1)].et);
          const lastClipEndUnix = lastClipEnd.getTime();

          if (lastClipEndUnix <= thisClipStartUnix && thisClipStartUnix <= (lastClipEndUnix + 1000)) {
            this.calendarDataByDate[this.dateSelected][(index)] = {
              st: formatDate(new Date(this.calendarDataByDate[this.dateSelected][(index - 1)].st), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
              et: formatDate(new Date(calData.et), 'yyyy-MM-dd HH:mm:ss', 'en-US')
            };
            this.calendarDataByDate[this.dateSelected].splice((index - 1), 1);
          }
        }
      });
    }
    this.adminService.journeys({dn: this.dn, date: this.dateSelected}).pipe(
      map(result => {
        this.journeys = result;
        return this.dn;
      })
    ).subscribe();

    this.confirmMessage.show = false;

  }


  ClipAvailable(): void {

    this.confirmMessage.show = false;
    const theDate = new Date(this.dateSelected);
    const timeArr = this.videoRequestForm.value.startTime.split(':');

    if (!timeArr[2]) {
      timeArr.push('00');
    }

    theDate.setFullYear(new Date(this.dateSelected).getFullYear());
    theDate.setMonth(new Date(this.dateSelected).getMonth());
    theDate.setDate(new Date(this.dateSelected).getDate());
    theDate.setHours(parseInt(timeArr[0]));
    theDate.setMinutes(parseInt(timeArr[1]));
    theDate.setSeconds(parseInt(timeArr[2]));

    const s = theDate.getSeconds();
    const t = s + parseInt(this.videoRequestForm.value.duration);
    const u = new Date(theDate).setSeconds(t);
    const v = new Date(u).getTime();
    const et = new Date(this.clipSelected.et).getTime();
    const st = new Date(this.clipSelected.st).getTime();
    const check2 = v;


    if (theDate.getTime() >= st && theDate.getTime() <= et && check2 >= st && check2 <= et) {

      this.videoRequestForm.value.camera.forEach(cam => {
        const videoToRequest = {
          st: formatDate(theDate, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
          et: formatDate(new Date(u), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
          dn: this.dn,
          ch: cam,
          // callback: environment.NOTIFICATIONS_API_URL + '/MflConnectWebhook',
        };

        this.sendVideoRequest(videoToRequest);
      });


      this.confirmMessage = {
        message: 'Clip requested',
        fullClip: false,
        reqButtonDisabled: false,
        show: true
      };



      // this.videoToRequest.et = new Date(multiLivestream);
    } else if (theDate.getTime() >= st && theDate.getTime() < et) {
      this.confirmMessage = {
        message: 'Clip for timespan not all available clip ends at '
          + formatDate(new Date(this.clipSelected.et), 'HH:mm:ss', 'en-US'),
        fullClip: false,
        reqButtonDisabled: false,
        show: true
      };

      setTimeout(() => {
        this.confirmMessage.show = false;
      }, 5000);

      this.videoToRequest = {
        st: formatDate(theDate, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
        et: formatDate(this.clipSelected.et, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
        dn: this.dn,
        ch: this.videoRequestForm.value.camera,
        // callback: environment.NOTIFICATIONS_API_URL + '/MflConnectWebhook',
      };
    } else {
      this.confirmMessage = {
        message: 'Clip for timespan not all available, ' + 'clip starts at '
          + formatDate(new Date(this.clipSelected.st), 'HH:mm:ss', 'en-US') +
          'clip ends at '
          + formatDate(new Date(this.clipSelected.et), 'HH:mm:ss', 'en-US'),
        fullClip: false,
        reqButtonDisabled: true,
        show: true
      };

      setTimeout(() => {
        this.confirmMessage.show = false;
      }, 5000);
    }
  }

///////////////////////////////////////////////////////////////////////////////////////////////////////
  SelectMedia(type, obj): void {

// console.log(this.availableMediaByDate);

    delete this.journeyOrClip;
    this.journeyOrClip = type;
  // reset the map
  //   this.resetMap();
    this.startDataPointSelected = false;
    this.hyperlapseStartSelected = false;
    this.hyperlapseEndSelected = false;



  if (type === 'journey') {
      // journey
     delete this.clipSelected;
     this.disableHyperlapseRequestBtn.buttonDisabed = false;
     this.rangeSelectorTick = 'minute';
     this.rangeSelectorMinorTick = 'minute';

     // if hyperlapse is longer than 10 mins auto select middle 10 mins
     const durationOfSelected = (new Date(obj.finish.timeUTC).getTime() - new Date(obj.start.timeUTC).getTime()) / 1000;

     // console.log(durationOfSelected);

     this.rangeSelectorStartValue = new Date(obj.start.timeUTC);
     this.rangeSelectorEndValue = new Date(obj.finish.timeUTC);

     if (durationOfSelected <= 300){
       this.hyperlapseRequestForm.patchValue({
         startTime: new Date(obj.start.timeUTC),
         endTime: new Date(obj.finish.timeUTC)
       });
     } else {
       const offset = (durationOfSelected - 300 ) / 2;
       this.hyperlapseRequestForm.patchValue({
         startTime: new Date(obj.start.timeUTC).setSeconds(new Date(obj.start.timeUTC).getSeconds() + offset),
         endTime: new Date(obj.finish.timeUTC).setSeconds(new Date(obj.finish.timeUTC).getSeconds() - offset)
       });
     }





     const msBetweenFrames = new Date((new Date(this.hyperlapseRequestForm.value.endTime).getTime()
       - new Date(this.hyperlapseRequestForm.value.startTime).getTime()) / 100);
     const date = formatDate(msBetweenFrames, 'mm:ss', 'en-US').toString();


     const dateParts = date.split(':');
     const mDisplay = parseInt(dateParts[0]) > 0 ? parseInt(dateParts[0]) + (parseInt(dateParts[0]) === 1 ? ' minute and ' : ' minutes and ') : '';
     const sDisplay = parseInt(dateParts[1]) > 0 ? parseInt(dateParts[1]) + (parseInt(dateParts[1]) === 1 ? ' seconds ' : ' seconds ') : '';
     this.timeBetweenHyperlapseFrames =  'The hyperlapse selected will start at ' + formatDate(obj.start.timeUTC, 'MMM d y, h:mm:ss a', 'en-US') +
     ' and will end at ' + formatDate(obj.finish.timeUTC, 'MMM d y, h:mm:ss a', 'en-US') + ', with  there being approximatley ' +
     mDisplay + sDisplay + ' between each frame of the hyperlapse.';


     const difference = (new Date(this.hyperlapseRequestForm.value.endTime).getTime() - new Date(this.hyperlapseRequestForm.value.startTime).getTime());
     this.hyperlapseDuration = Math.round(difference / 60000);

// need to move this to onchange also
     obj.mediaExists = [];

     if (this.availableMediaByDate[formatDate(this.hyperlapseRequestForm.value.startTime, 'yyyy-MM-dd', 'en-US')]) {
        this.availableMediaByDate[formatDate(this.hyperlapseRequestForm.value.startTime, 'yyyy-MM-dd', 'en-US')].forEach(media => {
          const JourneyStart = formatDate(this.hyperlapseRequestForm.value.startTime, 'yyyy-MM-dd HH:mm:ss', 'en-US');
          const JourneyEnd = formatDate(this.hyperlapseRequestForm.value.endTime, 'yyyy-MM-dd HH:mm:ss', 'en-US');

          // checks if any hyperlapses span the length of the journey from the start to 60s either side of the end
          if (media.mediaType === 'hyperlapse' && media.start === JourneyStart &&
            (new Date(media.end).getTime() >= (new Date(this.hyperlapseRequestForm.value.endTime).getTime() - 60000) &&
              new Date(media.end).getTime() <= (new Date(this.hyperlapseRequestForm.value.endTime).getTime() + 60000))) {
            this.disableHyperlapseRequestBtn.buttonDisabed = true;
            this.disableHyperlapseRequestBtn.message = 'Hyperlapse for period selected already exists';
          } else {
            this.disableHyperlapseRequestBtn.buttonDisabed = false;

          }

          if (new Date(JourneyStart) <= new Date(media.start) && new Date(JourneyEnd) >= new Date(media.end)) {
            obj.mediaExists.push(media);
          }

        });
      }

     this.journeySelected = obj;
     this.journeyVideoClipAvailable = '';


// snapshots for journey
     setTimeout(() => {
        this.confirmMessage.show = false;
      }, 200);


  } else {
      // recording
      delete this.journeySelected;
      this.rangeSelectorStartValue = new Date(obj.st);
      this.rangeSelectorEndValue = new Date(obj.et);
      this.footageType = 'hyperlapse';

      setTimeout(() => {
        this.clipSelected = obj;

        this.confirmMessage.show = false;
      }, 100);
    }

    this.setJourneyCalendar(1);
  }

  requestJourneyVideo(): void {
    const theDate = new Date(this.dateSelected);
    const timeArr = this.videoRequestForm.value.startTime.split(':');

    if (!timeArr[2]) {
      timeArr.push('00');
    }

    theDate.setFullYear(new Date(this.dateSelected).getFullYear());
    theDate.setMonth(new Date(this.dateSelected).getMonth());
    theDate.setDate(new Date(this.dateSelected).getDate());
    theDate.setHours(parseInt(timeArr[0]));
    theDate.setMinutes(parseInt(timeArr[1]));
    theDate.setSeconds(parseInt(timeArr[2]));

    const s = theDate.getSeconds();
    const t = s + parseInt(this.videoRequestForm.value.duration);
    const u = new Date(theDate).setSeconds(t);
    const v = new Date(u).getTime();
    const check2 = v;

    let journeyVideoClipAvailable = false;

    this.calendarDataByDate[this.dateSelected].forEach(recording => {
      // checks media start time, and duration against all media, to see if available in  full.
      const recordingStart = new Date(recording.st).getTime();
      const recordingEnd = new Date(recording.et).getTime();

      if (theDate.getTime() >= recordingStart && theDate.getTime() <= recordingEnd && check2 >= recordingStart && check2 <= recordingEnd){
        journeyVideoClipAvailable = true;
      }
    });

    if (journeyVideoClipAvailable) {

      // console.log(this.videoRequestForm.value.camera);

      this.journeyVideoClipAvailable = 'true';

      this.videoRequestForm.value.camera.forEach(channel => {
        const dataToSend = {
          st: formatDate(theDate, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
          et: formatDate(new Date(u), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
          dn: this.dn,
          ch: channel,
          // callback: environment.NOTIFICATIONS_API_URL + '/MflConnectWebhook',
        };

        console.log(dataToSend);

        this.sendVideoRequest(dataToSend);
        this.disableHyperlapseRequestBtn.buttonDisabed = true;
        this.disableHyperlapseRequestBtn.message = 'Video request received, uploading to cloud';
      });




    } else {
      this.journeyVideoClipAvailable = 'false';
      this.disableHyperlapseRequestBtn.buttonDisabed = true;
      this.disableHyperlapseRequestBtn.message = 'Video does not exist for the requested time';
    }

    setTimeout(() => {
      this.disableHyperlapseRequestBtn.buttonDisabed = false;
      delete this.disableHyperlapseRequestBtn.message;
    }, 2000);


  }

  viewMediaInModal(media): void {
    console.log(media)

    // check of there is more than one hyperlapse here, if so create elements
    // for the number of hyperlapses and fetch videos and apply blobs to element
    // will need seperate controls for multiple videos

    this.Dialog.open(this.viewEventMedia, {hasBackdrop: true, width: '90%', height: '90%', data: {obj: media}});


    this.existingMediaLoaded = false;


    if (media.video.length > 1){
      const container = (document.getElementById('videosContainer') as HTMLElement);

      let videosPending = 0;
      let index = 0;
      const channelsDisplaying = [];

      for (const hyperlapse of media.video){

        if (channelsDisplaying.includes(hyperlapse.channel)){
          if (videosPending < 1){
            this.existingMediaLoaded = true;
          }
          break;
        } else {
          channelsDisplaying.push(hyperlapse.channel);
        }


        videosPending ++;

        for (const cam of this.cameras) {
          if (hyperlapse.channel){
            if (cam.channel === hyperlapse.channel){
              hyperlapse.camPosition = cam.camPosition;
              break;
            }
          } else {
            if (cam.channel === hyperlapse.ch){
              hyperlapse.camPosition = cam.camPosition;
              break;
            }
          }
        }


        let videoFile;
        if (hyperlapse.filename){
          videoFile = hyperlapse.filename;
        } else {
          videoFile = hyperlapse.file;
        }


        this.adminService.viewVideo(videoFile).then(
          blob => {
            const theVideoId = videosPending;
            videosPending --;

            if (videosPending < 1){
              this.existingMediaLoaded = true;
            }

            const videoParent = (document.createElement('div') as HTMLElement);
            videoParent.id = 'videoParent' + theVideoId;
            videoParent.style.flexBasis = '50%';
            videoParent.style.textAlign = 'center';
            videoParent.style.fontWeight = 'bold';
            const videoTitle = (document.createElement('div') as HTMLElement);
            videoTitle.innerText = hyperlapse.camPosition + ' camera';
            videoParent.append(videoTitle);
            const videoEle = (document.createElement('video') as HTMLVideoElement);
            // videoEle.style.height = '250px';
            videoEle.style.width = '90%';
            videoEle.id = 'video' + theVideoId;
            videoEle.src = URL.createObjectURL(blob);

            videoEle.addEventListener('timeupdate', (event) => {
              this.existingMediaUpdate(event);
            });
            videoEle.addEventListener('loadeddata', (event) => {
              this.videoLoaded(event);
            });



            hyperlapse.videoDownloadLink = URL.createObjectURL(blob);
            videoParent.append(videoEle);
            container.append(videoParent);

            const updatedContainer = document.getElementById('videosContainer');

            for (let i = 1; i < updatedContainer.childNodes.length; i++){
              document.getElementById('videoParent' + i).style.flexBasis = '50%';
              document.getElementById('video' + i).style.width = '90%';
            }


          });
        index++;
      }
    } else {


      let videoFile;
      if (media.video[0].filename){
        videoFile = media.video[0].filename;
      } else {
        videoFile = media.video[0].file;
      }

      for (const cam of this.cameras) {
        if (media.video[0].channel){
          if (cam.channel === media.video[0].channel){
            media.video[0].camPosition = cam.camPosition;
            break;
          }
        } else {
          if (cam.channel === media.video[0].ch){
            media.video[0].camPosition = cam.camPosition;
            break;
          }
        }
      }


      this.adminService.viewVideo(videoFile).then(
        blob => {
          (document.getElementById('EventVideo') as HTMLVideoElement).src = URL.createObjectURL(blob);
          media.video[0].videoDownloadLink = URL.createObjectURL(blob);
          this.existingMediaLoaded = true;
        });
    }

    // console.log(media);
    // console.log(this.cameras);
    this.existingMediaPlaying = false;
  }

  downloadFile(video): void {

    // console.log(video);

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = video.videoDownloadLink;
    a.download = 'MFL Connect - Video download';
    a.click();
  }



  ///////////////////////// video controls/////////////////////////////////
  // pad time of the video
  str_pad_left(stringy, pad, length): string {
    return (new Array(length + 1).join(pad) + stringy).slice(-length);
  }

  videoLoaded(event): void {
    this.existingMediaCurrentTime = '00:00';
    const theDuration = Math.round(event.target.duration);

    const minutes = Math.floor(theDuration / 60);
    const seconds = theDuration - (minutes * 60);
    this.existingMediaDuration = this.str_pad_left(minutes, '0', 2) + ':' + this.str_pad_left(seconds, '0', 2);
  }

  playPauseExistingMedia(media): void {

    if (media.video.length === 1){
      if (this.existingMediaPlaying){
        (document.getElementById('EventVideo') as HTMLVideoElement).pause();
        this.existingMediaPlaying = false;
      } else {
        (document.getElementById('EventVideo') as HTMLVideoElement).play();
        this.existingMediaPlaying = true;
      }
    } else {
      const container = (document.getElementById('videosContainer'));
      if (this.existingMediaPlaying){
        container.childNodes.forEach(ele => {
          (ele.childNodes[1] as HTMLVideoElement).pause();
          this.existingMediaPlaying = false;
        });
      } else {
        container.childNodes.forEach(ele => {
          (ele.childNodes[1] as HTMLVideoElement).play();
          this.existingMediaPlaying = true;
        });
      }
    }

  }

  existingMediaEnded(): void {
    this.existingMediaPlaying = false;
  }

  existingMediaUpdate(event): void {
    // custom video progressBar
    const vid = event.target;

    const currentTime = Math.round(vid.currentTime);

    const minutes = Math.floor(currentTime / 60);
    const seconds = currentTime - (minutes * 60);
    this.existingMediaCurrentTime = this.str_pad_left(minutes, '0', 2) + ':' + this.str_pad_left(seconds, '0', 2);
    const percentage = ( vid.currentTime / vid.duration ) * 100;

    const videoProgressBar = (document.getElementById('videoProgressBar') as HTMLElement);
    (videoProgressBar.childNodes[0] as HTMLElement).style.width = `${percentage}%`;
    (videoProgressBar.childNodes[1] as HTMLElement).style.left = `calc(${percentage}% - 10px)`;
  }

  clickVideoProgressBar(event, media): void {
    // click custom video progressBar
    const videoProgressBar = ((document.getElementById('videoProgressBar') as HTMLElement).childNodes[0] as HTMLElement);
    const leftOffset = (videoProgressBar.parentNode as HTMLElement).offsetLeft;
    const leftValue = (event.pageX - leftOffset);
    const totalWidthOfBar = (videoProgressBar.parentNode as HTMLElement).offsetWidth;
    const percentageWidth = ( leftValue / totalWidthOfBar );


    if (media.video.length === 1){
      const vid = (document.getElementById('EventVideo') as HTMLVideoElement);
      vid.currentTime = vid.duration * percentageWidth;
    } else {

      const container = (document.getElementById('videosContainer'));
      container.childNodes.forEach(ele => {
        const vid = (ele.childNodes[1] as HTMLVideoElement);
        vid.currentTime = vid.duration * percentageWidth;
      });
    }


  }

  existingMediaNextFrame(media): void {
    if (media.video.length === 1){
      const video = (document.getElementById('EventVideo') as HTMLVideoElement);

      if (video.currentTime === 0 ){
        video.currentTime = 0.2;
      } else if (video.currentTime === video.duration) {
        video.currentTime = 0.2;
      } else {
        video.currentTime = (video.currentTime + 0.2);
      }
    } else {
      const container = (document.getElementById('videosContainer'));
      container.childNodes.forEach(ele => {
        const video = (ele.childNodes[1] as HTMLVideoElement);

        if (video.currentTime === 0 ){
          video.currentTime = 0.2;
        } else if (video.currentTime === video.duration) {
          video.currentTime = 0.2;
        } else {
          video.currentTime = (video.currentTime + 0.2);
        }      });
    }

  }
  existingMediaPreviousFrame(media): void {

    if (media.video.length === 1){
      const video = (document.getElementById('EventVideo') as HTMLVideoElement);

      if (video.currentTime > 0 ) {
        video.currentTime = (video.currentTime - 0.2);
      }
    } else {
      const container = (document.getElementById('videosContainer'));
      container.childNodes.forEach(ele => {
        const video = (ele.childNodes[1] as HTMLVideoElement);

        if (video.currentTime > 0 ) {
          video.currentTime = (video.currentTime - 0.2);
        }
      });
    }


  }





  calculatePointInTimeFromHyperlapse(hyper): void {


    this.adminService.hyperlapseDetail({_id: hyper._id}).subscribe(res => {
      let timeOfVid;
      let lengthOfVid;
      if (hyper.video.length === 1){
        timeOfVid = (document.getElementById('EventVideo') as HTMLVideoElement).currentTime;
        lengthOfVid = (document.getElementById('EventVideo') as HTMLVideoElement).duration;
      } else {
        const video = (document.getElementById('videosContainer').childNodes[0].childNodes[1] as HTMLVideoElement);
        timeOfVid = video.currentTime;
        lengthOfVid = video.duration;
      }

      const fpsOfVid = (res.frames / lengthOfVid);
      const timeAtPointInVid = res.timesUTC[Math.floor((timeOfVid * fpsOfVid))].time;
      const videoToRequest = {
        st: timeAtPointInVid,
        et: formatDate(new Date(timeAtPointInVid).getTime() + 20000, 'yyyy-MM-dd HH:mm:ss', 'en-US'),
        dn: this.dn,
        ch: this.channelForClipFromHyper,
        // callback: environment.NOTIFICATIONS_API_URL + '/MflConnectWebhook',
      };
      this.sendVideoRequest(videoToRequest);
      // console.log(videoToRequest);

      this.videoClipFromHyperlapse.message = 'Video request received, uploading to cloud';
      this.videoClipFromHyperlapse.disableButton = true;

      setTimeout(() => {
        this.videoClipFromHyperlapse.message = '';
        this.videoClipFromHyperlapse.disableButton = false;
      }, 4000);


    });
  }
  ////////////////////////////////////////////////////////////////////////////////////



  sendVideoRequest(theData): void {
    console.log(theData);
    // this.notificationService.requestVideo(theData, this.theVehicle).subscribe(r => {
    //   console.log(r);
    //   this.notificationService.fetchNotifications().subscribe(r => {
    //     this.dataShare.notificationsRaw.next(r);
    //   });
    // });

    this.adminService.videoRequest(theData).subscribe(result => {
      // add the below to the media uploads in progress db table
      console.log(result.mediaRequest);
      // this.notificationService.addToMediaQueue(result.mediaRequest, this.theVehicle).subscribe(r => {
      //   console.log(r);
      // });
    });
    this.requestEvents();
  }

  sendHyperlapseRequest(): void {

    console.log(this.hyperlapseRequestForm.value);

    const startTimeHyper = formatDate(new Date(this.hyperlapseRequestForm.value.startTime), 'yyyy-MM-dd HH:mm:ss', 'en-US');
    const difference = (new Date(this.hyperlapseRequestForm.value.endTime).getTime() - new Date(this.hyperlapseRequestForm.value.startTime).getTime());
    let resultInMinutes = Math.round(difference / 60000);

    if (resultInMinutes < 1){
      resultInMinutes = 1;
    }

    this.disableHyperlapseRequestBtn.buttonDisabed = true;
    this.disableHyperlapseRequestBtn.message = 'Video request received, uploading to cloud';

    this.hyperlapseRequestForm.value.camera.forEach(channel => {
      const dataToSend = {
        dn: this.dn,
        channel: parseInt(channel),
        startTimeHyper,
        durationMinutes: resultInMinutes,
        totalFrames: 100,
        // callback: environment.NOTIFICATIONS_API_URL + '/MflConnectWebhook'
      };



      console.log(dataToSend);

      this.adminService.hyperlapseRequest(dataToSend).subscribe(result => {
          this.disableHyperlapseRequestBtn.message = result.message;
          // this.notificationService.addToMediaQueue(result.hyperlapse, this.theVehicle).subscribe(r => {
          //   console.log(r);
          // });
      });

        // this.notificationService.requestHyperlapse(dataToSend, this.theVehicle).subscribe(r => {
        //   console.log(r);
        //   console.log(dataToSend);
        //
        // });
    });



    setTimeout(() => {
      this.disableHyperlapseRequestBtn.buttonDisabed = false;
      delete this.disableHyperlapseRequestBtn.message;
      }, 2000);

    this.requestEvents();
  }

  onCheckChange(event, source): void {
    if (source === 'video') {
      const formArray: UntypedFormArray = this.videoRequestForm.get('camera') as UntypedFormArray;
      if (event.target.checked) {
        // Add a new control in the arrayForm
        formArray.push(new UntypedFormControl(event.target.value));
      } else {
        let i = 0;
        formArray.controls.forEach((ctrl: UntypedFormControl) => {
          if (ctrl.value === event.target.value) {
            // Remove the unselected element from the arrayForm
            formArray.removeAt(i);
            return;
          }
          i++;
        });
      }
    } else {
      const formArray: UntypedFormArray = this.hyperlapseRequestForm.get('camera') as UntypedFormArray;
      if (event.target.checked) {
        // Add a new control in the arrayForm
        formArray.push(new UntypedFormControl(event.target.value));
      } else {
        let i = 0;
        formArray.controls.forEach((ctrl: UntypedFormControl) => {
          if (ctrl.value === event.target
            .value) {
            // Remove the unselected element from the arrayForm
            formArray.removeAt(i);
            return;
          }
          i++;
        });
      }
    }
  }

  onCheckChangeRec(event, source): void {
    if (source === 'video') {
      const formArray: UntypedFormArray = this.videoRequestForm.get('camera') as UntypedFormArray;
      if (event.target.checked) {
        // Add a new control in the arrayForm
        formArray.push(new UntypedFormControl(event.target.value));
      } else {
        let i = 0;
        formArray.controls.forEach((ctrl: UntypedFormControl) => {
          if (ctrl.value === event.target.value) {
            // Remove the unselected element from the arrayForm
            formArray.removeAt(i);
            return;
          }
          i++;
        });
      }
    } else {
      const formArray: UntypedFormArray = this.hyperlapseRequestForm.get('camera') as UntypedFormArray;
      if (event.target.checked) {
        // Add a new control in the arrayForm
        formArray.push(new UntypedFormControl(event.target.value));
      } else {
        let i = 0;
        formArray.controls.forEach((ctrl: UntypedFormControl) => {
          if (ctrl.value === event.target
            .value) {
            // Remove the unselected element from the arrayForm
            formArray.removeAt(i);
            return;
          }
          i++;
        });
      }
    }
  }



  viewMediaOnMap(e): void {

    // console.log(e);

    const mapDialog = this.Dialog.open(this.viewMediaMapModal, {hasBackdrop: true});




// need to add if video is pending upload
    mapDialog.afterOpened().subscribe(_ => {

          mapboxgl.accessToken = environment.mapbox.accessToken;

          this.eventMap = new mapboxgl.Map({
            container: 'eventMap',
            style: 'mapbox://styles/fleetfocus/cjvxkhjay5ti71dqa1lldx82p?optimize=true',
            zoom: 12,
            center: [this.theVehicle.health[0].alarmData.statusData.longitude, this.theVehicle.health[0].alarmData.statusData.latitude]
          });
          this.eventMap.addControl(new mapboxgl.NavigationControl());

          this.eventMap.on('load', () => {
            if (e.eventSource === 'hyperlapse' || e.eventSource === 'videoUpload') {


              const images = [
                {imageUrl: '././assets/img/licence-plate-1.png', id: 'whitePlate'},
                {imageUrl: '././assets/img/licence-plate-cluster.png', id: 'cluster-marker'},
                {imageUrl: '././assets/img/start-flag.png', id: 'startFlag'},
                {imageUrl: '././assets/img/finish-flag.png', id: 'finishFlag'},
                {imageUrl: '././assets/img/arrow1.png', id: 'arrow'},
                {imageUrl: '././assets/img/licence-plate-arrow.png', id: 'lpArrow'},
                {imageUrl: '././assets/img/car.png', id: 'car'},
                {imageUrl: '././assets/img/truck.png', id: 'truck'},
              ];
              Promise.all(
                images.map(img => {
                  return new Promise<void>((resolve, reject) => {
                    this.eventMap.loadImage(img.imageUrl, (error, res) => {
                      this.eventMap.addImage(img.id, res);
                      resolve();
                    });
                  });
                })
              );

              this.eventMap.addSource('journeyDataPoints', {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: []
                }
              });
              this.eventMap.addSource('journeyLine', {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: []
                }
              });


              ///
              const eventEnd = new Date(e.video[0].startTimeHyper).setSeconds(new Date(e.video[0].startTimeHyper).getSeconds() +
                (e.video[0].totalFrames * e.video[0].intervalSeconds));

              const date = formatDate(new Date(e.startTime), 'yyyy-MM-dd', 'en-US');
              let cumDistance = 0;
              const theGeoJson = {type: 'LineString', coordinates: []};
              const theFeatures = {type: 'FeatureCollection', features: []};

              this.adminService.journeys({dn: this.dn, date}).subscribe(result => {
                result.forEach(journey => {

                  if (new Date(journey.start.timeUTC) <= new Date(e.startTime) && new Date(e.startTime) <= new Date(journey.finish.timeUTC)) {
                    this.adminService.journeyDetails({_id: journey._id}).subscribe(res => {
                      // console.log(res);

                      res.tracks.forEach((item, index) => {

                        if (index + 1 < res.tracks.length) {
                          cumDistance += this.calcDistance(item.lat, item.lng, res.tracks[index + 1].lat, res.tracks[index + 1].lng, 'M');
                        }
                        const feature = {
                          type: 'Feature',
                          properties: {
                            head: item.head,
                            // colour: '#ef00c6',
                            colour: '#000000',
                            speed: Math.round(item.speed / 100 * 0.621371),    // to mph,
                            distance: cumDistance,
                            time: item.timeUTC,
                            lat: item.lat,
                            lng: item.lng
                          },
                          geometry: {
                            type: 'Point',
                            coordinates: [item.lng, item.lat]
                          }
                        };
                        // to remove tracking points with no timestamp, caused clicking them to bug out time range selected
                        if (item.timeUTC && new Date(item.timeUTC) >= new Date(e.video[0].startTimeHyper) && new Date(item.timeUTC) <= new Date(eventEnd)) {
                          theFeatures.features.push(feature);
                          theGeoJson.coordinates.push([item.lng, item.lat]);
                        }
                      });

                      this.eventMap.getSource('journeyLine').setData(theGeoJson);
                      this.eventMap.getSource('journeyDataPoints').setData(theFeatures);

                      this.eventMap.addLayer({
                        id: 'journeyDataPoints',
                        type: 'symbol',
                        source: 'journeyDataPoints',
                        layout: {
                          'icon-image': 'lpArrow',
                          'icon-allow-overlap': false,
                          'icon-size': 0.4,
                          'icon-rotate': ['get', 'head'],
                        },
                        paint: {
                          'icon-color': ['get', 'colour'],
                        },
                      });

                      this.eventMap.addLayer({
                        id: 'journeyLine',
                        type: 'line',
                        source: 'journeyLine',
                        layout: {
                          'line-join': 'round',
                          'line-cap': 'round'
                        },
                        paint: {
                          'line-color': '#1b58c4',
                          'line-width': {
                            base: 8,
                            stops: [
                              [1, 5],
                              [15, 10]
                            ]
                          },
                          'line-offset': {
                            stops: [
                              [1, 0],
                              [14, 0],
                              [18, -5],
                              [22, -15]
                            ]
                          },
                          'line-opacity': {
                            stops: [
                              [1, 0.9],
                              [16, 0.5]
                            ]
                          }
                        }
                      });

                      const bounds = theGeoJson.coordinates.reduce(function (b, coord) {
                        return b.extend(coord);
                      }, new mapboxgl.LngLatBounds(theGeoJson.coordinates[0], theGeoJson.coordinates[0]));


                      const padding = {bottom: 30, left: 30, top: 30, right: 30};

                      this.eventMap.fitBounds(bounds, {
                        padding, maxZoom: 16
                      });
                    });
                  }
                });
              });
            } else if (e.eventSource === 'hyperlapsePending') {


              const images = [
                {imageUrl: '././assets/img/licence-plate-1.png', id: 'whitePlate'},
                {imageUrl: '././assets/img/licence-plate-cluster.png', id: 'cluster-marker'},
                {imageUrl: '././assets/img/start-flag.png', id: 'startFlag'},
                {imageUrl: '././assets/img/finish-flag.png', id: 'finishFlag'},
                {imageUrl: '././assets/img/arrow1.png', id: 'arrow'},
                {imageUrl: '././assets/img/licence-plate-arrow.png', id: 'lpArrow'},
                {imageUrl: '././assets/img/car.png', id: 'car'},
                {imageUrl: '././assets/img/truck.png', id: 'truck'},
              ];
              Promise.all(
                images.map(img => {
                  return new Promise<void>((resolve, reject) => {
                    this.eventMap.loadImage(img.imageUrl, (error, res) => {
                      this.eventMap.addImage(img.id, res);
                      resolve();
                    });
                  });
                })
              );

              this.eventMap.addSource('journeyDataPoints', {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: []
                }
              });
              this.eventMap.addSource('journeyLine', {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: []
                }
              });


              ///
              const eventEnd = new Date(e.video[0].startTimeHyper).setSeconds(new Date(e.video[0].startTimeHyper).getSeconds() +
                (e.video[0].totalFrames * e.video[0].intervalSeconds));

              const date = formatDate(new Date(e.startTime), 'yyyy-MM-dd', 'en-US');
              let cumDistance = 0;
              const theGeoJson = {type: 'LineString', coordinates: []};
              const theFeatures = {type: 'FeatureCollection', features: []};

              this.adminService.journeys({dn: this.dn, date}).subscribe(result => {
                result.forEach(journey => {

                  if (new Date(journey.start.timeUTC) <= new Date(e.startTime) && new Date(e.startTime) <= new Date(journey.finish.timeUTC)) {
                    this.adminService.journeyDetails({_id: journey._id}).subscribe(res => {
                      // console.log(res);

                      res.tracks.forEach((item, index) => {

                        if (index + 1 < res.tracks.length) {
                          cumDistance += this.calcDistance(item.lat, item.lng, res.tracks[index + 1].lat, res.tracks[index + 1].lng, 'M');
                        }
                        const feature = {
                          type: 'Feature',
                          properties: {
                            head: item.head,
                            // colour: '#ef00c6',
                            colour: '#000000',
                            speed: Math.round(item.speed / 100 * 0.621371),    // to mph,
                            distance: cumDistance,
                            time: item.timeUTC,
                            lat: item.lat,
                            lng: item.lng
                          },
                          geometry: {
                            type: 'Point',
                            coordinates: [item.lng, item.lat]
                          }
                        };
                        // to remove tracking points with no timestamp, caused clicking them to bug out time range selected
                        if (item.timeUTC && new Date(item.timeUTC) >= new Date(e.video[0].startTimeHyper) && new Date(item.timeUTC) <= new Date(eventEnd)) {
                          theFeatures.features.push(feature);
                          theGeoJson.coordinates.push([item.lng, item.lat]);
                        }
                      });

                      this.eventMap.getSource('journeyLine').setData(theGeoJson);
                      this.eventMap.getSource('journeyDataPoints').setData(theFeatures);

                      this.eventMap.addLayer({
                        id: 'journeyDataPoints',
                        type: 'symbol',
                        source: 'journeyDataPoints',
                        layout: {
                          'icon-image': 'lpArrow',
                          'icon-allow-overlap': false,
                          'icon-size': 0.4,
                          'icon-rotate': ['get', 'head'],
                        },
                        paint: {
                          'icon-color': ['get', 'colour'],
                        },
                      });

                      this.eventMap.addLayer({
                        id: 'journeyLine',
                        type: 'line',
                        source: 'journeyLine',
                        layout: {
                          'line-join': 'round',
                          'line-cap': 'round'
                        },
                        paint: {
                          'line-color': '#1b58c4',
                          'line-width': {
                            base: 8,
                            stops: [
                              [1, 5],
                              [15, 10]
                            ]
                          },
                          'line-offset': {
                            stops: [
                              [1, 0],
                              [14, 0],
                              [18, -5],
                              [22, -15]
                            ]
                          },
                          'line-opacity': {
                            stops: [
                              [1, 0.9],
                              [16, 0.5]
                            ]
                          }
                        }
                      });

                      const bounds = theGeoJson.coordinates.reduce(function (b, coord) {
                        return b.extend(coord);
                      }, new mapboxgl.LngLatBounds(theGeoJson.coordinates[0], theGeoJson.coordinates[0]));


                      const padding = {bottom: 30, left: 30, top: 30, right: 30};

                      this.eventMap.fitBounds(bounds, {
                        padding, maxZoom: 16
                      });
                    });
                  }
                });
              });
            } else if (e.eventSource === 'videoUploadPending') {


              const images = [
                {imageUrl: '././assets/img/licence-plate-1.png', id: 'whitePlate'},
                {imageUrl: '././assets/img/licence-plate-cluster.png', id: 'cluster-marker'},
                {imageUrl: '././assets/img/start-flag.png', id: 'startFlag'},
                {imageUrl: '././assets/img/finish-flag.png', id: 'finishFlag'},
                {imageUrl: '././assets/img/arrow1.png', id: 'arrow'},
                {imageUrl: '././assets/img/licence-plate-arrow.png', id: 'lpArrow'},
                {imageUrl: '././assets/img/car.png', id: 'car'},
                {imageUrl: '././assets/img/truck.png', id: 'truck'},
              ];
              Promise.all(
                images.map(img => {
                  return new Promise<void>((resolve, reject) => {
                    this.eventMap.loadImage(img.imageUrl, (error, res) => {
                      this.eventMap.addImage(img.id, res);
                      resolve();
                    });
                  });
                })
              );

              this.eventMap.addSource('journeyDataPoints', {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: []
                }
              });
              this.eventMap.addSource('journeyLine', {
                type: 'geojson',
                data: {
                  type: 'FeatureCollection',
                  features: []
                }
              });


              ///
              const eventEnd = new Date(e.video[0].startTimeHyper).setSeconds(new Date(e.video[0].startTimeHyper).getSeconds() +
                (e.video[0].totalFrames * e.video[0].intervalSeconds));

              const date = formatDate(new Date(e.startTime), 'yyyy-MM-dd', 'en-US');
              let cumDistance = 0;
              const theGeoJson = {type: 'LineString', coordinates: []};
              const theFeatures = {type: 'FeatureCollection', features: []};

              this.adminService.journeys({dn: this.dn, date}).subscribe(result => {
                result.forEach(journey => {

                  if (new Date(journey.start.timeUTC) <= new Date(e.startTime) && new Date(e.startTime) <= new Date(journey.finish.timeUTC)) {
                    this.adminService.journeyDetails({_id: journey._id}).subscribe(res => {
                      // console.log(res);

                      res.tracks.forEach((item, index) => {

                        if (index + 1 < res.tracks.length) {
                          cumDistance += this.calcDistance(item.lat, item.lng, res.tracks[index + 1].lat, res.tracks[index + 1].lng, 'M');
                        }
                        const feature = {
                          type: 'Feature',
                          properties: {
                            head: item.head,
                            // colour: '#ef00c6',
                            colour: '#000000',
                            speed: Math.round(item.speed / 100 * 0.621371),    // to mph,
                            distance: cumDistance,
                            time: item.timeUTC,
                            lat: item.lat,
                            lng: item.lng
                          },
                          geometry: {
                            type: 'Point',
                            coordinates: [item.lng, item.lat]
                          }
                        };
                        // to remove tracking points with no timestamp, caused clicking them to bug out time range selected
                        if (item.timeUTC && new Date(item.timeUTC) >= new Date(e.video[0].startTimeHyper) && new Date(item.timeUTC) <= new Date(eventEnd)) {
                          theFeatures.features.push(feature);
                          theGeoJson.coordinates.push([item.lng, item.lat]);
                        }
                      });

                      this.eventMap.getSource('journeyLine').setData(theGeoJson);
                      this.eventMap.getSource('journeyDataPoints').setData(theFeatures);

                      this.eventMap.addLayer({
                        id: 'journeyDataPoints',
                        type: 'symbol',
                        source: 'journeyDataPoints',
                        layout: {
                          'icon-image': 'lpArrow',
                          'icon-allow-overlap': false,
                          'icon-size': 0.4,
                          'icon-rotate': ['get', 'head'],
                        },
                        paint: {
                          'icon-color': ['get', 'colour'],
                        },
                      });

                      this.eventMap.addLayer({
                        id: 'journeyLine',
                        type: 'line',
                        source: 'journeyLine',
                        layout: {
                          'line-join': 'round',
                          'line-cap': 'round'
                        },
                        paint: {
                          'line-color': '#1b58c4',
                          'line-width': {
                            base: 8,
                            stops: [
                              [1, 5],
                              [15, 10]
                            ]
                          },
                          'line-offset': {
                            stops: [
                              [1, 0],
                              [14, 0],
                              [18, -5],
                              [22, -15]
                            ]
                          },
                          'line-opacity': {
                            stops: [
                              [1, 0.9],
                              [16, 0.5]
                            ]
                          }
                        }
                      });

                      const bounds = theGeoJson.coordinates.reduce(function (b, coord) {
                        return b.extend(coord);
                      }, new mapboxgl.LngLatBounds(theGeoJson.coordinates[0], theGeoJson.coordinates[0]));


                      const padding = {bottom: 30, left: 30, top: 30, right: 30};

                      this.eventMap.fitBounds(bounds, {
                        padding, maxZoom: 16
                      });
                    });
                  }
                });
              });
            } else {
              new mapboxgl.Marker().setLngLat([e.lng, e.lat]).addTo(this.eventMap);

              this.eventMap.flyTo({
                center: [e.lng, e.lat],
                zoom: 12,
                speed: 1
              });
            }
          });
      });
  }


  dateSelectedChange(){
    this.setJourneyCalendar(0);

    this.dateSelected = formatDate(this.eventDate, 'yyyy-MM-dd', 'en-US');
    if (this.calendarDataByDate[this.dateSelected]){
      this.calendarDataByDate[this.dateSelected].reverse().forEach((calData, index) => {

        if (index >= 1) {
          const thisClipStart = new Date(calData.st);
          const thisClipStartUnix = thisClipStart.getTime();

          const lastClipEnd = new Date(this.calendarDataByDate[this.dateSelected][(index - 1)].et);
          const lastClipEndUnix = lastClipEnd.getTime();

          if (lastClipEndUnix <= thisClipStartUnix && thisClipStartUnix <= (lastClipEndUnix + 1000)) {
            this.calendarDataByDate[this.dateSelected][(index)] = {
              st: formatDate(new Date(this.calendarDataByDate[this.dateSelected][(index - 1)].st), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
              et: formatDate(new Date(calData.et), 'yyyy-MM-dd HH:mm:ss', 'en-US')
            };
            this.calendarDataByDate[this.dateSelected].splice((index - 1), 1);
          }
        }
      });
    }
    this.adminService.journeys({dn: this.dn, date: this.dateSelected}).pipe(
      map(result => {
        this.journeys = result;

        if (result && result.length > 0){
          result.forEach(journey => {
            journey.startPlaceLoading = true;
            journey.endPlaceLoading = true;

            this.adminService.journeyDetails({_id: journey._id}).subscribe(res => {
              journey.startPlace = '';

              if (res && res.startAddress && typeof res.startAddress !== 'undefined'){

                if (res.startAddress.place){
                  if (journey.startPlace.length > 1) { journey.startPlace += ','; }
                  journey.startPlace += ' ' + res.startAddress.place;
                }
                if (res.startAddress.town){
                  if (journey.startPlace > 1) { journey.startPlace += ','; }
                  journey.startPlace += ' ' + res.startAddress.town;
                }
              }
              journey.startPlaceLoading = false;


              journey.endPlace = '';

              if (res && res.endAddress && typeof res.endAddress !== 'undefined'){

                // if (res.endAddress.features[0].properties.address.town){
                //   if (journey.endPlace.length > 1) { journey.endPlace += ','; }
                //   journey.endPlace += ' ' + res.endAddress.features[0].properties.address.town;
                // }
                // if (res.endAddress.features[0].properties.address.postcode){
                //   if (journey.endPlace.length > 1) { journey.endPlace += ','; }
                //   journey.endPlace += ' ' + res.endAddress.features[0].properties.address.postcode;
                // }
                if (res.endAddress.place){
                  if (journey.endPlace.length > 1) { journey.endPlace += ','; }
                  journey.endPlace += ' ' + res.endAddress.place;
                }
                if (res.endAddress.town){
                  if (journey.endPlace.length > 1) { journey.endPlace += ','; }
                  journey.endPlace += ' ' + res.endAddress.town;
                }
              }
              journey.endPlaceLoading = false;



            });
          });
        }

        return this.dn;
      })
    ).subscribe();

    this.confirmMessage.show = false;
    this.requestEvents();
  }

  requestEvents(): void {

    this.numberOfVidsUploading.next(0);

    this.partnerEvents = [];
    const matchData = {match: {startTime: {$gte: '', $lt: ''}, dn: this.dn}};
    this.mediaEventsLoading = true;
    const theDaySelected = formatDate(new Date(this.dateSelected), 'yyyy-MM-dd 00:00:00', 'en-US');
    const theNextDaySelected = new Date(this.dateSelected);
    const theNextDaySelectedString = formatDate(new Date(theNextDaySelected).setDate(new Date(theNextDaySelected).getDate() + 1), 'yyyy-MM-dd 00:00:00', 'en-US');
    matchData.match.startTime.$gte = theDaySelected;
    matchData.match.startTime.$lt = theNextDaySelectedString;

    this.adminService.events(
      // matchData
      {
        dn: this.dn,
        startTime: theDaySelected,
        endTime: theNextDaySelectedString
      }
    ).subscribe(result => {
      // console.log(result);

      for (const e of result) {
        e.locLoading = true;

        if ((e.snapshots && e.snapshots.length >= 1 && e.snapshots[0].status === 'uploaded') || (e.videos && e.videos.length >= 1) ) {
            if (e.lng && e.lat){
              this.adminService.reverseGeocode({lng: e.lng, lat: e.lat}).subscribe(location => {

                e.locationStreetName = '';

                if (location.features[0].properties.address.road){
                  e.locationStreetName += location.features[0].properties.address.road;
                }
                if (location.features[0].properties.address.town){
                  if (e.locationStreetName.length > 1) { e.locationStreetName += ','; }
                  e.locationStreetName += ' ' + location.features[0].properties.address.town;
                }
                if (location.features[0].properties.address.postcode){
                  if (e.locationStreetName.length > 1) { e.locationStreetName += ','; }
                  e.locationStreetName += ' ' + location.features[0].properties.address.postcode;
                }
                // const locData = location.features[0].place_name.split(',');
                // e.locationStreetName = locData[0] + ', ' + locData[1];
                e.locLoading = false;
              }, () => { e.locLoading = false; });
            } else {
              e.locLoading = false;
            }

            e.snapshots.forEach(snap => {

              this.adminService.viewSnapshot(snap.file + '_sm').then(r => {
                snap.blobUrl = URL.createObjectURL(r);
              });

              for (const cam of this.cameras) {

                if (snap.channel){
                  if (cam.channel === snap.channel){
                    snap.camPosition = cam.camPosition;
                    break;
                  }
                } else {
                  if (cam.ch === snap.ch.toString()){
                    snap.camPosition = cam.camPosition;
                    break;
                  }
                }
              }
            });


            this.partnerEvents.push(e);
          }
      }
      this.matchVideoToEvents();
      this.filterEvents(null);


      setTimeout(() => {this.mediaEventsLoading = false; }, 500);


    });
  }


  matchVideoToEvents(): void {
    this.adminService.videoAvailable({dn : this.dn}).subscribe( result => {
      if (result){
        console.log(this.availableMediaByDate);

        result.forEach(video => {

          const startOfVideo = new Date(video.st).getTime();
          const endOfVideo = new Date(video.et).getTime();

          if (!this.availableMediaByDate[formatDate(startOfVideo, 'yyyy-MM-dd', 'EN-US')]) {
            this.availableMediaByDate[formatDate(startOfVideo, 'yyyy-MM-dd', 'EN-US')] = [];
          }
          this.availableMediaByDate[formatDate(startOfVideo, 'yyyy-MM-dd', 'EN-US')].push(video);

          let match = false;

          // this.partnerEventsDisplay.forEach(event => {
          //   const eventStart = new Date(event.startTime).getTime();
          //
          //   // match timestamps of video to event
          //   if (eventStart >= startOfVideo && eventStart <= endOfVideo){
          //     if (!event.video || event.video.length < 1) {
          //       event.video = [];
          //     }
          //     video.isViewable = true;
          //     event.video.push(video);
          //     event.videoType = 'video';
          //     match = true;
          //
          //   }
          // });



          if (!match && new Date(video.st).toLocaleDateString() === new Date(this.dateSelected).toLocaleDateString()){

            video.isViewable = true;
            const obj = {
              startTime: video.st,
              locationStreetName: '-',
              eventSource: 'videoUpload',
              videos2: [video],
              video: [video],
              videoType: 'video',
              locLoading: false
            };


            this.journeys.forEach(journey => {
              if (new Date(journey.start.timeUTC).getTime() <= startOfVideo && new Date(journey.finish.timeUTC).getTime() >= endOfVideo){
                this.adminService.journeyDetails({_id: journey._id}).subscribe(theDetails => {
                  const tracksInVid = theDetails.tracks.filter(item => {
                    return new Date(item.timeUTC) >= new Date(formatDate(new Date(startOfVideo), 'yyyy-MM-dd HH:mm:00', 'EN-US')) &&
                      new Date(item.timeUTC) <= new Date(formatDate(new Date(endOfVideo), 'yyyy-MM-dd HH:mm:59', 'EN-US'));
                  });

                  if (tracksInVid && tracksInVid.length > 0){
                    obj.locLoading = true;
                    this.adminService.reverseGeocode({lat: tracksInVid[0].lat, lng: tracksInVid[0].lng}).subscribe(location => {

                      obj.locationStreetName = '';


                      if (location.features[0].properties.address.road){
                        obj.locationStreetName += location.features[0].properties.address.road;
                      }

                      if (location.features[0].properties.address.town){
                        if (obj.locationStreetName.length > 1) { obj.locationStreetName += ','; }
                        obj.locationStreetName += ' ' + location.features[0].properties.address.town;
                      }
                      if (location.features[0].properties.address.postcode){
                        if (obj.locationStreetName.length > 1) { obj.locationStreetName += ','; }
                        obj.locationStreetName += ' ' + location.features[0].properties.address.postcode;
                      }

                      obj.locLoading = false;
                    }, () => {
                      obj.locationStreetName = '';
                      obj.locLoading = false;
                    });
                  } else {
                    obj.locLoading = false;
                  }
                });
              }
            });

            this.partnerEventsDisplay.push(obj);
            this.partnerEvents.push(obj);


            this.partnerEvents.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
            this.partnerEventsDisplay.sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());

          }

        });
      }

    });
    // console.log(this.partnerEvents);
// look at queued videos also, to disable button if video for timespan is already requested
    this.videosQueuedForUpload();
    this.hyperlapseVideos();
  }


  videosQueuedForUpload(): void {
    this.adminService.queuedVideoForDevice({dn: this.dn}).subscribe( result => {
      console.log(result);
      result.forEach(video => {

        const startOfVideo = new Date(video.st).getTime();
        const endOfVideo = new Date(video.et).getTime();


        if (!this.availableMediaByDate[formatDate(startOfVideo, 'yyyy-MM-dd', 'EN-US')]) {
          this.availableMediaByDate[formatDate(startOfVideo, 'yyyy-MM-dd', 'EN-US')] = [];
        }
        this.availableMediaByDate[formatDate(startOfVideo, 'yyyy-MM-dd', 'EN-US')].push(video);



        if (new Date(video.st).toLocaleDateString() === new Date(this.dateSelected).toLocaleDateString()){

          video.isViewable = false;
          const obj = {
            startTime: video.st,
            locationStreetName: '-',
            eventSource: 'videoUploadPending',
            videos2: [video],
            video: [video],
            videoType: 'video',
            percentComplete: 0,
            locLoading: false
          };

          if (video.uploadedFrames && video.uploadedFrames.length > 0){
            video.percentComplete = (video.uploadedFrames[0] / video.totalFrames) * 100;
          }


          this.journeys.forEach(journey => {
            if (new Date(journey.start.timeUTC).getTime() <= startOfVideo && new Date(journey.finish.timeUTC).getTime() >= endOfVideo) {
              this.adminService.journeyDetails({_id: journey._id}).subscribe(theDetails => {
                const tracksInVid = theDetails.tracks.filter(item => {
                  return new Date(item.timeUTC) >= new Date(formatDate(new Date(startOfVideo), 'yyyy-MM-dd HH:mm:00', 'EN-US')) &&
                    new Date(item.timeUTC) <= new Date(formatDate(new Date(endOfVideo), 'yyyy-MM-dd HH:mm:59', 'EN-US'));
                });

                if (tracksInVid && tracksInVid.length > 0) {
                  obj.locLoading = true;
                  this.adminService.reverseGeocode({
                    lat: tracksInVid[0].lat,
                    lng: tracksInVid[0].lng
                  }).subscribe(location => {

                    obj.locationStreetName = '';

                    if (location.features[0].properties.address.road){
                      obj.locationStreetName += location.features[0].properties.address.road;
                    }
                    if (location.features[0].properties.address.town){
                      if (obj.locationStreetName.length > 1) { obj.locationStreetName += ','; }
                      obj.locationStreetName += ' ' + location.features[0].properties.address.town;
                    }
                    if (location.features[0].properties.address.postcode){
                      if (obj.locationStreetName.length > 1) { obj.locationStreetName += ','; }
                      obj.locationStreetName += ' ' + location.features[0].properties.address.postcode;
                    }
                  }, () => {
                    obj.locationStreetName = '';
                    obj.locLoading = false;
                  });
                } else {
                  obj.locLoading = false;
                }
              });
            }
          });

          this.numberOfVidsUploading.next((this.numberOfVidsUploading.getValue() + 1));
          this.partnerEventsDisplay.push(obj);
          this.partnerEvents.push(obj);
          this.partnerEvents.sort((a, b) => {
            return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
          });

        }
      });
    });
  }




  hyperlapseVideos(): void {
    this.adminService.hyperlapse({dn: this.dn}).subscribe( async result => {

      if (result && result.finished.length >= 1){

        for (const video of result.finished) {

          const startOfVideo = new Date(video.startTimeHyper).getTime();
          let match = false;

          this.partnerEventsDisplay.forEach(event => {
            const eventStart = new Date(event.startTime).getTime();

            // match timestamps of video to event
            if (eventStart === startOfVideo && event.eventSource === 'hyperlapse'){
              if (!event.video || event.video.length < 1) {
                event.video = [];
              }
              video.isViewable = true;
              event.video.push(video);
              event.videoType = 'hyperlapse ';
              match = true;
            }
          });

          if (!match && new Date(video.startTimeHyper).toLocaleDateString() === new Date(this.dateSelected).toLocaleDateString()){

            video.et = new Date(video.startTimeHyper).setSeconds(new Date(video.startTimeHyper).getSeconds() +
              (video.totalFrames * video.intervalSeconds));

            if (!this.availableMediaByDate[formatDate(new Date(video.startTimeHyper), 'yyyy-MM-dd', 'EN-US')]) {
              this.availableMediaByDate[formatDate(new Date(video.startTimeHyper), 'yyyy-MM-dd', 'EN-US')] = [];
            }
            this.availableMediaByDate[formatDate(new Date(video.startTimeHyper), 'yyyy-MM-dd', 'EN-US')].push(video);


            if (new Date(video.startTimeHyper).toLocaleDateString() === new Date(this.dateSelected).toLocaleDateString()) {

              video.isViewable = true;
              const obj = {
                _id: video._id,
                startTime: video.startTimeHyper,
                locationStreetName: '-',
                eventSource: 'hyperlapse',
                videos2: [video],
                video: [video],
                percentComplete: 100,
                videoType: 'hyperlapse',
                thumbnail: null as any,
                locLoading: false
              };

              const image = await this.adminService.viewHyperThumb(video.file);


              if (image) {
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onloadend  = () => {
                  const base64data = reader.result;

                  if (base64data.toString().startsWith('data:text/plain')) {
                    obj.thumbnail = '././assets/eventPlaceholder.jpg';
                  } else {
                    obj.thumbnail = base64data;
                  }
                };
              }

              this.journeys.forEach(journey => {
                if (new Date(journey.start.timeUTC).getTime() <= new Date(video.startTimeHyper).getTime() &&
                  new Date(journey.finish.timeUTC).getTime() >= video.et) {
                  // console.log('match');
                  this.adminService.journeyDetails({_id: journey._id}).subscribe(theDetails => {
                    const tracksInVid = theDetails.tracks.filter(item => {
                      return new Date(item.timeUTC) >= new Date(formatDate(new Date(video.startTimeHyper),
                          'yyyy-MM-dd HH:mm:00', 'EN-US')) &&
                        new Date(item.timeUTC) <= new Date(formatDate(new Date(video.et),
                          'yyyy-MM-dd HH:mm:59', 'EN-US'));
                    });

                    if (tracksInVid && tracksInVid.length > 0) {
                      obj.locLoading = true;
                      this.adminService.reverseGeocode({
                        lat: tracksInVid[0].lat,
                        lng: tracksInVid[0].lng
                      }).subscribe(location => {
                        obj.locationStreetName = '';

                        if (location.features[0].properties.address.road){
                          obj.locationStreetName += location.features[0].properties.address.road;
                        }
                        if (location.features[0].properties.address.town){
                          if (obj.locationStreetName.length > 1) { obj.locationStreetName += ','; }
                          obj.locationStreetName += ' ' + location.features[0].properties.address.town;
                        }
                        if (location.features[0].properties.address.postcode){
                          if (obj.locationStreetName.length > 1) { obj.locationStreetName += ','; }
                          obj.locationStreetName += ' ' + location.features[0].properties.address.postcode;
                        }

                        obj.locLoading = false;
                      }, () => {
                        // obj.locationStreetName = 'Unknown';
                        obj.locLoading = false;
                      });
                    } else {
                      obj.locLoading = false;
                    }
                  });
                }
              });

              console.log(obj);


              this.partnerEventsDisplay.push(obj);
              this.partnerEvents.push(obj);
            }
            this.partnerEventsDisplay.sort(function(a, b) {
              return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
            });
          }
        }
      }

      if (result && result.queued.length >= 1){
        result.queued.forEach(video => {
          if (new Date(video.startTimeHyper).toLocaleDateString() === new Date(this.dateSelected).toLocaleDateString()) {

            video.isViewable = false;
            const obj = {
              startTime: video.startTimeHyper,
              locationStreetName: '-',
              eventSource: 'hyperlapsePending',
              videos2: [video],
              video: [video],
              percentComplete: 0,
              videoType: 'hyperlapse',
              locLoading: false
            };

            if (video?.totalFrames > 0){
              obj.percentComplete = ((video.frames / video.totalFrames) * 100);
            }

            this.journeys.forEach(journey => {
              if (new Date(journey.start.timeUTC).getTime() <= new Date(video.startTimeHyper).getTime() && new Date(journey.finish.timeUTC).getTime() >= video.et) {
                // console.log('match123');
                this.adminService.journeyDetails({_id: journey._id}).subscribe(theDetails => {
                  const tracksInVid = theDetails.tracks.filter(item => {
                    return new Date(item.timeUTC) >= new Date(formatDate(new Date(video.startTimeHyper), 'yyyy-MM-dd HH:mm:00', 'EN-US')) &&
                      new Date(item.timeUTC) <= new Date(formatDate(new Date(video.et), 'yyyy-MM-dd HH:mm:59', 'EN-US'));
                  });

                  if (tracksInVid && tracksInVid.length > 0) {
                    obj.locLoading = true;
                    this.adminService.reverseGeocode({
                      lat: tracksInVid[0].lat,
                      lng: tracksInVid[0].lng
                    }).subscribe(location => {
                      obj.locationStreetName = '';

                      if (location.features[0].properties.address.road){
                        obj.locationStreetName += location.features[0].properties.address.road;
                      }
                      if (location.features[0].properties.address.town){
                        if (obj.locationStreetName.length > 1) { obj.locationStreetName += ','; }
                        obj.locationStreetName += ' ' + location.features[0].properties.address.town;
                      }
                      if (location.features[0].properties.address.postcode){
                        if (obj.locationStreetName.length > 1) { obj.locationStreetName += ','; }
                        obj.locationStreetName += ' ' + location.features[0].properties.address.postcode;
                      }

                      obj.locLoading = false;
                    }, () => {
                      // obj.locationStreetName = 'Unknown';
                      obj.locLoading = false;
                    });
                  } else {
                    obj.locLoading = false;
                  }
                });
              } else {
                obj.locationStreetName = '';
              }
            });

            this.numberOfVidsUploading.next((this.numberOfVidsUploading.getValue() + 1));
            this.partnerEventsDisplay.push(obj);
            this.partnerEvents.push(obj);
            this.partnerEventsDisplay.sort(function(a, b) {
              return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
            });
          }
        });
      }
    });

  }

  filterEvents(event): void {

    event = event != null ? event : 'default';
    this.partnerEventsDisplay = [];

    if (event === 'severeOnly'){
      this.eventsFilters.videoOnly = false;
      this.eventsFilters.hyperlapseOnly = false;
      this.eventsFilters.videoQueueOnly = false;
      this.eventsFilters.journey = false;
    }

    if (event === 'videoOnly'){
      this.eventsFilters.severeOnly = false;
      this.eventsFilters.hyperlapseOnly = false;
      this.eventsFilters.videoQueueOnly = false;
      this.eventsFilters.journey = false;
    }
    if (event === 'videoQueueOnly'){
      this.eventsFilters.severeOnly = false;
      this.eventsFilters.videoOnly = false;
      this.eventsFilters.hyperlapseOnly = false;
      this.eventsFilters.journey = false;
    }

    if (event === 'hyperlapseOnly'){
      this.eventsFilters.severeOnly = false;
      this.eventsFilters.videoOnly = false;
      this.eventsFilters.videoQueueOnly = false;
      this.eventsFilters.journey = false;
    }





    if (this.eventsFilters.severeOnly) {
      this.partnerEventsDisplay = this.partnerEvents.filter(event => event.analysis && event.analysis.severity === 'severe');
    } else if (this.eventsFilters.videoQueueOnly) {
      this.partnerEventsDisplay = this.partnerEvents.filter(event => (event.videos2 && !this.anyEventsViewable(event) && event.videos2.length >= 1));
    } else {
      for (const event of this.partnerEvents) {
        if (this.eventsFilters.journey && event.eventSource !== 'journeyStart' ){
          this.partnerEventsDisplay.push(event);
        }

        if (!this.eventsFilters.journey && event.eventSource !== 'journeyStart' && event.eventSource !== 'journeyEnd'){
          this.partnerEventsDisplay.push(event);
        }
      }
    }



    this.partnerEventsDisplay.sort(function(a, b) {
      return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
    });
  }








  anyEventsViewable(event): boolean {
    let anyVideosViewable = false;
    if (event.video && event.video.length >= 1){
      event.video.forEach(video => {
        if (video.isViewable){
          anyVideosViewable = true;
        }
      });
    }
    return anyVideosViewable;
  }


  showHideFilters(event): void {
    // console.log(event.index);
    if (event.index === 1){
      this.showFilters = 1;
    } else {
      this.showFilters = 0;
    }
  }


  viewSnapshotModal(e): void {
    // console.log(e);
    console.log(e);
    this.changeSnapshot(e.snapshots[0]);
    this.Dialog.open(this.viewEventSnap, {hasBackdrop: true, width: '65%', data: {snapSelected: e.snapshots[0].file, obj: e}});
  }



  changeSnapshot(obj): void{
    // console.log(obj);

    if (obj.file){


      this.adminService.viewSnapshot(obj.file + '_lrg').then(r => {
        // console.log(URL.createObjectURL(r));
        (document.getElementById('eventSnapshotToView') as HTMLImageElement).src = URL.createObjectURL(r);
        this.cdRef.detectChanges();
      });
    } else {
      this.adminService.viewSnapshot(obj + '_lrg').then(r => {
        // console.log(URL.createObjectURL(r));
        (document.getElementById('eventSnapshotToView') as HTMLImageElement).src = URL.createObjectURL(r);
        this.cdRef.detectChanges();
      });
    }
  }


  requestEventVideo(event): void {

    const clipStart = new Date(event.startTime).setSeconds(new Date(event.startTime).getSeconds() - 10);
    const clipEnd = new Date(event.startTime).setSeconds(new Date(event.startTime).getSeconds() + 10);
    const videosForDay = this.availableMediaByDate[this.datePipe.transform( new Date(event.startTime), 'yyyy-MM-dd')];

    const dataToSend = {
      dn: this.dn,
      callback: environment.NOTIFICATIONS_API_URL + '/MflConnectWebhook',
      ch: this.channelForClipFromSnap,
      st: this.datePipe.transform( new Date(clipStart), 'yyyy-MM-dd HH:mm:ss'),
      et: this.datePipe.transform( new Date(clipEnd), 'yyyy-MM-dd HH:mm:ss')
    };

    if (videosForDay?.length > 0 ){
      videosForDay.forEach(video => {
        if (new Date(video.st).getTime() === clipStart){
          this.disasbleVideoFromEvent.buttonDisabled = true;
          this.disasbleVideoFromEvent.message = 'Video for this time period already exists';
        } else {
          this.disasbleVideoFromEvent.buttonDisabled = true;
          this.disasbleVideoFromEvent.message = 'Video request received, upload in progress';

          this.adminService.videoRequest(dataToSend).subscribe( result => {
            // console.log(result);
            setTimeout(() => {
              this.disasbleVideoFromEvent.buttonDisabled = false;
              delete this.disasbleVideoFromEvent.message;
              this.matchVideoToEvents();
            }, 2000);
          }, err => {
            // console.log(err);
          });
         }
      });
    } else {
      this.adminService.videoRequest(dataToSend).subscribe( result => {
        // console.log(result);
        setTimeout(() => {
          this.disasbleVideoFromEvent.buttonDisabled = false;
          delete this.disasbleVideoFromEvent.message;
          this.matchVideoToEvents();
        }, 2000);
      }, err => {
        // console.log(err);
      });
    }
  }

  hideEventOnImageError(file): void {
    (document.getElementById(file).parentNode as HTMLElement).style.display = 'none';
  }

  getFrontCamSnapFile(snapshots): string {

    // tslint:disable-next-line:no-shadowed-variable
    let data;

    for (const snap of snapshots){
      for (const cam of this.cameras) {
        if (cam.camPosition === 'Front'){
          data = snap.blobUrl;
        }
      }
    }

    if (!data){
      data = snapshots[0].blobUrl;
    }
    return data;
  }




}
