<h3>Change Password</h3>
<div style="display:flex;height: 60%;">
  <div style="flex:1;display:flex;flex-direction: column;justify-content: center;">

    <form autocomplete="off">
      <mat-form-field style="width:200px;">
        <mat-label>Current Password</mat-label>
        <input matInput [(ngModel)]="curPassword" [ngModelOptions]="{standalone: true}" type="password" autocomplete="do-not-autofill" id="field1">
      </mat-form-field>

      <mat-form-field style="width:200px;">
        <mat-label>New Password</mat-label>
        <input matInput [(ngModel)]="newPassword" [ngModelOptions]="{standalone: true}" type="password" autocomplete="do-not-autofill" id="field2">
      </mat-form-field>



      <mat-form-field style="width:200px;">
        <mat-label>Confirm New Password</mat-label>
        <input matInput [(ngModel)]="confirmNewPassword" [ngModelOptions]="{standalone: true}" type="password" autocomplete="do-not-autofill"  id="field3">
      </mat-form-field>

    </form>


  </div>
  <div style="flex:1;">
    <div style="font-weight: bold;text-align: center;margin-top: 15%;">
      Password Strength
    </div>
    <password-strength-meter [password]="newPassword" [minPasswordLength]="8"></password-strength-meter>
  </div>
</div>

<div>

  <button mat-raised-button [disabled]="changePasswordValidator()" (click)="submitPasswordChange()">
    Change Password
  </button>
</div>
