<!--
events-data length : {{eventsData.length}}
{{panelOpenStateEventFunct()}}
-->
<!--<mat-expansion-panel (opened)="panelOpenState = true; panelOpenStateEventFunct()"    class="eventPanel"  [hideToggle]="false"
                     (closed)="panelOpenState = false; panelOpenStateEventFunct()"   [expanded]="expanded()"
                     [disabled] = "false">

  <mat-expansion-panel-header collapsedHeight="*" expandedHeight="*">
    <mat-panel-title>-->
<!--      <div class="expansion-panel-flex-container">
        <div class="reg-bottom"><strong>{{vehicleProperties.properties.reg}}</strong></div>
        <div *ngIf="expanded()"><mat-icon class="expandIcon">warning</mat-icon></div>

        <div class="lastLocation">Current location:&nbsp;
          <strong>{{vehicleProperties.properties.address | slice:0:-16}}</strong></div>

      </div>-->
<!--    </mat-panel-title>
  &lt;!&ndash; <mat-panel-description >

    </mat-panel-description>&ndash;&gt;
  </mat-expansion-panel-header>-->
<div *ngIf="eventsData">
  <h4>Events</h4>
  <!--<div>&nbsp;{{eventsData.length}}&nbsp;event<span *ngIf="eventsData.length > 1 " >s</span>  &nbsp;&nbsp; </div>-->
  <!--{{eventsData[0] | json}}-->
  <div class="eventPanelScroll">
    <div (click)="openPictureBoxWithEvent(event)" *ngFor="let event of eventsData let i = index"
         class="eventContainer">

      {{event.speed}}

      {{event.snapshots | json}}
      {{event.videos | json}}
      {{event.eventSource}}
      {{event.startTime}}<br>
      <span *ngIf="event.analysis">
        {{event.analysis.severity}}
        {{event.analysis.eventType}}
      </span>
      <br>
      {{event._id}}<br>
      <div *ngFor="let snapshot of event.snapshots">

        <img class="eventImage" [src]="API_URL + '/angFetchSnapshot/'+ snapshot.file +'_sm'">
        <!--Satellite image instead !! Not useful maybe
        <img *ngIf="!event.snapshot"  class="eventImage"
             [src]="'https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/static/'+ event.lng +','+ event.lat +',15,0/320x180?access_token=' + accessToken">
  -->
        <!--       <img  *ngIf="event.eventType == 'GShock'"  class="eventPin" src="../..././assets/img/pin-shock.png"/>
                <img  *ngIf="event.eventType == 'Braking'"  class="eventPin" src="../..././assets/img/pin-brake.png"/>
                <img  *ngIf="event.eventType == 'speeding'"  class="eventPin" src="../..././assets/img/pin-speed.png"/>
                <img  *ngIf="event.eventType == 'acceleration'"  class="eventPin" src="../..././assets/img/pin-acceleration.png"/>
                <img  *ngIf="event.eventType == 'Emergency'"  class="eventPin" src="../..././assets/img/pin-emergency.png"/>
                <img  *ngIf="event.eventType == 'UserRequest'"  class="eventPin" src="../..././assets/img/pin-user-request.png"/>
                <img  *ngIf="event.eventType == 'video'"  class="eventPin" src="../..././assets/img/pin-video.png"/>
                <img  *ngIf="event.eventType == 'UNKNOWN'"  class="eventPin" src="../..././assets/img/pin-unknown.png"/>
                <img  *ngIf="event.eventType == 'Turning'"  class="eventPin" src="../..././assets/img/pin-turning.png"/>-->


        <img class="eventPin" src="../..././assets/img/pin-brake.svg"/>
        <svg class="eventSpeed" id="circle" xmlns="http://www.w3.org/2000/svg" width="130" viewBox="0 0 130 30">
          <defs>
            <style>
              .small {
                font-size: 15px;
                font-weight: bold;
                text-anchor: middle;
                font-family: "Arial Black"
              }
              .mph {
                font-size: 11px;
                font-weight: normal;
                text-anchor: middle;
                font-family: "Arial";
                fill: #fcfeff
              }
            </style>
          </defs>
          <circle cx="15" cy="15" r="13" stroke="red" stroke-width="3" fill="white"/>
          <text class="small" x="15" y="20"> {{event.speed}} </text>
          <text class="mph" x="42" y="20">mph</text>
        </svg>
        <span class="eventTime">{{event.date | date: 'shortTime'}}</span>
      </div>
      <!--</button>-->
    </div>
  </div>
</div>
<!--</mat-expansion-panel>-->


