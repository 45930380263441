import {Component, Input, Output, OnInit, EventEmitter, Inject, ViewChildren, ElementRef, QueryList, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';


import {VehiclesService} from '../vehicles.service';
// import {BigScreenService} from 'angular-bigscreen';

import {DeviceDetectorService} from 'ngx-device-detector';



import {ChartDataSets, ChartOptions} from 'chart.js';
import {Color, Label} from 'ng2-charts';
import {Router} from '@angular/router';

import mapboxgl from 'mapbox-gl';
import {interval} from 'rxjs';
import {startWith, switchMap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  event: any;
  message: boolean;
  accessToken;
  panelOpenState = true;
  MOBILE_BREAKPOINT = environment.MOBILE_BREAKPOINT;
  API_URL = environment.API_URL;

  dialogRef: MatDialogRef<PictureBoxComponent, any>;


  @Input() eventsData: any;
  @Input() vehicleEvent: any;
  @Input() vehicleProperties: any;
  @Output() getEvent: EventEmitter<any> = new EventEmitter();
  @Output() childEvent = new EventEmitter();
  @Output() panelOpenStateEvent = new EventEmitter<boolean>();


  // TODO vehicle service only here for access to multiLivestream getTestEventsFromJson
  constructor(public dialog: MatDialog, private vehiclesService: VehiclesService, private router: Router) {

  }


  eventsDataTest = [];


  panelOpenStateEventFunct() {
    this.panelOpenStateEvent.emit(this.panelOpenState);
  }


  openDialog(): void {

    if (this.dialogRef) {
      this.dialogRef.close();
    }

    console.log(this.router.url);
    let rect;

    if (this.router.url === '/event-list') {
      rect = document.getElementById('eventsContainer').getBoundingClientRect();
    } else {
      rect = document.getElementById('map').getBoundingClientRect();
    }


    const viewportX = [rect.right][0];
    const viewportY = [rect.bottom][0];
    let width = '';
    let height = '';
    let left = '';

    if (window.innerWidth < this.MOBILE_BREAKPOINT) {
      width = (viewportX) + 'px';
      height = (viewportY) + 'px';
      left = '0px';
    } else {
      width = (viewportX / 3 * 2) + 'px';
      height = ((viewportX / 3 * 2) * 0.8) + 'px';
      left = '30px';
    }


    this.dialogRef = this.dialog.open(PictureBoxComponent, {
      width,
      height,
      position: {
        left
      },
      data: {event: this.event}
    });


    /*    // TODO switch according to viewport size
        this.dialogRef = this.dialog.open(PictureBoxComponent, {
          width: (viewportX / 3 * 2) + 'px',
          position: {
            left: '30px'
          },
          data: {event: this.event}
        });*/


    this.dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('The dialog before was closed' + result);
        this.childEvent.emit();
      }
    });

  }


  ngOnInit() {
    this.accessToken = environment.mapbox.accessToken;

  }


  openPictureBoxWithEvent(event) {
    console.log('open picture box');
    console.log(event);
    this.event = event;
    this.getEvent.emit(event);
    this.openDialog();
  }


  stop() {
    console.log('Im stopping');
  }

  expanded() {
    // if (this.eventsData !== null) {
    if (typeof this.eventsData !== 'undefined') {
      return this.eventsData.length > 0;
    } else {
      return false;
    }
  }


}


///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////


@Component({
  selector: 'app-picture-box-component',
  templateUrl: 'picture-box.html',
})


export class PictureBoxComponent implements OnInit {

  i = 0;

  universalPlay: boolean;
  linkVideos = true;
  message: boolean;

  breakpoint: number;
  timerId: any;
  mobileDevice: boolean;

  coords = [];
  MOBILE_BREAKPOINT = environment.MOBILE_BREAKPOINT;
  API_URL = environment.API_URL

  map: mapboxgl.Map;
  style = 'mapbox://styles/fleetfocus/cjvxkhjay5ti71dqa1lldx82p?optimize=true';


  @ViewChildren('videoPlayer', {read: ElementRef}) videos: QueryList<ElementRef>;
  @ViewChild('modalWindow') modalwindow: ElementRef;


  lineChartData: ChartDataSets[] = [
    {data: [], label: 'mph'},
    {data: [], label: 'gForce'},
    {data: [], label: 'change in heading'},
  ];

  lineChart2Data: ChartDataSets[] = [
    {data: [], label: 'x'},
    {data: [], label: 'y'},
    {data: [], label: 'z'},
  ];


  lineChartLabels: Label[] = ['-9', '-8', '-7', '-6', '-5', '-4', '-3', '-2', '-1', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      borderColor: '#277809',
      backgroundColor: 'rgba(221,241,179,0.28)',
    },
    {
      borderColor: '#c91212',
      backgroundColor: 'rgba(239,166,245,0.28)',
    },
    {
      borderColor: '#4592ef',
      backgroundColor: 'rgba(177,209,238,0.28)',
    }
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';


  speedArray = [];
  chartLabels = [];
  directionArray = [];
  impactArray = [];
  gSensorXArray = [];
  gSensorYArray = [];
  gSensorZArray = [];

  // private bigScreenService: BigScreenService,

  constructor(
              private vehiclesService: VehiclesService,
              public dialogRef: MatDialogRef<PictureBoxComponent>, private deviceService: DeviceDetectorService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }


  ngOnInit() {


    console.log(this.data);
    console.log('model opened init');


    this.checkElement('#map2') // use whichever selector you want
      .then((element) => {
        console.log(element);
        console.log('READY READY READY READY READY READY');
        this.makeMap();
      });


    //

    if (typeof this.data.event.eventData !== 'undefined') {

      this.data.event.eventData.forEach((e, index) => {

        if (e.acquisitionTime !== '2048-00-00T00:00:00') {
          this.speedArray.push(e.speed / 100 * 0.621371);
          if (index + 1 < this.data.event.eventData.length) {

            this.directionArray.push(this.angleDistance(e.direction, this.data.event.eventData[(index + 1)].direction));
          }
          this.impactArray.push(e.gSensorImpact);

          this.gSensorXArray.push(e.gSensorX);
          this.gSensorYArray.push(e.gSensorY);
          this.gSensorZArray.push(e.gSensorZ);
        }


      });

      this.lineChartData[0].data = this.speedArray;
      this.lineChartData[1].data = this.impactArray;
      this.lineChartData[2].data = this.directionArray;

      this.lineChart2Data[0].data = this.gSensorXArray;
      this.lineChart2Data[1].data = this.gSensorYArray;
      this.lineChart2Data[2].data = this.gSensorZArray;

    }


    this.mobileDevice = this.deviceService.isMobile();  //  .isTablet();  .isDesktop();

    if (this.mobileDevice) {
      this.linkVideos = false;
    }

    this.breakpoint = (window.innerWidth <= this.MOBILE_BREAKPOINT) ? 1 : 2;
  }


/////////////////////////////

  /****** This checks that map2 div is ready*/
  rafAsync() {
    return new Promise(resolve => {
      requestAnimationFrame(resolve); // faster than set time out
    });
  }

  checkElement(selector) {
    if (document.querySelector(selector) === null) {
      return this.rafAsync().then(() => this.checkElement(selector));
    } else {
      return Promise.resolve(true);
    }
  }

////////////////////////////////////////////

  makeMap() {

    console.log(this.data.event.eventData[5].longitude);


    this.map = new mapboxgl.Map({
      container: 'map2',
      style: this.style,
      zoom: 15,
      maxZoom: 18,
      // center: [this.lng, this.lat]
      center: [this.data.event.eventData[5].longitude, this.data.event.eventData[5].latitude]

    });

    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disableRotation();

    this.map.on('load', (event) => {
      // console.log('we are loaded');

      this.map.loadImage('././assets/img/licence-plate-arrow.png', (error, image) => {   // pin-b
        this.map.addImage('lpArrow', image, {sdf: true});
      });


      this.map.addSource('geoDataPoints', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }  //   geoPointsData  regsData
      });


      const mapPoints = {type: 'FeatureCollection', features: []};
      this.data.event.eventData.forEach((e: any) => {


        const feature = {
          type: 'Feature',
          properties: {
            colour: '#fc31e7',
            head: e.direction,
          },
          geometry: {
            type: 'Point',
            coordinates: [e.longitude, e.latitude]
          }

        };

        mapPoints.features.push(feature);


      });
      this.map.getSource('geoDataPoints').setData(mapPoints);

      mapPoints.features.forEach((f) => {
        if (f.geometry.coordinates[0] !== 0) {
          this.coords.push(f.geometry.coordinates);
        }
      });


      console.log(this.coords);
      this.fitMapBoundsToJourney(0);


      this.map.addLayer({
        id: 'dataPoints',
        type: 'symbol',
        source: 'geoDataPoints',   //      'geoDataPoints', mapDataRegMarkers  mapDataSourceId
        layout: {
          // 'icon-image': 'pin-brake',
          'icon-image': 'lpArrow',   // ['get', 'deviceStatus'],  lpArrow
          //  'icon-ignore-placement': false,
          'icon-allow-overlap': true,


          'icon-size': 1,
          'icon-rotate': ['get', 'head'],
        },
        paint: {
          'icon-color': ['get', 'colour'],
        },
      });


    });

  }


  onNoClick(): void {
    clearInterval(this.timerId);
    this.dialogRef.close(true);
  }


  goForward(): void {

    console.log(this.timerId);
    clearInterval(this.timerId);
    if (this.i < 9) {
      this.i++;
    }
  }

  goBackwards(): void {
    clearInterval(this.timerId);
    if (this.i > 0) {
      this.i--;
    }
  }


  loop(): void {

    clearInterval(this.timerId);
    this.i = -1;
    this.timerId = setInterval(() => {
      this.i = (this.i > 8) ? 0 : this.i + 1;
      console.log('i: ' + this.i);

    }, 1000);

    console.log(this.timerId);

    // setTimeout(() => { clearInterval(this.timerId); this.i = 0; }, 30000);  // loop 3 times
  }

  stopLoop() {
    clearInterval(this.timerId);
  }


  onResize(event) {
    this.breakpoint = (window.innerWidth <= 420) ? 1 : 2;
  }

  /////////////////////////////


  toggleLinkVideos() {
    console.log('toggle link videos');

    this.videos.forEach(e => e.nativeElement.currentTime = 0);
    this.videos.forEach(e => e.nativeElement.pause());

    this.linkVideos = !this.linkVideos;
  }


  fullScreen() {
    // console.log('multiLivestream');
    // this.bigScreenService.request(this.modalwindow.nativeElement);
  }


  videoPlay() {
    if (this.linkVideos) {
      console.log('testPlay');

      this.videos.forEach(e => {
        const playPromise = e.nativeElement.play();
        /*if (playPromise !== undefined) {
          playPromise.then(_ => {
          })
            .catch(error => {
              console.log('catch error');
              e.nativeElement.pause();
            });
        }*/
      });
      this.universalPlay = true;
    }
  }


  videoPaused() {
    if (this.linkVideos) {
      this.videos.forEach(e => {
        e.nativeElement.pause();
      });
      this.universalPlay = false;

    }

  }


  videoScrub(event) {
    // console.log('time update');
    // console.log(event);

    if (!this.universalPlay && this.linkVideos) {
      this.videos.forEach(e => {
        if (e.nativeElement.currentTime !== event.target.currentTime && e.nativeElement.paused) {
          e.nativeElement.currentTime = event.target.currentTime;
        }
      });
    }
  }


  requestVideoFromEventWindow(videoEvent) {

    videoEvent.time = videoEvent.date;

    videoEvent.offset = true;


    console.log(videoEvent);
    this.vehiclesService.getVideoRequest(videoEvent)
      .subscribe(data => {
        this.message = true;
      });
  }

  angleDistance(a, b) {
    const c = Math.abs(a - b) % 360;       // This is either the distance or 360 - distance
    return c > 180 ? 360 - c : c;
  }


  fitMapBoundsToJourney(bottom) {

    console.log('fitMapBoundsToJourney: ' + bottom);

    const bounds = this.coords.reduce(function(b, coord) {
      return b.extend(coord);
    }, new mapboxgl.LngLatBounds(this.coords[0], this.coords[0]));

    console.log(bounds);
    let padding = {};

    if (window.innerWidth <= this.MOBILE_BREAKPOINT) {
      padding = {bottom, left: 20, top: 200, right: 20};
    } else {
      padding = {bottom: 100, left: 100, top: 100, right: 100};
    }

    this.map.fitBounds(bounds, {
      padding, maxZoom: 18
    });
  }


}
