<div style="position: relative;height:500px;" *ngIf="!existingMediaLoaded">
  <mat-spinner style="margin: auto;position: absolute;top: 0; left: 0; bottom: 0; right: 0;"></mat-spinner>
</div>

<div style="width: 100%;margin-top:10px;text-align: center" [hidden]="!existingMediaLoaded">
    <video style="height:590px;border-radius:5px;margin:0 auto;display: block;" type="video/mp4" id="EventVideo" src="" (ended)="existingMediaEnded()"
           (timeupdate)="existingMediaUpdate($event)" (loadeddata)="videoLoaded($event)">
    </video>



  <div style="display: flex;justify-content: center;align-items: center;margin-top:5px;width:100%;">
    <button mat-flat-button (click)="playPauseExistingMedia()" matTooltip="Play/pause">
      <mat-icon *ngIf="!existingMediaPlaying">play_arrow</mat-icon>
      <mat-icon *ngIf="existingMediaPlaying">pause</mat-icon>
    </button>
    <span style="margin-left: 5px;">{{existingMediaCurrentTime}}</span>

    <div style="display: inline-block;margin-left:10px;margin-right:10px;height:10px;flex:1;background:grey;position:relative;cursor:pointer;"
         id="videoProgressBar"
         (click)="clickVideoProgressBar($event)">
      <div style="position: absolute;background: black;height: 100%;width: 0"></div>
      <div style="position: absolute;background: #434343;height: 20px;width: 20px;left:-5px;top:-5px;border-radius:50%;"></div>
    </div>

    <span style="margin-right: 5px;">{{existingMediaDuration}}</span>

    <button type="button" mat-flat-button matTooltip="Download media"  (click)="downloadFile()">
      <mat-icon>file_download</mat-icon>
    </button>

  </div>
</div>
