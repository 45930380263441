<div style="overflow-y: scroll; height:60vh">
    <mat-form-field>
      <mat-label>Snapshot channel</mat-label>
      <mat-select [(ngModel)]="singleSnapshotChannel" [ngModelOptions]="{standalone: true}">
        <mat-option value="">-- Please select --</mat-option>
        <mat-option *ngFor="let camera of cameras" [value]="camera.channel">{{camera.channel}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button type="button" mat-flat-button (click)="snapshotRequest(singleSnapshotChannel)">Request snapshot</button>


    <table *ngIf="deviceParameters" style="width:300px;" border="1">
      <tr>
        <th>Configs match?</th>
        <th style="text-align:center;">
          <span *ngIf="configsMatch"><mat-icon style="color:#5C8459">done</mat-icon></span>
          <span *ngIf="!configsMatch"><mat-icon style="color:#F24141">close</mat-icon></span>
        </th>
      </tr>
      <tr>
      <tr>
        <th>Overrun mins (rec/power)</th>
        <th [ngClass]="{'operations-issue': deviceParameters?.POWER?.AccOffRecTime !== deviceParameters?.POWER?.delay,
                        'pass': deviceParameters?.POWER?.AccOffRecTime === deviceParameters?.POWER?.delay}"  style="text-align:center;">
          {{deviceParameters?.POWER?.AccOffRecTime}} / {{deviceParameters?.POWER?.delay}} </th>
      </tr>
    </table>


    <div>
      <h3 style="text-decoration: underline"><b>Device config</b></h3>
      <div *ngFor="let p of config | keyvalue">
        <strong>{{p.key}}</strong><br>
        {{p.value | json}}
        <br><br>
      </div>

    </div>



    <div>
      <h3 style="text-decoration: underline"><b>Device params</b></h3>
      <br>
      <div *ngFor="let p of deviceParameters  | keyvalue">
        <strong>{{p.key}}</strong><br>
        {{p.value | json}}
        <br><br>
      </div>
    </div>
    <div *ngIf="deviceStatus">
      deviceTime   {{deviceStatus?.deviceTime}}<br>
      acquisitionTime    {{deviceStatus?.acquisitionTime}}    <br>
      <br>
      ModuleWorkingStatusidentifierBit    {{deviceStatus?.ModuleWorkingStatusidentifierBit}}     <br>
      mobileNetworkModule: {{deviceStatus?.mobileNetworkModule}} <br>
      locationModule: {{deviceStatus?.locationModule}}<br>
      wifiModule: {{deviceStatus?.wifiModule}}<br>
      gSensorModule: {{deviceStatus?.gSensorModule}}<br>
      recording: {{deviceStatus?.recording}}<br><br>
      mobileNetwork    {{deviceStatus?.mobileNetwork}}    <br>
      GPSLocation    {{deviceStatus?.GPSLocation}}     <br>
      <br>
      gSensor    {{deviceStatus?.gSensor}}    <br>
      recordingStatus    {{deviceStatus?.recordingStatus}}     <br>
      recordingStatusArray: {{this.deviceStatus?.recordingStatusArray}} <br><br>
      HardDiskStatusBit    {{deviceStatus?.HardDiskStatusBit}}     <br>
      HardDiskStatusBitArray    {{deviceStatus?.HardDiskStatusBitArray}}<br>
      HardDiskId    {{deviceStatus?.HardDiskId}}     <br>
      HardDiskStatus    {{deviceStatus?.HardDiskStatus}}     <br>
      HardDiskSizeMb    {{deviceStatus?.HardDiskSizeMb}}     <br>
      HardDiskBalanceCapacity    {{deviceStatus?.HardDiskBalanceCapacity}}     <br><br>
      alarmStatusIdBit    {{deviceStatus?.alarmStatusIdBit}}     <br>
      <hr>
      videoLoss      {{deviceStatus?.videoLossAny}} <br>
      motionDetection      {{deviceStatus?.motionDetectionAny}} <br>
      videoBlind      {{deviceStatus?.videoBlindAny}} <br>
      alarmInputTrigger      {{deviceStatus?.alarmInputTriggerAny}} <br>
      overSpeedAlarm      {{deviceStatus?.overSpeedAlarm}} <br>
      lowSpeedAlarm      {{deviceStatus?.lowSpeedAlarm}} <br>
      emergencyAlarm      {{deviceStatus?.emergencyAlarm}} <br>
      overTimeStop      {{deviceStatus?.overTimeStop}} <br>
      vibrationAlarm      {{deviceStatus?.vibrationAlarm}} <br>
      outGEOFencingAlarm      {{deviceStatus?.outGEOFencingAlarm}} <br>
      enterGEOFencingAlarm      {{deviceStatus?.enterGEOFencingAlarm }} <br>
      exitLineAlarm      {{deviceStatus?.exitLineAlarm }} <br>
      enterLineAlarm      {{deviceStatus?.enterLineAlarm }} <br>
      fuelLevelAlarm      {{deviceStatus?.fuelLevelAlarm }} <br>
      <hr>
      <br>
      videoLoss    {{deviceStatus?.videoLoss}}    <br>
      videoLossArray    {{deviceStatus?.videoLossArray}}   <br><br>
      motionDetection    {{deviceStatus?.motionDetection}}     <br>
      motionDetectionArray    {{deviceStatus?.motionDetectionArray}}   <br><br>
      videoBlind    {{deviceStatus?.videoBlind}}     <br>
      videoBlindArray    {{deviceStatus?.videoBlindArray}}   <br><br>
      alarmInputTrigger    {{deviceStatus?.alarmInputTrigger}}     <br>
      alarmInputTriggerArray    {{deviceStatus?.alarmInputTriggerArray}}   <br>
      <br><br>
      <div *ngIf="calendar">
        <div *ngFor="let c of calendar.modRecordingHistory">
          {{c.st}} - {{c.et}}
        </div>
      </div>
    </div>

</div>
