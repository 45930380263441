<table>
  <tr>
    <th></th>
    <th style="text-align: center">Health</th>
    <th style="text-align: right">Live Status</th>
  </tr>
  <tr>
    <th style="text-align: left">
      <svg style="width:24px;height:24px;vertical-align: middle" viewBox="0 0 24 24">
        <path fill="currentColor" d="M17,17H7V7H17M21,11V9H19V7C19,5.89 18.1,5 17,5H15V3H13V5H11V3H9V5H7C5.89,5 5,5.89 5,7V9H3V11H5V13H3V15H5V17A2,2 0 0,0 7,19H9V21H11V19H13V21H15V19H17A2,2 0 0,0 19,17V15H21V13H19V11M13,13H11V11H13M15,9H9V15H15V9Z" />
      </svg>
      Device connection:
    </th><!---online/offline/standby (standby if diagnostics on and ignition off)--->
    <td style="text-align: right;">
      <div *ngIf="deviceHealth.connection == 'green'" class="onlineCircle"></div>
      <div *ngIf="deviceHealth.connection == 'yellow'" class="yellowCircle"></div>
      <div *ngIf="deviceHealth.connection == 'red'" class="offlineCircle"></div>

      <div *ngIf="deviceHealth.connection == ''" class="greyCircle"></div>
    </td>
    <td style="text-align: right;padding:5px;">
      <div *ngIf="deviceConnected && deviceStatus?.identifierBit1Array && deviceStatus?.identifierBit1Array[0]=='1'">
        Online
        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device online and connected">
          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
        </svg>
      </div>
      <div *ngIf="!deviceConnected || deviceConnected == null">
        Device Offline
        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device offline">
          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
        </svg>
      </div>
      <div *ngIf="deviceStatus && deviceStatus?.identifierBit1Array && deviceStatus?.identifierBit1Array[0]=='0' && deviceConnected">
        Standby
        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device in standby">
          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
        </svg>
      </div>
    </td>
  </tr>
  <tr>
    <th>
      <svg style="width:24px;height:24px;vertical-align: middle;" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12,3C7.58,3 4,4.79 4,7C4,9.21 7.58,11 12,11C16.42,11 20,9.21 20,7C20,4.79 16.42,3 12,3M4,9V12C4,14.21 7.58,16 12,16C16.42,16 20,14.21 20,12V9C20,11.21 16.42,13 12,13C7.58,13 4,11.21 4,9M4,14V17C4,19.21 7.58,21 12,21C16.42,21 20,19.21 20,17V14C20,16.21 16.42,18 12,18C7.58,18 4,16.21 4,14Z" />
      </svg>
      Memory status:
    </th>
    <td style="text-align: center">
      <div class="onlineCircle" *ngIf="deviceHealth.storage == 'green'"></div>
      <div class="yellowCircle" *ngIf="deviceHealth.storage == 'yellow'"></div>
      <div class="offlineCircle" *ngIf="deviceHealth.storage == 'red'"></div>

      <div *ngIf="deviceHealth.storage == ''" class="greyCircle"></div>

    </td>
    <td style="text-align: right;padding: 5px;">
      <div *ngIf="deviceConnected && deviceStatus && (deviceStatus.HardDiskSizeMb / 1000) >= 75">
        Good capacity ({{Math.floor(deviceStatus.HardDiskSizeMb / 1000)}} GB)
        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Extended memory">
          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
        </svg>
      </div>
      <div *ngIf="deviceConnected && deviceStatus && (deviceStatus.HardDiskSizeMb / 1000) > 35 && (deviceStatus.HardDiskSizeMb / 1000) < 75">
        Average capacity ({{Math.floor(deviceStatus.HardDiskSizeMb / 1000)}} GB)
        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Standard memory">
          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
        </svg>
      </div>
      <div *ngIf="deviceConnected && deviceStatus && (deviceStatus.HardDiskSizeMb / 1000) < 35">Low capacity</div>
      <div *ngIf="!deviceConnected">
        Device Offline
        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device offline">
          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
        </svg>
      </div>
    </td>
  </tr>
  <tr>
    <th>
      <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
        <path fill="currentColor" d="M11.62,1L17.28,6.67L15.16,8.79L13.04,6.67L11.62,8.09L13.95,10.41L12.79,11.58L13.24,12.04C14.17,11.61 15.31,11.77 16.07,12.54L12.54,16.07C11.77,15.31 11.61,14.17 12.04,13.24L11.58,12.79L10.41,13.95L8.09,11.62L6.67,13.04L8.79,15.16L6.67,17.28L1,11.62L3.14,9.5L5.26,11.62L6.67,10.21L3.84,7.38C3.06,6.6 3.06,5.33 3.84,4.55L4.55,3.84C5.33,3.06 6.6,3.06 7.38,3.84L10.21,6.67L11.62,5.26L9.5,3.14L11.62,1M18,14A4,4 0 0,1 14,18V16A2,2 0 0,0 16,14H18M22,14A8,8 0 0,1 14,22V20A6,6 0 0,0 20,14H22Z" />
      </svg>
      GPS signal strength:
    </th><!---satellites connected to NOT ACCURACY--->
    <td>
      <div class="onlineCircle" *ngIf="deviceHealth.gps == 'green'"></div>
      <div class="yellowCircle" *ngIf="deviceHealth.gps == 'yellow'"></div>
      <div class="offlineCircle" *ngIf="deviceHealth.gps == 'red'"></div>

      <div *ngIf="deviceHealth.gps == ''" class="greyCircle"></div>

    </td>
    <td style="text-align: right;padding: 5px;">
      <div *ngIf="deviceStatus?.satellitesQuantity > 5 && deviceConnected">
        Good
        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Good GPS signal">
          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
        </svg>
      </div>
      <div *ngIf="deviceStatus?.satellitesQuantity < 3 && deviceConnected">
        Poor
        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Poor GPS signal">
          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
        </svg>
      </div>
      <div *ngIf="(deviceStatus?.satellitesQuantity == 3 || deviceStatus?.satellitesQuantity == 4) && deviceConnected">
        Average
        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Average GPS signal">
          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
        </svg>
      </div>
      <div *ngIf="!deviceConnected">
        Device Offline
        <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device offline">
          <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
        </svg>
      </div>
    </td>
  </tr>
  <tr>
    <th>
      <svg style="width:24px;height:24px;vertical-align: middle;" viewBox="0 0 24 24">
        <path fill="currentColor" d="M21,4H16V20H21V4M14,9H9V20H14V9M7,14H2V20H7V14Z" />
      </svg>
      4G signal strength:
    </th><!---network tpye not signal strength--->
    <td>
      <div class="onlineCircle" *ngIf="deviceHealth.cellular == 'green'"></div>
      <div class="yellowCircle" *ngIf="deviceHealth.cellular == 'yellow'"></div>
      <div class="offlineCircle" *ngIf="deviceHealth.cellular == 'red'"></div>

      <div *ngIf="deviceHealth.cellular == ''" class="greyCircle"></div>

    </td>
    <td style="text-align: right;padding: 5px;">
                <span *ngIf="deviceConnected && deviceStatus?.signalTrafficLight == 'weak'">
                  Poor
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Media will not upload">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </span>
      <span *ngIf="deviceConnected && deviceStatus?.signalTrafficLight == 'average'">
                  Average
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Slow media uploads">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </span>
      <span *ngIf="deviceConnected && deviceStatus?.signalTrafficLight == 'good'">
                  Good
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Fast media uploads">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </span>
      <span *ngIf="!deviceConnected || !deviceStatus?.signalTrafficLight">
                  Device Offline
                  <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24" matTooltip="Device offline">
                    <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                </span>
    </td>
  </tr>
  <tr>
    <th>
      <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
        <path fill="currentColor" d="M13,19.92C14.8,19.7 16.35,18.95 17.65,17.65C18.95,16.35 19.7,14.8 19.92,13H16.92C16.7,14 16.24,14.84 15.54,15.54C14.84,16.24 14,16.7 13,16.92V19.92M10,8H14L17,11H19.92C19.67,9.05 18.79,7.38 17.27,6C15.76,4.66 14,4 12,4C10,4 8.24,4.66 6.73,6C5.21,7.38 4.33,9.05 4.08,11H7L10,8M11,19.92V16.92C10,16.7 9.16,16.24 8.46,15.54C7.76,14.84 7.3,14 7.08,13H4.08C4.3,14.77 5.05,16.3 6.35,17.6C7.65,18.9 9.2,19.67 11,19.92M12,2C14.75,2 17.1,3 19.05,4.95C21,6.9 22,9.25 22,12C22,14.75 21,17.1 19.05,19.05C17.1,21 14.75,22 12,22C9.25,22 6.9,21 4.95,19.05C3,17.1 2,14.75 2,12C2,9.25 3,6.9 4.95,4.95C6.9,3 9.25,2 12,2Z" />
      </svg>
      G-force sensor:
    </th>
    <td>
      <div *ngIf="deviceHealth.gforce == 'red' && !vehicle?.gsensorBlocked" class="offlineCircle"></div>
      <div *ngIf="deviceHealth.gforce == 'green' && !vehicle?.gsensorBlocked" class="onlineCircle"></div>

      <div *ngIf="deviceHealth.gforce == '' && !vehicle?.gsensorBlocked" class="greyCircle"></div>
      <div *ngIf="vehicle?.gsensorBlocked">blocked</div>




    </td>
    <td style="text-align: right;padding: 5px;">
      <span [innerHTML]="deviceHealth.gforce === 'red' ? 'Needs calibration' : deviceHealth.gforce === 'green' ? 'Calibrated' : 'Unknown'"></span>
      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24"
           [matTooltip]="deviceHealth.gforce === 'red' ? 'G-force sensor needs calibration' : deviceHealth.gforce === 'green' ? 'G-force sensor is calibrated':
                     'Unknown if G-force sensor is calibrated at last update'">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>

    </td>
  </tr>
  <tr *ngFor="let camera of vehicle?.cameras">
    <th>
      <svg style="width:24px;height:24px;vertical-align:middle;" viewBox="0 0 24 24">
        <path fill="currentColor" d="M17,10.5V7A1,1 0 0,0 16,6H4A1,1 0 0,0 3,7V17A1,1 0 0,0 4,18H16A1,1 0 0,0 17,17V13.5L21,17.5V6.5L17,10.5Z" />
      </svg>
      <span>{{camera?.camPosition}} camera ({{camera?.channel}}):</span>
    </th>
    <td>
      <div [ngClass]="cameraHealth(camera) && cameraRecording(camera) ? 'onlineCircle' : 'offlineCircle'"></div>
    </td>
    <td style="text-align: right;padding: 5px;">
      <!--- last health update rec stat---->
      <span [innerHTML]="cameraRecordingLive(camera) && deviceConnected ? 'Recording' : 'Not recording'"></span>

      <svg style="width:16px;height:16px;vertical-align: middle;color:#909090;" viewBox="0 0 24 24"
           [matTooltip]="cameraRecordingLive(camera) && deviceConnected ? 'The camera is recording' :
                     'The camera is not recording'">
        <path fill="currentColor" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
      </svg>
    </td>
  </tr>
</table>
