<style>
  /* Create four equal columns that sits next to each other */

  ::ng-deep .mat-dialog-container {
    padding: 5px 20px 5px 20px !important;
  }


  ::ng-deep.mat-tab-label, ::ng-deep.mat-tab-label-active{
    min-width: 100px!important;
    padding: 10px!important;
    margin: 10px!important;
  }


  img {
    width: 100%;
  }


  .right {
    float: right;
  }

  .left {
    float: left;
  }


  .clear-fix {
    clear: both;
    margin-bottom: 10px;
  }


  .close-top-right {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 10000
  }

  .vid-pic-container {
    position: relative;
  }

  .eventPin {
    width: 27px;
    margin-right: 5px;
    vertical-align: middle
  }

  p {
    font-size: 12px;
    margin: 3px 0px 10px 0px;
  }



  .table td {
    border:1px solid black ;
    border-collapse: collapse;
  }


  #map2 {
    height: 500px;
    width: 100%;
  }




</style>
<h3>Alert info</h3>

<!--{{data | json}}-->

<div class="vid-pic-container">
  <button class="close-top-right" (click)="onNoClick()" mat-icon-button><i
    class="material-icons  md-dark disable-text ">close</i></button>
  <mat-tab-group>



    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>camera</mat-icon>&nbsp;Snapshot
      </ng-template>
      API_URL: {{API_URL}}
      <div *ngIf="data.event.snapshots">
        <div *ngFor="let snapshot of data.event.snapshots">
          <img class="eventImage"
               [src]="API_URL + '/angFetchSnapshot/'+ snapshot.file + '_lrg'">
        </div>
      </div>
    </mat-tab>



    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>graph</mat-icon>&nbsp;Graph
      </ng-template>

      <div class="chart-wrapper">
        <canvas baseChart
                [datasets]="lineChartData"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="lineChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
                [plugins]="lineChartPlugins">
        </canvas>
      </div>


      <div class="chart-wrapper">
        <canvas baseChart
                [datasets]="lineChart2Data"
                [labels]="lineChartLabels"
                [options]="lineChartOptions"
                [colors]="lineChartColors"
                [legend]="lineChartLegend"
                [chartType]="lineChartType"
                [plugins]="lineChartPlugins">
        </canvas>
      </div>








    </mat-tab>




    <mat-tab>
      <ng-template mat-tab-label>
        Data
      </ng-template>





      {{data.event.analysis | json}}


      <div *ngIf="data.event.overpass">
        <br>
        <strong>Overpass:</strong>

        {{data.event.overpass | json}}

      </div>

      <br>



      <table class="table">
        <tr>
          <th>time</th>
          <th>mph</th>
          <th>direction</th>
          <th>gSensorX</th>
          <th>gSensorY</th>
          <th>gSensorZ</th>
          <th>gSensorTilt</th>
          <th>gSensorImpact</th>
        </tr>
        <tr *ngFor="let e of data.event.eventData; index as i" class="redBG" [ngClass]="{'redBG':i === 9}">
          <td>
            {{e.acquisitionTime}}
          </td>
          <td>
            {{ (e.speed/100*0.621371) | number : '1.1-1' }}
          </td>
          <td>
            {{e.direction}}
          </td>
          <td>
            {{e.gSensorX}}
          </td>
          <td>
            {{e.gSensorY}}
          </td>
          <td>
            {{e.gSensorZ}}
          </td>
          <td>
            {{e.gSensorTilt}}
          </td>
          <td>
            {{e.gSensorImpact}}
          </td>
        </tr>
      </table>





    </mat-tab>


    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>videocam</mat-icon>&nbsp;Video
      </ng-template>


      <!--1000030_20201204112145-20201204112155_1_21_h265-->



<!--      <div *ngIf="data.event.video">
      <div *ngFor="let media of  data.event.video">
        <div *ngFor="let ch of  media['ch']">
          {{ch.filename | json}}

          <div style="height: 320px; width: 480px;">
            <video controls="controls" #videoPlayer
                   style="height: 100%; width: 100%; object-fit: fill; background-color: #333333"
                   (timeupdate)="videoScrub($event)" (play)="videoPlay()" (pause)="videoPaused()" muted loop
                   src="https://www.myfleetlive.org:8000/angFetch/{{data.event.video[0]}}#t=0.1"
                   type="video/mp4">
            </video>
          </div>


        </div>


        <br><br>
      </div>
      </div>-->






      <div *ngIf="!data.event.videoLink">
        <button mat-button (click)="requestVideoFromEventWindow(data.event)">
          <mat-icon>videocam</mat-icon>
          Request video clip
        </button>
        <!--{{model | json}}-->
        <p *ngIf="message">Your video request is being processed</p>
      </div>

<!--      <video controls="controls" #videoPlayer
             style="height: 100%; width: 100%; object-fit: fill; background-color: #333333"
             (timeupdate)="videoScrub($event)" (play)="videoPlay()" (pause)="videoPaused()" muted loop

             src="https://www.myfleetlive.org:8000/angFetch/100020_1597825303-1597825323-25_1_h264#t=0.1"
             type="video/mp4">
      </video>-->

      <div *ngIf="data.event.video">
        <p>

          <span *ngIf="!mobileDevice">
          <button mat-button (click)="toggleLinkVideos()" matTooltip="Toggle simultaneous channel scrub and play">
          <mat-icon *ngIf="linkVideos">link</mat-icon>
          <mat-icon *ngIf="!linkVideos">link_off</mat-icon>
        </button>
        <button mat-button (click)="fullScreen()" matTooltip="Fullscreen all channels">
          <mat-icon>fullscreen</mat-icon>
        </button>
            </span>


        </p>
        <!--universalPlay: {{universalPlay}}
        linkVideos: {{linkVideos}}-->
        <div #modalWindow>
          <mat-grid-list [cols]="breakpoint" rowHeight="16:9" (window:resize)="onResize($event)">
            <mat-grid-tile *ngFor="let video of data.event.video">
              <video controls="controls" #videoPlayer
                     style="height: 100%; width: 100%; object-fit: fill; background-color: #333333"
                     (timeupdate)="videoScrub($event)" (play)="videoPlay()" (pause)="videoPaused()" muted loop
                     src="{{API_URL}}/angFetch/{{video}}#t=0.1"
                     type="video/mp4">
              </video>


<!--              <video controls="controls" #videoPlayer
                     style="height: 100%; width: 100%; object-fit: fill; background-color: #333333"
                     (timeupdate)="videoScrub($event)" (play)="videoPlay()" (pause)="videoPaused()" muted loop
                     src="https://www.myfleetlive.org/media/100020/100020_1597825303-1597825323-25_1_h264.mp4"
                     type="video/mp4">
              </video>-->




            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </div>
    </mat-tab>


    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>map</mat-icon>&nbsp;Map
      </ng-template>

      <button (click)="makeMap()">makeMap</button>

      <div id="map2" >dfgdfgdfgdfg</div>





    </mat-tab>



    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>assignment</mat-icon>&nbsp;Review
      </ng-template>


      <h2>Mechanical Turk</h2>

      <div class="example-container">


        <mat-form-field appearance="fill">
          <mat-label>Incident type</mat-label>
          <mat-select>
            <mat-option value="option">Braking</mat-option>
            <mat-option value="option">Cornering</mat-option>
            <mat-option value="option">Acceleration</mat-option>
            <mat-option value="option">Speed hump</mat-option>
            <mat-option value="option">Pothole</mat-option>
            <mat-option value="option">Road surface</mat-option>
          </mat-select>
        </mat-form-field>
        <br>


        <mat-form-field appearance="fill">
          <mat-label>Severity</mat-label>
          <mat-select>
            <mat-option value="option">Mild</mat-option>
            <mat-option value="option">Moderate</mat-option>
            <mat-option value="option">Severe</mat-option>
          </mat-select>
        </mat-form-field>
        <br>




        <mat-form-field appearance="fill">
          <mat-label>Driver score</mat-label>
          <mat-select>
            <mat-option value="option">Yes</mat-option>
            <mat-option value="option">No</mat-option>

          </mat-select>
        </mat-form-field>
        <br>



        <mat-form-field appearance="fill">
          <mat-label>Trigger alert</mat-label>
          <mat-select>
            <mat-option value="option">Yes</mat-option>
            <mat-option value="option">No</mat-option>

          </mat-select>
        </mat-form-field>
        <br>

        <label>Trigger alert</label>

        <mat-radio-group aria-label="Trigger alert">
          <mat-radio-button value="1">Yes</mat-radio-button>
          <mat-radio-button value="2">No</mat-radio-button>
        </mat-radio-group>

        <br>

        <mat-form-field appearance="fill">
          <mat-label>Notes</mat-label>
          <textarea matInput></textarea>
        </mat-form-field>
      </div>





    </mat-tab>




  </mat-tab-group>
</div>

