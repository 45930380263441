import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import {ActivatedRoute, Data} from '@angular/router';
import { DataSharingService } from "../data-sharing.service";
import { NotificationsService } from "../notifications.service";


@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit {

  existingMediaPlaying: boolean;
  existingMediaLoaded: boolean;
  existingMediaCurrentTime: string;
  existingMediaDuration: string;



  constructor(private adminService: AdminService, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe( paramMap => {
      this.existingMediaLoaded = false;

      this.loadVideo(paramMap.get('id'));
    });
  }

  loadVideo(videoFile): void {
    this.adminService.viewVideo(videoFile).then(
      blob => {
        if (blob){
          (document.getElementById('EventVideo') as HTMLVideoElement).src = URL.createObjectURL(blob);
          this.existingMediaLoaded = true;
        }
    });
  }




  str_pad_left(stringy, pad, length): string {
    return (new Array(length + 1).join(pad) + stringy).slice(-length);
  }

  videoLoaded(event): void {
    this.existingMediaCurrentTime = '00:00';
    const theDuration = Math.round(event.target.duration);

    const minutes = Math.floor(theDuration / 60);
    const seconds = theDuration - (minutes * 60);
    this.existingMediaDuration = this.str_pad_left(minutes, '0', 2) + ':' + this.str_pad_left(seconds, '0', 2);
  }

  playPauseExistingMedia(): void {

      if (this.existingMediaPlaying){
        (document.getElementById('EventVideo') as HTMLVideoElement).pause();
        this.existingMediaPlaying = false;
      } else {
        (document.getElementById('EventVideo') as HTMLVideoElement).play();
        this.existingMediaPlaying = true;
      }
  }

  existingMediaEnded(): void {
    this.existingMediaPlaying = false;
  }


  existingMediaUpdate(event): void {
    // custom video progressBar
    const vid = event.target;

    const currentTime = Math.round(vid.currentTime);

    const minutes = Math.floor(currentTime / 60);
    const seconds = currentTime - (minutes * 60);
    this.existingMediaCurrentTime = this.str_pad_left(minutes, '0', 2) + ':' + this.str_pad_left(seconds, '0', 2);
    const percentage = ( vid.currentTime / vid.duration ) * 100;

    const videoProgressBar = (document.getElementById('videoProgressBar') as HTMLElement);
    (videoProgressBar.childNodes[0] as HTMLElement).style.width = `${percentage}%`;
    (videoProgressBar.childNodes[1] as HTMLElement).style.left = `calc(${percentage}% - 10px)`;
  }

  clickVideoProgressBar(event): void {
    // click custom video progressBar
    const videoProgressBar = ((document.getElementById('videoProgressBar') as HTMLElement).childNodes[0] as HTMLElement);
    const leftOffset = (videoProgressBar.parentNode as HTMLElement).offsetLeft;
    const leftValue = (event.pageX - leftOffset);
    const totalWidthOfBar = (videoProgressBar.parentNode as HTMLElement).offsetWidth;
    const percentageWidth = ( leftValue / totalWidthOfBar );
    const vid = (document.getElementById('EventVideo') as HTMLVideoElement);
    vid.currentTime = vid.duration * percentageWidth;
  }


  downloadFile(): void {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = (document.getElementById('EventVideo') as HTMLVideoElement).src;
    a.download = 'MFL Connect - Video download';
    a.click();
  }










}
