import { Component, OnInit } from '@angular/core';
import {AdminService} from "../../admin.service";
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-driver-health-camera-report',
  templateUrl: './driver-health-camera-report.component.html',
  styleUrls: ['./driver-health-camera-report.component.css'],
  providers: [DatePipe]
})
export class DriverHealthCameraReportComponent implements OnInit {


  displayedColumns = ['deviceNo', 'cameras', 'snapshots'];
  dataSource: any;
  reportData: any;
  start: number = 0;
  limit: number = 15;
  end: number = this.limit + this.start;
  selectedRowIndex: number = null;

  constructor(private adminService: AdminService, private datePipe: DatePipe) { }

  ngOnInit(): void {


    this.adminService.camHealth({
      st: this.datePipe.transform(new Date().setDate(new Date().getDate() - 7), 'yyyy-MM-dd 00:00:00'),
      et: this.datePipe.transform( new Date(), 'yyyy-MM-dd HH:mm:ss')
    }).subscribe(r => {
      const grouped = r.reduce(function(rv, x) {
        (rv[x['dn']] = rv[x['dn']] || []).push(x);
        return rv;
      }, {});

      const numeric_array = new Array();

      for (var items in grouped){
        numeric_array.push( grouped[items] );
      }

      this.reportData = numeric_array;
      this.dataSource = this.getTableData(this.start, this.end);
      this.updateIndex();
    });
  }

  onTableScroll(e) {
    const tableViewHeight = e.target.offsetHeight // viewport
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      let data = this.getTableData(this.start, this.end);;
      this.dataSource = this.dataSource.concat(data);
      this.updateIndex();
    }
  }

  getTableData(start, end) {
    const toReturn = [];
    const data = this.reportData.filter((value, index) => index >= start && index < end);

    data.forEach(device => {
      this.adminService.deviceDetails({dn: device[0].dn}).subscribe(r => {
        if(r[0]?.healthChecked?.hEventId){
          this.adminService.fetchEventById({dn: device[0].dn, id:r[0]?.healthChecked?.hEventId}).subscribe(r2 => {
            device.snapshots = r2[0].snapshots;
            r2[0].snapshots.forEach(snap => {
              snap.camPosition = r[0].cameras.filter(cam => cam.channel === snap.ch)?.[0].camPosition
            })
            toReturn.push(device)
          })
        }
      });
    });
    console.log(data)
    return data;
  }

  updateIndex() {
    this.start = this.end;
    this.end = this.limit + this.start;
  }

  selectedRow(row) {
    console.log('selectedRow', row)
  }

  showSnap(element, camPos) {
    const ele = JSON.parse(JSON.stringify(element))
    delete ele.snapshots


    const flagged = ele.filter(cam => cam.cameraHealthWarning === camPos);

    return flagged?.length > 0

  }

}
