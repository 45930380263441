
import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-health-summary',
  templateUrl: './health-summary.component.html',
  styleUrls: ['./health-summary.component.css']
})
export class HealthSummaryComponent implements OnInit {
  Math = Math;
  @Input() healthGraphRawData;
  @Input() cameras;
  @Input() numberOfStatusUpdatesForHealth;
  @Input() deviceConnected;
  @Input() deviceStatus;
  @Input() vehicle;
  @Input() calendar;

  deviceHealth = {
    connection : '',
    storage : '',
    cellular : '',
    gforce : '',
    gps: ''
  };


  constructor() { }

  ngOnInit(): void {



    if (this.calendar?.hours < 10){
      this.deviceHealth.storage = 'red';
    } else if (this.calendar?.hours < 20){
      this.deviceHealth.storage = 'yellow';
    } else {
      this.deviceHealth.storage = 'green';
    }

    setTimeout(() => { this.healthInit(); }, 1000);

  }


  healthInit(): void {
    if (this.healthGraphRawData?.length > 1){
      let satelliteTotal = 0;
      let cellularTotal = 0;

      this.healthGraphRawData.reverse().forEach((alarm, index) => {

        // most recent record
        if (index === (this.healthGraphRawData.length - 1)){
          const secondsSinceUpdate = ((new Date().getTime() - new Date(alarm.alarmData.statusData.deviceTime).getTime()) / 1000);
          const d = Math.floor(secondsSinceUpdate / (3600 * 24));

          if (d < 5) {
            this.deviceHealth.connection = 'green';
          } else if (d < 10) {
            this.deviceHealth.connection = 'yellow';
          } else if (d > 10) {
            this.deviceHealth.connection = 'red';
          }
          this.deviceHealth.gforce = '';

          if (alarm?.alarmData?.statusData?.speed === 0 ){
            if (Math.abs(alarm.alarmData.statusData.gSensorImpact) < 10){
              this.deviceHealth.gforce = 'green';
            } else {
              this.deviceHealth.gforce = 'red';
            }
          }
        }

        satelliteTotal += alarm?.alarmData?.statusData?.satellitesQuantity;

        if (alarm?.alarmData?.statusData?.networkType === 5){
          cellularTotal ++;
        }
      });

      if ((satelliteTotal / this.numberOfStatusUpdatesForHealth) === 0){
        this.deviceHealth.gps = 'red';
      } else if ((satelliteTotal / this.numberOfStatusUpdatesForHealth) < 5){
        this.deviceHealth.gps = 'yellow';
      } else {
        this.deviceHealth.gps = 'green';
      }

      if ((cellularTotal / this.numberOfStatusUpdatesForHealth) * 100 < 30){
        this.deviceHealth.cellular = 'red';
      } else if ((cellularTotal / this.numberOfStatusUpdatesForHealth) * 100 < 50){
        this.deviceHealth.cellular = 'yellow';
      } else {
        this.deviceHealth.cellular = 'green';
      }
    }


  }

  cameraHealth(camera): boolean {
    if (this.healthGraphRawData?.length > 0){
      let videoLossArrayString = this.healthGraphRawData[this.healthGraphRawData.length - 1 ].alarmData.statusData.videoLoss;
      while (videoLossArrayString.length < 8) {
        videoLossArrayString = '0' + videoLossArrayString;
      }
      const videoLossArray = videoLossArrayString.split('');
      videoLossArray.reverse();

      if (videoLossArray[parseInt(camera.channel) - 1] === '1'){
        return false;
      } else {
        return true;
      }
    }
  }

  cameraRecording(camera): boolean {
    if (this.healthGraphRawData?.length > 0){
      let recordingArrayString = this.healthGraphRawData[this.healthGraphRawData.length - 1 ].alarmData.statusData.recordingStatus;
      while (recordingArrayString.length < 8) {
        recordingArrayString = '0' + recordingArrayString;
      }
      const recordingArray = recordingArrayString.split('');
      recordingArray.reverse();

      if (recordingArray[parseInt(camera.channel) - 1] === '0') {
        return false;
      } else {
        return true;
      }
    }
  }

  cameraRecordingLive(camera): boolean {
    if (this.deviceStatus){
      if (this.deviceStatus.recordingStatusArray[parseInt(camera.channel) - 1] === '0') {
        return false;
      } else {
        return true;
      }

    }
  }



}
