import { AfterViewInit, Component, ElementRef, ViewChild, Input } from '@angular/core';
import Hls from 'hls.js';
import {AdminService} from '../admin.service';

// your component
@Component({
  selector: 'app-livestream',
  templateUrl: './livestream.component.html',
  styleUrls: ['./livestream.component.css']
})

// called after Angular has fully initialized a component's view.
export class LivestreamComponent implements AfterViewInit {

  @Input() LIVESTREAM_URL;
  @Input() dn;
  @Input() cam;

  // access the declared DOM element; expose all methods and properties
  @ViewChild('videoPlayer') videoElementRef!: ElementRef;
  @ViewChild('videoContainer') videoContainerRef!: ElementRef;

  // declare and inherit the HTML video methods and its properties
  videoElement!: HTMLVideoElement;
  hlsTryLoadTimer;
  hls;
  loading = false;



  playing = false;

  config = {
    manifestLoadingRetryDelay: 100,
    liveDurationInfinity: true,
    backBufferLength: 2,
    maxLiveSyncPlaybackRate: 2,
    xhrSetup: xhr => {
      // headers: { 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('token')).access_token  }
      xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('access_token'));
    }
  }

  constructor(private adminService: AdminService) {

  }

  ngAfterViewInit(): void {
  }


  initHlsPlayback(): void {

    // the element could be either a wrapped DOM element or a nativeElement
    this.videoElement = this.videoElementRef?.nativeElement;

    if (Hls.isSupported()) {
      this.hls = new Hls(this.config);

      this.hls.loadSource(`${this.LIVESTREAM_URL}${this.dn}_${this.cam.channel}_0.m3u8`);

      this.hls.attachMedia(this.videoElement);

      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        this.videoElement.play();
        this.playing = true;
        this.loading = false;
      });


      this.hls.on(Hls.Events.ERROR,  (event, data) => {

        if (data.details === 'bufferStalledError') {
          console.log('bufferStalledError');
        }

        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              // try to recover network error
              if (data.details === 'manifestParsingError' ) {
                console.log(`waiting for stream to start ...`);
              } else {
                console.log('unknown network error encountered, try to recover');
              }
              this.hlsTryLoadTimer = setTimeout(() => this.hlsTryLoad(), 1000);
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              console.log('fatal media error encountered, try to recover');
              this.hls.destroy();
              this.hls.recoverMediaError();
              break;
            default:
              // cannot recover
              console.log('cannot recover');
              this.hls.destroy();
              this.playing = false;
              break;
          }
        }
      });
    }

    else if (this.videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      // alert("Livestream is not currently supported on Apple devices");
    }
  }

  hlsTryLoad(): void {
    this.hls.loadSource(`${this.LIVESTREAM_URL}${this.dn}_${this.cam.channel}_0.m3u8`);
    this.hls.startLoad();
  }


  startStopStream(startStop): void {


    console.log(startStop);

    this.adminService.startStopStream(`${this.dn}_${this.cam.channel}_0`, startStop).subscribe(r => {
    });

    if (startStop){
      this.loading = true;
      this.initHlsPlayback();
    } else {
      this.loading = false;
      this.hls.destroy();
      this.playing = false;
    }
  }


}
